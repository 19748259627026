import React, { useEffect, useState } from 'react';
import './News.scss';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ArticleCard from './ArticleCard/ArticleCard';
import articleService from '../../../_services/article.service';
import RelatedNewsBlock from './RelatedNewsBlock';
import MessageDialog from './MessageDialog/MessageDialog';

const NewsPage = () => {
  const [articles, setArticles] = useState([]);
  const [hadMessage, setHadMessage] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    articleService
      .getAll()
      .then((res) => {
        if (res.isSuccess) {
          const isViewMessage = window.localStorage.getItem('message-view');
          if (res.news.current.length > 0 && !isViewMessage) {
            setHadMessage(res.news.current);
          }
          return setArticles(res.news.all);
        }
        throw Error('Пусто)');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const theme = useTheme();
  const spacing = useMediaQuery(theme.breakpoints.up('sm'));
  const skeletonCards = (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Skeleton height={367} style={{ transform: 'none' }} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Skeleton height={367} style={{ transform: 'none' }} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Skeleton height={367} style={{ transform: 'none' }} />
      </Grid>
    </>
  );

  return (
    <div className="news">
      <RelatedNewsBlock loading={loading} articles={articles} />
      <div className="news__grid">
        <Grid container spacing={spacing ? 5 : undefined} component="section">
          {loading && skeletonCards}
          {!loading
            && articles.map((item) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={item.id}
                className="news__grid-item"
                component="article"
              >
                <ArticleCard
                  date={item.createdDate}
                  title={item.title}
                  content={item.description}
                  image={item.image}
                  urlName={item.name}
                />
              </Grid>
            ))}
        </Grid>
      </div>
      {hadMessage && <MessageDialog content={hadMessage} />}
    </div>
  );
};

export default NewsPage;
