import React from 'react';
import { useDispatch } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ServicesTable from './ServicesTable';
import {
  fetchByServices,
  setError,
  setStatus,
} from '../../../_reducers/serviceReducer';
import fetchStatus from '../../../_constants/fetchStatus';

const Services = () => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getServicesAsync = async (contracts, page, pageSize) => {
    try {
      dispatch(setStatus(fetchStatus.PENDING));
      const pageNumber = page + 1;
      let token;
      if (executeRecaptcha) {
        token = await executeRecaptcha();
      }
      const { Contracts, ClientUnit } = contracts;

      dispatch(
        fetchByServices({
          pageNumber,
          rowsPerPage: pageSize,
          token,
          Contracts,
          ClientUnit,
        })
      );
    } catch (e) {
      dispatch(setError(e.message));
    }
  };

  return <ServicesTable getServices={getServicesAsync} />;
};

export default Services;
