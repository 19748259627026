import { Hidden, TableCell } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderingParameterName } from '../../_reducers/newSortReducer';

import StyledTableHead from '../_components/StyledTableHead';
import StyledTableSortLabel from '../_components/StyledTableSortLabel';
import TableHeadCheckbox from './TableComponents/TableHeadCheckbox';
import RBAC from '../../_helpers/RBAC';
import { Role } from '../../_constants/Roles';

const SortTableHead = () => {
  const { t } = useTranslation();
  const order = useSelector((state) => state.sort.order);
  const dispatch = useDispatch();
  const orderingParameter = useSelector(
    (state) => state.sort.orderingParameterName
  );

  const handleSetOrderingParameterName = (parameter) => () => {
    const isAsc = orderingParameter === parameter && order === 'asc';
    dispatch(
      setOrderingParameterName({ order: isAsc ? 'desc' : 'asc', parameter })
    );
  };

  return (
    <StyledTableHead>
      <TableCell padding="none" variant="head" />
      <RBAC allowedRoles={[Role.Admin, Role.Manager, Role.Nurse, Role.Nurse, Role.Sorter, Role.User]}> 
        <TableCell variant="head" padding="checkbox">
          <TableHeadCheckbox className="" />
        </TableCell>
      </RBAC>
      <TableCell
        sortDirection={orderingParameter === 'FullName' ? order : false}
        className="services-table__th"
      >
        <StyledTableSortLabel
          active={orderingParameter === 'FullName'}
          direction={orderingParameter === 'FullName' ? order : 'asc'}
          onClick={handleSetOrderingParameterName('FullName')}
        >
          {t('Sort.FullName')}
        </StyledTableSortLabel>
      </TableCell>
      <TableCell className="services-table__th" padding="none" variant="head">
        {t('Sort.DateOfBirth')}
      </TableCell>
      <RBAC allowedRoles={[Role.Admin, Role.Manager, Role.Nurse, Role.Nurse, Role.Sorter, Role.User]}>  
        <TableCell
          sortDirection={orderingParameter === 'Barcode' ? order : false}
          className="services-table__th"
          variant="head"
        >
          <StyledTableSortLabel
            active={orderingParameter === 'Barcode'}
            direction={orderingParameter === 'Barcode' ? order : 'asc'}
            onClick={handleSetOrderingParameterName('Barcode')}
          >
            {t('Sort.Barcode')}
          </StyledTableSortLabel>
        </TableCell>
      </RBAC>
      <Hidden smDown>
        <TableCell className="services-table__th" variant="head">
          {t('Sort.Cotract')}
        </TableCell>
        <TableCell className="services-table__th" variant="head">
          {t('Sort.Status')}
        </TableCell>
        <TableCell className="services-table__th" variant="head" />
      </Hidden>
    </StyledTableHead>
  );
};

export default SortTableHead;
