import {
  useMediaQuery,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import articleService from '../../../../_services/article.service';

import CustomOutlinedInput from '../../../Inputs/BaseOutlinedInput/CustomOutlinedInput';
import FormDialog from '../../../_components/FormDialog/FormDialog';
import './NewsMessageDialog.scss';

const MessageDialog = ({ content }) => {
  const [open, setOpen] = useState(true);
  const [scroll] = useState('paper');

  const handleClose = () => {
    setOpen(false);
  };

  const { control, errors, handleSubmit } = useForm({ mode: 'onChange' });

  const [loading, setLoading] = useState(false);

  const [articles, setArticles] = useState(content);
  const [currentArticle, setcurrentArticle] = useState();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    const nonViewedArticle = articles.find((article) => !article.viewed);
    setcurrentArticle(nonViewedArticle);
  }, [articles]);

  const handleCheckViewedArticle = (data) => {
    setLoading(true);

    articleService
      .articleAgrement(currentArticle.id, data.fullName)
      .then(() => {
        const result = articles.map((article) => {
          if (article.id === currentArticle.id) {
            // eslint-disable-next-line no-param-reassign
            article.viewed = true;
          }
          return article;
        });
        setArticles(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (currentArticle) {
    return (
      <FormDialog
        onSubmit={handleSubmit(handleCheckViewedArticle)}
        maxWidth="lg"
        fullWidth
        fullScreen={fullScreen}
        open={open}
        scroll={scroll}
        className="news-message-dialog"
      >
        <DialogTitle className="news-message-dialog__title">
          {currentArticle.title}
        </DialogTitle>
        <DialogContent
          dangerouslySetInnerHTML={{ __html: currentArticle.content }}
        />
        <DialogActions className="news-message-dialog__actions">
          <Controller
            name="fullName"
            control={control}
            defaultValue=""
            rules={{ required: 'Это поле обязательно!' }}
            render={({ value, onChange }) => (
              <CustomOutlinedInput
                label="ФИО"
                value={value}
                onChange={onChange}
                className="news-message-dialog__input"
                errors={errors.fullName}
              />
            )}
          />
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            className="news-message-dialog__btn"
            type="submit"
          >
            {loading ? (
              <CircularProgress
                color="primary"
                style={{ width: 20, height: 20 }}
              />
            ) : (
              'Ознакомлен'
            )}
          </Button>
        </DialogActions>
      </FormDialog>
    );
  }
  return null;
};

MessageDialog.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ).isRequired,
};

export default MessageDialog;
