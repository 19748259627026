import React from 'react';
import PropTypes from 'prop-types';
import InfoPopup from '../../_components/InfoPopup';
import remarkIcon from './img/remark.svg';
import commentIcon from './img/comment.svg';
import descIcon from './img/desc.svg';
import './ServiceInfo.scss';

const ServiceInfo = ({ serviceInfo }) => {
  const {
    results, remark, comment, description
  } = serviceInfo;

  const hasInfo = results || remark || comment || description;

  if (hasInfo) {
    return (
      <InfoPopup
        size="small"
        popoverClass="service-info"
        paperClass="service-info__paper"
      >
        {comment && (
          <div className="service-info__block">
            <div className="service-info__icon">
              <img src={commentIcon} alt="" width="24px" height="24px" />
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: comment }}
              className="service-info__text service-info__text--comment"
            />
          </div>
        )}
        {remark && (
          <div className="service-info__block">
            <div className="service-info__icon">
              <img src={remarkIcon} alt="" width="24px" height="24px" />
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: remark }}
              className="service-info__text service-info__text--remark"
            />
          </div>
        )}

        {(description || results) && (
          <div className="service-info__block">
            <div className="service-info__icon">
              <img src={descIcon} alt="" width="24px" height="24px" />
            </div>
            {description && (
              <p className="service-info__text service-info__text--description">
                {description}
              </p>
            )}
            {results && (
              <p className="service-info__text service-info__text--description">
                {results}
              </p>
            )}
          </div>
        )}
      </InfoPopup>
    );
  }

  return null;
};

ServiceInfo.propTypes = {
  results: PropTypes.string,
  remark: PropTypes.string,
  comment: PropTypes.string,
  description: PropTypes.string,
};

ServiceInfo.defaultProps = {
  results: '',
  remark: '',
  comment: '',
  description: '',
};

export default ServiceInfo;
