import { Grid } from '@material-ui/core';
import React, { useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Contract from '../../_enchancers/Contract';
import { setClientUnit, setContract } from '../../_reducers/contractReducer';
import useClientUnits from '../Contract/useClientUnits';
import useContract from '../Contract/useContract';
import ContractAutocomplete from '../Inputs/ContractAutocomplete';

const ContractFields = (props) => {
  const { t } = useTranslation();
  const { clientUnits: globalClientUnits } = useClientUnits(true);
  const [clientUnits, setClientUnits] = useState(globalClientUnits);

  const { errors, control, setValue } = useFormContext();

  const { contracts, currentContract: globalContract } = useContract(true);
  const dispatch = useDispatch();

  const onContractChange = (value) => {
    if (value) {
      const isMoreThanOneUnit = value?.clientUnits?.length > 1;
      const { contract, clientUnits: currentContractUnits } =
        Contract.getClientUnitsByContract(value, isMoreThanOneUnit);

      if (value?.id !== -1) {
        dispatch(setContract(contract));
      }
      setClientUnits(currentContractUnits);
    } else {
      setClientUnits([]);
    }
    setValue('clientUnit', null);

    return value;
  };

  const onClientUnitChange = (value) => {
    if (globalContract && value?.id !== -1) {
      if (value) {
        const index = globalContract.clientUnits.findIndex(
          (item) => item.id === value.id
        );
        if (index !== -1) {
          dispatch(setClientUnit(value));
        }
      } else {
        dispatch(setClientUnit(null));
      }
    }
    return value;
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={3} className="services-form__grid-item">
        <Controller
          name="contract"
          control={control}
          rules={{ required: t('ValidateErrors.Required') }}
          render={({ onChange, value }) => (
            <ContractAutocomplete
              errors={errors.contract}
              options={contracts}
              limitTags={2}
              filled
              value={value}
              onChange={(newVal) => onChange(onContractChange(newVal))}
              label={t('Input.Contract')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} className="services-form__grid-item">
        <Controller
          name="clientUnit"
          control={control}
          render={({ onChange, value }) => (
            <ContractAutocomplete
              errors={errors.clientUnit}
              options={clientUnits}
              limitTags={2}
              filled
              value={value}
              onChange={(newVal) => onChange(onClientUnitChange(newVal))}
              label={t('Input.Subdivision')}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default ContractFields;
