import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import '../../Dialogs/Dialog.scss';
import { useSelector } from 'react-redux';

import React, { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns/esm';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { Alert, Autocomplete } from '@material-ui/lab';
import useFetchResults from '../../../_helpers/scripts/useFetchResults';
import ApiClient from '../../../ApiClient/ApiClient';
import LazyImg from '../../../_helpers/LazyImg';
import PhoneIcon from '../../Dialogs/img/phone.svg';
import SexIcon from '../../Dialogs/img/sex.svg';
import MailIcon from '../../Dialogs/img/mail.svg';
import LocationIcon from '../../Dialogs/img/location.svg';
import StyledKeyboardDatePicker from '../../Inputs/StyledKeyboardDatePicker';
import phonePattern from '../../../_helpers/phoneNumPattern';
import emailPattern from '../../../_helpers/emailPattern';
import errorService from '../../../_constants/errors';
import fetchStatus from '../../../_constants/fetchStatus';
import '../../Admin/_components/AdminDialog.scss';
import renderClientUnits from '../../../_helpers/scripts/renderClientUnits';
import StyledDialogTitle from '../../Dialogs/StyledDialogTitle';
import genderHelper from '../../../_constants/genderHelper';
import errorHelper from '../../../_constants/errorHelper';
import StyledTextField from '../../Inputs/StyledTextField';
import GetIdentificationNumberTypes from '../IdentificationNumberType/GetIdentificationNumberType';
import StyledTextFieldWithLoading from '../../Inputs/StyledTextFieldWithLoading';
import mobilePhoneHelper from '../../../_helpers/mobilePhoneHelper';
import useContract from '../../Contract/useContract';
import useClientUnits from '../../Contract/useClientUnits';
import useApiContracts from '../../Contract/useApiContracts';

const CreatePatientDialog = ({ open, onClose, savePatient }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const success = status === fetchStatus.FULFILLED;
  const error = status === fetchStatus.REJECTED;

  const { message, setMessage } = useFetchResults();
  const { currentContract } = useContract();
  const { currentClientUnit } = useClientUnits();
  const contracts = useApiContracts();
  const formatedClientUnit = renderClientUnits(currentClientUnit);
  const { enqueueSnackbar } = useSnackbar();

  const fetchByNewPatient = async (data) => {
    let formatedData;

    try {
      const {
        lastName,
        firstName,
        paternalName,
        gender,
        dateOfBirth,
        mobilePhone,
        email,
        address,
        identificationNumberType,
        identificationNumber,
      } = data;
      setStatus(fetchStatus.PENDING);

      formatedData = {
        lastName,
        firstName,
        paternalName,
        gender,
        email,
        address,
        mobilePhone: mobilePhoneHelper.formatPhoneToFetch(mobilePhone),
        dateOfBirth: format(dateOfBirth, 'yyyyMMdd'),
        contractId: contracts.Contracts.toString(),
      };

      if (identificationNumber) {
        formatedData.identificationNumberTypeId = identificationNumberType.id;
        formatedData.identificationNumber = identificationNumber;
      }
    } catch (e) {
      setStatus(fetchStatus.REJECTED);
      setMessage(e.message);
      return false;
    }

    const token = await executeRecaptcha();

    const patientId = await ApiClient.CreatePatient(formatedData, token, contracts)
      .then((res) => {
        if (res) {
          if (res.isSuccess) {
            enqueueSnackbar(t('CreateOrder.PatientCreated'), {
              variant: 'success',
            });
            return res.patientId;
          }
          throw new Error(res.statusMessage);
        }
        throw new Error(errorService.common);
      })
      .catch((e) => {
        setStatus(fetchStatus.REJECTED);
        setMessage(e.message);
        return null;
      });

    if (patientId) {
      await ApiClient.GetPatient(patientId, contracts).then((res) => {
        setStatus(fetchStatus.FULFILLED);
        savePatient(res);
      }).catch((e) => {
        setStatus(fetchStatus.REJECTED);
        setMessage(e.message);
      });
    }
  };

  // eslint-disable-next-line no-return-await
  const onSubmit = async (data) => await fetchByNewPatient(data);

  const {
    control, errors, handleSubmit, formState, setValue, watch
  } = useForm(
    {
      mode: 'onChange',
      defaultValues: {
        lastName: '',
        firstName: '',
        paternalName: '',
        gender: -1,
        dateOfBirth: null,
        mobilePhone: '',
        email: '',
        address: '',
        identificationNumberType: null,
        identificationNumber: '',
      },
    }
  );

  const { isSubmitting, isDirty, dirtyFields } = formState;

  const dateValidation = (value) => {
    // eslint-disable-next-line no-restricted-globals
    if (value instanceof Date && !isNaN(value)) {
      return true;
    }
    return errorService.invalidDate;
  };

  const hasIdentificationNumberType = watch('identificationNumberType');

  useEffect(() => {
    if (
      dirtyFields.identificationNumberType
      || hasIdentificationNumberType === null
    ) {
      setValue('identificationNumber', '', {
        shouldDirty: false,
        shouldValidate: true,
      });
    }
  }, [hasIdentificationNumberType]);

  const setError = (mess) => {
    setMessage(mess);
    setStatus(fetchStatus.REJECTED);
  };

  const options = useSelector((state) => state.identificationNumberType.types);
  const optionsLoading = useSelector(
    (state) => state.identificationNumberType.status === fetchStatus.PENDING
  );

  return (
    <>
      <GetIdentificationNumberTypes setMessage={setError} />
      <Dialog
        className="a-dialog dialog new-patient__dialog"
        open={open}
        scroll="body"
        onClose={onClose}
        PaperComponent="form"
        fullWidth
        fullScreen={fullScreen}
        onSubmit={handleSubmit(onSubmit)}
        PaperProps={{ className: 'a-dialog__paper', noValidate: true }}
      >
        <StyledDialogTitle
          title={t('Dialog.NewPatientTitle')}
          onClose={onClose}
        />
        <div className="new-patient__content">
          <p className="new-patient__sub-title">
            {t('Dialog.NewPatientSubTitle')}
          </p>
          {(error || success) && (
            <Box paddingBottom={2}>
              <Alert severity={success ? 'success' : 'error'}>{message}</Alert>
            </Box>
          )}
          <Grid container className="new-patient__grid" spacing={2}>
            <Grid item xs={6}>
              <StyledTextField
                variant="outlined"
                disabled
                fullWidth
                label={t('Input.Contract')}
                value={currentContract?.name}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                variant="outlined"
                disabled
                fullWidth
                label={t('Input.Subdivision')}
                value={formatedClientUnit}
              />
            </Grid>
          </Grid>
          <div className="services-form">
            <div className="new-patient__divider">
              <h2 className="new-patient__content-title">
                {t('Dialog.Profile')}
              </h2>
              <Divider />
            </div>

            <Grid container className="new-patient__grid" spacing={3}>
              <Grid item xs={12} sm={4} className="new-patient__grid-item">
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: errorHelper.required }}
                  render={({ onChange, value }) => (
                    <StyledTextField
                      fullWidth
                      variant="outlined"
                      required
                      value={value}
                      onChange={onChange}
                      label={t('Input.LastName')}
                      error={Boolean(errors.lastName?.message)}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: errorHelper.required }}
                  render={({ onChange, value }) => (
                    <StyledTextField
                      fullWidth
                      variant="outlined"
                      required
                      value={value}
                      onChange={onChange}
                      label={t('Input.FirstName')}
                      error={Boolean(errors.firstName?.message)}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="paternalName"
                  control={control}
                  render={({ onChange, value }) => (
                    <StyledTextField
                      fullWidth
                      variant="outlined"
                      label={t('Input.MiddleName')}
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors.paternalName?.message)}
                      helperText={errors.paternalName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="gender"
                  control={control}
                  rules={{
                    validate: (value) => (value === -1 ? t('Patient.SelectGender') : true),
                  }}
                  render={({ onChange, value }) => (
                    <StyledTextField
                      select
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.gender?.message)}
                      helperText={errors.gender?.message}
                      value={value}
                      onChange={onChange}
                      label={t('Input.Sex')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LazyImg src={SexIcon} alt="" />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {genderHelper.gendersWithNeutrals.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {t(item.label)}
                        </MenuItem>
                      ))}
                    </StyledTextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  rules={{
                    required: errorHelper.required,
                    validate: dateValidation,
                  }}
                  render={({ onChange, value }) => (
                    <StyledKeyboardDatePicker
                      inputVariant="outlined"
                      label={t('Input.DateOfBirth')}
                      required
                      value={value}
                      onChange={onChange}
                      errors={errors.dateOfBirth}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <div className="new-patient__divider">
              <h2 className="new-patient__content-title">
                {t('Dialog.Contacts')}
              </h2>
              <Divider />
            </div>
            <Grid container className="new-patient__grid" spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="mobilePhone"
                  control={control}
                  rules={{
                    required: errorHelper.required,
                    validate: (value) => (phonePattern.test(value)
                      ? true
                      : t('ValidateErrors.PhonePattern')),
                  }}
                  render={({ onChange, value }) => (
                    <ReactInputMask
                      mask="+375 99 999 99 99"
                      maskChar="_"
                      value={value}
                      onChange={onChange}
                    >
                      {(inputProps) => (
                        <StyledTextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...inputProps}
                          label={t('Input.Phone')}
                          required
                          variant="outlined"
                          fullWidth
                          name="phone"
                          InputProps={{
                            ...inputProps.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src={PhoneIcon}
                                  alt=""
                                  width={24}
                                  height={24}
                                />
                              </InputAdornment>
                            ),
                          }}
                          onChange={onChange}
                          value={value}
                          helperText={errors.mobilePhone?.message}
                          error={Boolean(errors.mobilePhone?.message)}
                        />
                      )}
                    </ReactInputMask>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    validate: (value) => (value
                      ? emailPattern.test(value)
                        ? true
                        : t('ValidateErrors.Email')
                      : true),
                  }}
                  render={({ onChange, value }) => (
                    <StyledTextField
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LazyImg src={MailIcon} loading="lazy" />
                          </InputAdornment>
                        ),
                      }}
                      label="Email"
                      name="Email"
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors.email?.message)}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="address"
                  control={control}
                  render={({ onChange, value }) => (
                    <StyledTextField
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LazyImg src={LocationIcon} loading="lazy" />
                          </InputAdornment>
                        ),
                      }}
                      label={t('Input.Address')}
                      name="location"
                      value={value}
                      onChange={onChange}
                      errors={Boolean(errors.address?.message)}
                      helperText={errors.address?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box py={2} color="greyLight.main">
              <Divider color="inherit" />
            </Box>
            <Box mb={{ xs: 2, sm: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="identificationNumberType"
                    control={control}
                    render={({ onChange, value }) => (
                      <Autocomplete
                        fullWidth
                        value={value}
                        loading={optionsLoading}
                        onChange={(_, newVal) => onChange(newVal)}
                        options={options}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <StyledTextFieldWithLoading
                            {...params}
                            error={Boolean(errors.identificationNumberType)}
                            helperText={
                              errors.identificationNumberType?.message
                            }
                            variant="outlined"
                            label={t('Input.DocumentType')}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="identificationNumber"
                    control={control}
                    rules={{
                      required: hasIdentificationNumberType
                        ? errorHelper.required
                        : undefined,
                    }}
                    render={({ onChange, value }) => (
                      <StyledTextField
                        fullWidth
                        disabled={!hasIdentificationNumberType}
                        CustomTextHelperPosition="absolute"
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.identificationNumber)}
                        helperText={errors.identificationNumber?.message}
                        variant="outlined"
                        label={t('Input.DocumentNo')}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
        <DialogActions>
          {success ? (
            <Button
              onClick={onClose}
              type="submit"
              className="dialog__submit dialog__btn"
            >
              {t('Common.Ok')}
            </Button>
          ) : (
            <>
              <Button
                onClick={onClose}
                disabled={isSubmitting}
                className="dialog__cancel dialog__btn"
              >
                {t('Common.Cancel')}
              </Button>
              <Button
                disabled={!isDirty || isSubmitting}
                type="submit"
                className="dialog__submit dialog__btn"
              >
                {t('Common.Save')}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

CreatePatientDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  savePatient: PropTypes.func.isRequired,
};

export default CreatePatientDialog;
