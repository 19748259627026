import {
  Hidden,
  Table,
  TableContainer,
  TablePagination,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import StyledTableCell from '../../_components/StyledTableCell';
import StyledTableHead from '../../_components/StyledTableHead';
import StyledTableBody from '../../_components/StyledTableBody';
import StyledTableSortLabel from '../../_components/StyledTableSortLabel';
import PreOrderRow from './PreOrderRow';
import SortFunc from '../../../_helpers/sort-funtions';
import '../../Admin/StyledTable.scss';
import tablePaginationHelper from '../../../_helpers/tablePaginationHelper';

const PreOrderTable = ({
  rows,
  totalCount,
  setPageSize,
  setPageNumber,
  pageNumber,
  pageSize,
}) => {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');

  const handleChangePageSize = (e) => {
    setPageSize(parseInt(e.target.value, 10));
  };

  const handleChangePage = (_, page) => {
    setPageNumber(page);
  };

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const showSm = useMediaQuery('(min-width:768px) ');

  return (
    <>
      <TableContainer>
        <Table className="styled-table" size={desktop ? 'medium' : 'small'}>
          <StyledTableHead>
            <StyledTableCell width={40}>&nbsp;</StyledTableCell>
            <StyledTableCell
              sortDirection={orderBy === 'lastName' ? order : false}
            >
              <StyledTableSortLabel
                hideSortIcon
                active={orderBy === 'lastName'}
                direction={orderBy === 'lastName' ? order : 'asc'}
                onClick={handleRequestSort('lastName')}
              >
                {t('Input.FullName')}
              </StyledTableSortLabel>
            </StyledTableCell>
            <StyledTableCell
              sortDirection={orderBy === 'dateOfBirth' ? order : false}
            >
              <StyledTableSortLabel
                hideSortIcon
                active={orderBy === 'dateOfBirth'}
                direction={orderBy === 'dateOfBirth' ? order : 'asc'}
                onClick={handleRequestSort('dateOfBirth')}
              >
                {t('Input.DateOfBirth')}
              </StyledTableSortLabel>
            </StyledTableCell>
            <StyledTableCell
              sortDirection={orderBy === 'barcode' ? order : false}
            >
              <StyledTableSortLabel
                hideSortIcon
                active={orderBy === 'barcode'}
                direction={orderBy === 'barcode' ? order : 'asc'}
                onClick={handleRequestSort('barcode')}
              >
                {t('Input.Barcode')}
              </StyledTableSortLabel>
            </StyledTableCell>
            {showSm && (
              <StyledTableCell
                sortDirection={orderBy === 'paymentChannelId' ? order : false}
              >
                <StyledTableSortLabel
                  hideSortIcon
                  active={orderBy === 'paymentChannelId'}
                  direction={orderBy === 'paymentChannelId' ? order : 'asc'}
                  onClick={handleRequestSort('paymentChannelId')}
                >
                  {t('Sort.Payment')}
                </StyledTableSortLabel>
              </StyledTableCell>
            )}
            <Hidden smDown>
              <StyledTableCell
                sortDirection={orderBy === 'created' ? order : false}
              >
                <StyledTableSortLabel
                  hideSortIcon
                  active={orderBy === 'created'}
                  direction={orderBy === 'created' ? order : 'asc'}
                  onClick={handleRequestSort('created')}
                >
                  {t('Result.OrderDate')}
                </StyledTableSortLabel>
              </StyledTableCell>
            </Hidden>
            <StyledTableCell align="right" />
          </StyledTableHead>
          <StyledTableBody>
            {rows.length > 0
              ? SortFunc.stableSort(
                rows,
                SortFunc.getComparator(order, orderBy)
              ).map((row, index) => (
                <PreOrderRow
                  isLastRow={pageSize === index + 1}
                  row={row}
                  key={row.preOrderId}
                />
              ))
              : null}
          </StyledTableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="services-table__pagination"
        rowsPerPageOptions={tablePaginationHelper.rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={pageSize}
        page={pageNumber}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangePageSize}
      />
    </>
  );
};

export default PreOrderTable;
