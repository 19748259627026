/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import logo from './img/logo_h.svg';
import date2 from './img/date2.svg';
import date1 from './img/date1.svg';
import './Direction.scss';
import genderHelper from '../../_constants/genderHelper';
import getPatientFullName from '../../_helpers/scripts/getPatientFullName';
import { Box } from '@material-ui/core';

// TODO: PropTypes

class DirectionToPrint extends Component {
  render() {
    const { data } = this.props;
    if (data) {
      const direction = data.directions[0];
      return (
        <div className="direction" width="100%">
          <div>
            <div className="dir__header">
              <img alt="Helix" src={logo} width="123px" height="38px" />
              {direction.barcode && (
                <div>
                  <span className="dir__header-span">
                    <img
                      alt=""
                      src={date2}
                      width="32px"
                      height="32px"
                      className="dir__header-icon"
                    />
                    <h2 className="dir__header-title">{direction.barcode}</h2>
                  </span>
                  <h4 className="dir__header-subtitle">
                    <Translation>{(t) => t('Direction.Barcode')}</Translation>
                  </h4>
                </div>
              )}
              {direction.createdDate && (
                <div>
                  <span className="dir__header-span">
                    <img
                      alt=""
                      src={date1}
                      width="32px"
                      height="32px"
                      className="dir__header-icon"
                    />
                    <h2 className="dir__header-title">
                      {direction.createdDate}
                    </h2>
                  </span>
                  <h4 className="dir__header-subtitle">
                    <Translation>
                      {(t) => t('Direction.createdDate')}
                    </Translation>
                  </h4>
                </div>
              )}
            </div>

            <div className="dir__about">
              <div className="dir__info">
                <h3 className="dir__info-title">
                  {getPatientFullName(direction.patient)}
                </h3>
                <table
                  border="0"
                  cellPadding="0"
                  cellSpacing="0"
                  className="info-dir"
                >
                  <tr>
                    <td className="info__cell info__cell--padding">
                      <Translation>{(t) => t('Input.Gender')}</Translation>
                      :
                    </td>
                    <td className="info__cell">
                      {genderHelper.GetGenderLocalizedLabel(
                        direction.patient.gender
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="info__cell info__cell--padding">Email:</td>
                    <td className="info__cell">
                      {direction.patient.email !== null
                        && direction.patient.email}
                    </td>
                  </tr>
                  <tr>
                    <td className="info__cell info__cell--padding">
                      <Translation>{(t) => t('Input.Phone')}</Translation>
                      :
                    </td>
                    <td className="info__cell">{direction.patient.phone}</td>
                  </tr>
                  <tr>
                    <td className="info__cell info__cell--padding">
                      <Translation>
                        {(t) => t('Direction.Contract')}
                      </Translation>
                      :
                    </td>
                    <td className="info__cell">{direction.contract.name}</td>
                  </tr>
                </table>
              </div>
              {
                // TODO: возвращать CodeForWebFFSOrder с бекенда
                /* <div className="dir__code code__dir">
         <h3 className="code__title">xfNDPNGR</h3>
         <p className="code__subtitle">
           код для получения результатов через сайт
         </p>
       </div> */
              }
            </div>
            <div className="dir__table dir-table">
              <table border="0" cellPadding="0" cellSpacing="0" width="100%">
                <thead>
                  <tr className="dir-table__head">
                    <th className="dir-table__cell dir-table__cell_head">
                      <Translation>
                        {(t) => t('Direction.NameOfResearch')}
                      </Translation>
                    </th>
                    <th className="dir-table__cell dir-table__cell_head">
                      <Translation>{(t) => t('Direction.Price')}</Translation>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {direction.services
                    && direction.services.map((service, index) => (
                      <tr>
                        <td className="dir-table__cell">
                          {`${service.code} — ${service.name}`}
                        </td>
                        <td className="dir-table__cell">{service.price}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <Box mb={2} px={2}>
              <h4 className="dir__title-m-b acc-direction__bio-title">
                <Translation>{(t) => t('Direction.Biomaterial')}</Translation>
              </h4>
            </Box>
            {direction.tubes?.length > 0 && (
              <div className="dir__about acc-direction__bio">
                <table
                  border="0"
                  cellPadding="0"
                  cellSpacing="0"
                  className="dir-table"
                >
                  <thead>
                    <tr className="dir-table__head_small">
                      <th width="50%" className="dir-table__cell_head">
                        <Translation>{(t) => t('Direction.Name')}</Translation>
                      </th>
                      <th width="50%" className="dir-table__cell_head">
                        <Translation>{(t) => t('Direction.count')}</Translation>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {direction.tubes.map((tube) => (
                      <tr className="dir-table__row_bio">
                        <td className="dir-table__cell">{tube.code}</td>
                        <td className="dir-table__cell">{tube.count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="dir__order-info">
              <div className="dir__time">
                <div className="dir__time-border" />
                <span className="dir__time-info dir__working-days">
                  <h4 className="dir__time-title dir__order-info-title">
                    <Translation ns="Direction">
                      {(t) => t('dayWithCount', {
                        count: direction.workingDays,
                      })}
                    </Translation>
                  </h4>
                  <h6 className="dir__order-info-subtitle">
                    <Translation>{(t) => t('Direction.text')}</Translation>
                  </h6>
                </span>
              </div>
              <div className="dir__price">
                <div className="dir__time-border" />
                <span className="dir__time-info">
                  <h4 className="dir__title">{direction.sumPrice}</h4>
                  <h6 className="dir__order-info-subtitle">
                    <Translation>
                      {(t) => t('Direction.totalPrice')}
                    </Translation>
                  </h6>
                </span>
              </div>
            </div>
            <div className="b-dir__sign">
              <div>
                <div className="dir__sign" />
                <p className="dir__order-info-subtitle dir__sign-subtitle">
                  <Translation>
                    {(t) => t('Direction.CustomerSignature')}
                  </Translation>
                </p>
              </div>
              <div>
                <div className="dir__sign" />
                <p className="dir__order-info-subtitle dir__sign-subtitle">
                  <Translation>
                    {(t) => t('Direction.AdminSignature')}
                  </Translation>
                </p>
              </div>
            </div>
            <div className="dir__card">
              <p className="dir__card-about">
                <Translation>{(t) => t('Direction.Сonditions')}</Translation>
              </p>
              <div className="dir__card-sign" />
              <p className="dir__order-info-subtitle dir__sign-subtitle">
                <Translation>{(t) => t('Direction.Сonditions_1')}</Translation>
              </p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default DirectionToPrint;
