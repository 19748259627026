import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import {
  Button,
  TableCell,
  TableRow,
  Collapse,
  Box,
  Typography,
} from '@material-ui/core';
import NestedTable from './NestedTable/NestedTable';
import PrintDirectionButton from './ServicesDialog/PrintDirectionButton';
import DeleteOrderButton from './ServicesDialog/DeleteOrderButton';
import EditOrder from './ServicesDialog/EditOrder';
import FormatStatusId from '../../_helpers/scripts/FormatStatusId';
import TableCheckbox from './TableComponents/TableCheckbox';
import RestoreOrderButton from './ServicesDialog/RestoreOrderButton';
import { sortState } from '../../_constants/OrderStatusConst';
import SortState from './SortState';
import PrintBarcodeButton from './ServicesDialog/PrintBarcodeButton';
import getPatientFullName from '../../_helpers/scripts/getPatientFullName';
import RBAC from '../../_helpers/RBAC';
import { Role } from '../../_constants/Roles';

const ArrowsButton = () => (
  <div className="services-table__collapse-icons">
    <ExpandLess />
    <ExpandMore />
  </div>
);

const Row = ({
  row, isItemSelected, labelId, setSelected
}) => {
  const [open, setOpen] = useState();
  const tableCellRef = useRef('cell');
  const [hidden, setHidden] = useState(false);

  return (
    <>
      <TableRow
        hover
        hidden={hidden}
        className={clsx('services-table__row--first-row services-table__row', {
          filled: open,
        })}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.barcode}
        selected={isItemSelected}
      >
        <TableCell
          rowSpan={2}
          ref={tableCellRef}
          style={{ height: open ? tableCellRef.current.scrollHeight : 49 }}
          className="services-table__cell services-table__collapse-btn"
        >
          <Button
            type="button"
            className="services-table__collapse-btn"
            onClick={() => setOpen(!open)}
            style={{ height: '100%' }}
          >
            <ArrowsButton />
          </Button>
        </TableCell>
        <RBAC allowedRoles={[Role.Admin, Role.Manager, Role.Nurse, Role.Nurse, Role.Sorter, Role.User]}>
          <TableCheckbox
            className="services-table__cell"
            setSelected={setSelected}
            isItemSelected={isItemSelected}
            labelId={labelId}
            barcode={row.barcode}
          />
        </RBAC>
        <TableCell scope="row" className="services-table__cell">
          {getPatientFullName(row.patient)}
        </TableCell>
        <TableCell className="services-table__cell">
          {row.patient.dateOfBirth}
        </TableCell>
        <RBAC allowedRoles={[Role.Admin, Role.Manager, Role.Nurse, Role.Nurse, Role.Sorter, Role.User]}>
          <TableCell className="services-table__cell">
            <Box display="flex" alignItems="center" gridGap="8px">
              <Typography component="span">{row.barcode}</Typography>
              <SortState
                allowedState={[
                  sortState.NEW,
                  sortState.CANCELLED,
                  sortState.IMPORTED,
                  sortState.SENT,
                ]}
              >
                <PrintBarcodeButton barcode={row.barcode} />
              </SortState>
            </Box>
          </TableCell>
        </RBAC>
        <TableCell className="services-table__cell">
          {row.contractName}
        </TableCell>
        <TableCell className="services-table__cell">
          {FormatStatusId(row.sortingState)}
        </TableCell>
        <TableCell align="right" className="services-table__cell">
          <Box display="flex" justifyContent="end" gridGap={4}>
            <RBAC allowedRoles={[Role.Admin, Role.Manager, Role.Nurse, Role.Nurse, Role.Sorter, Role.User]}>
              <SortState allowedState={[sortState.NEW]}>
                <DeleteOrderButton hideRow={setHidden} barcode={row.barcode} />
              </SortState>
            </RBAC>
            <SortState allowedState={[sortState.CANCELLED]}>
              <RestoreOrderButton hideRow={setHidden} barcode={row.barcode} />
            </SortState>
            <RBAC allowedRoles={[Role.Admin, Role.Manager, Role.Nurse, Role.Nurse, Role.Sorter, Role.User]}>
              <SortState allowedState={[sortState.NEW]}>
                <EditOrder barcode={row.barcode} />
              </SortState>
            </RBAC>
            <RBAC allowedRoles={[Role.Admin, Role.Manager, Role.Nurse, Role.Nurse, Role.Sorter, Role.User]}>  
              <PrintDirectionButton barcode={row.barcode} />
            </RBAC>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow
        hidden={hidden}
        selected={isItemSelected}
        className={clsx('services-table__row--second-row services-table__row', {
          filled: open,
        })}
      >
        <TableCell colSpan={6} className="services-table__cell">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <NestedTable services={row.services} barcode={row.barcode} />
          </Collapse>
        </TableCell>
        <TableCell colSpan={2} className="services-table__cell" />
      </TableRow>
    </>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    orderId: PropTypes.number,
    barcode: PropTypes.number,
    patient: {
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      gender: PropTypes.number,
      dateOfBirth: PropTypes.string,
      email: PropTypes.string,
    },
    sortingState: PropTypes.number,
    contract: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    services: PropTypes.arrayOf(
      PropTypes.shape({
        serviceId: PropTypes.number,
        serviceCode: PropTypes.string,
        serviceName: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default Row;
