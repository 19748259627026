import { TableBody, withStyles } from '@material-ui/core';

const StyledTableBody = withStyles((theme) => ({
  root: {
    '& > .MuiTableRow-root:last-child > .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
}))(TableBody);

export default StyledTableBody;
