import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  barcode: null
};

const directionReducer = createSlice({
  name: 'direction',
  initialState,
  reducers: {
    setBarcode(state, action) {
      state.barcode = action.payload;
    }
  }
});

export const { setBarcode } = directionReducer.actions;
export default directionReducer.reducer;
