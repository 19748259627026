import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PatientPage from './Patient/PatientPage';
import OrderStepper from './Stepper/OrderStepper';
import './OrderCreate.scss';
import '../Results/Results.scss';
import Ordering from './Ordering/Ordering';
import Services from './Services/Services';
import EditOrderService from './Services/EditOrderService';
import CreateOrderRouteService from './CreateOrderRouteService';
import PreOrderService from './Services/PreOrderService';
import { setActiveStep } from '../../_reducers/basketReducer';

const CreateOrderPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isEditOrder = useSelector((state) => state.basket.editOrder);
  const barcode = useSelector((state) => state.basket.order?.barcode);
  const activeStep = useSelector((state) => state.basket.activeStep || 0);

  const handleNext = () => dispatch(setActiveStep(activeStep + 1));

  const steps = useMemo(
    () => [
      {
        label: 'Patient',
        component: PatientPage,
      },
      {
        label: 'Services',
        component: Services,
      },
      {
        label: 'Checkout',
        component: Ordering,
      },
    ],
    []
  );

  const getPage = () => {
    const ActivePage = steps[activeStep].component;
    return <ActivePage handleNext={handleNext} />;
  };

  const handleStep = (step) => dispatch(setActiveStep(step));

  return (
    <>
      <EditOrderService setStep={handleStep} />
      <PreOrderService setStep={handleStep} />
      <CreateOrderRouteService />
      <div id="order-create" className="order">
        <div className="order__nav header">
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box
              component="header"
              marginLeft={{ xs: 6, sm: 0 }}
              className="order-create__header"
            >
              {isEditOrder ? (
                <>
                  <Typography className="header__title">
                    {t('CreateOrder.Edit')}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {barcode}
                  </Typography>
                </>
              ) : (
                <h1 className="header__title">{t('Menu.CreateOrder')}</h1>
              )}
            </Box>
            <OrderStepper
              steps={steps}
              setStep={handleStep}
              activeStep={activeStep}
            />
          </Box>
        </div>
        <div className="order__content">{getPage()}</div>
      </div>
    </>
  );
};

export default CreateOrderPage;
