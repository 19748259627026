import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import './Registration.scss';
import '../Login/Login.scss';
import { Container, Grid } from '@material-ui/core';

import Logo from './img/logo.svg';
import ImgLog from './img/img_log.svg';
import { ChangeLanguageButton } from '../i18n/ChangeLanguageDialog';
import { matchedRoute } from '../../_helpers/func';
import { AppRoute } from '../../const/app-routes';

import LazyImg from '../../_helpers/LazyImg';

import RegisterForm from './RegisterForm';

export default function Registration() {
  const { t, i18n } = useTranslation();
  return (
    <div className="login register">
      <Container className="login__container">
        <Grid container>
          <Grid xs={6}>
            <div className="logo-container">
              <NavLink to={matchedRoute(i18n.language, AppRoute.Login)}>
                <LazyImg
                  className="login__img-logo"
                  src={Logo}
                  width="160px"
                  height="70px"
                />
              </NavLink>
            </div>
          </Grid>
          <Grid xs={6} className="login__lang-btn">
            <ChangeLanguageButton />
          </Grid>
          <Grid xs={0} md={6} className="login__img-container">
            <div>
              <img
                className="login__img-fluid"
                src={ImgLog}
                alt=""
                loading="lazy"
              />
            </div>
          </Grid>
          <Grid xs={12} md={6}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className="login__wrapper">
                <h1 className="login__form-title">{t('Register.Title')}</h1>
                <h2 className="login__form-sub-title register__subtitle">
                  {t('Register.Subtitle')}
                </h2>
                <RegisterForm />
              </div>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
