import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userRoles: null,
  user: null,
  Name: '',
};

const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRole(state, action) {
      state.userRoles = action.payload;
    },
    setUser(state, action) {
      const { userRole, email } = action.payload;
      state.user = action.payload;
      state.Name = email;
      state.userRoles = userRole;
    },
    setUserName(state, action) {
      state.Name = action.payload;
    },
  },
});

export const {
  setUser, setUserName, setRole
} = userReducer.actions;
export default userReducer.reducer;
