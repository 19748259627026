import {
  Checkbox,
  Collapse,
  Hidden,
  Box,
  IconButton,
  TableCell,
  TableRow,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import './DiscountTable.scss';
import PropTypes from 'prop-types';
import CustomHidden from './CustomHidden';
import DiscountSwitch from '../_helpers/DiscountSwitch';
import EditCard from '../Dialog/EditCard';
import DiscountRBAC from './DicountRBAC';
import NestedRow from './NestedRow';
import localizeTimePeriod from '../../../../_helpers/scripts/localizeTimePeriod';
import DiscountCustomer from './DiscountCustomer';

const Row = ({ row, isItemSelected, SelectItem }) => {
  const [open, setOpen] = useState(false);

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  const theme = useTheme();
  const isCollapsed = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow
        className={clsx('styled-table__row', {
          'styled-table__row--clickable': isCollapsed,
        })}
        selected={isItemSelected}
        onClick={isCollapsed ? handleOpen : undefined}
        hover={isCollapsed ? true : undefined}
      >
        <Hidden lgUp>
          <TableCell
            padding="checkbox"
            className="styled-table__cell discount-table__cell"
          >
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </Hidden>
        <DiscountRBAC>
          <TableCell
            padding="checkbox"
            className="styled-table__cell discount-table__cell"
            onClick={handleStopPropagation}
          >
            <Checkbox
              checked={isItemSelected}
              onClick={(e) => SelectItem(e, row.id)}
              color="primary"
            />
          </TableCell>
        </DiscountRBAC>
        <CustomHidden match="cardNumber">
          <TableCell className="styled-table__cell discount-table__cell">
            {row.cardNumber}
          </TableCell>
        </CustomHidden>
        <CustomHidden match="discountType">
          <TableCell className="styled-table__cell discount-table__cell">
            {row.discountType?.value}
          </TableCell>
        </CustomHidden>
        <CustomHidden match="contract">
          <TableCell className="styled-table__cell discount-table__cell">
            {row.contract.name}
          </TableCell>
        </CustomHidden>
        <CustomHidden match="customer">
          <TableCell className="styled-table__cell discount-table__cell">
            <DiscountCustomer />
          </TableCell>
        </CustomHidden>
        <CustomHidden match="startValidity">
          <TableCell className="styled-table__cell discount-table__cell">
            {`${row.startValidity} - ${row.endValidity}`}
          </TableCell>
        </CustomHidden>
        <CustomHidden match="possibleNumberOfUses">
          <TableCell className="styled-table__cell discount-table__cell">
            {`${localizeTimePeriod(row.periodTypeOfUses.id)} - ${
              row.possibleNumberOfUses
            }`}
          </TableCell>
        </CustomHidden>
        <CustomHidden match="totalNumberOfUses">
          <TableCell className="styled-table__cell discount-table__cell">
            {row.realNumberOfUses}
          </TableCell>
        </CustomHidden>
        <CustomHidden match="isActive">
          <TableCell
            padding="checkbox"
            className="styled-table__cell discount-table__cell"
          >
            <DiscountSwitch value={row.isActive} id={[row.id]} />
          </TableCell>
        </CustomHidden>
        <TableCell className="styled-table__cell styled-table__cell_icons discount-table__cell">
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            onClick={handleStopPropagation}
          >
            <EditCard data={row} />
          </Box>
        </TableCell>
      </TableRow>
      <Hidden lgUp>
        <TableRow className="styled-table__row">
          <TableCell style={{ padding: 0 }} colSpan={10}>
            <Collapse in={open} unmountOnExit>
              <NestedRow row={row} />
            </Collapse>
          </TableCell>
        </TableRow>
      </Hidden>
    </>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    cardNumber: PropTypes.string,
    customer: PropTypes.shape({
      id: PropTypes.number,
      fullName: PropTypes.string,
      birthday: PropTypes.string,
    }),
    contract: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    discountType: PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.number,
    }),
    startValidity: PropTypes.string,
    endValidity: PropTypes.string,
    isActive: PropTypes.bool,
    possibleNumberOfUses: PropTypes.number,
    realNumberOfUses: PropTypes.number,
    periodTypeOfUses: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  SelectItem: PropTypes.func.isRequired,
};

export default Row;
