import React, { useState } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import ApiClient from '../../../ApiClient/ApiClient';

import LazyImg from '../../../_helpers/LazyImg';

import { ReactComponent as ExcelIcon } from '../Calculator/img/excel.svg';
import { ReactComponent as CloseIcon } from '../Calculator/img/delete.svg';
import ExcelBigIcon from '../Calculator/img/excel_big.svg';

import '../ServiceExtension/Directory.scss';
import '../Calculator/Fileform.scss';
import '../../Dialogs/Dialog.scss';

import '../Calculator/Desc.scss';

import useDialog from '../../../_helpers/scripts/useDialog';
import useFetchResults from '../../../_helpers/scripts/useFetchResults';
import AlertDialog from '../../Dialogs/AlertDialog';
import bytesToMegaBytes from '../../../_helpers/scripts/bytesToMegaBytes';
import SubmitButton from '../../Login/SubmitButton';

const MedicalCenters = () => {
  const {
    loading,
    setLoading,
    error,
    setError,
    message,
    setMessage,
    success,
    setSuccess,
  } = useFetchResults();
  const { open, handleClose, handleOpen } = useDialog();
  const { t } = useTranslation('Admin');

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [file, setFile] = useState(null);

  const handleDeleteFile = () => {
    setFile(null);
  };

  const fetchByMedicalCenters = (fileToPayload) => async () => {
    if (fileToPayload) {
      setLoading(true);
      const formData = new window.FormData();
      formData.append('formFile', fileToPayload);

      const token = await executeRecaptcha();

      await ApiClient.SyncMedCenters(formData, token)
        .then((res) => {
          if (res) {
            setMessage(res.statusMessage);
            if (res.isSuccess) {
              setSuccess(true);
            } else {
              setError(true);
            }
          }
        })
        .catch((e) => {
          setError(true);
          setMessage(e.message);
        });
      setLoading(false);
    } else {
      setError(true);
      setMessage(t('Alert.FileLoadError'));
    }
    handleOpen();
  };

  const handleUploadFile = (files) => {
    if (success) {
      setSuccess(false);
    }
    if (error) {
      setError(false);
    }
    if (files[0].size > 15 * 10 ** 6) {
      setMessage(t('Alert.FileSizeError'));
      setError(true);
      handleOpen();
      return;
    }
    setFile(files[0]);
  };

  const formButton = (
    <>
      <label htmlFor="upload-calc-file-input">
        <Button
          component="span"
          startIcon={<ExcelIcon />}
          variant="contained"
          color="primary"
          className="file-form__btn"
        >
          {file && success ? t('Text.NewFile') : t('Text.File')}
        </Button>
      </label>
      <input
        disabled={loading}
        onChange={(e) => {
          handleUploadFile([...e.target.files]);
        }}
        type="file"
        accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        className="file-form__form"
        id="upload-calc-file-input"
      />
    </>
  );

  const selectFile = (
    <>
      <h4 className="file-form__label file-form__mb">{t('Text.MedText')}</h4>
      {formButton}
      <h5 className="file-form__label-sm">{t('Text.CalcText_2')}</h5>
    </>
  );

  const fileIsSelected = file && (
    <>
      <div className="file-form__file">
        <span className="file-form__close-btn-container">
          <IconButton
            className="file-form__close-btn"
            title={t('Control.Delete')}
            onClick={handleDeleteFile}
          >
            <CloseIcon />
          </IconButton>
          <div className="file-form__img-container">
            <LazyImg
              src={ExcelBigIcon}
              width="88px"
              height="88px"
              alt={t('Control.Delete')}
            />
          </div>
        </span>
        <span className="file-form__list">
          <dl className="desc file-form__list-item">
            <dt className="desc__title">{t('Text.Name')}</dt>
            <dd className="desc__description">{file.name}</dd>
          </dl>
          <dl className="desc file-form__list-item">
            <dt className="desc__title">{t('Text.Size')}</dt>
            <dd className="desc__description">
              {file && `${bytesToMegaBytes(file.size)} Mb`}
            </dd>
          </dl>
          <dl className="desc file-form__list-item">
            <dt className="desc__title">{t('Text.UploadDate')}</dt>
            <dd className="desc__description">
              {file && format(file.lastModifiedDate, 'dd.MM.yyyy')}
            </dd>
          </dl>
        </span>
      </div>
      <span className="file-form__btn-md">
        {success ? (
          formButton
        ) : (
          <SubmitButton
            onClick={fetchByMedicalCenters(file)}
            loading={loading}
            className="file-form__mb-sm"
          >
            {t('Button.Upload')}
          </SubmitButton>
        )}

        <h5 className="file-form__label-sm">{t('Text.CalcText_2')}</h5>
      </span>
    </>
  );

  return (
    <div className="file-form">
      <div className="file-form__container">
        {file ? fileIsSelected : selectFile}
      </div>
      <AlertDialog
        open={open}
        onClose={handleClose}
        error={error}
        success={success}
        message={message}
      />
    </div>
  );
};
export default MedicalCenters;
