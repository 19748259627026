const replaceItems = (value, items, id) => {
  let newPrefixes = items.slice();

  let currentItems = items.filter((prefix) => id.indexOf(prefix.id) !== -1);

  currentItems = currentItems.map((item) => ({
    ...item,
    isActive: value,
  }));

  currentItems.forEach((currentItem) => {
    const index = items.findIndex((prefix) => prefix.id === currentItem.id);

    newPrefixes = [
      ...newPrefixes.slice(0, index),
      currentItem,
      ...newPrefixes.slice(index + 1),
    ];
  });
  return newPrefixes;
};

export default replaceItems;
