import React, { useEffect, useState } from 'react';
import {
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from './img/search.svg';
import { ReactComponent as CloseIcon } from './img/close.svg';
import useDebounce from '../../_helpers/scripts/useDebounce';
import fetchStatus from '../../_constants/fetchStatus';

const FetchSearchInput = ({
  label,
  className,
  fetch,
  disabled,
  onClear,
  defaultValue,
  getInputValueCallback,
  name,
}) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const loading = status === fetchStatus.PENDING;
  const error = status === fetchStatus.REJECTED;

  const [value, setValue] = useState(defaultValue);
  const debouncedSearchValue = useDebounce(value, 400);

  const onValueChanged = (e) => {
    setValue(e.currentTarget.value);
  };

  const clearValue = () => {
    setValue('');
    onClear();
  };

  const SearchAsync = async () => {
    try {
      setStatus(fetchStatus.PENDING);
      await fetch(debouncedSearchValue);
      getInputValueCallback(debouncedSearchValue);
      setStatus(fetchStatus.FULFILLED);
    } catch (e) {
      setMessage(e.message);
      setStatus(fetchStatus.REJECTED);
    }
  };

  useEffect(() => {
    if (debouncedSearchValue && debouncedSearchValue.length > 2) {
      SearchAsync();
    }
    if (debouncedSearchValue === '' && defaultValue !== debouncedSearchValue) {
      onClear();
    }
  }, [debouncedSearchValue]);

  const isDefault = !value && !loading;
  const hasClearIcon = !disabled && !loading && !isDefault;

  const helperText = value?.length !== 0 && value?.length < 3 ? t('Search.Warning') : '';

  return (
    <>
      <TextField
        helperText={message || helperText}
        label={label}
        name={name}
        error={error}
        disabled={disabled}
        value={value}
        onChange={onValueChanged}
        className={clsx('filled-input', className)}
        fullWidth
        variant="filled"
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <>
              {isDefault ? (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ) : null}
              {loading ? (
                <InputAdornment position="end">
                  <CircularProgress color="primary" size={20} />
                </InputAdornment>
              ) : null}
              {hasClearIcon ? (
                <InputAdornment position="end">
                  <IconButton color="primary" onClick={clearValue} size="small">
                    <CloseIcon width="20px" height="20px" />
                  </IconButton>
                </InputAdornment>
              ) : null}
            </>
          ),
        }}
      />
    </>
  );
};

FetchSearchInput.propTypes = {
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  onClear: PropTypes.func,
  getInputValueCallback: PropTypes.func,
};

FetchSearchInput.defaultProps = {
  onClear: () => {},
  defaultValue: '',
  className: '',
  getInputValueCallback: () => {},
};

export default FetchSearchInput;
