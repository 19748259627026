import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useDebounce from '../../_helpers/scripts/useDebounce';

const InputWithDebounce = ({
  component: Component,
  value,
  onChange,
  errors,
  isTargetNeed,
  validate,
  onClear,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value);

  const debouncedSearchValue = useDebounce(inputValue, 200);
  const [error, setError] = useState('');

  useEffect(() => {
    const isValid = validate(inputValue);
    if (typeof isValid === 'boolean' && isValid) {
      onChange(inputValue);
      setError('');
    } else {
      setError(isValid);
    }
  }, [debouncedSearchValue]);

  const customOnChange = isTargetNeed
    ? (e) => setInputValue(e.target.value)
    : setInputValue;

  const handleClearSearch = () => {
    setInputValue('');
  };

  return (
    <Component
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={inputValue}
      onClear={onClear ? handleClearSearch : undefined}
      onChange={customOnChange}
      error={Boolean(error)}
      helperText={error}
    />
  );
};

InputWithDebounce.propTypes = {
  component: PropTypes.elementType.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
  isTargetNeed: PropTypes.bool,
  validate: PropTypes.func.isRequired,
  onClear: PropTypes.bool,
};

InputWithDebounce.defaultProps = {
  isTargetNeed: false,
  errors: {},
  onClear: false,
};

export default InputWithDebounce;
