import React, { Suspense } from 'react';
import * as locales from '@material-ui/core/locale';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';
import './index.scss';

import { SnackbarProvider } from 'notistack';
import theme from './_helpers/MuiTheme/MuiTheme';

import './ApiClient/Interceptor';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import { AppRoute } from './const/app-routes';
import Fallback from './Components/_components/Fallback';
import CorporateCabinet from './App/CorporateCabinet';
import OrderImport from './App/OrderImport';

function language(lang) {
  switch (lang) {
    case 'ru': {
      return 'ruRU';
    }
    case 'en': {
      return 'default';
    }
    case 'be': {
      return 'ruRU';
    }
    default: {
      return 'default';
    }
  }
}

const App = () => {
  const { i18n } = useTranslation();

  return (
    <ThemeProvider theme={createTheme(theme, locales[language(i18n.language)])}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Suspense fallback={<Fallback />}>
          <ScrollToTop />
          <Switch>
            <Route path={AppRoute.OrderImportRoutes.Main} component={OrderImport} />
            <Route path="*" component={CorporateCabinet} />
          </Switch>
        </Suspense>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
