import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import './AdminDialog.scss';

const AdminDialog = ({
  open,
  onClose,
  onSubmit,
  children,
  className,
  paperClassName,
  ...props
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Dialog
      {...props}
      fullScreen={fullScreen}
      PaperComponent="form"
      fullWidth
      open={open}
      onClose={onClose}
      PaperProps={{ className: clsx('a-dialog__paper', paperClassName) }}
      onSubmit={onSubmit}
      className={clsx('a-dialog', className)}
    >
      {children}
    </Dialog>
  );
};

AdminDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.elementType),
    PropTypes.elementType,
  ]).isRequired,
};

AdminDialog.defaultProps = {
  onSubmit: undefined,
  paperRef: undefined,
};

export default AdminDialog;
