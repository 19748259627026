import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, Redirect } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { AppRoute } from '../../../const/app-routes';
import fetchStatus from '../../../_constants/fetchStatus';
import useLangRoute from '../../../_helpers/scripts/useLangRoute';
import {
  fetchByCalculator,
  setCreatedDate,
} from '../../../_reducers/basketReducer';
import AlertDialog from '../../Dialogs/AlertDialog';
import OrderForm from './OrderForm';
import getHeaderDto from '../../../_helpers/scripts/getHeaderDto';
import ApiClient from '../../../ApiClient/ApiClient';
import createOrderMessage from './createOrderMessage';
import errorHelper from '../../../_constants/errorHelper';
import { setBarcode } from '../../../_reducers/directionReducer';
import formatBarcode from '../../../_helpers/formatBarcode';
import useContract from '../../Contract/useContract';

const CreatePreOrder = () => {
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const langPath = useLangRoute();

  const services = useSelector((state) => state.basket.serviceCodes);
  const currentOrder = useSelector((state) => state.basket.order);
  const orderServiceCodes = currentOrder?.services;
  const patientId = useSelector((state) => state.basket.patient?.id);
  const isPreOrder = useSelector((state) => state.basket.isPreOrder);
  const { currentContract } = useContract();

  const handleClose = () => setStatus(fetchStatus.IDLE);

  const CretePreOrderAsync = async (formData) => {
    let order;
    try {
      const moddifedData = {
        ...formData,
        paymentChannel: currentOrder.paymentChannel,
      };
      const header = getHeaderDto(moddifedData, services);

      order = {
        patient: {
          id: patientId,
        },
        header,
      };
    } catch (e) {
      setMessage(t('CreateOrder.Message.NotValid', { error: e.message }));
      setStatus(fetchStatus.REJECTED);
      return false;
    }

    let token;
    if (executeRecaptcha) {
      token = await executeRecaptcha();
    }

    return ApiClient.CreateOrder(order, token, { Contracts: currentContract.id })
      .then((res) => {
        if (res.isSuccess) {
          dispatch(
            setBarcode(
              formatBarcode(formData.barcode)
            )
          );
          setStatus(fetchStatus.FULFILLED);
          dispatch(setCreatedDate(new Date()));
          return true;
        }
        const errorMessage = t(
          `CreateOrder.Message.${createOrderMessage.get(
            res.clientMessage?.code
          )}`
        );
        throw new Error(errorMessage || res.statusMessage);
      })
      .catch((e) => {
        const validateErrors = errorHelper.getErrors(e);
        if (validateErrors.length > 0) {
          const errorMessage = Object.entries(e.errors)
            .map(([name, value]) => `${name}: ${value.map((i) => i).join(' ')}`)
            .join(';');

          setMessage(errorMessage);
        } else {
          setMessage(e.message);
        }
        setStatus(fetchStatus.REJECTED);
        return false;
      });
  };

  useEffect(() => {
    const SetServiceCodes = async () => {
      let token;
      if (executeRecaptcha) {
        token = await executeRecaptcha();
      }
      dispatch(fetchByCalculator([orderServiceCodes, token, false, true]));
    };

    SetServiceCodes();
  }, []);

  return (
    <>
      <OrderForm onSubmit={CretePreOrderAsync} />
      {status === fetchStatus.FULFILLED && (
        <Redirect
          to={{
            pathname: langPath(AppRoute.CreateOrderSuccess),
          }}
          push
        />
      )}
      <AlertDialog
        open={status === fetchStatus.REJECTED}
        onClose={handleClose}
        message={message}
        error
      />
      <Prompt
        when={isPreOrder && status !== fetchStatus.FULFILLED}
        message={t('CreateOrder.PreOrderLeave')}
      />
    </>
  );
};

export default CreatePreOrder;
