import { Hidden } from '@material-ui/core';
import React from 'react';

const headCellsQueries = new Map();

headCellsQueries.set('cardNumber', 'none');
headCellsQueries.set('discountType', 'none');
headCellsQueries.set('contract', 'sm');
headCellsQueries.set('customer', 'sm');
headCellsQueries.set('startValidity', 'md');
headCellsQueries.set('possibleNumberOfUses', 'md');
headCellsQueries.set('totalNumberOfUses', 'md');
headCellsQueries.set('isActive', 'sm');

const CustomHidden = ({ children, match, type = 'Down' }) => {
  const value = headCellsQueries.get(match);

  if (value !== 'none') {
    const hideProps = { [`${value}${type}`]: true };

    return <Hidden {...hideProps}>{children}</Hidden>;
  }
  return <>{children}</>;
};

export default CustomHidden;
