import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import LangRouter from '../Components/i18n/LangRouter';
import CheckAuth from '../Components/Auth/CheckAuth';

const CorporateCabinet = ({ children }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey="6Lc5BYUaAAAAAI9cvd-cMhEVZ6OGN8CUSmVEZZJa"
    scriptProps={{
      async: true, // optional, default to false,
      defer: true, // optional, default to false
      // appendTo: 'head', // optional, default to "head", can be "head" or "body",
      // nonce: undefined, // optional, default undefined
    }}
  >
    <LangRouter />
    <CheckAuth />
  </GoogleReCaptchaProvider>
);

export default CorporateCabinet;
