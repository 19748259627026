import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import React from 'react';

const CustomChip = ({
  className,
  label,
  onClick,
  selectedIcon,
  onDelete,
  selected,
  value,
}) => {
  const deleteProps = selected ? { onDelete, deleteIcon: selectedIcon } : {};
  return (
    <Chip
      className={clsx(
        'filter__chip',
        selected && 'filter__chip--selected',
        className
      )}
      label={label}
      onClick={() => onClick(value)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...deleteProps}
    />
  );
};
CustomChip.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedIcon: PropTypes.element.isRequired,
  selected: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default CustomChip;
