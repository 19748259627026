import React from 'react';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopLeftRadius: 11,
    borderTopRightRadius: 11,
    borderBottomRightRadius: (props) => (props.squareBottom ? 0 : 11),
    borderBottomLeftRadius: (props) => (props.squareBottom ? 0 : 11),
  },
}));

const StyledFormContainer = ({
  children,
  onSubmit,
  squareBottom,
  ...props
}) => {
  const classes = useStyles({ squareBottom });
  return (
    <Box
      bgcolor="blueLightPlusNew.main"
      component="form"
      onSubmit={onSubmit}
      classes={{ root: classes.root }}
      px={{ xs: 2, sm: 3, lg: 4 }}
      py={{ xs: 2, sm: 4, lg: 5 }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default StyledFormContainer;
