import {
  Grid,
  Backdrop,
  Paper,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './PatientTable.scss';
import FilterDialog from './FilterDialog';
import { ReactComponent as DownloadIcon } from '../img/download.svg';
import { ReactComponent as UploadIcon } from '../img/upload.svg';
import PatientTable from './PatientTable';
import ExcelButtons from './ExcelButtons';
import ApiClient from '../../../ApiClient/ApiClient';
import '../../Results/Results.scss';
import { clearBasket, setPatient } from '../../../_reducers/basketReducer';
import errorService from '../../../_constants/errors';
import {
  setError,
  setRows,
  setSearchValue,
  setStatus,
} from '../../../_reducers/patientReducer';
import isObject from '../../../_helpers/scripts/isObject';
import fetchStatus from '../../../_constants/fetchStatus';
import SearchField from './SearchField';
import PatientContractFields from './PatientContractFields';
import NewPatientButton from '../NewPatient/NewPatientButton';
import useContract from '../../Contract/useContract';
import useClientUnits from '../../Contract/useClientUnits';
import useApiContracts from '../../Contract/useApiContracts';
import '../../../_helpers/styles/SynForm.scss';

const PatientPage = ({ handleNext }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const status = useSelector((state) => state.patient.status);
  const idle = status === fetchStatus.IDLE;
  const loading = status === fetchStatus.PENDING;
  const FULFILLED = status === fetchStatus.FULFILLED;
  const error = status === fetchStatus.REJECTED;

  // TODO: сделать вывод фильтров

  const [chipData, setChipData] = React.useState([
    { key: 0, label: 'Пол' },
    { key: 1, label: 'Иванов' },
    { key: 2, label: '12.04.1983' },
  ]);

  const handleDelete = (chipToDelete) => () => {
    if (chipToDelete === true) {
      return setChipData([]);
    }
    return setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const page = useSelector((state) => state.patient.page);
  const rowsPerPage = useSelector((state) => state.patient.pageSize);
  const searchValue = useSelector((state) => state.patient.searchValue);
  const filterData = useSelector((state) => state.patient.filterData);
  const currentPatient = useSelector((state) => state.basket.patient);
  const hadServices = useSelector(
    (state) => state.basket.addedServices.length > 0
  );

  const handleChangeSearchValue = (value) => {
    dispatch(setSearchValue(value));
  };

  const handleClearSearch = () => {
    dispatch(setSearchValue(''));
  };

  const [message, setMessage] = useState('');

  const [informationConsent, setInformationConsent] = useState(false);

  const { currentContract } = useContract();
  const { currentClientUnit } = useClientUnits();
  const contracts = useApiContracts();
  const isContractSelected = Boolean(currentContract);

  const fetchByPatientSearch = async () => {
    try {
      dispatch(setStatus(fetchStatus.PENDING));

      let token = null;
      if (executeRecaptcha) {
        token = await executeRecaptcha();
      }

      const data = {
        ...filterData,
        term: searchValue,
        contractId: currentContract.id,
        pageNumber: page + 1,
        pageSize: rowsPerPage,
      };

      await ApiClient.PatientSearch(data, token, contracts)
        .then((res) => {
          if (res) {
            setInformationConsent(res.isInformationConsent);
            if (res.isSuccess) {
              if (res.patient?.length > 0) {
                dispatch(setStatus(fetchStatus.FULFILLED));
                return dispatch(
                  setRows({ rows: res.patient, total: res.totalCount })
                );
              }
            }
            throw new Error(t('Patient.UsersNotFound'));
          }
          throw new Error(errorService.common);
        })
        .catch((e) => {
          dispatch(setStatus(fetchStatus.REJECTED));
          setMessage(e.message);
        });
    } catch (e) {
      dispatch(setStatus(fetchStatus.REJECTED));
      setMessage(e.message);
    }
  };

  const savePatient = (patient) => {
    if (patient === null) {
      dispatch(setPatient(null));
    }
    if (currentPatient?.id !== patient.id && hadServices) {
      dispatch(clearBasket());
    }
    if (patient && isObject(patient)) {
      dispatch(setPatient(patient));
      handleNext();
    }
  };

  const [patientLoad, setPatientLoad] = useState(false);

  const fetchByPatient = async (patientId) => {
    setPatientLoad(true);

    await ApiClient.GetPatient(patientId, contracts)
      .then((res) => {
        if (res) {
          savePatient(res);
        } else {
          throw new Error(t('Patient.UserNotFound'));
        }
      })
      .catch((e) => {
        dispatch(setError({ error: true, message: e.message }));
      });

    setPatientLoad(false);
  };

  useEffect(() => {
    if (isContractSelected) {
      fetchByPatientSearch();
    }
    return () => {
      dispatch(setStatus(fetchStatus.IDLE));
    };
  }, [
    currentContract,
    currentClientUnit,
    page,
    rowsPerPage,
    searchValue,
    filterData,
  ]);

  const handleSelect = (patientId) => () => {
    fetchByPatient(patientId);
  };

  const epmtyTable = (
    <div className="patient__tng">
      <div className="patient__tng-about">{t('key', { ns: 'EmptyTable' })}</div>
      <div className="patient__tng-or">
        <span className="patient__vertical" />
        <span className="patient__tng-or-label">
          {t('key', { ns: 'EmptyTable', context: 'or' })}
        </span>
      </div>
      <div className="patient__tng-excel excel-tng">
        <p className="excel-tng__text">
          {t('key', { ns: 'EmptyTable', context: 'download' })}
        </p>
        <p>
          <span className="flexible justify-content-center align-items-center">
            <UploadIcon className="excel-tng__icon color-blue-light" />
            <span className="excel-tng__label color-blue-light">
              {t('download.key', { ns: 'EmptyTable' })}
              <br />
              {t('download.key_1', { ns: 'EmptyTable' })}
            </span>
          </span>
        </p>
        <p className="excel-tng__text">
          {t('key', { ns: 'EmptyTable', context: 'fill' })}
        </p>
        <p>
          <span className="flexible justify-content-center align-items-center">
            <DownloadIcon className="excel-tng__icon color-blue-light" />
            <span className="excel-tng__label color-blue-light">
              {t('upload.key', { ns: 'EmptyTable' })}
              <br />
              {t('upload.key_1', { ns: 'EmptyTable' })}
            </span>
          </span>
        </p>
      </div>
    </div>
  );

  const errorBlock = (
    <Box p={4} textAlign="center">
      <Typography variant="h6" color="error">
        {message}
      </Typography>
    </Box>
  );
  const loadingBlock = (
    <Box padding={4} textAlign="center">
      <CircularProgress color="primary" />
    </Box>
  );

  return (
    <div className="patient">
      <div className="patient__block syn-form">
        <ExcelButtons className="patient__excel" />

        <Paper className={clsx('syn-form__paper')}>
          <div
            className={clsx(
              'syn-form__header',
              'patient__form',
              !FULFILLED && 'patient__form-idle'
            )}
          >
            <Grid
              container
              className="patient__grid"
              alignItems="center"
              spacing={2}
            >
              <PatientContractFields />
              <Grid
                item
                className="patient__grid-item"
                xs={9}
                sm={6}
                md={3}
                lg={3}
              >
                <SearchField
                  hasClearIcon
                  disabled={!isContractSelected}
                  onResetValue={handleClearSearch}
                  value={searchValue}
                  onChange={handleChangeSearchValue}
                  label={t('Input.SearchPlaceholder')}
                />
              </Grid>
              <Grid
                item
                className="patient__grid-item"
                xs={3}
                sm={6}
                md={1}
                lg={2}
              >
                <FilterDialog disabled={!isContractSelected} />
              </Grid>
              <Grid
                item
                className="patient__grid-item patient__grid-item--new-patient"
                xs={12}
                sm={6}
                md={2}
                lg={3}
              >
                <NewPatientButton
                  savePatient={savePatient}
                  disabled={!isContractSelected}
                />
              </Grid>
            </Grid>
          </div>
          {currentContract && FULFILLED && (
            <PatientTable
              SelectPatient={handleSelect}
              informationConsent={informationConsent}
            />
          )}
        </Paper>
        {error && errorBlock}
        {loading && loadingBlock}
        {idle && epmtyTable}
      </div>
      <Backdrop
        style={{
          trantransitionDelay: patientLoad ? '1000ms' : '0ms',
        }}
        open={patientLoad}
        unmountOnExit
        className="backdrop__z-index"
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

PatientPage.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default PatientPage;
