import { TextField } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './OutlinedInput.scss';

const CustomOutlinedSelect = ({
  icon,
  onChange,
  value,
  errors,
  className,
  label,
  name,
  children,
  fullWidth,
  ...props
}) => {
  const error = errors !== undefined && errors !== false;

  return (
    <>
      <div
        className={clsx('oulined-input__grid', className, {
          'oulined-input__grid--full-width': fullWidth,
        })}
      >
        {icon && <div className="oulined-input__icon">{icon}</div>}
        <TextField
          {...props}
          select
          className={clsx('oulined-input__input')}
          error={error}
          onChange={onChange}
          value={value}
          label={label}
          name={name}
          fullWidth
          InputLabelProps={{
            classes: { error: 'oulined-input__input--label-error' },
          }}
          margin="none"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
        >
          {children}
        </TextField>
      </div>
      {error && (
        <div role="alert" className="oulined-input__alert-block">
          <span className="oulined-input__alert-message">{errors.message}</span>
        </div>
      )}
    </>
  );
};

CustomOutlinedSelect.propTypes = {
  icon: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  password: PropTypes.bool,
};

CustomOutlinedSelect.defaultProps = {
  icon: null,
  errors: false,
  name: null,
  fullWidth: false,
  password: undefined,
};

export default CustomOutlinedSelect;
