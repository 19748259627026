import {
  Dialog, DialogTitle, Button, DialogActions
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ApiClient from '../../../ApiClient/ApiClient';
import '../../Dialogs/Dialog.scss';
import { setModifed } from '../../../_reducers/sortReducer';
import useSortContracts from '../useSortContracts';

const RemoveServiceDialog = ({
  open,
  onClose,
  serviceCode,
  barcode,
  disabledButton,
  setCanceled
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({ state: false, message: '' });
  const [error, setError] = useState({ state: false, message: '' });

  const contracts = useSortContracts();

  const dispatch = useDispatch();

  const handleRemove = async () => {
    setLoading(true);
    await ApiClient.SortingDelete(barcode, serviceCode, contracts)
      .then((res) => {
        if (res !== undefined && res.isSuccess) {
          if (res.deleteState) {
            setSuccess({ state: true, message: res.statusMessage });
            disabledButton();
            setCanceled(true);
          } else {
            setError({ state: true, message: res.statusMessage });
          }
        } else throw new Error(res.statusMessage);
      })
      .catch((e) => setError({ state: true, message: e.message }))
      .finally(() => {
        setLoading(false);
        dispatch(setModifed());
      });
  };
  return (
    <Dialog open={open} onClose={onClose} className="dialog">
      {!success.state && !error.state && (
        <>
          <DialogTitle className="dialog__title">
            {t('Dialog.RemoveService')}
          </DialogTitle>
          <DialogActions>
            <Button
              disable={loading}
              onClick={onClose}
              className="dialog__btn dialog__cancel"
            >
              {t('Common.No')}
            </Button>
            <Button
              disable={loading}
              color="primary"
              onClick={handleRemove}
              className="dialog__btn dialog__submit"
            >
              {t('Common.Yes')}
            </Button>
          </DialogActions>
        </>
      )}
      {success.state && (
        <>
          <DialogTitle className="dialog__title">{success.message}</DialogTitle>
          <DialogActions>
            <Button onClick={onClose} className="dialog__submit dialog__btn">
              {t('Common.Ok')}
            </Button>
          </DialogActions>
        </>
      )}
      {error.state && (
        <>
          <DialogTitle className="dialog__title dialog__error">
            {error.message}
          </DialogTitle>
          <DialogActions>
            <Button onClick={onClose} className="dialog__submit dialog__btn">
              {t('Common.Ok')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

RemoveServiceDialog.propTypes = {
  setCanceled: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  disabledButton: PropTypes.func.isRequired,
  serviceCode: PropTypes.string.isRequired,
  barcode: PropTypes.number.isRequired,
};

export default RemoveServiceDialog;
