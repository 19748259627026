import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import React from 'react';
import { useFilledInputStyles } from '../../../_helpers/MuiTheme/FIeldStyles';

const FilledDatePicker = ({
  card,
  format,
  errors,
  error,
  id,
  label,
  mask,
  helperText,
  name,
  maxDate,
  onChange,
  value,
  ...props
}) => {
  const classes = useFilledInputStyles();
  const Error = Boolean(errors?.message) || error;

  const errorMessage = () => {
    if (Error) {
      if (error && typeof error === 'string') {
        return error;
      }
      return errors.message;
    }
    return undefined;
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        fullWidth
        InputProps={{
          disableUnderline: true,
        }}
        InputLabelProps={{
          classes: { root: classes.label },
        }}
        FormHelperTextProps={{
          classes: { root: classes.formHelper },
        }}
        className={clsx(classes.datePickerInput, {
          [classes.cardDatePicker]: card,
        })}
        id={id}
        error={Error}
        helperText={helperText || errorMessage()}
        variant="inline"
        format={format}
        maxDate={maxDate}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        mask={mask}
      />
    </MuiPickersUtilsProvider>
  );
};

FilledDatePicker.propTypes = {
  card: PropTypes.bool,
  format: PropTypes.string,
  id: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  errors: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  label: PropTypes.string,
  mask: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Date).isRequired,
};

FilledDatePicker.defaultProps = {
  card: false,
  format: 'dd.MM.yyyy',
  id: undefined,
  error: false,
  errors: {},
  label: '',
  mask: '__/__/____',
  maxDate: new Date(),
  name: undefined,
  onChange: () => {},
  helperText: undefined
};

export default FilledDatePicker;
