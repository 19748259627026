/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../ApiClient/ApiClient';
import i18n from '../i18n';
import fetchStatus from '../_constants/fetchStatus';
import orderCreateService from '../_services/orderCreate.service';

const initialState = {
  services: [],
  searchServices: [],
  search: false,
  total: null,
  message: '',
  status: fetchStatus.IDLE,
  totalCount: 0,
};

const fetchByServices = createAsyncThunk(
  'services/getServices',
  async (data, thunk) => {
    const { pageNumber, rowsPerPage, token, Contracts, ClientUnit, search } =
      data;

    return orderCreateService
      .service(Contracts, pageNumber, rowsPerPage, token, search, {
        Contracts,
        ClientUnit,
      })
      .then((res) => {
        if (res.isSuccess) {
          if (search) {
            if (res.services.length > 0) {
              return { services: res.services, search };
            }
            return thunk.rejectWithValue(i18n.t('Errors.ServicesNotFound'));
          }
          return {
            services: res.services,
            search,
            totalPages: res.page?.totalPages,
            pageSize: rowsPerPage
          };
        }
        return thunk.rejectWithValue(res.statusMessage);
      })
      .catch((e) => thunk.rejectWithValue(e.message));
  }
);

const fetchBySearchServices = createAsyncThunk(
  'services/search',
  async (data, thunk) => {
    const { term, token, Contracts } = data;

    return ApiClient.ServiceSearch(term, token, Contracts)
      .then((res) => {
        if (res.services.length > 0) {
          return { services: res.services };
        }
        return thunk.rejectWithValue(i18n.t('Errors.ServicesNotFound'));
      })
      .catch((e) => thunk.rejectWithValue(e.message));
  }
);

const serviceReducer = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServices(state, action) {
      const { services, totalCount } = action.payload;

      state.status = fetchStatus.FULFILLED;
      state.services = services;
      state.totalCount = totalCount;
    },
    setError(state, action) {
      state.status = fetchStatus.REJECTED;
      state.message = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    disableSearch(state) {
      state.search = false;
      state.status = fetchStatus.FULFILLED;
    },
  },
  extraReducers: {
    [fetchByServices.pending]: (state) => {
      state.status = fetchStatus.PENDING;
    },
    [fetchByServices.fulfilled]: (state, action) => {
      const { services, search, totalPages, pageSize } = action.payload;

      state.status = fetchStatus.FULFILLED;

      if (search) {
        state.searchServices = services;
        state.search = search;
      } else {
        state.services = services;
        state.totalCount = totalPages * pageSize;
      }
      state.total = services.length;
    },
    [fetchByServices.rejected]: (state, action) => {
      state.status = fetchStatus.REJECTED;
      state.message = action.payload;
    },
    [fetchBySearchServices.pending]: (state) => {
      state.status = fetchStatus.PENDING;
    },
    [fetchBySearchServices.fulfilled]: (state, action) => {
      const { services } = action.payload;
      state.searchServices = services;
      state.search = true;
      state.status = fetchStatus.FULFILLED;
    },
    [fetchBySearchServices.rejected]: (state, action) => {
      state.status = fetchStatus.REJECTED;
      state.search = true;
      state.message = action.payload;
    },
  },
});

export { fetchByServices, fetchBySearchServices };
export const { setServices, setError, setStatus, disableSearch } =
  serviceReducer.actions;
export default serviceReducer.reducer;
