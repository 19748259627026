import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';
import StyledTextField from '../../../Inputs/StyledTextField';

const name = 'cardNumber';

const SearchCardField = ({ prefix, value, onChange }) => {
  const { t } = useTranslation('Admin');
  return (
    <ReactInputMask
      disabled={!prefix}
      mask={`${prefix}999999`}
      maskChar=""
      name={name}
      value={value}
      onChange={onChange}
    >
      {(inputProps) => (
        <StyledTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
          fullWidth
          disabled={!prefix}
          variant="filled"
          label={t('Discount.cardNumber')}
        />
      )}
    </ReactInputMask>
  );
};

SearchCardField.propTypes = {
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchCardField;
