import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import fetchStatus from '../../../_constants/fetchStatus';
import useLangRoute from '../../../_helpers/scripts/useLangRoute';
import AlertDialog from '../../Dialogs/AlertDialog';
import OrderForm from './OrderForm';
import ApiClient from '../../../ApiClient/ApiClient';
import createOrderMessage from './createOrderMessage';
import { AppRoute } from '../../../const/app-routes';
import errorHelper from '../../../_constants/errorHelper';
import { setCreatedDate } from '../../../_reducers/basketReducer';
import getHeaderDto from '../../../_helpers/scripts/getHeaderDto';
import { setBarcode } from '../../../_reducers/directionReducer';
import useApiContracts from '../../Contract/useApiContracts';

const CreateOrder = () => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const contracts = useApiContracts();
  const dispatch = useDispatch();
  const langPath = useLangRoute();

  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(fetchStatus.IDLE);

  const services = useSelector((state) => state.basket.serviceCodes);
  const patientId = useSelector((state) => state.basket.patient?.id);

  const handleClose = () => setStatus(fetchStatus.IDLE);

  const CreateOrderAsync = async (formData) => {
    let order;
    try {
      const header = getHeaderDto(formData, services);

      order = {
        patient: {
          id: patientId,
        },
        header,
      };
    } catch (e) {
      setMessage(t('CreateOrder.Message.NotValid', { error: e.message }));
      setStatus(fetchStatus.REJECTED);
      return false;
    }

    let token;
    if (executeRecaptcha) {
      token = await executeRecaptcha();
    }

    return ApiClient.CreateOrder(order, token, contracts)
      .then((res) => {
        if (res.isSuccess) {
          dispatch(setBarcode(res.barcode));
          setStatus(fetchStatus.FULFILLED);
          dispatch(setCreatedDate(new Date()));
          return true;
        }

        if (res.clientMessage) {
          const errorMessage = t(
            `CreateOrder.Message.${createOrderMessage.get(
              res.clientMessage?.code
            )}`
          );
          throw new Error(errorMessage);
        }

        throw new Error(res.statusMessage || errorHelper.common);
      })
      .catch((e) => {
        const validateErrors = errorHelper.getErrors(e);
        if (validateErrors.length > 0) {
          const errorMessage = Object.entries(e.errors)
            .map(([name, value]) => `${name}: ${value.map((i) => i).join(' ')}`)
            .join(';');

          setMessage(errorMessage);
        } else {
          setMessage(e.message);
        }
        setStatus(fetchStatus.REJECTED);
        return false;
      });
  };

  return (
    <>
      <OrderForm onSubmit={CreateOrderAsync} />
      {status === fetchStatus.FULFILLED && (
        <Redirect
          to={{
            pathname: langPath(AppRoute.CreateOrderSuccess),
          }}
          push
        />
      )}
      <AlertDialog
        open={status === fetchStatus.REJECTED}
        onClose={handleClose}
        message={message}
        error
      />
    </>
  );
};

export default CreateOrder;
