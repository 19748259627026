import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Box,
  Hidden,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Typography,
  Fade,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SortActiveIcon } from '../../img/Sort_up.svg';
import { ReactComponent as SortIcon } from '../../img/Sort2.svg';
import DialogButton from '../../MailManager/DialogButton';
import '../../StyledTable.scss';
import '../Discount.scss';
import './CurrentDiscount.scss';
import SortFunc from '../../../../_helpers/sort-funtions';
import CreateCard from '../Dialog/CreateCard';
import Filter from '../Filter/Filter';
import Row from './Row';
import CustomHidden from './CustomHidden';
import DiscountRBAC from './DicountRBAC';
import useRole from '../../../../_helpers/scripts/useRole';
import { Role } from '../../../../_constants/Roles';
import fetchStatus from '../../../../_constants/fetchStatus';
import SelectAllCheckbox from './SelectAllCheckbox';
import DiscountStatusButton from '../_helpers/DiscountStatusButton';
import {
  fetchByDiscountWithFilter,
  setPageNumber,
  setPageSize,
} from '../../../../_reducers/discountReducer';
import {
  fetchByInitDiscountFilterData,
  setIdleStatus,
} from '../../../../_reducers/initSearchDiscountCardsByFilter';
import tablePaginationHelper from '../../../../_helpers/tablePaginationHelper';

const headCells = [
  {
    id: 'cardNumber',
    label: 'Discount.cardNumber',
  },
  {
    id: 'discountType',
    label: 'Discount.discountType',
  },
  {
    id: 'contract',
    label: 'Discount.Contract',
  },
  {
    id: 'customer',
    label: 'Discount.fullName',
  },
  {
    id: 'startValidity',
    label: 'Discount.Validity',
  },
  {
    id: 'possibleNumberOfUses',
    label: 'Discount.countByMounth',
  },
  {
    id: 'totalNumberOfUses',
    label: 'Discount.countOfUses',
  },
  {
    id: 'isActive',
    label: 'Discount.status',
  },
];

const CurrentDiscountPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Admin');
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);
  const [selected, setSelected] = useState([]);

  const pageSize = useSelector((state) => state.discount.pageSize);
  const page = useSelector((state) => state.discount.pageNumber);
  const totalCount = useSelector((state) => state.discount.totalCount);
  const rows = useSelector((state) => state.discount.cards);
  const searchParametrs = useSelector(
    (state) => state.discount.searchParametrs
  );
  const isGetExtendedInfo = useSelector(
    (state) => state.discount.isGetExtendedInfo
  );
  const message = useSelector((state) => state.discount.message);
  const status = useSelector((state) => state.discount.status);

  const error = status === fetchStatus.REJECTED;
  const FULFILLED = status === fetchStatus.FULFILLED;
  const loading = status === fetchStatus.PENDING;

  useEffect(() => {
    const data = {
      searchParameter: searchParametrs,
      pagination: {
        pageNumber: page + 1,
        pageSize,
      },
      isGetExtendedInfo,
    };
    dispatch(fetchByDiscountWithFilter(data));
  }, [page, pageSize, searchParametrs, isGetExtendedInfo]);

  const handleChangePage = (e, newPage) => {
    dispatch(setPageNumber(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setPageSize(parseInt(event.target.value, 10)));
  };

  const handleResetSelected = () => {
    setSelected([]);
  };

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const numSelected = selected.length;

  const renderRows = rows?.length > 0 ? SortFunc.stableSort(
    rows,
    SortFunc.getComparator(order, orderBy)
  ).map((row) => {
    const isItemSelected = isSelected(row.id);
    return (
      <Row
        key={row.id}
        row={row}
        isItemSelected={isItemSelected}
        SelectItem={handleClick}
      />
    );
  }) : [];

  const theme = useTheme();
  const isFullHD = useMediaQuery(theme.breakpoints.up('lg'));

  const isHadTabs = useRole([Role.Admin]);

  const filterDataStatus = useSelector(
    (state) => state.searchCardsByFilterReducer.status
  );

  const filterDataIdle = filterDataStatus === fetchStatus.IDLE;

  useEffect(() => {
    if (filterDataIdle) {
      dispatch(fetchByInitDiscountFilterData());
    }
    return () => {
      dispatch(setIdleStatus());
    };
  }, []);

  useEffect(() => {
    setSelected([]);
  }, [searchParametrs]);

  return (
    <div className="styled-table">
      <Paper
        className={clsx('styled-table__container', {
          'styled-table__container--rounded': !isHadTabs,
        })}
      >
        <Box
          className={clsx('styled-table__head', {
            'styled-table__head--rounded': !isHadTabs,
          })}
          flexDirection="column"
        >
          <Filter>
            <DiscountRBAC>
              <DialogButton
                title={t('Discount.NewCard')}
                component={CreateCard}
              />
            </DiscountRBAC>
          </Filter>
          <DiscountRBAC>
            {numSelected > 0 && (
              <Fade in={numSelected > 0}>
                <Box pt={{ xs: 2, md: 3 }}>
                  <span className="styled-table__selected-label">
                    {`${t('Discount.total')}: ${numSelected}`}
                  </span>
                  <DiscountStatusButton
                    resetSelected={handleResetSelected}
                    id={selected}
                  />
                </Box>
              </Fade>
            )}
          </DiscountRBAC>
        </Box>
        {FULFILLED && (
          <div className="styled-table__table-container services-table">
            <Table className={clsx('styled-table__table', 'discount-table')}>
              <TableHead>
                <TableRow className="styled-table__thead-row">
                  <Hidden lgUp>
                    <TableCell className="styled-table__th" />
                  </Hidden>
                  <DiscountRBAC>
                    <TableCell padding="checkbox" className="styled-table__th">
                      <SelectAllCheckbox
                        numSelected={numSelected}
                        setSelected={setSelected}
                        rowsCount={rows.length}
                      />
                    </TableCell>
                  </DiscountRBAC>
                  {headCells.map((headCell) => {
                    const active = orderBy === headCell.id;
                    return (
                      <CustomHidden match={headCell.id} key={headCell.id}>
                        <TableCell
                          sortDirection={active ? order : false}
                          component="th"
                          className="styled-table__th"
                        >
                          <TableSortLabel
                            hideSortIcon={!isFullHD}
                            active={active}
                            direction={active ? order : 'asc'}
                            onClick={handleRequestSort(headCell.id)}
                            IconComponent={active ? SortActiveIcon : SortIcon}
                          >
                            {t(headCell.label)}
                          </TableSortLabel>
                        </TableCell>
                      </CustomHidden>
                    );
                  })}
                  <TableCell className="styled-table__th" />
                </TableRow>
              </TableHead>
              <TableBody>{renderRows}</TableBody>
            </Table>
            <TablePagination
              className="services-table__pagination"
              rowsPerPageOptions={tablePaginationHelper.rowsPerPageOptions}
              component="div"
              count={totalCount}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
      {loading && (
        <Box p={5} textAlign="center">
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box p={5} textAlign="center">
          <Typography color="error" variant="h6">
            {message}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default CurrentDiscountPage;
