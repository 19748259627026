import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const RBAC = ({ allowedRoles, children }) => {
  const role = useSelector((state) => state.userReducer.userRoles);
  if (role) {
    const access = allowedRoles.indexOf(role.id) !== -1;
    return access && children;
  }
  return false;
};

RBAC.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.node.isRequired,
};

export default RBAC;
