import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setClientUnit } from '../../_reducers/contractReducer';
import ContractAutocomplete from '../Inputs/ContractAutocomplete';
import useClientUnits from './useClientUnits';

const ClientUnitField = ({
  onChange,
  value,
  errors,
  variant,
  disabled,
  allowSelectAll,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clientUnits, currentClientUnit } = useClientUnits(allowSelectAll);

  const onClientUnitChange = (newClientUnit) => {
    if (!newClientUnit || newClientUnit?.id !== -1) {
      dispatch(setClientUnit(newClientUnit));
    }
    onChange(newClientUnit);
  };

  const fieldValue = value || currentClientUnit;

  return (
    <ContractAutocomplete
      disabled={disabled}
      errors={errors}
      options={clientUnits}
      filled={variant === 'filled'}
      value={fieldValue}
      onChange={(newVal) => onClientUnitChange(newVal)}
      label={t('Input.Subdivision')}
    />
  );
};

ClientUnitField.propTypes = {
  allowSelectAll: PropTypes.bool,
  errors: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  value: PropTypes.shape({ name: PropTypes.string, id: PropTypes.number }),
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['filled', 'outlined']),
};

ClientUnitField.defaultProps = {
  errors: null,
  value: null,
  variant: 'outlined',
  allowSelectAll: false,
  onChange: () => {},
  disabled: false,
};

export default ClientUnitField;
