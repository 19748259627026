import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import BaseInput from './BaseFilledInput/BaseInput';
import CustomOutlinedInput from './BaseOutlinedInput/CustomOutlinedInput';
import './SynAutocomplete.scss';
import StyledPaper from '../_components/StyledPaper';

// eslint-disable-next-line react/jsx-props-no-spreading

const CustomAutocomplete = ({
  value,
  options,
  label,
  filled,
  onChange,
  id,
  name,
  errors,
  className,
  freeSolo,
  inputValue,
  disabled,
  placeholder,
  onInputChange,
  multiple,
  getOptionLabel,
  filterOptions,
  getOptionSelected,
  filterSelectedOptions,
  disableCloseOnSelect,
  getEventObj,
  loading,
  InputProps,
  ...props
}) => (
  <Autocomplete
    className={clsx(className, {
      'filled-input': filled,
      'outlined-input-multiple': multiple,
    })}
    options={options}
    classes={{
      ...props.classes,
      paper: 'syn-autocomplete__paper',
      popper: 'syn-autocomplete__popper',
      listbox: 'syn-scroll',
      option: 'syn-autocomplete__option',
    }}
    PaperComponent={StyledPaper}
    value={value}
    disabled={disabled}
    filterSelectedOptions={filterSelectedOptions}
    getOptionLabel={getOptionLabel}
    inputValue={inputValue}
    getOptionSelected={getOptionSelected}
    multiple={multiple}
    freeSolo={freeSolo}
    filterOptions={filterOptions}
    id={id}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    loading={loading}
    disableCloseOnSelect={disableCloseOnSelect}
    onInputChange={onInputChange}
    onChange={(event, newValue) => {
      getEventObj ? onChange(event, newValue) : onChange(newValue);
    }}
    renderInput={(params) => (filled ? (
      <BaseInput
          // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        loading={loading}
        label={label}
        InputProps={{ ...params.InputProps, InputProps }}
        multiple={multiple}
        name={name}
        errors={errors}
      />
    ) : (
      <CustomOutlinedInput
          // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        label={label}
        multiple={multiple}
        loading={loading}
        InputProps={{ ...params.InputProps, InputProps }}
        name={name}
        errors={errors}
      />
    ))}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

CustomAutocomplete.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  filled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  className: PropTypes.string,
  freeSolo: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  filterSelectedOptions: PropTypes.bool,
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  getEventObj: PropTypes.bool,
  filterOptions: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  inputValue: PropTypes.string,
};

CustomAutocomplete.defaultProps = {
  value: undefined,
  className: undefined,
  disabled: false,
  errors: {},
  filled: false,
  filterOptions: undefined,
  multiple: undefined,
  getOptionSelected: undefined,
  filterSelectedOptions: undefined,
  freeSolo: false,
  getOptionLabel: undefined,
  id: undefined,
  name: undefined,
  options: [],
  disableCloseOnSelect: false,
  placeholder: '',
  label: '',
  inputValue: undefined,
  onInputChange: undefined,
  getEventObj: false,
};

export default CustomAutocomplete;
