import React from 'react';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setError } from '../../../_reducers/errorReducer';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import { ReactComponent as PdfIcon } from '../img/pdf.svg';
import useResult from '../useResult';
import base64ToType from '../../../_helpers/scripts/base64ToType';
import downloadHelper from '../../../_helpers/downloadHelper';

const DownloadResultButton = ({ barcode, patient, view }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    GetResultBase64Async, message, status, SetStatusIdle
  } = useResult();

  const downloadResultPDf = async () => {
    try {
      let url = null;

      if (view) {
        url = structuredClone(view);
      } else {
        const result = await GetResultBase64Async(barcode);
        if (result) {
          const blob = base64ToType(result, 'application/pdf');
          url = URL.createObjectURL(blob);
        } else {
          dispatch(setError(message || t('Errors.BadRequest')));
        }
      }

      if (url) {
        downloadHelper.downloadFile(url, downloadHelper.getFileName(patient, barcode));
      } else {
        dispatch(setError(message || t('Errors.BadRequest')));
      }
    } catch (e) {
      dispatch(setError(e.message || t('Errors.BadRequest')));
    }

    if (!status.idle) {
      SetStatusIdle();
    }
  };

  const handleDownload = () => {
    downloadResultPDf();
  };

  return (
    <CustomizedTooltip title={t('Tooltip.DownloadResults')}>
      <IconButton
        color="primary"
        disabled={status.loading}
        size="small"
        onClick={handleDownload}
      >
        <PdfIcon className="btn-group__img" />
      </IconButton>
    </CustomizedTooltip>
  );
};

DownloadResultButton.propTypes = {
  barcode: PropTypes.number.isRequired,
  view: PropTypes.instanceOf(Blob),
};

DownloadResultButton.defaultProps = {
  view: null,
};

export default DownloadResultButton;
