const retry = (callback, times = 3) => {
  let numberOfTries = 0;
  return new Promise((resolve) => {
    const interval = setInterval(async () => {
      numberOfTries++;
      if (numberOfTries === times) {
        clearInterval(interval);
      }
      try {
        await callback();
        clearInterval(interval);
        resolve();
      } catch (err) {
      }
    }, 2500);
  });
};

export default { retry };
