import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import setInputFilter from '../../../_helpers/scripts/setInputFilter';
import BaseInput from '../../Inputs/BaseFilledInput/BaseInput';

const FirstNameField = ({ onChange, value }) => {
  const { t } = useTranslation();
  const onFieldChange = () => {
    setInputFilter(
      document.getElementById('FirstNameId'),
      (val) => /^[^0-9\s]*$/i.test(val),
      onChange
    );
  };
  return (
    <BaseInput
      value={value}
      label={t('Input.FirstName')}
      name="FirstName"
      id="FirstNameId"
      onChange={onFieldChange}
    />
  );
};

FirstNameField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default FirstNameField;
