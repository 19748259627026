import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';

import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import MenuIcon from '@material-ui/icons/Menu';

import { NavLink } from 'react-router-dom';

import './Menu.scss';

import {
  Hidden, Drawer, Typography, makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from './img/info.svg';
import { ReactComponent as Create } from './img/create.svg';
import { ReactComponent as Sorting } from './img/sort.svg';
import { ReactComponent as Results } from './img/result.svg';
import { ReactComponent as Settings } from './img/settings.svg';
import { ReactComponent as AdminIcon } from './img/admin.svg';

import { AppRoute } from '../../const/app-routes';
import { LangDialog } from '../i18n/ChangeLanguageDialog';
import RBAC from '../../_helpers/RBAC';
import { AllowedRoles } from '../../_constants/Roles';
import ExitButton from './Exit/ExitButton';
import ChangeLangButton from './Language/ChangeLangButton';
import useLangRoute from '../../_helpers/scripts/useLangRoute';
import UserNameLabel from './UserNameLabel';
import LogoButton from './LogoButton';

const StyledMenuItem = ({ route, icon, label }) => (
  <ListItem
    button
    dense
    disableRipple
    className="menu-list__item"
    activeClassName="menu__item--selected"
    component={NavLink}
    to={route}
  >
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText>
      <Typography variant="caption">{label}</Typography>
    </ListItemText>
  </ListItem>
);

StyledMenuItem.propTypes = {
  route: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function SwipeableTemporaryDrawer() {
  const { t } = useTranslation();
  const route = useLangRoute();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    value: 'ru',
    dialogOpen: false,
  });
  const [Open, setOpen] = useState(false);
  const classes = useStyles();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event
      && event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx('menu-list', {
        'menu-list__full-list': anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="menu-list__list" dense>
        <LogoButton />
        <div className="menu-list__pages">
          <RBAC allowedRoles={AllowedRoles.News}>
            <StyledMenuItem
              route={route(AppRoute.News)}
              label={t('Menu.Info')}
              icon={(
                <Info
                  className="menu-list__item-icon"
                  width="24px"
                  height="24"
                />
              )}
            />
          </RBAC>

          <RBAC allowedRoles={AllowedRoles.CreateOrder}>
            <StyledMenuItem
              route={route(AppRoute.CreateOrder)}
              label={t('Menu.CreateOrder')}
              icon={(
                <Create
                  className="menu-list__item-icon"
                  width="24px"
                  height="24"
                />
              )}
            />
          </RBAC>

          <RBAC allowedRoles={AllowedRoles.Sort}>
            <StyledMenuItem
              route={route(AppRoute.Sort)}
              label={t('Menu.Sort')}
              icon={(
                <Sorting
                  className="menu-list__item-icon"
                  width="24px"
                  height="24"
                />
              )}
            />
          </RBAC>

          <RBAC allowedRoles={AllowedRoles.Results}>
            <StyledMenuItem
              route={route(AppRoute.Results)}
              label={t('Menu.Results')}
              icon={(
                <Results
                  className="menu-list__item-icon"
                  width="24px"
                  height="24"
                />
              )}
            />
          </RBAC>

          <RBAC allowedRoles={AllowedRoles.Settings}>
            <StyledMenuItem
              route={route(AppRoute.Settings)}
              label={t('Menu.Options')}
              icon={(
                <Settings
                  className="menu-list__item-icon"
                  width="24px"
                  height="24"
                />
              )}
            />
          </RBAC>
          <RBAC allowedRoles={AllowedRoles.Admin}>
            <StyledMenuItem
              route={route(AppRoute.Admin)}
              label={t('Menu.Admin')}
              icon={(
                <AdminIcon
                  className="menu-list__item-icon"
                  width="24px"
                  height="24"
                />
              )}
            />
          </RBAC>
        </div>
        <UserNameLabel />
        <ListItem className="menu-list__item menu-list__item--exit">
          <ExitButton />
          <ChangeLangButton />
        </ListItem>
      </List>
      <LangDialog open={Open} setOpen={setOpen} />
    </div>
  );

  const StikyMenuButton = () => {
    const header = document.getElementById('mobile-menu-button-id');
    if (header) {
      const sticky = header.offsetTop;
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    }
  };

  window.onscroll = () => {
    if (document.documentElement.scrollWidth <= 600) {
      StikyMenuButton();
    }
  };

  return (
    <div id="menu" className="menu">
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Hidden xsDown implementation="js">
            <Drawer
              classes={{ paper: classes.drawer }}
              open
              variant="permanent"
              className="menu-list__drawer"
            >
              {list(anchor)}
            </Drawer>
          </Hidden>
          <Hidden smUp implementation="js">
            <div className="menu__mobile-menu-icon" id="mobile-menu-button-id">
              <IconButton
                className="menu__mobile-menu-button"
                onClick={toggleDrawer(anchor, true)}
                color="primary"
                aria-label="open menu"
              >
                <MenuIcon />
              </IconButton>
            </div>
            <Drawer
              className="menu-list__drawer"
              classes={{ paper: classes.drawer }}
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </Drawer>
          </Hidden>
        </React.Fragment>
      ))}
    </div>
  );
}
