import { TableCell, withStyles } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      lineHeight: '18px',
    },
  },
  head: {
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '14.4px',
    textTransform: 'uppercase',
    color: theme.palette.title.main,
    backgroundColor: theme.palette.blueLightPlusNew.main,
    borderBottom: 'none',
  },
  sizeSmall: {
    [theme.breakpoints.up('sm')]: {
      padding: '16px 12px 16px 16px',
      '&$paddingCheckbox:last-child': {
        paddingLeft: 0,
        paddingRight: 4,
      },
    },
    '&$paddingCheckbox:last-child': {
      paddingLeft: 6,
      paddingRight: 6,
    },
    padding: '16px 4px 16px 6px',
  },

  paddingCheckbox: {},
}))(TableCell);

export default StyledTableCell;
