import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessage } from '../../../_reducers/serviceExtensionReducer';

const SyncAlert = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.serviceExtension.message);

  const handleClose = () => {
    dispatch(clearMessage());
  };

  useEffect(
    () => () => {
      dispatch(clearMessage());
    },
    [message]
  );

  if (message) {
    return (
      <Box mb={3}>
        <Alert onClose={handleClose}>{message}</Alert>
      </Box>
    );
  }
  return message;
};

export default SyncAlert;
