import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ModalEmailButton from '../Dialogs/ModalEmailButton';
import DownloadResultButton from '../Buttons/DownloadResultButton';
import formatDate from '../../../_helpers/scripts/formatDate';
import ViewResultButton from '../Buttons/ViewResultButton';
import getPatientFullName from '../../../_helpers/scripts/getPatientFullName';

const ArrowsButton = () => (
  <div className="services-table__collapse-icons">
    <ExpandLess />
    <ExpandMore />
  </div>
);

const MobileRow = ({ row }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState();
  const tableCellRef = useRef('cell');

  const handleOpenNestedTable = () => {
    setOpen(!open);
  };
  return (
    <>
      <TableRow
        onClick={handleOpenNestedTable}
        className={clsx('services-table__row--first-row services-table__row', {
          filled: open,
        })}
        tabIndex={-1}
        key={row.patient}
      >
        <TableCell
          rowSpan={2}
          ref={tableCellRef}
          style={{ height: open ? tableCellRef.current.scrollHeight : 49 }}
          className="services-table__cell services-table__collapse-btn"
        >
          <Button
            type="button"
            className="services-table__collapse-btn"
            style={{ height: '100%' }}
          >
            <ArrowsButton />
          </Button>
        </TableCell>
        <TableCell scope="row" className="services-table__cell">
          {getPatientFullName(row.patient)}
        </TableCell>
        <TableCell className="services-table__cell">{row.barcode}</TableCell>
        <TableCell className="services-table__cell">{row.state}</TableCell>
      </TableRow>
      <TableRow
        className={clsx('services-table__row--second-row services-table__row', {
          filled: open,
        })}
      >
        <TableCell colSpan={5} className="services-table__cell">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <span>
              <Table className="collapsed-table">
                <TableBody>
                  <TableRow className="collapsed-table__row">
                    <TableCell
                      className="collapsed-table__cell-th"
                      component="th"
                    >
                      {t('Sort.DateOfBirth')}
                    </TableCell>
                    <TableCell className="collapsed-table__cell collapsed-table__cell--padding-right">
                      {formatDate(row.patient.dateOfBirth)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="collapsed-table__row">
                    <TableCell
                      className="collapsed-table__cell-th"
                      component="th"
                    >
                      {t('Result.OrderDate')}
                    </TableCell>
                    <TableCell className="collapsed-table__cell collapsed-table__cell--padding-right">
                      {formatDate(row.orderDate)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="collapsed-table__row">
                    <TableCell
                      className="collapsed-table__cell-th"
                      component="th"
                    >
                      {t('Result.DeadLineDate')}
                    </TableCell>
                    <TableCell className="collapsed-table__cell collapsed-table__cell--padding-right">
                      {formatDate(row.deadLineDate)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="collapsed-table__row">
                    <TableCell
                      className="collapsed-table__cell collapsed-table__cell--padding-right"
                      colSpan={2}
                    >
                      <Box display="flex" gridGap={16}>
                        <ModalEmailButton barcode={row.barcode} />
                        <DownloadResultButton
                          patient={row.patient}
                          barcode={row.barcode}
                          state={row.state}
                        />
                        <ViewResultButton patient={row.patient} barcode={row.barcode} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table className="nested-table service-table__nested-table">
                <TableHead>
                  <TableRow className="nested-table__row">
                    <TableCell className="nested-table__cell-th">
                      {t('Sort.ServiceCode')}
                    </TableCell>
                    <TableCell className="nested-table__cell-th">
                      {t('Sort.Service')}
                    </TableCell>
                    <TableCell className="nested-table__cell-th">
                      {t('Result.ServiceStatus')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.services.map((item) => (
                    <TableRow className="nested-table__row">
                      <TableCell className="nested-table__cell">
                        {item.serviceCode}
                      </TableCell>
                      <TableCell className="nested-table__cell">
                        {item.serviceName}
                      </TableCell>
                      <TableCell className="nested-table__cell">
                        {item.serviceStatus}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </span>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

MobileRow.propTypes = {
  row: PropTypes.shape({
    orderId: PropTypes.number.isRequired,
    barcode: PropTypes.string,
    patient: PropTypes.shape({
      personId: PropTypes.number,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
      gender: PropTypes.string,
      dateOfBirth: PropTypes.string,
    }),
    services: PropTypes.arrayOf(
      PropTypes.shape({
        serviceCode: PropTypes.string,
        serviceName: PropTypes.string,
        serviceStatus: PropTypes.string,
      })
    ),
    orderDate: PropTypes.string,
    state: PropTypes.string,
    deadLineDate: PropTypes.string,
    clientUnitId: PropTypes.number,
    clientUnitName: PropTypes.string,
    createdByUserAccount: PropTypes.string,
    workSpace: PropTypes.oneOf([null]),
  }).isRequired,
};

export default MobileRow;
