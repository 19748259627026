import { Checkbox, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { sortState } from '../../../_constants/OrderStatusConst';
import SortState from '../SortState';

const TableCheckbox = ({
  className,
  labelId,
  isItemSelected,
  setSelected,
  barcode,
}) => (
  <SortState allowedState={[sortState.NEW, sortState.IMPORTED, sortState.SENT]}>
    <TableCell padding="checkbox" className={className}>
      <Checkbox
        color="primary"
        className="services-table__checkbox"
        disableRipple
        onClick={(event) => {
          event.stopPropagation();
          setSelected(event, barcode);
        }}
        checked={isItemSelected}
        inputProps={{ 'aria-labelledby': labelId }}
      />
    </TableCell>
  </SortState>
);

TableCheckbox.propTypes = {
  className: PropTypes.string.isRequired,
  labelId: PropTypes.number.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  barcode: PropTypes.number.isRequired,
};

export default TableCheckbox;
