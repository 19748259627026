import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../ApiClient/ApiClient';

const empty = 'EMPTY';
const getYearRegexp = /\d+$/;

const initialState = {
  loading: false,
  orders: [],
  error: false,
  errorMessage: '',
  totalCount: 0,
  pageSize: 20,
  page: 0,
  maxPage: 0,
  previousBody: null,
  isFirstRequest: true,
  isModifed: false,
  selected: [],
  sortingState: 1,
  previousContracts: null,
};

// TODO: удалить
const fetchBySortOrders = createAsyncThunk(
  'sort/fetchBySortOrdersOld',
  async (data, thunk) => {
    const response = await ApiClient.Sorting(
      data.body,
      data.reCaptchaToken,
      data.formContracts
    );
    if (response.orders) {
      const immetRes = response.orders.map((item) => ({
        ...item,
        dateOfBirth: item.patient.dateOfBirth.match(getYearRegexp)[0],
        firstName: item.patient.firstName,
        contractName: item.contract.name,
        contractId: item.contract.id,
      }));
      return { ...response, orders: immetRes };
    }
    return thunk.rejectWithValue({
      type: empty,
      message: response.statusMessage,
    });
  }
);

//TODO: убрать
const sortReducer = createSlice({
  name: 'sort_old',
  initialState,
  reducers: {
    closeError(state) {
      state.error = false;
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
      state.page = 0;
    },
    changePage(state, action) {
      state.error = false;
      state.page = action.payload;
    },
    setPreviousBody(state, action) {
      state.previousBody = action.payload;
    },
    setMaxPage(state, action) {
      state.maxPage = action.payload;
    },
    setModifed(state) {
      state.isModifed = true;
    },
    resetOrders(state) {
      state.orders = [];
      state.maxPage = 0;
      state.page = 0;
      state.selected = [];
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSortingState(state, action) {
      state.sortingState = action.payload;
    },
    setLoading(state) {
      state.loading = true;
    },
    setPrevContracts(state, action) {
      state.previousContracts = action.payload;
    },
    setNewOrders(state, action) {
      state.orders = action.payload.orders;
      state.totalCount = action.payload.totalCount;
    },
  },
  extraReducers: {
    [fetchBySortOrders.pending]: (state) => {
      state.error = false;
      state.loading = true;
      state.isModifed = false;
      state.isFirstRequest = false;
    },
    [fetchBySortOrders.fulfilled]: (state, action) => {
      state.orders = state.orders.concat(action.payload.orders);
      state.totalCount = action.payload.totalCount;
      state.loading = false;
    },
    [fetchBySortOrders.rejected]: (state, action) => {
      state.error = true;
      state.totalCount = 0;
      if (action.payload) {
        state.errorMessage = action.payload.message;
        state.orders = [];
      } else {
        state.errorMessage = action.error.message;
      }
      state.loading = false;
    },
  },
});

export { fetchBySortOrders };
export const {
  closeError,
  changePageSize,
  changePage,
  setPreviousBody,
  setModifed,
  resetOrders,
  setMaxPage,
  setSelected,
  setSortingState,
  setLoading,
  setPrevContracts,
  setNewOrders,
} = sortReducer.actions;
export default sortReducer.reducer;
