import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../ApiClient/ApiClient';
import errorHelper from '../_constants/errorHelper';
import fetchStatus from '../_constants/fetchStatus';

const initialState = {
  pageNumber: 0,
  totalCount: null,
  pageSize: 10,
  status: fetchStatus.IDLE,
  cards: [],
  searchParametrs: {},
  isGetExtendedInfo: true,
};

const fetchByDiscountCards = createAsyncThunk(
  'discount/fetchByDiscountCards',
  async (data, { rejectWithValue }) => ApiClient.DiscountCards(data.page + 1, data.pageSize)
    .then((res) => {
      if (res) {
        if (res.isSuccess) {
          return {
            totalCount: res.totalCount,
            cards: res.discountCards,
          };
        }
        return rejectWithValue(res.statusMessage);
      }
      return rejectWithValue(errorHelper.common);
    })
    .catch((e) => rejectWithValue(e.message))
);

const fetchByDiscountWithFilter = createAsyncThunk(
  'prefix/DiscountWithFilter',
  async (data, thunk) => ApiClient.SearchDiscountCardByFilters(data)
    .then((res) => {
      if (res) {
        if (res.isSuccess) {
          if (
            res.totalCount === 0
              && res.extendedInfoOfDiscountCards.length === 0
          ) {
            throw new Error(errorHelper.notFoundByFilter);
          }

          const result = {
            totalCount: res.totalCount,
          };

          if (res.extendedInfoOfDiscountCards) {
            result.cards = res.extendedInfoOfDiscountCards;
          }

          return result;
        }
        throw new Error(res.statusMessage);
      }
      return new Error(errorHelper.common);
    })
    .catch((e) => thunk.rejectWithValue(e.message))
);

const discountReducer = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    setDiscountCards(state, action) {
      state.cards = action.payload;
    },
    setPageSize(state, action) {
      state.pageNumber = 0;
      state.pageSize = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    setSearchParams(state, action) {
      state.searchParametrs = action.payload;
      state.pageNumber = 0;
    },
  },
  extraReducers: {
    [fetchByDiscountWithFilter.pending]: (state) => {
      state.status = fetchStatus.PENDING;
    },
    [fetchByDiscountWithFilter.fulfilled]: (state, action) => {
      state.status = fetchStatus.FULFILLED;
      state.cards = action.payload.cards;
      state.totalCount = action.payload.totalCount;
    },
    [fetchByDiscountWithFilter.rejected]: (state, action) => {
      state.status = fetchStatus.REJECTED;
      state.message = action.payload;
    },
  },
});

export { fetchByDiscountCards, fetchByDiscountWithFilter };
export const {
  setPageSize, setDiscountCards, setPageNumber, setSearchParams
} = discountReducer.actions;
export default discountReducer.reducer;
