import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableHead,
  TableBody,
  Button,
  TableCell,
  TableRow,
  Collapse,
  Box,
} from '@material-ui/core';
import ModalEmail from './Dialogs/ModalEmailButton';
import DownloadResultButton from './Buttons/DownloadResultButton';
import formatDate from '../../_helpers/scripts/formatDate';
import ViewResultButton from './Buttons/ViewResultButton';
import getPatientFullName from '../../_helpers/scripts/getPatientFullName';

const ArrowsButton = () => (
  <div className="services-table__collapse-icons">
    <ExpandLess />
    <ExpandMore />
  </div>
);

const Row = ({ row }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState();
  const tableCellRef = useRef('cell');

  return (
    <>
      <TableRow
        className={clsx('services-table__row--first-row services-table__row', {
          filled: open,
        })}
      >
        <TableCell
          rowSpan={2}
          ref={tableCellRef}
          style={{ height: open ? tableCellRef.current.scrollHeight : 49 }}
          className="services-table__cell services-table__collapse-btn"
        >
          <Button
            type="button"
            className="services-table__collapse-btn"
            onClick={() => setOpen(!open)}
            style={{ height: '100%' }}
          >
            <ArrowsButton />
          </Button>
        </TableCell>
        <TableCell scope="row" className="services-table__cell">
          {getPatientFullName(row.patient)}
        </TableCell>
        <TableCell className="services-table__cell">
          {formatDate(row.patient.dateOfBirth)}
        </TableCell>
        <TableCell className="services-table__cell">{row.barcode}</TableCell>
        <TableCell className="services-table__cell">{row.state}</TableCell>
        <TableCell className="services-table__cell">
          {formatDate(row.orderDate)}
        </TableCell>
        <TableCell className="services-table__cell">
          {formatDate(row.deadLineDate)}
        </TableCell>
        <TableCell align="right" className="services-table__cell">
          {row.state !== 'Новый заказ'
            && row.state !== 'Отменен'
            && row.state !== 'Непроизводственный' && (
              <Box display="flex" gridGap={8}>
                <ModalEmail barcode={row.barcode} />
                <DownloadResultButton patient={row.patient} barcode={row.barcode} />
                <ViewResultButton barcode={row.barcode} />
              </Box>
          )}
        </TableCell>
      </TableRow>
      <TableRow
        className={`services-table__row--second-row services-table__row ${
          open ? 'filled' : ''
        }`}
      >
        <TableCell colSpan={7} className="services-table__cell">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table
              size="small"
              className="nested-table service-table__nested-table"
            >
              <TableHead>
                <TableRow className="nested-table__row">
                  <TableCell className="nested-table__cell-th">
                    {t('Sort.ServiceCode')}
                  </TableCell>
                  <TableCell className="nested-table__cell-th">
                    {t('Sort.Service')}
                  </TableCell>
                  <TableCell className="nested-table__cell-th">
                    {t('Result.ServiceStatus')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.services
                  && row.services.map((item) => (
                    <TableRow>
                      <TableCell className="nested-table__cell">
                        {item.serviceCode}
                      </TableCell>
                      <TableCell className="nested-table__cell">
                        {item.serviceName}
                      </TableCell>
                      <TableCell className="nested-table__cell">
                        {item.serviceStatus}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
        <TableCell className="services-table__cell" />
      </TableRow>
    </>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    orderId: PropTypes.number.isRequired,
    barcode: PropTypes.number,
    patient: PropTypes.shape({
      personId: PropTypes.number,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
      gender: PropTypes.string,
      dateOfBirth: PropTypes.string,
    }),
    services: PropTypes.arrayOf(
      PropTypes.shape({
        serviceCode: PropTypes.string,
        serviceName: PropTypes.string,
        serviceStatus: PropTypes.string,
      })
    ),
    orderDate: PropTypes.string,
    state: PropTypes.string,
    deadLineDate: PropTypes.string,
    clientUnitId: PropTypes.number,
    clientUnitName: PropTypes.string,
    createdByUserAccount: PropTypes.string,
    workSpace: PropTypes.oneOf([null]),
  }).isRequired,
};

export default Row;
