/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../ApiClient/ApiClient';
import errorHelper from '../_constants/errorHelper';

const getClientUnit = (unit) => {
  if (unit) {
    if (Array.isArray(unit)) {
      return unit.map((item) => item.id);
    }
    return unit.id;
  }
  return null;
};

const fetchByCalculator = createAsyncThunk(
  'basket/calculate-services',
  async (data, { rejectWithValue, getState }) => {
    const [codes, reCaptchaToken, isBiomaterial, isCalculateByContract] = data;

    let gender;
    try {
      gender = getState().basket.patient.gender;
    } catch (e) {
      return rejectWithValue(errorHelper.selectUser);
    }

    let userContracts;
    try {
      const Contracts = getState().contract.value.id;
      const ClientUnit = getClientUnit(getState().contract.clientUnit);

      userContracts = { Contracts, ClientUnit };
    } catch (e) {
      return rejectWithValue(
        `${errorHelper.selectContract} ${
          process.env.NODE_ENV === 'development' && JSON.stringify(e)
        }`
      );
    }

    const response = await ApiClient.CalculateServices(
      codes,
      gender,
      reCaptchaToken,
      userContracts,
      isBiomaterial,
      isCalculateByContract
    ).then((res) => {
      if (res) {
        return res;
      }
      return rejectWithValue(errorHelper.common);
    });
    return { ...response, parrentCodes: codes };
  }
);

const initialState = {
  open: false,
  patient: null,
  addedServices: [],
  serviceCodes: [],
  parrentCodes: [],
  price: 0,
  biomaterials: [],
  workingDays: 0,
  loading: false,
  error: false,
  message: '',
  conflict: false,
  editOrder: false,
  order: null,
  createdDate: null,
  isPreOrder: false,
  activeStep: 0,
};

const basketReducer = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    setPatient(state, action) {
      state.patient = action.payload;
    },
    setEditOrder(state, action) {
      state.editOrder = true;
      state.contract = action.payload?.contract || null;
      state.clientUnit = action.payload?.clientUnit || null;
      state.patient = action.payload.patient;
      state.order = action.payload;
    },
    clearBasket(state) {
      state.addedServices = [];
      state.biomaterials = [];
      state.serviceCodes = [];
      state.parrentCodes = [];
      state.workingDays = 0;
      state.price = 0;
    },
    resetBasket(state) {
      state.addedServices = [];
      state.biomaterials = [];
      state.serviceCodes = [];
      state.parrentCodes = [];
      state.workingDays = 0;
      state.price = 0;
      state.editOrder = false;
      state.isPreOrder = false;
      state.order = null;
      state.patient = null;
      state.activeStep = 0;
    },
    setParrentCodes(state, action) {
      state.parrentCodes = action.payload;
    },
    setErrorFalse(state) {
      state.error = false;
      state.conflict = false;
      state.message = '';
    },
    setCreatedDate(state, action) {
      state.createdDate = action.payload;
    },
    setPreOrder(state, action) {
      state.isPreOrder = true;
      state.contract = action.payload?.contract || null;
      state.clientUnit = action.payload?.clientUnit || null;
      state.patient = action.payload.patient;
      state.order = action.payload;
    },
    unenablePreOrder(state) {
      state.isPreOrder = false;
    },
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    setOpen(state, action) {
      state.open = action.payload;
    }
  },
  extraReducers: {
    [fetchByCalculator.pending]: (state) => {
      state.error = false;
      state.loading = true;
    },
    [fetchByCalculator.fulfilled]: (state, action) => {
      state.loading = false;
      try {
        const response = action.payload;
        if (response.isSuccess) {
          state.parrentCodes = response.parrentCodes;
          state.price = response.serviceInfo.totalPrice;
          state.workingDays = response.serviceInfo.totalWorkingDays;

          const { services } = response.serviceInfo;
          if (services?.length > 0) {
            const ids = services.map((item) => item.code);
            state.serviceCodes = ids;
            state.addedServices = services;
          }

          if (response.biomaterials) {
            const { isSuccess, substances } = response.biomaterials;

            if (isSuccess) {
              state.biomaterials = substances;
            }
          } else {
            state.biomaterials = [];
          }
        } else {
          if (!response.checkServices.isSuccess) {
            state.conflict = response.checkServices.yes;
            state.message = response.checkServices.statusMessage;
          } else {
            state.message = response.statusMessage;
          }
          state.error = true;
        }
      } catch (e) {
        state.loading = false;
        state.error = true;
        state.message = e.message;
      }
    },
    [fetchByCalculator.rejected]: (state, action) => {
      state.error = true;
      state.message = action.payload ? action.payload : errorHelper.common;
      state.loading = false;
    },
  },
});
export { fetchByCalculator };
export const {
  setPatient,
  setErrorFalse,
  setParrentCodes,
  setEditOrder,
  setCreatedDate,
  clearBasket,
  clearContracts,
  resetBasket,
  setPreOrder,
  unenablePreOrder,
  setActiveStep,
  setOpen,
} = basketReducer.actions;
export default basketReducer.reducer;
