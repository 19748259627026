import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Box, Paper, Typography } from '@material-ui/core';
import './Results.scss';
import '../../_helpers/styles/Header.scss';
import ServiceFormGroup from '../ServiceFormGroup/ServiceFormGroup';
import ResultTable from './ResultTable';
import {
  fetchByResults,
  setOrderState,
  setPrevContracts,
  setPreviousBody,
  setStatus,
  resetOrders,
  changePage,
} from '../../_reducers/resultsReducer';
import Contract from '../../_enchancers/Contract';
import fetchStatus from '../../_constants/fetchStatus';
import CustomProgress from '../_components/CustomProgress';

function formatDate(date) {
  return date !== null ? format(date, 'yyyyMMdd') : null;
}

const Results = ({ pageSize, previousContracts }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order = useSelector((state) => state.resultsReducer.order);
  const orderBy = useSelector((state) => state.resultsReducer.orderBy);
  const message = useSelector((state) => state.resultsReducer.message);
  const previousBody = useSelector(
    (state) => state.resultsReducer.previousBody
  );
  const status = useSelector((state) => state.resultsReducer.status);
  const error = status === fetchStatus.REJECTED;
  const loading = status === fetchStatus.PENDING;
  const fulfilled = status === fetchStatus.FULFILLED;

  const { executeRecaptcha } = useGoogleReCaptcha();

  const fetchNewResults = async (body, newPage, size) => {
    dispatch(setStatus(fetchStatus.PENDING));
    const token = await executeRecaptcha('results_page');

    const bodyWithPagination = {
      ...body,
      pageNumber: newPage + 1,
      pageSize: size,
    };

    dispatch(
      fetchByResults({
        body: bodyWithPagination,
        reCaptchaToken: token,
        formContracts: previousContracts,
      })
    );
  };

  const getResultByRequestSort = async (newOrder, newOrderBy) => {
    dispatch(setStatus(fetchStatus.PENDING));
    const token = await executeRecaptcha();

    const body = {
      ...previousBody,
      sorting: {
        name: newOrderBy,
        isAsc: newOrder === 'asc',
      },
    };

    const bodyWithPagination = {
      ...body,
      pageNumber: 1,
      pageSize,
    };

    dispatch(setPreviousBody(body));
    dispatch(
      setOrderState({
        orderBy: newOrderBy,
        order: newOrder,
      })
    );

    dispatch(
      fetchByResults({
        body: bodyWithPagination,
        reCaptchaToken: token,
        formContracts: previousContracts,
      })
    );
  };

  const GetResults = async (data) => {
    const {
      cabinetOrderStatus,
      barcode,
      lastName,
      firstName,
      createdDate,
      resultDate,
      contract,
      clientUnit,
    } = data;

    dispatch(setStatus(fetchStatus.PENDING));
    const token = await executeRecaptcha('results_page');

    const createdDateStart = formatDate(createdDate[0]);
    const createdDateEnd = formatDate(createdDate[1]);

    const resultDateStart = formatDate(resultDate[0]);
    const resultDateEnd = formatDate(resultDate[1]);

    const body = {
      cabinetOrderStatus,
      clientUnitId: clientUnit ? clientUnit.id : null,
      lastName: lastName || null,
      firstName: firstName || null,
      createdDateStart,
      createdDateEnd,
      resultDateStart,
      resultDateEnd,
      sorting: {
        name: orderBy,
        isAsc: order === 'asc',
      },
    };
    if (barcode) {
      body.barcode = barcode.toString();
    }

    let formContracts;

    formContracts = {
      Contracts: Contract.getContractId(contract.id),
    };

    if (clientUnit) {
      formContracts = {
        ...formContracts,
        ClientUnit: Contract.getClientUnitId(clientUnit.id, contract),
      };
    }
    dispatch(setPrevContracts(formContracts));
    dispatch(setPreviousBody(body));
    dispatch(changePage(0));
    const bodyWithPagination = {
      ...body,
      pageNumber: 1,
      pageSize,
    };

    dispatch(
      fetchByResults({
        body: bodyWithPagination,
        reCaptchaToken: token,
        formContracts,
      })
    );
  };

  const setFormData = (data) => {
    GetResults(data);
  };

  useEffect(
    () => () => {
      dispatch(resetOrders());
    },
    []
  );

  return (
    <>
      <header className="header">
        <div className="header__wrapper">
          <h1 className="header__title">{t('Result.Title')}</h1>
        </div>
      </header>
      <main id="resultsId" className="services">
        <Paper className="results__paper">
          <ServiceFormGroup setFormData={setFormData} />
          {fulfilled && (
            <ResultTable
              getResults={fetchNewResults}
              getResultByRequestSort={getResultByRequestSort}
            />
          )}
        </Paper>
        {loading && <CustomProgress />}
        {error && (
          <Box textAlign="center" p={3}>
            <Typography variant="h6" color="error">
              {message}
            </Typography>
          </Box>
        )}
      </main>
    </>
  );
};

const mapState = (state) => ({
  pageSize: state.resultsReducer.pageSize,
  pageNumber: state.resultsReducer.page,
  previousContracts: state.resultsReducer.previousContracts,
});

Results.propTypes = {
  previousContracts: PropTypes.shape({
    Contracts: PropTypes.number,
    ClientUnit: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.number,
    ]),
  }),
  pageSize: PropTypes.number.isRequired,
};

Results.defaultProps = {
  previousContracts: null,
};

export default connect(mapState)(Results);
