import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Circle } from '../../img/green.svg';

const TubesTable = ({ biometerials, fullHeight }) => {
  const { t } = useTranslation();
  return (
    <div className="basket ">
      {biometerials?.length > 0 && (
        <>
          <div className="">
            <h4 className="basket__title basket__title-padding">
              {t('CreateOrder.Tubes')}
            </h4>
          </div>
          <TableContainer
            className={clsx('syn-scroll', {
              'basket__tubes-container': !fullHeight,
            })}
          >
            <Table stickyHeader className="service-list">
              <TableHead>
                <TableRow className="">
                  <TableCell className="basket__cell basket__head-cell basket__head-cell--tubes">
                    {t('CreateOrder.Name')}
                  </TableCell>
                  <TableCell className="basket__cell basket__head-cell basket__head-cell--tubes">
                    {t('CreateOrder.Basket.amount')}
                  </TableCell>
                  <TableCell className="basket__cell basket__head-cell basket__head-cell--tubes" />
                </TableRow>
              </TableHead>
              <TableBody>
                {biometerials.map((row, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow
                    key={row.name + index}
                    className="service-list__row"
                  >
                    <TableCell className="service-list__cell basket__cell">
                      {row.name}
                    </TableCell>
                    <TableCell className="service-list__cell basket__cell">
                      {row.count}
                    </TableCell>
                    <TableCell className="service-list__cell basket__cell">
                      <Circle
                        className="service-list__circle"
                        style={{ color: row.tubeColor }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default TubesTable;
