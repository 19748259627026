import React, { useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import AdminDialog from '../../_components/AdminDialog';
import DiscountSwitch from '../_helpers/DiscountSwitch';
import './EditDiscount.scss';
import AdminContent from '../../_components/AdminContent';
import AdminActions from '../../_components/AdminActions';
import BaseFilledInput from '../../../Inputs/BaseFilledInput/BaseInput';
import errorHelper from '../../../../_constants/errorHelper';
import StyledKeyboardDatePicker from '../../../Inputs/StyledKeyboardDatePicker';
import fetchStatus from '../../../../_constants/fetchStatus';
import ApiClient from '../../../../ApiClient/ApiClient';
import FullNameField from '../Field/FullNameField';
import formatDate from '../_helpers/formatDate';
import LoadingButton from '../../../_components/LoadingButton';

const getFormatDate = (stringDate) => {
  const [day, month, year] = stringDate.split('.');
  return new Date(year, parseInt(month, 10) - 1, day);
};

const StringToDate = (stringDate) =>
  stringDate.split(' - ').map((item) => {
    const formatedString = item.trim();

    const date = getFormatDate(formatedString);
    return formatDate(date, 'yyyyMMdd');
  });

const EditCardDialog = ({ data, open, onClose }) => {
  const { t } = useTranslation();
  const startEndValidity = `${data.startValidity} - ${data.endValidity}`;

  const { control, handleSubmit, formState, errors, setValue, reset } = useForm(
    {
      defaultValues: {
        cardNumber: data.cardNumber,
        fullName: data?.customer?.fullName,
        birthday:
          data?.customer?.birthday && getFormatDate(data.customer.birthday),
        contract: data.contract.name,
        discountType: data.discountType.value,
        timePeriod: data.periodTypeOfUses.name,
        isActive: data.isActive,
        possibleNumberOfUses: data.possibleNumberOfUses,
        periodTypeOfUses: data.periodTypeOfUses.name,
        startEndValidity,
      },
    }
  );
  const { isDirty, isSubmitting } = formState;

  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const success = status === fetchStatus.FULFILLED;
  const wasFetched = status !== fetchStatus.IDLE;

  const fetchByChangeDiscountCard = (formData) => {
    try {
      const [startValidity, endValidity] = StringToDate(
        formData.startEndValidity
      );

      const fetchData = {
        discountCardId: data.id,
        contractId: data.contract.id,
        discountTypeId: data.discountType.id,
        startValidity,
        endValidity,
        isActive: formData.isActive,
        possibleNumberOfUses: formData.possibleNumberOfUses,
        periodTypeOfUsesId: data.periodTypeOfUses.id,
        customer: {
          id: data.customer.id,
          fullName: formData.fullName,
          birthday: formatDate(formData.birthday),
        },
      };

      return ApiClient.UpdateDiscountCard(fetchData)
        .then((res) => {
          if (res) {
            if (res.isSuccess) {
              setStatus(fetchStatus.FULFILLED);
              reset(formData);
              return setMessage(
                t('Admin:Discount.editCardSuccessMessage', {
                  cardNumber: formData.cardNumber,
                })
              );
            }
            throw new Error(res.statusMessage);
          }
          throw new Error(errorHelper.common);
        })
        .catch((e) => {
          setMessage(e.message);
          setStatus(fetchStatus.REJECTED);
        });
    } catch (e) {
      setMessage(e.message);
      setStatus(fetchStatus.REJECTED);
    }
  };

  const onSubmit = async (formData) => fetchByChangeDiscountCard(formData);

  const handleSetIsActive = (value) => {
    setValue('isActive', value);
  };

  return (
    <AdminDialog
      className="dialog edit-discount"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="dialog__header"
      >
        <h2 className="dialog__title">{t('Admin:Discount.cardEdit')}</h2>
        <Controller
          control={control}
          name="isActive"
          render={({ value, onChange }) => (
            <DiscountSwitch
              end
              onSuccess={handleSetIsActive}
              id={[data.id]}
              value={value}
              onChange={onChange}
              className="edit-discount__switch"
            />
          )}
        />
      </Box>
      <AdminContent>
        {wasFetched && (
          <Box marginBottom={3}>
            <Alert color={success ? 'success' : 'error'}>{message}</Alert>
          </Box>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="cardNumber"
              control={control}
              render={({ value, onChange }) => (
                <BaseFilledInput
                  card
                  readOnly
                  label={t('Admin:Discount.cardNumber2')}
                  value={value}
                  errors={errors.cardNumber}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="fullName"
              control={control}
              rules={{ required: errorHelper.required }}
              render={({ value, onChange }) => (
                <FullNameField
                  label={t('Input.FullName')}
                  value={value}
                  name="fullName"
                  onChange={onChange}
                  errors={errors.fullName}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="birthday"
              control={control}
              rules={{ required: errorHelper.required }}
              render={({ value, onChange }) => (
                <StyledKeyboardDatePicker
                  label={t('Input.DateOfBirth')}
                  value={value}
                  name="dateOfBirth"
                  onChange={onChange}
                  errors={errors.birthday}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="contract"
              control={control}
              render={({ value, onChange }) => (
                <BaseFilledInput
                  readOnly
                  card
                  label={t('Input.Contract')}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="discountType"
              control={control}
              render={({ value, onChange }) => (
                <BaseFilledInput
                  label={t('Admin:Discount.discountType')}
                  value={value}
                  card
                  readOnly
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="startEndValidity"
              control={control}
              render={({ value, onChange }) => (
                <BaseFilledInput
                  card
                  readOnly
                  label={t('Admin:Discount.cardValidity')}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="timePeriod"
              control={control}
              render={({ value, onChange }) => (
                <BaseFilledInput
                  card
                  label={t('Admin:Discount.timePeriod')}
                  readOnly
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="possibleNumberOfUses"
              control={control}
              rules={{
                required: errorHelper.required,
              }}
              render={({ value, onChange }) => (
                <BaseFilledInput
                  card
                  label={`${t('Admin:Discount.possibleNumberOfUses')}*`}
                  value={value}
                  readOnly
                  onChange={(field) => onChange(field.floatValue)}
                />
              )}
            />
          </Grid>
        </Grid>
      </AdminContent>
      <AdminActions>
        <Button className="dialog__btn dialog__cancel" onClick={onClose}>
          {t('Common.Cancel')}
        </Button>
        <LoadingButton
          loading={isSubmitting}
          type="submit"
          className="dialog__btn dialog__submit"
          disabled={!isDirty || isSubmitting}
        >
          {t('Common.Save')}
        </LoadingButton>
      </AdminActions>
    </AdminDialog>
  );
};

EditCardDialog.propTypes = {
  data: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditCardDialog;
