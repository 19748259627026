import { Button, Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AdminActions from '../../Admin/_components/AdminActions';
import AdminTitle from '../../Admin/_components/AdminTitle';

const DeleteDialog = ({ open, onClose, handleDelete }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} className="dialog">
      <AdminTitle>{`${t('CreateOrder.Basket.clear')}?`}</AdminTitle>
      <AdminActions>
        <Button className="dialog__btn dialog__cancel" onClick={onClose}>
          {t('Common.Cancel')}
        </Button>
        <Button className="dialog__btn dialog__submit" onClick={handleDelete}>
          {t('Common.Delete')}
        </Button>
      </AdminActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default DeleteDialog;
