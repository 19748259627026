const downloadFile = (file, name) => {
  const a = document.createElement('a');
  a.href = file;
  a.download = name;
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(file);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
};

const removeSpecSymbols = (str) => str.replace(/[\.\,\/]/gm, '');

const getFileName = (patient, barcode) =>
  `${removeSpecSymbols(patient.lastName)}_${removeSpecSymbols(
    patient.firstName
  )}_${barcode}`;

export default { downloadFile, getFileName };
