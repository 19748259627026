import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { toNumber } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from '../../../_constants/Roles';
import RBAC from '../../../_helpers/RBAC';
import CardPrefixPage from './CardPrefix/CardPrefixPage';
import CurrentDicountPage from './CurrentDiscount/CurrentDiscountPage';

const pages = [<CurrentDicountPage />, <CardPrefixPage />];

const Discount = () => {
  const { t } = useTranslation('Admin');

  const [value, setValue] = React.useState(0);

  const handleChange = (event) => {
    setValue(toNumber(event.currentTarget.value));
  };

  return (
    <div className="mail tabs">
      <RBAC allowedRoles={[Role.Admin]}>
        <div className="tabs__container">
          <div className="mail__tabs">
            <Button
              value={0}
              onClick={handleChange}
              className={clsx('mail__button tabs__button', {
                mail__button_selected: value === 0,
              })}
            >
              {t('Title.CurrentDiscount')}
            </Button>
            <Button
              value={1}
              onClick={handleChange}
              className={clsx('mail__button', {
                mail__button_selected: value === 1,
              })}
            >
              {t('Title.CardPrefix')}
            </Button>
          </div>
        </div>
      </RBAC>

      <div>{pages[value]}</div>
    </div>
  );
};

export default Discount;
