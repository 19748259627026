import ApiClient from '../ApiClient/ApiClient';
import i18n from '../i18n';
import store from '../_enchancers/configureStore';
import { setServices, setError } from '../_reducers/serviceReducer';

const fetchByService = async (
  term,
  recaptcha,
  Contracts,
  ClientUnit,
  searchStatus = true
) => {
  await ApiClient.ServiceSearch(term, recaptcha, {
    Contracts,
    ClientUnit,
  })
    .then((res) => {
      if (res) {
        if (res.services.length > 0) {
          return store.dispatch(
            setServices({ services: res.services, search: searchStatus })
          );
        }
      }
      throw Error(i18n.t('Errors.ServicesError'));
    })
    .catch((e) => {
      store.dispatch(setError(e.message));
    });
};

const calculator = async (
  codes,
  gender,
  reCaptchaToken,
  Contracts,
  isCalculateByContract = false,
  isBiomaterial = false
) => ApiClient.CalculateServices(
  codes,
  gender,
  reCaptchaToken,
  Contracts,
  isCalculateByContract,
  isBiomaterial
);

const service = async (
  contractId,
  page,
  pageSize,
  token,
  search,
  Contracts
) => {
  if (search) {
    return ApiClient.ServiceSearch(search, token, Contracts);
  }
  return ApiClient.Service(contractId, page, pageSize, token, Contracts);
};

const orderCreateService = { search: fetchByService, calculator, service };

export default orderCreateService;
