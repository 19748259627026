import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import Content from '../Components/Content/Content';
import Error404 from '../Components/Error404/Error404';
import Error500 from '../Components/Error500/Error500';
import News from '../Components/News/Info';
import CreateOrderPage from '../Components/OrderCreate/CreateOrderPage';
import PasswordCreate from '../Components/PasswordCreate/PasswordCreate';
import PasswordRecovery from '../Components/PasswordRecovery/PasswordRecovery';
import Registration from '../Components/Registration/Registration';
import Results from '../Components/Results/Results';
import { AppRoute } from '../const/app-routes';
import PrivateRoute from '../_helpers/PrivateRoute';
import Login from '../Components/Login/Login';
import { AllowedRoles, isSatisfiedRoles } from '../_constants/Roles';
import Admin from '../Components/Admin/Admin';

import adminRu from '../locales/ru/Admin.json';
import adminEn from '../locales/en/Admin.json';
import adminBe from '../locales/be/Admin.json';
import OrderingCompleted from '../Components/OrderCreate/OrderingCompleted/OrderingCompleted';
import SortPage from '../Components/Sort/SortPage';
import useLangRoute from '../_helpers/scripts/useLangRoute';
import Fallback from '../Components/_components/Fallback';

const Settings = React.lazy(() => import('../Components/Settings/Settings'));

const Routes = () => {
  const [, i18n] = useTranslation();
  const route = useLangRoute();
  const matchedPath = i18n.language === 'ru' ? '' : `/${i18n.language}`;
  const currentUser = useSelector((state) => state.userReducer.user);
  const userRoleId = useSelector((state) => state.userReducer.userRoles?.id);

  const loadResources = () => {
    i18n.addResourceBundle('ru', ['Admin'], adminRu);
    i18n.addResourceBundle('be', ['Admin'], adminBe);
    i18n.addResourceBundle('en', ['Admin'], adminEn);
  };

  const handleRenderByRole = () => {
    if (currentUser) {
      if (
        userRoleId &&
        isSatisfiedRoles(AllowedRoles.CreateOrder, userRoleId)
      ) {
        return <Redirect to={route(AppRoute.CreateOrder)} />;
      }
      return <Redirect to={route(AppRoute.News)} />;
    }
    return <Login />;
  };

  return (
    <Suspense fallback={<Fallback />}>
      <Switch>
        <Route path={route('/')} exact render={handleRenderByRole} />
        <Route path={route(AppRoute.Login)} exact render={handleRenderByRole} />
        <Route
          path={`${matchedPath}${AppRoute.Registration}`}
          exact
          component={Registration}
        />
        <Route
          path={`${matchedPath}${AppRoute.PasswordRecovery}`}
          exact
          component={PasswordRecovery}
        />
        <Route
          path={`${matchedPath}${AppRoute.CreatePassword}`}
          exact
          component={PasswordCreate}
        />
        <Route
          path={`${matchedPath}${AppRoute.Error404}`}
          exact
          component={Error404}
        />
        <Route
          path={`${matchedPath}${AppRoute.CreateListForPatient}`}
          exact
          component={Error404}
        />
        <PrivateRoute
          path={`${matchedPath}${AppRoute.Results}`}
          exact
          roles={AllowedRoles.Results}
          component={() => (
            <Content>
              <Results />
            </Content>
          )}
        />
        <PrivateRoute
          path={`${matchedPath}${AppRoute.News}`}
          component={({ match }) => (
            <Content removeContainer>
              <News match={match} />
            </Content>
          )}
        />
        <PrivateRoute
          path={`${matchedPath}${AppRoute.Settings}`}
          roles={AllowedRoles.Settings}
          component={() => (
            <Content>
              <Settings />
            </Content>
          )}
        />
        <PrivateRoute
          path={`${matchedPath}${AppRoute.Admin}`}
          roles={AllowedRoles.Admin}
          component={() => {
            loadResources();

            return (
              <Content>
                <Admin />
              </Content>
            );
          }}
        />
        <PrivateRoute
          path={`${matchedPath}${AppRoute.Sort}`}
          roles={AllowedRoles.Sort}
          component={({ match }) => (
            <Content>
              <SortPage match={match} />
            </Content>
          )}
        />
        <PrivateRoute
          path={`${matchedPath}${AppRoute.CreateOrderSuccess}`}
          exact
          roles={AllowedRoles.CreateOrder}
          component={() => (
            <Content>
              <OrderingCompleted />
            </Content>
          )}
        />
        <PrivateRoute
          path={`${matchedPath}${AppRoute.CreateOrder}`}
          roles={AllowedRoles.CreateOrder}
          component={() => (
            <Content>
              <CreateOrderPage />
            </Content>
          )}
        />
        <Route
          path={`${matchedPath}${AppRoute.Error500}`}
          render={() => <Error500 />}
        />
        <Route path="*" render={() => <Error404 />} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
