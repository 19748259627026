import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ContractAutocomplete from '../Inputs/ContractAutocomplete';

import { setContract } from '../../_reducers/contractReducer';
import useContract from './useContract';

const ContractField = ({
  errors,
  allowSelectAllContracts,
  value,
  onChange,
  onClear,
  callbackOnChange,
  variant,
  className,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { contracts, currentContract } = useContract(allowSelectAllContracts);

  const fieldValue = value || currentContract;

  const onContractChange = (newContract) => {
    if (callbackOnChange && newContract?.id !== currentContract?.id) {
      callbackOnChange();
    }
    if (onClear && newContract === null) {
      onClear();
    }
    if (!newContract || newContract?.id !== -1) {
      dispatch(setContract(newContract));
    }
    onChange(newContract);
  };

  return (
    <ContractAutocomplete
      className={className}
      errors={errors}
      options={contracts}
      limitTags={2}
      filled={variant === 'filled'}
      value={fieldValue}
      onChange={(newVal) => onContractChange(newVal)}
      label={t('Input.Contract')}
    />
  );
};

ContractField.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string,
  }),
  allowSelectAllContracts: PropTypes.bool,
  value: PropTypes.shape({ name: PropTypes.string, id: PropTypes.number }),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  callbackOnChange: PropTypes.func,
  variant: PropTypes.oneOf(['filled', 'outlined']),
};

ContractField.defaultProps = {
  errors: null,
  allowSelectAllContracts: false,
  value: null,
  onClear: undefined,
  callbackOnChange: undefined,
  variant: 'outlined',
  onChange: () => {},
  className: '',
};

export default ContractField;
