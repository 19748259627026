import { DialogContent } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const AdminContent = ({ center, children, className }) => (
  <DialogContent
    className={clsx(
      'a-dialog__content',
      'syn-scroll',
      center && ' a-dialog__container-center',
      className
    )}
  >
    {children}
  </DialogContent>
);

AdminContent.propTypes = {
  center: PropTypes.bool,
  className: PropTypes.string,
};

AdminContent.defaultProps = {
  className: undefined,
  center: false,
};

export default AdminContent;
