import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import NestedTable from '../NestedTable/NestedTable';
import FormatStatusId from '../../../_helpers/scripts/FormatStatusId';
import PrintDirectionButton from '../ServicesDialog/PrintDirectionButton';
import DeleteOrderButton from '../ServicesDialog/DeleteOrderButton';
import RestoreOrderButton from '../ServicesDialog/RestoreOrderButton';
import EditOrder from '../ServicesDialog/EditOrder';
import TableCheckbox from '../TableComponents/TableCheckbox';
import { sortState } from '../../../_constants/OrderStatusConst';
import SortState from '../SortState';
import getPatientFullName from '../../../_helpers/scripts/getPatientFullName';

const ArrowsButton = () => (
  <div className="services-table__collapse-icons">
    <ExpandLess />
    <ExpandMore />
  </div>
);

const MobileRow = ({
  row, isItemSelected, labelId, setSelected, modifed
}) => {
  const [open, setOpen] = useState();
  const [hidden, setHidden] = useState(false);
  const tableCellRef = useRef('cell');
  const { t } = useTranslation();

  const handleOpenNestedTable = () => {
    setOpen(!open);
  };
  return (
    <>
      <TableRow
        hidden={hidden}
        onClick={handleOpenNestedTable}
        className={clsx('services-table__row--first-row services-table__row', {
          filled: open,
          modifed,
        })}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.barcode}
        selected={isItemSelected}
      >
        <TableCell
          rowSpan={2}
          ref={tableCellRef}
          style={{ height: open ? tableCellRef.current.scrollHeight : 49 }}
          className="services-table__cell services-table__collapse-btn"
        >
          <Button
            type="button"
            className="services-table__collapse-btn"
            style={{ height: '100%' }}
          >
            <ArrowsButton />
          </Button>
        </TableCell>
        <TableCheckbox
          className="services-table__cell services-table__cell--checkbox"
          setSelected={setSelected}
          barcode={row.barcode}
          labelId={labelId}
          isItemSelected={isItemSelected}
        />
        <TableCell scope="row" className="services-table__cell">
          {getPatientFullName(row.patient)}
        </TableCell>
        <TableCell className="services-table__cell">{row.barcode}</TableCell>
        <TableCell className="services-table__cell">
          {FormatStatusId(row.sortingState)}
        </TableCell>
      </TableRow>
      <TableRow
        hidden={hidden}
        selected={isItemSelected}
        className={clsx('services-table__row--second-row services-table__row', {
          filled: open,
          modifed,
        })}
      >
        <TableCell colSpan={5} className="services-table__cell">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table className="collapsed-table">
              <TableBody>
                <TableRow className="collapsed-table__row">
                  <TableCell
                    className="collapsed-table__cell-th"
                    component="th"
                  >
                    {t('Sort.Cotract')}
                  </TableCell>
                  <TableCell
                    align="right"
                    className="collapsed-table__cell collapsed-table__cell--padding-right"
                  >
                    {row.contract.name}
                  </TableCell>
                </TableRow>
                <TableRow className="collapsed-table__row">
                  <TableCell
                    className="collapsed-table__cell-th"
                    component="th"
                  >
                    {t('Sort.DateOfBirth')}
                  </TableCell>
                  <TableCell
                    align="right"
                    className="collapsed-table__cell collapsed-table__cell--padding-right"
                  >
                    {row.patient.dateOfBirth}
                  </TableCell>
                </TableRow>
                <TableRow className="collapsed-table__row">
                  <TableCell
                    className="collapsed-table__cell collapsed-table__cell--padding-right"
                    colSpan={2}
                  >
                    <Box gridGap={4} display="flex">
                      <SortState allowedState={[sortState.NEW]}>
                        <DeleteOrderButton
                          hideRow={setHidden}
                          barcode={row.barcode}
                        />
                      </SortState>
                      <SortState allowedState={[sortState.CANCELLED]}>
                        <RestoreOrderButton
                          hideRow={setHidden}
                          barcode={row.barcode}
                        />
                      </SortState>
                      <SortState allowedState={[sortState.NEW]}>
                        <EditOrder barcode={row.barcode} />
                      </SortState>
                      <PrintDirectionButton barcode={row.barcode} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <NestedTable services={row.services} barcode={row.barcode} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapState = (state) => ({
  modifed: state.sortReducer.isModifed,
});

export default connect(mapState)(MobileRow);
