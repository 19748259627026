const getExtraFields = (formData) => {
  const fieldsToExclude = ['commissionaire', 'barcode', 'paymentChannel', 'remarks'];
  const entries = Object.entries(formData).filter(
    ([name, value]) => value && !fieldsToExclude.includes(name)
  );

  if (entries.length > 0) {
    return Object.fromEntries(entries);
  }
  return null;
};

export default getExtraFields;
