import React, { useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import RemoveServiceButton from '../ServicesDialog/RemoveServiceButton';
import { sortState } from '../../../_constants/OrderStatusConst';
import RBAC from '../../../_helpers/RBAC';
import { Role } from '../../../_constants/Roles';

const NestedTableRow = ({ service, barcode, sortingState }) => {
  const [canceled, setCanceled] = useState(false);

  const couldServiceDeleted = sortingState === sortState.NEW
  || sortingState === sortState.CANCELLED;
  return (
    <TableRow
      className={clsx(
        'nested-table__row',
        canceled && 'nested-table__row_canceled'
      )}
    >
      <TableCell className="nested-table__cell">
        {service.serviceCode}
      </TableCell>
      <TableCell
        className="nested-table__cell"
        colSpan={couldServiceDeleted ? 1 : 2}
      >
        {service.serviceName}
      </TableCell>
      <RBAC allowedRoles={[Role.Admin, Role.Manager, Role.Nurse, Role.Nurse, Role.Sorter, Role.User]}>   
        {couldServiceDeleted && (
          <TableCell
            padding="checkbox"
            colSpan="2"
            className="nested-table__cell"
          >
            <RemoveServiceButton
              serviceCode={service.serviceCode}
              barcode={barcode}
              setCanceled={setCanceled}
            />
          </TableCell>
        )}
      </RBAC>
    </TableRow>
  );
};

NestedTableRow.propTypes = {
  barcode: PropTypes.number.isRequired,
  service: PropTypes.shape({
    serviceId: PropTypes.number,
    serviceCode: PropTypes.string,
    serviceName: PropTypes.string,
  }).isRequired,
  sortingState: PropTypes.number.isRequired
};

const mapState = (state) => ({
  sortingState: state.sort.sortingState,
});

export default connect(mapState)(NestedTableRow);
