import React from 'react';
import { useTranslation } from 'react-i18next';
import './Fallback.scss';

const Fallback = () => {
  const { t } = useTranslation();
  return (
    <div className="fallback">
      <p className="fallback__text">{t('Loading')}</p>
    </div>
  );
};

export default Fallback;
