import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PreOrderService = ({ setStep }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.preOrder) {
      setStep(2);
    }

    return () => {
      if (location.state?.preOrder) {
        location.state.preOrder = null;
      }
    };
  }, []);

  return null;
};

export default PreOrderService;
