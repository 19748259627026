import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { ru, enAU, be } from 'date-fns/locale';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from './img/date.svg';
import StyledTextField from './StyledTextField';

const locales = {
  ru,
  en: enAU,
  be,
};

const StyledKeyboardDatePicker = ({
  icon,
  id,
  onChange,
  value,
  errors,
  label,
  name,
  className,
  onFocus,
  format,
  maxDate,
  maxDateMessage,
  inputVariant,
  disablePast,
  shouldDisableDate,
  ...props
}) => {
  const error = Boolean(errors?.message);
  const { i18n } = useTranslation();

  const locale = React.useMemo(() => locales[i18n.language], [i18n.language]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <KeyboardDatePicker
        {...props}
        onFocus={onFocus}
        fullWidth
        TextFieldComponent={StyledTextField}
        format={format}
        inputVariant={inputVariant}
        label={label}
        error={error}
        helperText={errors?.message}
        maxDateMessage={maxDateMessage}
        name={name}
        maxDate={maxDate}
        KeyboardButtonProps={{
          color: 'primary',
        }}
        disablePast={disablePast}
        keyboardIcon={<CalendarIcon />}
        onChange={onChange}
        shouldDisableDate={shouldDisableDate}
        value={value}
        aria-invalid={error ? 'true' : 'false'}
      />
    </MuiPickersUtilsProvider>
  );
};

StyledKeyboardDatePicker.propTypes = {
  format: PropTypes.string,
  errors: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  maxDate: PropTypes.instanceOf(Date),
};

StyledKeyboardDatePicker.defaultProps = {
  format: 'dd.MM.yyyy',
  errors: {
    type: 'manual',
    message: '',
  },
  maxDate: new Date(),
};

export default StyledKeyboardDatePicker;
