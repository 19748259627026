import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import preOrderStatusHelper from '../../../_helpers/preOrderStatusHelper';
import StyledTextField from '../../Inputs/StyledTextField';

const PreOrderStatusField = ({ value, onChange }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (options.length === 0) {
        const arr = [];
        preOrderStatusHelper.all.forEach((val, key) => {
            arr.push(<MenuItem key={key} value={key}>{t(`PreOrder.Status.${val}`)}</MenuItem>);
        });
        setOptions(arr);
    }
  }, [t, options]);

  return (
    <StyledTextField
      variant="filled"
      fullWidth
      select
      label={t('Input.OrderStatus')}
      name="OrderStatus"
      id="OrderStatusId"
      onChange={onChange}
      value={value}
    >
      {options}
    </StyledTextField>
  );
};

PreOrderStatusField.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PreOrderStatusField;
