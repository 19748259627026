import {
  Box,
  Collapse,
  Hidden,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import paymentChannelHelper from '../../../_helpers/paymentChannelHelper';
import StyledTableCell from '../../_components/StyledTableCell';

import CreateOrderButton from './CreatePreOrderButton';
import TableCollapseButton from '../TableCollapseButton';
import StyledTableRow from '../../_components/StyledTableRow';

const useStyles = makeStyles((theme) => ({
  borderBottom: {
    borderBottom: 'none !important',
  },
  headCell: {
    fontSize: '0.75rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.palette.title.main,
    borderBottom: `1px solid ${theme.palette.blueLightPlus.main}`,
  },
  nestedTableRow: {
    '&:last-child  .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  nestedTableCell: {
    [theme.breakpoints.up('sm')]: {
      padding: '10px 16px',
    },
    padding: '6px 8px',
    '&:first-child': {
      paddingLeft: 0,
    },
  },
  minWidth: {
    minWidth: 85,
  },
}));

const PreOrderRow = ({ row, isLastRow }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen((val) => !val);
  };

  const [hide, setHide] = useState(false);

  const buttonClassName = clsx({
    [classes.borderBottom]: isLastRow,
  });
  const theme = useTheme();

  const showSm = useMediaQuery('(min-width:768px) ');
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <StyledTableRow
        onClick={mobile ? handleOpen : undefined}
        hidden={hide}
        expanded={open}
        key={row.preOrderId}
      >
        <TableCollapseButton
          TableCellProps={{
            rowSpan: 2,
            padding: 'checkbox',
            className: buttonClassName,
          }}
          open={open}
          onClick={handleOpen}
        />
        <StyledTableCell className={classes.borderBottom}>
          {`${row.patient.lastName} ${row.patient.firstName}`}
        </StyledTableCell>
        <StyledTableCell className={classes.borderBottom}>
          {row.patient.dateOfBirth}
        </StyledTableCell>
        <StyledTableCell className={classes.borderBottom}>
          {row.barcode}
        </StyledTableCell>
        {showSm && (
          <StyledTableCell className={classes.borderBottom}>
            {paymentChannelHelper.all.get(row.paymentChannelId)}
          </StyledTableCell>
        )}
        <Hidden smDown>
          <StyledTableCell className={classes.borderBottom}>
            {row.created}
          </StyledTableCell>
        </Hidden>
        <StyledTableCell className={classes.borderBottom} padding="checkbox">
          <Box display="flex" alignItems="center">
            <CreateOrderButton
              size={mobile ? 'small' : 'medium'}
              barcode={row.barcode}
              contract={row.contract}
            />
          </Box>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow expanded={open} hidden={hide}>
        <StyledTableCell colSpan={8} padding="checkbox">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box px={{ xs: 1, sm: 2 }} maxWidth={758}>
              <Table>
                {!showSm && (
                  <TableRow>
                    <StyledTableCell
                      component="th"
                      className={clsx(
                        classes.headCell,
                        classes.nestedTableCell
                      )}
                    >
                      {t('Sort.Payment')}
                    </StyledTableCell>
                    <StyledTableCell className={classes.nestedTableCell}>
                      {paymentChannelHelper.all.get(row.paymentChannelId)}
                    </StyledTableCell>
                  </TableRow>
                )}
                <Hidden mdUp>
                  <TableRow>
                    <StyledTableCell
                      component="th"
                      className={clsx(
                        classes.headCell,
                        classes.nestedTableCell
                      )}
                    >
                      {t('Result.OrderDate')}
                    </StyledTableCell>
                    <StyledTableCell className={classes.nestedTableCell}>
                      {row.created}
                    </StyledTableCell>
                  </TableRow>
                </Hidden>
              </Table>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={clsx(
                        classes.headCell,
                        classes.nestedTableCell,
                        classes.minWidth
                      )}
                    >
                      {t('Sort.ServiceCode')}
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.headCell,
                        classes.nestedTableCell
                      )}
                    >
                      {t('Sort.Service')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.services.map((item) => (
                    <TableRow
                      className={classes.nestedTableRow}
                      key={item.serviceId}
                    >
                      <StyledTableCell className={classes.nestedTableCell}>
                        {item.serviceCode}
                      </StyledTableCell>
                      <StyledTableCell className={classes.nestedTableCell}>
                        {item.serviceName}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default PreOrderRow;
