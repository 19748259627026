import { Button } from '@material-ui/core';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NavLink, Redirect, Route, Switch
} from 'react-router-dom';
import { AppRoute } from '../../const/app-routes';
import { AllowedRoles } from '../../_constants/Roles';
import PrivateRoute from '../../_helpers/PrivateRoute';
import useLangRoute from '../../_helpers/scripts/useLangRoute';
import '../../_helpers/styles/SynTabs.scss';
import RBAC from '../../_helpers/RBAC';
import Sorting from './Sorting';
import PreOrderPage from './PreOrder/PreOrderPage';
import Fallback from '../_components/Fallback';

const OutsourcingPage = React.lazy(() => import('./Outsourcing/OutsourcePage'));

const SortPage = ({ match }) => {
  const { path } = match;
  const { t } = useTranslation();
  const route = useLangRoute();

  return (
    <>
      <header className="header">
        <div className="header__wrapper">
          <h1 className="header__title">{t('Sort.Title')}</h1>
        </div>
      </header>
      <div className="syn-tabs">
        <Button
          component={NavLink}
          to={path}
          exact
          disableTouchRipple
          className="syn-tabs__button"
          activeClassName="syn-tabs__button--selected"
        >
          {t('Sort.Title')}
        </Button>
        <RBAC allowedRoles={AllowedRoles.Outsource}>
          <Button
            component={NavLink}
            to={route(AppRoute.Outsourcing)}
            exact
            disableTouchRipple
            className="syn-tabs__button"
            activeClassName="syn-tabs__button--selected"
          >
            {t('Sort.Outsource')}
          </Button>
        </RBAC>
        <RBAC allowedRoles={AllowedRoles.PreOrder}>
          <Button
            component={NavLink}
            to={route(AppRoute.PreOrder)}
            exact
            disableTouchRipple
            className="syn-tabs__button"
            activeClassName="syn-tabs__button--selected"
          >
            {t('Sort.Preorders')}
          </Button>
        </RBAC>
      </div>
      <Suspense fallback={<Fallback />}>
        <Switch>
          <Route exact path={path} component={Sorting} />
          <PrivateRoute
            roles={AllowedRoles.Outsource}
            exact
            path={route(AppRoute.Outsourcing)}
            component={OutsourcingPage}
          />
          <PrivateRoute
            roles={AllowedRoles.PreOrder}
            exact
            path={route(AppRoute.PreOrder)}
            component={PreOrderPage}
          />
          <Redirect to={route(AppRoute.Error404)} />
        </Switch>
      </Suspense>
    </>
  );
};

export default SortPage;
