import React from 'react';
import { Role } from '../../../../_constants/Roles';
import PropTypes from 'prop-types';
import RBAC from '../../../../_helpers/RBAC';

const DiscountRBAC = ({ children }) => {
  return <RBAC allowedRoles={[Role.Admin]}>{children}</RBAC>;
};

DiscountRBAC.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DiscountRBAC;
