function setInputFilter(textbox, inputFilter, onChange) {
  [
    'input',
    'keydown',
    'keyup',
    'mousedown',
    'mouseup',
    'contextmenu',
    'drop',
  ].forEach((event) => {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
        onChange(this.value);
      } else if (this.hasOwnProperty('oldValue')) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = '';
      }
    });
  });
}

export default setInputFilter;
