import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialContainer from '@material-ui/core/Container';
import { Container, Grid } from '@material-ui/core';

import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Logo from './logo.svg';
import ImgLog from './img_log.svg';
import passIcon from '../Settings/img/password.svg';

import { matchedRoute } from '../../_helpers/func';
import { AppRoute } from '../../const/app-routes';
import CustomOutlinedInput from '../Inputs/BaseOutlinedInput/CustomOutlinedInput';
import LazyImg from '../../_helpers/LazyImg';
import '../Login/Login.scss';
import '../Registration/Registration.scss';
import { ChangeLanguageButton } from '../i18n/ChangeLanguageDialog';
import useQuery from '../../_helpers/scripts/useQuery';
import ApiClient from '../../ApiClient/ApiClient';
import useFetchResults from '../../_helpers/scripts/useFetchResults';
import useDialog from '../../_helpers/scripts/useDialog';
import AlertDialog from '../Dialogs/AlertDialog';
import SubmitButton from '../Login/SubmitButton';

const PasswordCreate = () => {
  const [t, i18n] = useTranslation();
  const {
    handleSubmit, control, errors, getValues
  } = useForm({
    mode: 'onChange',
  });

  const {
    loading,
    setLoading,
    error,
    setError,
    message,
    setMessage,
    success,
    setSuccess,
    resetValues,
  } = useFetchResults();
  const { open, handleOpen, handleClose } = useDialog();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const query = useQuery();

  const fetchByChangePassword = async (newPassword, confirmPassword, user) => {
    setLoading(true);
    const data = {
      user,
      newPassword,
      confirmPassword,
    };

    const token = await executeRecaptcha();

    await ApiClient.ChangeForgotPassword(data, token)
      .then((res) => {
        if (res) {
          setMessage(res.statusMessage);
          if (res.isSuccess) {
            setSuccess(true);
          } else {
            setError(true);
          }
        }
      })
      .catch((e) => {
        setMessage(e.message);
        setError(true);
      });
    setLoading(false);
    handleOpen();
  };

  const onSubmit = (data) => {
    const { newPassword, confirmPassword } = data;
    const user = query.get('id');

    resetValues();
    fetchByChangePassword(newPassword, confirmPassword, user);
  };

  const img = <LazyImg src={passIcon} />;

  return (
    <div className="login register">
      <Container className="login__container">
        <Grid container>
          <Grid xs={6}>
            <div className="logo-container">
              <NavLink to={matchedRoute(i18n.language, AppRoute.Login)}>
                <LazyImg
                  className="login__img-logo"
                  src={Logo}
                  width="160px"
                  height="70px"
                />
              </NavLink>
            </div>
          </Grid>
          <Grid xs={6} className="login__lang-btn">
            <ChangeLanguageButton />
          </Grid>
          <Grid xs={0} md={6} className="login__img-container">
            <div>
              <img
                className="login__img-fluid"
                src={ImgLog}
                alt=""
                loading="lazy"
              />
            </div>
          </Grid>
          <Grid xs={12} md={6}>
            <MaterialContainer component="main" maxWidth="xs">
              <CssBaseline />
              <div className="login__wrapper">
                <h1 className="login__form-title">
                  {t('PasswordRecovery.TitleRecovered')}
                </h1>
                <h2 className="login__form-sub-title">
                  {t('PasswordRecovery.SubtitleRecovered')}
                </h2>
                <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="login__form-item">
                    <Controller
                      name="newPassword"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t('ValidateErrors.Required'),
                        minLength: {
                          value: 8,
                          message: t('ValidateErrors.Min8Len'),
                        },
                      }}
                      render={({ onChange, value }) => (
                        <CustomOutlinedInput
                          password
                          icon={img}
                          label={t('Input.Password')}
                          value={value}
                          onChange={onChange}
                          errors={errors.newPassword}
                        />
                      )}
                    />
                  </div>
                  <div className="login__form-item">
                    <Controller
                      name="confirmPassword"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t('ValidateErrors.Required'),
                        validate: () => (getValues('newPassword')
                          !== getValues('confirmPassword')
                          ? t('Errors.PasswordError')
                          : true),
                      }}
                      render={({ onChange, value }) => (
                        <CustomOutlinedInput
                          password
                          icon={img}
                          label={t('Input.ConfirmPassword')}
                          value={value}
                          onChange={onChange}
                          errors={errors.confirmPassword}
                        />
                      )}
                    />
                  </div>

                  <h4 className="register__prompt login__form-item">
                    {t('PasswordRecovery.InputLabelRecovered')}
                  </h4>
                  <SubmitButton
                    loading={loading}
                    label={t('PasswordRecovery.SubmitButtonRecovered')}
                  />
                </form>
              </div>
            </MaterialContainer>
          </Grid>
        </Grid>
      </Container>
      {open && (
        <AlertDialog
          open={open}
          onClose={handleClose}
          message={message}
          success={success}
          error={error}
        />
      )}
    </div>
  );
};

export default PasswordCreate;
