import React from 'react';
import './Error404.css';

import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { Box, Typography } from '@material-ui/core';
import { matchedRoute } from '../../_helpers/func';
import ErrorSvg from './Error404.svg';

export default function Error404() {
  const [t, i18n] = useTranslation();
  const location = useLocation();

  const toRoute = location.state?.to || '/';
  return (
    <Container component={Box} maxWidth="sm">
      <Box
        pt={{ xs: 3, md: 5, lg: 7 }}
        mb={{ xs: 2, md: 3, lg: 4 }}
        textAlign="center"
      >
        <Typography variant="h1" paragraph>
          {t('Error_404.Title')}
        </Typography>
        <Typography paragraph>{t('Error_404.Subtitle')}</Typography>
      </Box>
      <Box maxWidth={304} mb={4} mx="auto">
        <Button
          component={Link}
          to={matchedRoute(i18n.language, toRoute)}
          fullWidth
          variant="contained"
          color="primary"
        >
          {t('Error_404.SubmitButton')}
        </Button>
      </Box>
      <Container component="div" maxWidth="sm">
        <Box
          component="img"
          maxWidth="100%"
          src={ErrorSvg}
          alt="Error"
          width="527px"
          height="auto"
        />
      </Container>
    </Container>
  );
}
