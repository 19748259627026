import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const DiscountGenerationTypeField = ({ errors, value, onChange }) => {
  const { t } = useTranslation();
  const generationType = useSelector(
    (state) => state.prefixGenerationType.generationType
  );
  return (
    <FormControl error={errors?.message} component="fieldset">
      <FormLabel className="a-dialog__label">
        {t('Admin:Discount.generationType')}
      </FormLabel>
      <RadioGroup
        row
        aria-label="discount Prefix Generate Type"
        name="discountPrefixGenerateType"
        value={value}
        onChange={onChange}
      >
        {generationType.map((item) => (
          <FormControlLabel
            key={item.id}
            value={item.id.toString()}
            control={<Radio color="primary" />}
            label={t(`Admin:Discount.generationType_${item.id}`)}
          />
        ))}
      </RadioGroup>
      {errors?.message && <FormHelperText>{errors.message}</FormHelperText>}
    </FormControl>
  );
};

export default DiscountGenerationTypeField;
