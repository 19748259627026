import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Box,
  IconButton,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import ApiClient from '../../../../ApiClient/ApiClient';
import errorHelper from '../../../../_constants/errorHelper';
import fetchStatus from '../../../../_constants/fetchStatus';
import { ReactComponent as CloseIcon } from '../../../Dialogs/img/close.svg';
import '../../../Dialogs/Dialog.scss';
import replaceItems from './replaceItems';
import { setDiscountCards } from '../../../../_reducers/discountReducer';
import { useTranslation } from 'react-i18next';

const DiscountStatusDialog = ({ open, onClose, id, resetSelected }) => {
  const { t } = useTranslation('Admin');
  const dispatch = useDispatch();
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const loading = status === fetchStatus.PENDING;

  const cards = useSelector((state) => state.discount.cards);

  const [message, setMessage] = useState('');

  const fetchByChangeStatus = (activeStatus) => {
    setStatus(fetchStatus.PENDING);
    const fetchData = {
      discountCardIds: id,
      isActive: activeStatus,
    };
    return ApiClient.UpdateDiscountCardStatus(fetchData)
      .then((res) => {
        if (res) {
          if (res.isSuccess) {
            const newCards = replaceItems(activeStatus, cards, id);

            dispatch(setDiscountCards(newCards));

            setStatus(fetchStatus.FULFILLED);
            resetSelected();
            onClose();
            return;
          }
          throw new Error(res.statusMessage);
        }
        throw new Error(errorHelper.common);
      })
      .catch((e) => {
        setStatus(fetchStatus.REJECTED);
        setMessage(e.message);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} className="dialog">
      <DialogTitle className="dialog__title">
        <Box display="flex" justifyContent="space-between">
          {t('Discount.StatusDialog.Title', { context: 'card' })}
          <Box mr={-2} mt={-1}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      {message && (
        <DialogContent>
          <DialogContentText color="error">{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => fetchByChangeStatus(false)}
          className="dialog__btn dialog__cancel"
        >
          {t('Discount.StatusDialog.Deactivate')}
        </Button>
        <Button
          disabled={loading}
          onClick={() => fetchByChangeStatus(true)}
          className="dialog__btn dialog__submit"
        >
          {t('Discount.StatusDialog.Activate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountStatusDialog;
