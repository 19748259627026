import React from 'react';
import { NavLink } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import Logo from './logo.svg';
import ImgLog from './img_log.svg';
import MailIcon from '../Dialogs/img/mail.svg';

import { matchedRoute } from '../../_helpers/func';
import { AppRoute } from '../../const/app-routes';
import emailPattern from '../../_helpers/emailPattern';
import useFetchResults from '../../_helpers/scripts/useFetchResults';
import CustomOutlinedInput from '../Inputs/BaseOutlinedInput/CustomOutlinedInput';
import LazyImg from '../../_helpers/LazyImg';
import '../Registration/Registration.scss';
import '../Login/Login.scss';
import { ChangeLanguageButton } from '../i18n/ChangeLanguageDialog';
import ApiClient from '../../ApiClient/ApiClient';
import useDialog from '../../_helpers/scripts/useDialog';
import AlertDialog from '../Dialogs/AlertDialog';
import SubmitButton from '../Login/SubmitButton';

const PasswordRecovery = () => {
  const [t, i18n] = useTranslation();
  const {
    control, errors, getValues, handleSubmit
  } = useForm({
    mode: 'onChange',
  });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    loading,
    error,
    message,
    success,
    setLoading,
    setError,
    setMessage,
    setSuccess,
    resetValues,
  } = useFetchResults();
  const { open, handleOpen, handleClose } = useDialog();
  const required = t('ValidateErrors.Required');

  const fetchByForgotPassword = async (email) => {
    setLoading(true);
    const token = await executeRecaptcha();

    await ApiClient.ForgotPassword(email, token)
      .then((res) => {
        if (res) {
          setMessage(res.statusMessage);
          if (res.isSuccess) {
            setSuccess(true);
          } else {
            setError(true);
          }
        }
      })
      .catch((e) => {
        setMessage(e.message);
        setError(true);
      });
    setLoading(false);
    handleOpen();
  };

  const onSubmit = (data) => {
    resetValues();
    fetchByForgotPassword(data.email);
  };

  return (
    <div className="login register">
      <Container className="login__container">
        <Grid container>
          <Grid xs={6}>
            <div className="logo-container">
              <NavLink to={matchedRoute(i18n.language, AppRoute.Login)}>
                <LazyImg
                  className="login__img-logo"
                  src={Logo}
                  width="160px"
                  height="70px"
                />
              </NavLink>
            </div>
          </Grid>
          <Grid xs={6} className="login__lang-btn">
            <ChangeLanguageButton />
          </Grid>
          <Grid xs={0} md={6} className="login__img-container">
            <div>
              <img
                className="login__img-fluid"
                src={ImgLog}
                alt=""
                loading="lazy"
              />
            </div>
          </Grid>
          <Grid xs={12} md={6}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className="login__wrapper">
                <h1 className="login__form-title">
                  {t('PasswordRecovery.Title')}
                </h1>
                <h2 className="login__form-sub-title">
                  {t('PasswordRecovery.Subtitle')}
                </h2>
                <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="login__form-item">
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required,
                        validate: () => emailPattern.test(getValues('email'))
                          || t('ValidateErrors.Email'),
                      }}
                      defaultValue=""
                      render={({ onChange, value }) => (
                        <CustomOutlinedInput
                          icon={<LazyImg src={MailIcon} />}
                          label="Email"
                          name="Email"
                          className="required"
                          value={value}
                          onChange={onChange}
                          errors={errors.email}
                        />
                      )}
                    />
                  </div>
                  <SubmitButton
                    loading={loading}
                    label={t('PasswordRecovery.SubmitButton')}
                  />
                </form>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Container>
      {open && (
        <AlertDialog
          open={open}
          onClose={handleClose}
          message={message}
          success={success}
          error={error}
        />
      )}
    </div>
  );
};

export default PasswordRecovery;
