import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  pageSize: 10,
  pageNumber: 0,
  services: [],
  totalCount: 0,
  loading: false,
};

const manualReducer = createSlice({
  name: 'manual',
  initialState,
  reducers: {
    setServices(state, action) {
      state.services = action.payload.services;
      state.totalCount = action.payload.totalCount;
      state.pageNumber = 0;
    },
    addServices(state, action) {
      const services = current(state.services);
      state.services = services.concat(action.payload.services);
      state.totalCount = action.payload.totalCount;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
      state.pageNumber = 1;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
  },
});

export const { setPageSize, setPageNumber, setServices, addServices } =
  manualReducer.actions;
export default manualReducer.reducer;
