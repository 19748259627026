import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useDialog from '../../../_helpers/scripts/useDialog';
import { ReactComponent as PrintIcon } from '../../../_icons/print.svg';
import useApiContracts from '../../Contract/useApiContracts';
import Direction from '../../Direction/Direction';
import DirectionToPrint from '../../Direction/DirectionToPrint';
import LoadingButton from '../../_components/LoadingButton';

const PrintDirectionButton = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const barcode = useSelector((state) => state.direction.barcode);
  const { open, handleClose, handleOpen } = useDialog();
  const contracts = useApiContracts();

  return (
    <>
      <LoadingButton
        loading={loading}
        disabled={!barcode || loading}
        onClick={handleOpen}
        color="primary"
        startIcon={<PrintIcon />}
      >
        {t('Tooltip.Direction')}
      </LoadingButton>
      {open && (
        <Direction
          loading={loading}
          setLoading={setLoading}
          onClose={handleClose}
          contracts={contracts}
          component={DirectionToPrint}
          barcode={barcode}
        />
      )}
    </>
  );
};

export default PrintDirectionButton;
