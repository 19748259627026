import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

const LoadingButton = ({ loading, children, ...props }) => (
  <Button {...props}>
    {loading ? <CircularProgress color="inherit" size={24} /> : children}
  </Button>
);

export default LoadingButton;
