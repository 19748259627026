const cash = 2;
const online = 3;
const ERIP = 4;

const all = new Map([
  [cash, 'Cash'],
  [online, 'Online'],
  [ERIP, 'ERIP'],
]);

export default {
  cash, online, ERIP, all
};
