import {
  Button,
  Dialog,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import { ReactComponent as AddIcon } from '../../Dialogs/img/add.svg';

const DialogButton = ({ title, component: Component, mobileIcon, ...props }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      {mobile && mobileIcon ? (
        <CustomizedTooltip title={title}>
          <IconButton onClick={handleOpen} color="primary">
            <AddIcon />
          </IconButton>
        </CustomizedTooltip>
      ) : (
        <Button
          onClick={handleOpen}
          startIcon={<AddIcon />}
          color="primary"
          variant="contained"
          className="styled-table__header-button"
        >
          {title}
        </Button>
      )}

      {open && <Component open={open} onClose={handleClose} {...props} />}
    </>
  );
};

DialogButton.propTypes = {
  title: PropTypes.string.isRequired,
  component: PropTypes.func,
  mobileIcon: PropTypes.bool,
};

DialogButton.defaultProps = {
  component: Dialog,
  mobileIcon: false,
};

export default DialogButton;
