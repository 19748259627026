import { TableRow, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import React from 'react';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => (props.expanded ? theme.palette.blueLightPlusNew.main : 'inherit'),
    transition: '200ms background-color cubic-bezier(0, 0, 0.2, 1) 0ms',
    '& .MuiTableCell-root': {
      transition: '200ms border-bottom cubic-bezier(0, 0, 0.2, 1) 0ms',
      borderBottom: (props) => (props.expanded
        ? `1px solid ${theme.palette.blueLight.main}`
        : `1px solid ${theme.palette.blueLightPlus.main}`),
    },
    [theme.breakpoints.up('md')]: {
      '& > .MuiTableCell-root': {
        '&:first-child': {
          paddingLeft: 16,
        },
        '&:last-child': {
          paddingRight: 16,
        },
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& > .MuiTableCell-root': {
        '&:first-child': {
          paddingLeft: 32,
        },
        '&:last-child': {
          paddingRight: 32,
        },
      },
    },
  },
}));

const StyledTableRow = (props) => {
  const classes = styles(props);
  return (
    <TableRow
      {...props}
      className={clsx(classes.root, props.className, props.classes)}
    >
      {props.children}
    </TableRow>
  );
};

StyledTableRow.propTypes = {
  expanded: PropTypes.bool,
};

StyledTableRow.defaultProps = {
  expanded: false,
}

export default StyledTableRow;
