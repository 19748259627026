import { throttle } from 'lodash-es';
import {
  combineReducers,
  configureStore,
  createAction,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import userReducer from '../_reducers/userReducer';
import resultsReducer from '../_reducers/resultsReducer';
import sortReducer from '../_reducers/sortReducer';
import basketReducer from '../_reducers/basketReducer';
import patientReducer from '../_reducers/patientReducer';
import errorReducer from '../_reducers/errorReducer';
import FieldGroupFormReducer from '../_reducers/FieldGroupFormReducer';
import Storage from '../_helpers/Storage';
import serviceReducer from '../_reducers/serviceReducer';
import manualReducer from '../_reducers/manualReducer';
import prefixReducer from '../_reducers/prefixReducer';
import prefixGenerationTypeReducer from '../_reducers/prefixGenerationTypeReducer';
import initCreateDiscountCardReducer from '../_reducers/initCreateDiscountCardReducer';
import searchCardsByFilterReducer from '../_reducers/initSearchDiscountCardsByFilter';
import serviceExtensionReducer from '../_reducers/serviceExtensionReducer';
import discountReducer from '../_reducers/discountReducer';
import newSortReducer from '../_reducers/newSortReducer';
import identificationNumberTypeReducer from '../_reducers/identificationNumberTypeReducer';
import infoHubReducer from '../_reducers/infoHubReducer';
import directionReducer from '../_reducers/directionReducer';
import contractReducer from '../_reducers/contractReducer';
import orderImportReducer from '../_reducers/orderImportReducer';
import history from './history';

const preloadedState = Storage.getItem('redux-store');
const userLogout = createAction('user/logout');

const combineReducer = combineReducers({
  router: connectRouter(history),
  userReducer,
  resultsReducer,
  FieldGroupFormReducer,
  sortReducer,
  sort: newSortReducer,
  errorReducer,
  basket: basketReducer,
  service: serviceReducer,
  patient: patientReducer,
  manual: manualReducer,
  prefix: prefixReducer,
  prefixGenerationType: prefixGenerationTypeReducer,
  initCreateDiscountCard: initCreateDiscountCardReducer,
  discount: discountReducer,
  searchCardsByFilterReducer,
  serviceExtension: serviceExtensionReducer,
  identificationNumberType: identificationNumberTypeReducer,
  infoHub: infoHubReducer,
  direction: directionReducer,
  contract: contractReducer,
  orderImport: orderImportReducer,
});

const middleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: ['infoHub/setConnection'],
    ignoreState: ['infoHub'],
  },
});

const rootReducer = (state, action) => {
  if (action.type === userLogout.type) {
    state = undefined;
  }
  return combineReducer(state, action);
};

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

middleware.push(routerMiddleware(history));

const store = configureStore({
  preloadedState: preloadedState !== null ? preloadedState : false,
  reducer: rootReducer,
  middleware,
});

store.subscribe(
  throttle(() => {
    Storage.saveItem('redux-store', {
      userReducer: store.getState().userReducer,
      results: store.getState().results,
      basket: store.getState().basket,
      direction: store.getState().direction,
      contract: store.getState().contract,
    });
  }, 1000)
);

export { userLogout };
export default store;
