import { DialogActions } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const AdminActions = ({ children }) => (
  <DialogActions className="a-dialog__actions">{children}</DialogActions>
);

AdminActions.propTypes = {
  children: PropTypes.symbol.isRequired,
};

export default AdminActions;
