import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../Basket/Basket.scss';
import '../ServicesTable/ServiceList.scss';
import '../Basket/OrderInfo.scss';
import './Ordering.scss';

import { useTranslation } from 'react-i18next';
import { ReactComponent as Delete2Icon } from '../img/delete2.svg';
import { ReactComponent as Circle } from '../img/green.svg';
import { clearBasket } from '../../../_reducers/basketReducer';
import useDialog from '../../../_helpers/scripts/useDialog';
import DeleteButton from '../Basket/DeleteButton';
import DeleteDialog from './DeleteDialog';

const SmallBasket = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.basket.addedServices);
  const biometerials = useSelector((state) => state.basket.biomaterials);
  const parrentCodes = useSelector((state) => state.basket.parrentCodes);
  const isPreOrder = useSelector((state) => state.basket.isPreOrder);
  const isLoading = useSelector((state) => state.basket.loading);

  const { open, handleOpen, handleClose } = useDialog();

  const handleDeleteAll = () => {
    dispatch(clearBasket());
    handleClose();
  };

  return (
    <>
      {isLoading ? (
        <Box p={5} textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      ) : rows.length > 0 ? (
        <>
          <TableContainer
            component={Box}
            flexGrow={1}
            className="ordering__table-container syn-scroll"
          >
            <Table stickyHeader className="ordering__table">
              <TableHead>
                <TableRow>
                  <TableCell className="basket__head-cell-color service-list__head-cell">
                    {t('CreateOrder.Code')}
                  </TableCell>
                  <TableCell className="service-list__head-cell basket__head-cell-color ">
                    {t('CreateOrder.Name')}
                  </TableCell>
                  <TableCell className="service-list__head-cell basket__head-cell-color ">
                    {t('CreateOrder.Price')}
                  </TableCell>
                  <TableCell className="service-list__head-cell basket__head-cell-color ">
                    {t('CreateOrder.WorkingDays')}
                  </TableCell>
                  <TableCell className="service-list__head-cell basket__head-cell-color service-list__display-sm" />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.code} className="service-list__row">
                    <TableCell className="service-list__cell">
                      {row.code}
                    </TableCell>
                    <TableCell className="service-list__cell">
                      {row.name}
                    </TableCell>
                    <TableCell className="service-list__cell">
                      {row.price}
                    </TableCell>
                    <TableCell className="service-list__cell">
                      {row.term}
                    </TableCell>
                    <TableCell className="service-list__cell service-list__cell--btn">
                      {parrentCodes.indexOf(row.code) !== -1 ? (
                        <DeleteButton code={row.code} />
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box textAlign="end">
            <Button
              onClick={handleOpen}
              disabled={isPreOrder}
              startIcon={<Delete2Icon />}
              color="primary"
            >
              {t('CreateOrder.Basket.clear')}
            </Button>
          </Box>
        </>
      ) : (
        <Box
          flexGrow={0.5}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h5" className="color-title">
            {t('CreateOrder.Basket.Empty')}
          </Typography>
        </Box>
      )}

      {!isLoading && biometerials.length > 0 && (
        <>
          <div>
            <h4 className="basket__title basket__title-padding">
              {t('CreateOrder.Tubes')}
            </h4>
          </div>
          <TableContainer className="ordering__tubes-container syn-scroll">
            <Table stickyHeader className="ordering__table">
              <TableHead>
                <TableRow>
                  <TableCell className="service-list__head-cell basket__head-cell-color">
                    {t('CreateOrder.Name')}
                  </TableCell>
                  <TableCell className="service-list__head-cell basket__head-cell-color">
                    {t('CreateOrder.Basket.amount')}
                  </TableCell>
                  <TableCell className="service-list__head-cell basket__head-cell-color service-list__display-sm" />
                </TableRow>
              </TableHead>
              <TableBody>
                {biometerials.map((row) => (
                  <TableRow className="service-list__row">
                    <TableCell className="service-list__cell">
                      {row.name}
                    </TableCell>
                    <TableCell className="service-list__cell">
                      {row.count}
                    </TableCell>
                    <TableCell className="service-list__cell">
                      <Circle
                        className="service-list__circle"
                        style={{ color: row.tubeColor }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {open && (
        <DeleteDialog
          open={open}
          onClose={handleClose}
          handleDelete={handleDeleteAll}
        />
      )}
    </>
  );
};

export default SmallBasket;
