const SettingsRoutes = {
  Staff: '/settings/staff',
  Edit: '/settings/edit-password',
  Calculator: '/settings/calculator',
};

const InfoRoutes = {
  FAQ: '/info/faq',
  Manual: '/info/manual',
  News: '/info/news',
  Article: '/info/article',
};

const MailManagerRoutes = {
  Sender: '/admin/mail-manager/sender',
  Mask: '/admin/mail-manager/mask',
  Smtp: '/admin/mail-manager/smtp',
};

const AdminRoutes = {
  Contracts: '/admin/contracts',
  ContractSettings: '/admin/contracts/settings',
  ContractSilab: '/admin/contracts/silab',
  Users: '/admin/users',
  Barcode: '/admin/barcode',
  MailManager: '/admin/mail-manager',
  Sync: '/admin/synchronization',
  ServiceExtension: '/admin/service-extension',
  ServiceExtensionRefresh: '/admin/service-extension/refresh',
  Discount: '/admin/discount',
  MailManagerRoutes,
  Promotion: '/admin/promotion',
};

const NewsRoutes = {
  Articles: '/admin/news',
  Add: '/admin/news/add',
  Edit: '/admin/news/edit',
};

const OrderImportRoutes = {
  Main: '/order-import',
  PersonDublicates: '/order-import/person-dublicates',
  Manual: '/order-import/person-dublicates/manual-check',
  Master: '/order-import/person-dublicates/master',
  Relationship: '/order-import/person-dublicates/relationship',
};

export const AppRoute = {
  Admin: '/admin',
  Login: '/login',
  Registration: '/registration',
  PasswordRecovery: '/password-recovery',
  CreatePassword: '/create-password',
  Error404: '/error404',
  Error500: '/error500',
  Menu: '/menu',
  Settings: '/settings',
  Results: '/results',
  News: '/info',
  Sort: '/sort',
  Outsourcing: '/sort/outsourcing',
  PreOrder: '/sort/preorder',
  CreateOrder: '/create-order',
  CreateOrderServices: '/create-order/services',
  CreateOrderCheckout: '/create-order/services/checkout',
  CreateOrderSuccess: '/create-order-success',
  SettingsRoutes,
  InfoRoutes,
  AdminRoutes,
  NewsRoutes,
  OrderImportRoutes,
};
