import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ApiClient from '../../ApiClient/ApiClient';
import errorHelper from '../../_constants/errorHelper';
import fetchStatus from '../../_constants/fetchStatus';
import clientMessageHelper from '../../_helpers/clientMessageHelper';

const useResult = () => {
  const [state, setState] = useState(fetchStatus.IDLE);
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const contracts = useSelector(
    (s) => s.resultsReducer.previousContracts
  );

  const [message, setMessage] = useState('');

  const error = state === fetchStatus.REJECTED;
  const success = state === fetchStatus.FULFILLED;
  const loading = state === fetchStatus.PENDING;
  const idle = state === fetchStatus.IDLE;

  const status = {
    error,
    success,
    loading,
    idle,
  };

  const GetResultBase64Async = async (barcode) => {
    try {
      setState(fetchStatus.PENDING);
      let token;
      if (executeRecaptcha) {
        token = await executeRecaptcha();
      }
      return ApiClient.ResultsPdf(barcode, token, contracts)
        .then((res) => {
          if (res.isSuccess) {
            setState(fetchStatus.FULFILLED);
            return res.base64Pdf;
          }
          const clientMessage = res.clientMessage?.code
            ? t(clientMessageHelper.Message.get(res.clientMessage.code), {
              barcode,
            })
            : false;
          throw new Error(clientMessage || res.statusMessage || null);
        })
        .catch((e) => {
          setMessage(e.message || errorHelper.common);
          setState(fetchStatus.REJECTED);
          return null;
        });
    } catch (e) {
      setMessage(e.message || errorHelper.common);
      setState(fetchStatus.REJECTED);
      return null;
    }
  };

  const SetStatusIdle = () => setState(fetchStatus.IDLE);

  return {
    GetResultBase64Async,
    status,
    message,
    SetStatusIdle,
  };
};

export default useResult;
