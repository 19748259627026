import { Button, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import Direction from './Direction';
import AccompanyingDirectionToPrint from './AccompanyingDirectionToPrint';
import useSortContracts from '../Sort/useSortContracts';

const AccompanyingDirectionButton = ({
  selected,
  selectedOrdersByBarcode,
  className,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const contracts = useSortContracts();
  const sortingState = useSelector((state) => state.sort.sortingState);

  const handleOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        disabled={!selected}
        title={!selected && t('Sort.AccompanyingDirectionTooltip')}
        onClick={handleOpen}
        variant="outlined"
        color="primary"
        className={className}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          t('Sort.AccompanyingDirection')
        )}
      </Button>
      {open && (
        <Direction
          sortingState={sortingState}
          component={AccompanyingDirectionToPrint}
          onClose={handleClose}
          contracts={contracts}
          setLoading={setLoading}
          loading={loading}
          barcode={selectedOrdersByBarcode}
        />
      )}
    </>
  );
};

const mapState = (state) => ({
  selected: state.sort.selected.length > 0,
  selectedOrdersByBarcode: state.sort.selected,
});

AccompanyingDirectionButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  selectedOrdersByBarcode: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string,
};

AccompanyingDirectionButton.defaultProps = {
  className: '',
};

export default connect(mapState)(AccompanyingDirectionButton);
