import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import fetchStatus from '../../../../_constants/fetchStatus';

const DiscountPrefixGenerationTypeStatus = () => {
  const generationTypeStatus = useSelector(
    (state) => state.prefixGenerationType.status
  );
  const message = useSelector((state) => state.prefixGenerationType.message);

  const generationType = useSelector(
    (state) => state.prefixGenerationType.generationType
  );

  const loading = generationTypeStatus === fetchStatus.PENDING;
  const error = generationTypeStatus === fetchStatus.REJECTED
    || generationType.length === 0;

  return (
    <>
      {loading && (
        <Box display="flex" alignItems="center" mb={2}>
          <Box marginRight={2}>
            <CircularProgress style={{ width: 16, height: 16 }} />
          </Box>
          <Typography>
            Загрузка типов типов генерации для создания префиксов
          </Typography>
        </Box>
      )}
      {error && (
        <Typography color="error" variant="h5">
          {message}
        </Typography>
      )}
    </>
  );
};

export default DiscountPrefixGenerationTypeStatus;
