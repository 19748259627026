import {
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TablePagination,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SortFunc from '../../../_helpers/sort-funtions';
import { setPage, setPageSize } from '../../../_reducers/patientReducer';
import EnhancedTableHead from '../../_components/EnchantedTableHead';
import PatientInfoReport from './PatientInfoReport';
import genderHelper from '../../../_constants/genderHelper';
import PatientCardButton from '../PatientCard/PatientCardButton';
import tablePaginationHelper from '../../../_helpers/tablePaginationHelper';

const headCells = [
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Input.FullName',
    expand: false,
  },
  {
    id: 'gender',
    numeric: false,
    disablePadding: false,
    label: 'Input.Gender',
    expand: false,
  },
  {
    id: 'dateOfBirth',
    numeric: false,
    disablePadding: false,
    label: 'Input.DateOfBirth',
    expand: true,
  },
];

const PatientTable = ({ informationConsent, SelectPatient }) => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const rows = useSelector((state) => state.patient.rows);
  const page = useSelector((state) => state.patient.page);
  const rowsPerPage = useSelector((state) => state.patient.pageSize);
  const totalRows = useSelector((state) => state.patient.total);
  const patientID = useSelector((state) =>
    state.basket.patient ? state.basket.patient.id : null
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setPageSize(parseInt(event.target.value, 10)));
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

  return (
    <>
      <TableContainer>
        <Table className="services-table patient-table styled-table">
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {SortFunc.stableSort(
              rows,
              SortFunc.getComparator(order, orderBy)
            ).map((row) => (
              <TableRow
                hover
                key={row.id}
                onClick={SelectPatient(row.id)}
                className={clsx(
                  'patient-table__row',
                  'styled-table__row--clickable',
                  {
                    'patient-table__row--selected': row.id === patientID,
                  }
                )}
              >
                <TableCell className="patient-table__cell">
                  {`${row.lastName} ${row.firstName}`}
                </TableCell>
                <TableCell className="patient-table__cell">
                  {genderHelper.GetGenderLocalizedLabel(row.gender)}
                </TableCell>
                <TableCell className="patient-table__cell">
                  {row.dateOfBirth}
                </TableCell>
                <TableCell
                  className="patient-table__cell patient-table__cell--icons "
                  align="right"
                >
                  {informationConsent ? (
                    <PatientInfoReport patientId={row.id} />
                  ) : null}
                  <PatientCardButton
                    handleNext={SelectPatient(row.id)}
                    patientId={row.id}
                  />
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{ height: 60 * emptyRows }}
                className="patient-table__row"
              >
                <TableCell className="patient-table__cell" colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="services-table__pagination"
        rowsPerPageOptions={tablePaginationHelper.rowsPerPageOptions}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

PatientTable.propTypes = {
  informationConsent: PropTypes.bool.isRequired,
  SelectPatient: PropTypes.func.isRequired,
};

export default PatientTable;
