/* eslint-disable jsx-a11y/label-has-associated-control */
import { ButtonBase, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ApiClient from '../../../ApiClient/ApiClient';
import Contract from '../../../_enchancers/Contract';
import base64ToBlob from '../../../_helpers/scripts/base64ToBlob';
import { setError } from '../../../_reducers/errorReducer';
import useClientUnits from '../../Contract/useClientUnits';
import useContract from '../../Contract/useContract';
import { ReactComponent as DownloadIcon } from '../img/download.svg';
import { ReactComponent as UploadIcon } from '../img/upload.svg';

const ExcelButtons = ({ className }) => {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState({ load: false, upload: false });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentContract } = useContract();
  const { currentClientUnit } = useClientUnits();

  const disable = disabled || currentContract === null;

  const getRequsetParams = () => {
    let formContracts;

    formContracts = {
      Contracts: Contract.getContractId(currentContract.id),
    };

    if (currentClientUnit) {
      formContracts = {
        ...formContracts,
        ClientUnit: Contract.getClientUnitId(currentClientUnit.id, currentContract),
      };
    }

    return formContracts;
  };

  const downloadTemplate = async () => {
    setDisabled(true);
    setLoading({ ...loading, load: true });

    await ApiClient.OrderExcelTemplate(getRequsetParams())
      .then((res) => {
        if (res && res.isSuccess) {
          const blob = base64ToBlob(res.excelTemplate);
          const url = URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = url;
          a.download = 'excelTemplate.xlsx';
          document.body.append(a);
          const clickHandler = () => {
            setTimeout(() => {
              URL.revokeObjectURL(url);
              a.removeEventListener('click', clickHandler);
            }, 150);
          };
          a.addEventListener('click', clickHandler, false);
          a.click();
        }
      })
      .finally(() => {
        setLoading({ ...loading, load: false });
        setDisabled(false);
      });
  };

  const uploadTemplate = async (event) => {
    if (event.target.files[0]) {
      const files = event.target.files[0];

      const formData = new window.FormData();
      formData.append('formFile', files);

      setDisabled(true);
      setLoading({ ...loading, upload: true });

      await ApiClient.OrderCreateOrdersFromExcelData(
        formData,
        getRequsetParams()
      )
        .then((res) => {
          if (res) {
            if (res.isSuccess) {
              const blob = base64ToBlob(res.bytes);
              const url = URL.createObjectURL(blob);

              const a = document.createElement('a');
              a.href = url;
              a.download = 'excelTemplate.xlsx';
              document.body.append(a);
              const clickHandler = () => {
                setTimeout(() => {
                  URL.revokeObjectURL(url);
                  a.removeEventListener('click', clickHandler);
                }, 150);
              };
              a.addEventListener('click', clickHandler, false);
              a.click();
            } else {
              dispatch(setError(res.statusMessage));
            }
          }
        })
        .catch((error) => {
          dispatch(setError(error.message));
        })
        .finally(() => {
          setDisabled(false);
          setLoading({ ...loading, upload: false });
          document.getElementById('upload-excel-file-input').value = '';
        });
    }
  };

  return (
    <div className={clsx('excel', className)}>
      <div className="excel__buttons-wrap">
        <ButtonBase
          title={disable ? t('Patient.Tooltip') : undefined}
          disabled={disable}
          onClick={downloadTemplate}
          className={clsx('excel__button', disable && 'excel__button_disabled')}
        >
          {(loading.load && <CircularProgress />) || (
            <>
              <UploadIcon className="excel__icon" />
              <span className="excel__label">
                {t('download.key', { ns: 'EmptyTable' })}
                <br />
                {t('download.key_1', { ns: 'EmptyTable' })}
              </span>
            </>
          )}
        </ButtonBase>

        <label
          htmlFor="upload-excel-file-input"
          className="excel__button-label"
        >
          <ButtonBase
            title={disable ? t('Patient.Tooltip') : undefined}
            disabled={disable}
            component="span"
            className={clsx(
              'excel__button',
              disable && 'excel__button_disabled'
            )}
          >
            {(loading.upload && <CircularProgress />) || (
              <>
                <DownloadIcon className="excel__icon" />
                <span className="excel__label">
                  {t('upload.key', { ns: 'EmptyTable' })}
                  <br />
                  {t('upload.key_1', { ns: 'EmptyTable' })}
                </span>
              </>
            )}
          </ButtonBase>
        </label>
      </div>
      <input
        disabled={disable}
        onChange={uploadTemplate}
        type="file"
        accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        className="excel__file-input"
        id="upload-excel-file-input"
      />
    </div>
  );
};

ExcelButtons.propTypes = {
  className: PropTypes.string.isRequired,
};

export default ExcelButtons;
