import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import errorHelper from '../../_constants/errorHelper';
import ApiClient from '../../ApiClient/ApiClient';
import '../Dialogs/Dialog.scss';
import './Import.scss';
import DialogBarcodeItem from './DialogBarcodeItem';
import useFetchResult from '../../_helpers/scripts/useFetchStatus';
import { setOrders, setSelected } from '../../_reducers/newSortReducer';
import useSortContracts from '../Sort/useSortContracts';

const OrderImport = () => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatсh = useDispatch();

  const selectedOrders = useSelector((state) => state.sort.selected);
  const sortOrders = useSelector((state) => state.sort.orders);
  const totalCount = useSelector((state) => state.sort.totalCount);

  const isSelected = selectedOrders.length > 0;

  const [state, setState] = useState({
    message: '',
    orders: [],
  });

  const {
    setStatus, fulFilled, error, fetchStatus, loading
  } = useFetchResult();
  const open = fulFilled || error;

  const contracts = useSortContracts();

  const handleImportOrders = async () => {
    try {
      setStatus(fetchStatus.PENDING);
      const token = await executeRecaptcha();
      await ApiClient.SortingImport(selectedOrders, token, contracts)

        .then((res) => {
          if (res) {
            const { orders, statusMessage, isSuccess } = res;
            if (isSuccess) {
              setState({ message: statusMessage, orders: orders || [] });
              dispatсh(setSelected([]));
              return res;
            }
            setState({
              message: res.statusMessage || errorHelper.common,
              orders: orders || [],
            });
            setStatus(fetchStatus.REJECTED);
            return res;
          }
          throw new Error(errorHelper.common);
        })
        .then(async (res) => {
          if (res.isSuccess) {
            let ords = sortOrders.slice();

            const importedBarcodes = res.orders
              .map((item) => (item.isSuccess ? item.barcode : null))
              .filter((item) => item);

            importedBarcodes.forEach((barcode) => {
              const index = ords.findIndex((o) => o.barcode === barcode);

              ords = [...ords.slice(0, index), ...ords.slice(index + 1)];
            });
            const newTotalCount = totalCount - importedBarcodes.length;
            dispatсh(setOrders({ orders: ords, totalCount: newTotalCount }));
            setStatus(fetchStatus.FULFILLED);
          }
        })
        .catch((e) => {
          setState({
            message: `${errorHelper.common}: ${e.message}`,
            orders: [],
          });
          setStatus(fetchStatus.REJECTED);
        });
    } catch (e) {
      setState({
        message: `${errorHelper.common}: ${e.message}`,
        orders: [],
      });
      setStatus(fetchStatus.REJECTED);
    }
  };

  const handleClose = () => {
    setStatus(fetchStatus.IDLE);
    setState({ message: '', orders: [] });
  };
  const disabled = !isSelected;

  return (
    <>
      <Button
        onClick={handleImportOrders}
        title={disabled ? t('Sort.AccompanyingDirectionTooltip') : undefined}
        disabled={disabled || loading}
        variant="contained"
        color="primary"
        className="sort__send-button"
      >
        {loading ? <CircularProgress size={24} /> : t('Sort.SubmitButton')}
      </Button>

      {open && (
        <Dialog className="dialog import" open={open} onClose={handleClose}>
          <DialogTitle className="dialog__title">{state.message}</DialogTitle>
          {state?.orders?.length > 0 && (
            <DialogContent className="dialog__content">
              {state.orders.map((item) => (
                <DialogBarcodeItem order={item} />
              ))}
            </DialogContent>
          )}
          <DialogActions>
            <Button
              onClick={handleClose}
              className="dialog__submit dialog__btn"
            >
              {t('Common.Ok')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default OrderImport;
