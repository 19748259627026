const IDLE = 'idle';
const PENDING = 'pending';
const FULFILLED = 'fulfilled';
const REJECTED = 'rejected';

const fetchStatus = {
  IDLE,
  PENDING,
  FULFILLED,
  REJECTED,
};

export default fetchStatus;
