import { ListItem } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppRoute } from '../../const/app-routes';
import CollabLogo from './img/logo_vertic_white.svg';

const LogoButton = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleGoToCreateOrder = () => {
    history.push(AppRoute.CreateOrder, { resetBasket: true });
  };

  return (
    <ListItem
      button
      onClick={handleGoToCreateOrder}
      className="menu-list__logo"
      title={t('CreateOrder.Basket.clear')}
    >
      <img src={CollabLogo} width="104px" height="auto" alt="На главную" />
    </ListItem>
  );
};

export default LogoButton;
