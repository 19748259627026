export const LangCodes = {
  en: 'en-US',
  ru: 'ru-RU',
  be: 'be',
};

export const LangCodesMap = new Map([
  ['en', 'en-US'],
  ['ru', 'ru-RU'],
  ['be', 'be'],
]);

export function matchLang(lng) {
  let result;
  LangCodesMap.forEach((val, key) => {
    if (lng === key) {
      result = val;
    }
  });

  return result;
}
