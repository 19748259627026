import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { AppRoute } from '../../../../const/app-routes';
import decodeImage from '../../../../_helpers/scripts/decodeImage';
import useLangRoute from '../../../../_helpers/scripts/useLangRoute';
import { ReactComponent as Date2Icon } from '../../img/date2.svg';
import './ArticleCard.scss';

const ArticleCard = React.memo(({ title, image, date, content, urlName }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const route = useLangRoute();
  const [decodedImage, setDecodedImage] = useState(null);
  useEffect(() => {
    if (!decodedImage) {
      decodeImage(image).then((decoded) => setDecodedImage(decoded));
    }
  }, [image]);

  const handleToArticle = () => {
    history.push(route(`${AppRoute.InfoRoutes.Article}/${urlName}`));
  };

  return (
    <Card className="article-card">
      <CardActionArea
        onClick={handleToArticle}
        className="article-card__action-area"
      >
        {image.length > 0 && (
          <CardMedia
            component="img"
            className="article-card__media"
            image={decodedImage}
            title={title}
          />
        )}
        <CardContent className="article-card__content">
          <h5 className="article-card__title">{title}</h5>
          {date ? (
            <h6 className="article-card__sub-title">
              <span className="article-card__sub-title-icon">
                <Date2Icon />
              </span>
              {format(new Date(date), 'EEEE MMM dd, yyyy')}
            </h6>
          ) : null}
          <div className="article-card__text-colapsed">
            <p className="article-card__text">{content}</p>
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions className="article-card__action">
        <Button size="small" color="primary" onClick={handleToArticle}>
          {t('Info.More')}
        </Button>
      </CardActions>
    </Card>
  );
});

export default ArticleCard;
