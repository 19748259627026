import defaultImage from './img';

/* eslint-disable import/prefer-default-export */
export function configureFakeBackend() {
  let articles = JSON.parse(localStorage.getItem('articles')) || [
    {
      id: 0,
      title: 'Статья про анализы',
      name: 'base-medical-article',
      createdDate: new Date(),
      content: '',
      description: '...',
      showAsMessage: false,
      contract: [],
      image: defaultImage,
    },
  ];

  // monkey patch fetch to setup fake backend
  const realFetch = window.fetch;
  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      // helper functions

      function ok(body) {
        resolve({
          ok: true,
          text: () => Promise.resolve(JSON.stringify(body)),
        });
      }

      function error(message) {
        resolve({
          status: 400,
          text: () => Promise.resolve(JSON.stringify({ message })),
        });
      }

      function idFromUrl() {
        const urlParts = url.split('/');
        return parseInt(urlParts[urlParts.length - 1]);
      }

      function nameFromUrl() {
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1];
      }

      function body() {
        return opts.body && JSON.parse(opts.body);
      }

      function newArticleId() {
        return articles.length ? Math.max(...articles.map((x) => x.id)) + 1 : 1;
      }

      handleRoute();

      function handleRoute() {
        if (opts) {
          const { method } = opts;
          switch (true) {
            case url.endsWith('/articles') && method === 'GET':
              return getArticles();
            case url.match(/\/articles\/\d+$/) && method === 'GET':
              return getArticleById();
            case url.match(/\/articles\/[^\d+]\w.*/) && method === 'GET':
              return getArticleByName();
            case url.endsWith('/articles') && method === 'POST':
              return createArticle();
            case url.match(/\/articles\/\d+$/) && method === 'PUT':
              return updateArticle();
            case url.match(/\/articles\/\d+$/) && method === 'DELETE':
              return deleteArticle();
            default:
              // pass through any requests not handled above
              return realFetch(url, opts)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
          }
        } else {
          return realFetch(url, opts)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
        }
      }

      // route functions

      function getArticles() {
        return ok({
          news: {
            all: articles,
            current: articles.filter((article) => article.showAsMessage) || [],
          },
          isSuccess: true,
        });
      }

      function getArticleById() {
        const article = articles.find((x) => x.id === idFromUrl());
        return ok(article);
      }
      function getArticleByName() {
        const article = articles.find((x) => x.name === nameFromUrl());
        return ok(article);
      }

      function createArticle() {
        const article = body();

        article.id = newArticleId();
        article.createdDate = new Date().toISOString();
        articles.push(article);
        localStorage.setItem('articles', JSON.stringify(articles));

        return ok();
      }

      function updateArticle() {
        try {
          const params = body();
          const article = articles.find((x) => x.id === idFromUrl());

          Object.assign(article, params);
          localStorage.setItem('articles', JSON.stringify(articles));

          return ok();
        } catch (e) {
          error(e.message);
        }
      }

      function deleteArticle() {
        const idToDelete = idFromUrl();
        articles = articles.filter((x) => x.id !== idToDelete);
        localStorage.setItem('articles', JSON.stringify(articles));

        return ok();
      }
    });
  };
}
