import axios from 'axios';
import store, { userLogout } from '../_enchancers/configureStore';
import config from '../ApiClient/config';
import Storage from '../_helpers/Storage';
import { APP_ID } from '../_constants/CONSTANT';
import i18n from '../i18n';
import { setRole } from '../_reducers/userReducer';
import { setUserContracts } from '../_reducers/contractReducer';

const login = async (UserName, Password, Recaptcha, AppId) => {
  const data = {
    UserName,
    Password,
    additionalInfo: true,
  };
  const fullUrl = `${config.apiUrl}/v1.0/Token/login`;
  return axios
    .post(fullUrl, data, {
      headers: {
        ...{
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        Recaptcha,
        AppId,
      },
    })
    .then((res) => {
      const user = res.data;
      Storage.saveItem('currentUser', user);
      return user;
    })
    .catch((error) => {
      if (error?.response?.status) {
        if (error?.response.status === 501) {
          throw i18n.t('Errors.CaptchaNotAvalible');
        }
        if (error?.response.status === 401) {
          throw i18n.t('Errors.Unauthorized');
        }
        if (error.response?.data) {
          throw error.response.data;
        }
      }
      throw error.message;
    });
};

const refresh = async (data) => {
  const fullUrl = `${config.apiUrl}/v1.0/Token/refresh`;
  const appId = window.localStorage.getItem(APP_ID);

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (appId) {
    headers.appId = appId;
  }
  const token = await axios
    .post(fullUrl, data, {
      headers,
      authorization: false,
    })
    .then((res) => res?.data)
    .catch(() => null);

  return token;
};

function logout() {
  store.dispatch(userLogout());
  Storage.removeItem('currentUser');
  Storage.removeItem(APP_ID);
}

const refreshTokenAndUserData = async () => {
  let currentUser = Storage.getItem('currentUser');

  const data = {
    accessToken: currentUser?.accessToken,
    refreshToken: currentUser?.refreshToken,
    refreshTokenExpiryTime: new Date(),
  };
  currentUser = await refresh(data);

  if (currentUser) {
    Storage.saveItem('currentUser', currentUser);

    store.dispatch(setRole(currentUser.userRole));
    store.dispatch(setUserContracts(currentUser.userContracts));
    return true;
  }
  logout();
  return null;
};

const revoke = async () => {
  const fullUrl = `${config.apiUrl}/v1.0/Token/revoke`;
  const appId = window.localStorage.getItem(APP_ID);
  const accessToken = Storage.getItem('currentUser')
    ? `Bearer ${Storage.getItem('currentUser').accessToken}`
    : null;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  headers.Authorization = accessToken;

  if (appId) {
    headers.appId = appId;
  }
  return axios
    .post(
      fullUrl,
      {},
      {
        headers,
      }
    )
    .then(() => true)
    .catch((e) => {
      if (e?.response?.status === 401) {
        return true;
      }
      throw new Error(e.message);
    });
};

const authenticationService = {
  login,
  logout,
  refresh,
  refreshTokenAndUserData,
  revoke,
};

export default authenticationService;
