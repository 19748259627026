import React from 'react';
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DiscountSwitch from '../_helpers/DiscountSwitch';
import localizeTimePeriod from '../../../../_helpers/scripts/localizeTimePeriod';
import DiscountCustomer from './DiscountCustomer';

const NestedRow = ({ row }) => {
  const { t } = useTranslation('Admin');
  return (
    <Table>
      <TableBody>
        <Hidden mdUp>
          <TableRow className="discount-table__row">
            <TableCell className="discount-table__th" component="th">
              {t('Discount.Contract')}
            </TableCell>
            <TableCell className="styled-table__cell discount-table__cell">
              {row.contract.name}
            </TableCell>
          </TableRow>
        </Hidden>
        <Hidden mdUp>
          <TableRow className="discount-table__row">
            <TableCell className="discount-table__th" component="th">
              {t('Discount.fullName')}
            </TableCell>
            <TableCell className="styled-table__cell discount-table__cell">
              <DiscountCustomer />
            </TableCell>
          </TableRow>
        </Hidden>
        <Hidden lgUp>
          <TableRow className="discount-table__row">
            <TableCell className="discount-table__th" component="th">
              {t('Discount.Validity')}
            </TableCell>
            <TableCell className="styled-table__cell discount-table__cell">
              {`${row.startValidity} - ${row.endValidity}`}
            </TableCell>
          </TableRow>
        </Hidden>
        <Hidden lgUp>
          <TableRow className="discount-table__row">
            <TableCell className="discount-table__th" component="th">
              {t('Discount.countByMounth')}
            </TableCell>
            <TableCell className="styled-table__cell discount-table__cell">
              {`${localizeTimePeriod(row.periodTypeOfUses.id)} - ${
                row.possibleNumberOfUses
              }`}
            </TableCell>
          </TableRow>
        </Hidden>
        <Hidden lgUp>
          <TableRow className="discount-table__row">
            <TableCell className="discount-table__th" component="th">
              {t('Discount.countOfUses')}
            </TableCell>
            <TableCell className="styled-table__cell discount-table__cell">
              {row.realNumberOfUses}
            </TableCell>
          </TableRow>
        </Hidden>
        <Hidden mdUp>
          <TableRow className="discount-table__row">
            <TableCell className="discount-table__th" component="th">
              {t('Discount.status')}
            </TableCell>
            <TableCell className="styled-table__cell discount-table__cell">
              <DiscountSwitch value={row.isActive} id={[row.id]} end />
            </TableCell>
          </TableRow>
        </Hidden>
      </TableBody>
    </Table>
  );
};

NestedRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    cardNumber: PropTypes.string,
    customer: PropTypes.shape({
      id: PropTypes.number,
      fullName: PropTypes.string,
      birthday: PropTypes.string,
    }),
    contract: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    discountType: PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.number,
    }),
    startValidity: PropTypes.string,
    endValidity: PropTypes.string,
    isActive: PropTypes.bool,
    possibleNumberOfUses: PropTypes.number,
    realNumberOfUses: PropTypes.number,
    periodTypeOfUses: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default NestedRow;
