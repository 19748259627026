import { MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  orderResultStatus,
  sortingOrderStatus,
} from '../../../_constants/OrderStatusConst';
import BaseInputSelect from '../../Inputs/BaseFilledInput/BaseInputSelect';

const OrderStatusField = ({ onChange, value, sorting }) => {
  const [items, setItems] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const arr = [];

    if (sorting) {
      sortingOrderStatus.forEach(({ id, name }) => {
        arr.push(
          <MenuItem key={id} value={id}>
            {t(`MenuItem.SortStatuses.${name}`)}
          </MenuItem>
        );
      });
    } else {
      orderResultStatus.all.forEach((name, key) => {
        arr.push(
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem key={key} value={name}>
            {t(`MenuItem.ResultStatuses.${name}`)}
          </MenuItem>
        );
      });
    }

    setItems(arr);
  }, []);

  return (
    <BaseInputSelect
      label={t('Input.OrderStatus')}
      name="OrderStatus"
      id="OrderStatusId"
      onChange={onChange}
      value={value}
    >
      {items}
    </BaseInputSelect>
  );
};

OrderStatusField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  sorting: PropTypes.bool.isRequired,
};

export default OrderStatusField;
