import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    height: 'initial',
    color: theme.palette.title.main,

    fontWeight: 500,
    fontSize: 16,
    lineHeight: '18px',
    letterSpacing: '0.15px',

    '&:hover, &:focus, &:focus-within': {
      textDecoration: 'underline',
      backgroundColor: 'unset',
    },

    '@media (min-width:600px)': {
      fontSize: 18,
      lineHeight: '21.8px',
    },
  },
}));

const StyledBreadcrumbsButton = ({ onClick, children }) => {
  const classes = useStyles();

  return (
    <Button classes={classes} onClick={onClick} variant="h3" color="inherit">
      {children}
    </Button>
  );
};

export default StyledBreadcrumbsButton;
