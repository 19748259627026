import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiClient from '../../../ApiClient/ApiClient';
import fetchStatus from '../../../_constants/fetchStatus';
import AlertDialog from '../../Dialogs/AlertDialog';
import { ReactComponent as TableIcon } from '../../Dialogs/img/table.svg';
import PatientCard from './PatientCard';
import '../../../_helpers/styles/SynTooltip.scss';
import '../../Admin/_components/AdminDialog.scss';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import useApiContracts from '../../Contract/useApiContracts';

const PatientCardButton = ({ patientId, handleNext }) => {
  const { t } = useTranslation();
  const [patient, setPatient] = useState();
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const [message, setMessage] = useState('');

  const loading = status === fetchStatus.PENDING;
  const open = status === fetchStatus.FULFILLED;
  const error = status === fetchStatus.REJECTED;

  const contracts = useApiContracts();

  const fetchByPatient = async () => {
    setStatus(fetchStatus.PENDING);

    return ApiClient.GetPatient(patientId, contracts)
      .then((res) => {
        if (res) {
          setPatient(res);
          setStatus(fetchStatus.FULFILLED);
        } else {
          throw new Error(t('Errors.PatientNotFound'));
        }
      })
      .catch((e) => {
        setMessage(e.message);
        setStatus(fetchStatus.REJECTED);
      });
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setStatus(fetchStatus.IDLE);
  };

  const handleGetPatient = () => {
    fetchByPatient(patientId);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    handleGetPatient();
  };

  return (
    <>
      <IconButton color="primary" disabled={loading} onClick={handleClick}>
        <CustomizedTooltip title={t('Patient.PatientCard')}>
          <TableIcon />
        </CustomizedTooltip>
      </IconButton>
      {open && (
        <PatientCard
          open={open}
          patient={patient}
          handleNext={handleNext}
          onClose={handleClose}
        />
      )}
      <AlertDialog message={message} open={error} error onClose={handleClose} />
    </>
  );
};

PatientCardButton.propTypes = {
  patientId: PropTypes.number.isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default PatientCardButton;
