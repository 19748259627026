import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialContainer from '@material-ui/core/Container';
import { Container, Grid } from '@material-ui/core';

import './Login.scss';
import {
  Link, NavLink, useHistory, useLocation
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Controller, useForm } from 'react-hook-form';
import { matchedRoute } from '../../_helpers/func';
import { ChangeLanguageButton } from '../i18n/ChangeLanguageDialog';
import Logo from './img/logo_vertic.svg';
import ImgLog from './img/legend_logo.svg';
import { AppRoute } from '../../const/app-routes';

import authenticationService from '../../_services/authentication.service';
import { setUser } from '../../_reducers/userReducer';
import emailPattern from '../../_helpers/emailPattern';
import CustomOutlinedInput from '../Inputs/BaseOutlinedInput/CustomOutlinedInput';
import MailIcon from '../Dialogs/img/mail.svg';
import '../Dialogs/Dialog.scss';
import PassIcon from '../Settings/img/password.svg';
import { AllowedRoles } from '../../_constants/Roles';
import ApiClient from '../../ApiClient/ApiClient';
import fetchStatus from '../../_constants/fetchStatus';
import AlertDialog from '../Dialogs/AlertDialog';
import LoadingButton from '../_components/LoadingButton';
import { APP_ID } from '../../_constants/CONSTANT';
import { setUserContracts } from '../../_reducers/contractReducer';

const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const [t, i18n] = useTranslation();

  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [status, setStatus] = useState(fetchStatus.IDLE);
  const [message, setMessage] = useState('');

  const loading = status === fetchStatus.PENDING;
  const error = status === fetchStatus.REJECTED;

  const handleClose = () => setStatus(fetchStatus.IDLE);

  const {
    control, errors, trigger, handleSubmit, formState
  } = useForm({
    mode: 'onChange',
  });

  const { isSubmitting } = formState;

  const getAppIdAsync = async () => {
    let reCaptchaToken;
    if (executeRecaptcha) {
      reCaptchaToken = await executeRecaptcha();
    } else {
      throw new Error(t('Errors.CaptchaNotAvalible'));
    }
    const appId = await ApiClient.App(reCaptchaToken)
      .then((res) => res.appId)
      .catch(() => null);

    window.localStorage.setItem(APP_ID, appId);

    return appId;
  };

  const onSubmit = async (data) => {
    try {
      setStatus(fetchStatus.PENDING);
      const appId = await getAppIdAsync();

      let role;
      let token;
      if (executeRecaptcha) {
        token = await executeRecaptcha();
      } else {
        throw new Error(t('Errors.CaptchaNotAvalible'));
      }
      const isLogged = await authenticationService
        .login(data.email, data.password, token, appId)
        .then((res) => {
          role = res.userRole;
          dispatch(setUserContracts(res.userContracts));
          dispatch(setUser({ userRole: role, email: data.email }));

          if (window.clarity) {
            window.clarity("set", "userId", data.email.toString());
          }

          return true;
        })
        .catch((e) => {
          if (typeof e === 'string') {
            setMessage(e);
          } else {
            setMessage(e?.message);
          }
          setStatus(fetchStatus.REJECTED);
          return false;
        });
      if (isLogged) {
        const { from } = location.state || {
          from: {
            pathname: matchedRoute(
              i18n.language,
              AllowedRoles.CreateOrder.indexOf(role.id) !== -1
                ? AppRoute.CreateOrder
                : AppRoute.News
            ),
          },
        };
        setStatus(fetchStatus.FULFILLED);

        history.push(from);
      }
    } catch (e) {
      setMessage(e.message);
      setStatus(fetchStatus.REJECTED);
    }
  };

  const handleEmailValidate = (value) => {
    if (process.env.NODE_ENV === 'development') {
      return true;
    }
    return emailPattern.test(value) || t('ValidateErrors.Email');
  };

  return (
    <>
      <AlertDialog open={error} onClose={handleClose} message={message} error />
      <div className="login">
        <Container className="login__container">
          <Grid container>
            <Grid item xs={6}>
              <div className="logo-container">
                <NavLink to={matchedRoute(i18n.language, AppRoute.Login)}>
                  <img
                    className="login__img-logo"
                    src={Logo}
                    width="165px"
                    height="70px"
                    alt=""
                  />
                </NavLink>
              </div>
            </Grid>
            <Grid item xs={6} className="login__lang-btn">
              <ChangeLanguageButton />
            </Grid>
            <Grid item md={6} className="login__img-container">
              <div>
                <img className="login__img-fluid" src={ImgLog} alt="" />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <MaterialContainer component="main" maxWidth="xs">
                <CssBaseline />
                <div className="login__wrapper">
                  <h1 className="login__form-title">{t('SingIn.Title')}</h1>
                  <h2 className="login__form-sub-title">
                    {t('SingIn.Subtitle')}
                  </h2>
                  <form
                    className="login__form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="login__form-item">
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: t('ValidateErrors.Required'),
                          validate: handleEmailValidate,
                        }}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <CustomOutlinedInput
                            onFocus={() => trigger('email')}
                            icon={(
                              <img
                                src={MailIcon}
                                width="24px"
                                height="24px"
                                alt=""
                              />
                            )}
                            label="Email"
                            name="Email"
                            InputProps={{ autoComplete: 'off' }}
                            value={value}
                            onChange={onChange}
                            errors={errors.email}
                          />
                        )}
                      />
                    </div>
                    <div className="login__form-item">
                      <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: t('ValidateErrors.Required'),
                          minLength: {
                            value: 8,
                            message: t('ValidateErrors.Min8Len'),
                          },
                        }}
                        render={({ onChange, value }) => (
                          <CustomOutlinedInput
                            password
                            onFocus={() => trigger('password')}
                            icon={(
                              <img
                                src={PassIcon}
                                width="24px"
                                height="24px"
                                alt=""
                              />
                            )}
                            label={t('Input.Password')}
                            InputProps={{ autoComplete: 'off' }}
                            value={value}
                            onChange={onChange}
                            errors={errors.password}
                          />
                        )}
                      />
                    </div>
                    <div className="login__forgot-pass-btn">
                      <Link
                        to={matchedRoute(
                          i18n.language,
                          AppRoute.PasswordRecovery
                        )}
                        variant="overline"
                      >
                        {t('SingIn.InputLabel')}
                      </Link>
                    </div>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      loading={isSubmitting || loading}
                      disabled={isSubmitting || loading}
                      variant="contained"
                      color="primary"
                      className="login__submit"
                      onSubmit={onSubmit}
                    >
                      {t('SingIn.SubmitButton')}
                    </LoadingButton>
                  </form>
                </div>
              </MaterialContainer>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Login;
