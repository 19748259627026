import { IconButton } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CustomizedTooltip from '../CustomizedTooltip/CustomizedTooltip';
import { ReactComponent as PrintIcon } from './img/print.svg';
import ApiClient from '../../ApiClient/ApiClient';
import fetchStatus from '../../_constants/fetchStatus';
import errorHelper from '../../_constants/errorHelper';
import { setError } from '../../_reducers/errorReducer';

const InfoAgreement = ({ barcode, patientId, className, headerContract }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [status, setStatus] = React.useState(fetchStatus.IDLE);

  const loading = status === fetchStatus.PENDING;

  const fetchByReport = async () => {
    setStatus(fetchStatus.PENDING);
    await ApiClient.Report(barcode, patientId, headerContract)
      .then((res) => {
        if (res.isSuccess && res.informationConsent !== null) {
          const infoagree = document.createElement('div', {});
          infoagree.style.display = 'none';
          infoagree.id = `infoagree${barcode || patientId}`;

          document.body.appendChild(infoagree);

          const iframe = document.createElement('iframe', {});
          iframe.id = `agr_${barcode}`;
          iframe.width = '100%';
          iframe.height = '100%';

          const doc = document.getElementById(
            `infoagree${barcode || patientId}`
          );
          doc.appendChild(iframe);

          iframe.contentWindow.document.open();
          iframe.contentWindow.document.write(res.informationConsent);
          iframe.contentWindow.focus();
          iframe.contentWindow.print();
          iframe.contentWindow.document.close();

          const listener = () => {
            document.body.removeChild(infoagree);
          };
          window.addEventListener('beforeprint', listener, false);
          window.removeEventListener('beforeprint', listener, false);

          setStatus(fetchStatus.IDLE);
          return;
        }
        throw new Error(res.statusMessage || errorHelper.common);
      })
      .catch((e) => {
        setStatus(fetchStatus.IDLE);
        dispatch(setError(e.message));
      });
  };

  const handleClick = (e) => {
    e.stopPropagation();
    fetchByReport();
  };

  return (
    <IconButton
      color="primary"
      disabled={loading}
      className={className}
      onClick={handleClick}
    >
      <CustomizedTooltip title={t('Tooltip.PrintInfoAgreement')}>
        <PrintIcon />
      </CustomizedTooltip>
    </IconButton>
  );
};

InfoAgreement.propTypes = {
  barcode: PropTypes.number,
  patientId: PropTypes.number,
  className: PropTypes.string,
  headerContract: PropTypes.shape({
    Contracts: PropTypes.number,
    ClientUnit: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

InfoAgreement.defaultProps = {
  className: undefined,
  barcode: undefined,
  patientId: undefined,
};

export default InfoAgreement;
