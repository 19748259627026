import { CABINET_API_VERSION } from '../_constants/CONSTANT';
import Storage from './Storage';

const clearCache = () => {
  window.caches.keys().then((names) => {
    names.forEach((name) => {
      window.caches.delete(name);
    });
  });
};

const checkVersion = (newVersion) => {
  const currentVersion = Storage.get(CABINET_API_VERSION);

  const isNewer = currentVersion !== newVersion;

  if (isNewer) {
    Storage.save(CABINET_API_VERSION, newVersion);
  }
  return isNewer;
};

const resetCache = (newVersion) => {
  const isNewer = checkVersion(newVersion);
  if (isNewer) {
    clearCache();
  }
};

export default { resetCache };
