import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import Contract from '../../_enchancers/Contract';

const getUserClientUnits = createSelector(
  (state) => state.contract.value,
  (_, allowAll) => allowAll,
  (currentContract, allowAll) => {
    if (currentContract?.clientUnits?.length > 1) {
      if (allowAll) {
        return [Contract.allUnits, ...currentContract?.clientUnits];
      }
    }
    return currentContract?.clientUnits || [];
  }
);

const useClientUnits = (allowAll = false) => {
  const clientUnits = useSelector((state) => getUserClientUnits(state, allowAll));
  const currentClientUnit = useSelector((state) => state.contract.clientUnit);

  return { clientUnits, currentClientUnit };
};

export default useClientUnits;
