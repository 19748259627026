import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import setInputFilter from '../../../_helpers/scripts/setInputFilter';
import StyledTextField from '../../Inputs/StyledTextField';

const LastNameInput = ({ onChange, value }) => {
  const { t } = useTranslation();
  const onFieldChange = () => {
    setInputFilter(
      document.getElementById('LastNameId'),
      (val) => /^[^0-9\s]*$/i.test(val),
      onChange
    );
  };
  return (
    <StyledTextField
      variant="filled"
      fullWidth
      name="LastName"
      id="LastNameId"
      label={t('Input.LastName')}
      onChange={onFieldChange}
      value={value}
    />
  );
};

LastNameInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default LastNameInput;
