import { IconButton, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Dialog.scss';
import CloseIcon from './img/close.svg';

const StyledDialogTitle = ({
  title, onClose, withIcon, children, error, success
}) => {
  const { t } = useTranslation();

  return (
    <Box
      flex={0}
      flexBasis={0}
      flexShrink={0}
      display="flex"
      alignItems="center"
      flexWrap="wrap"
      gridGap={16}
      margin={0}
      maxWidth={withIcon ? "calc(100% - 22px)" : undefined}
      paddingX={3}
      paddingY={2}
    >
      <Typography
        variant="h2"
        color={error ? 'error' : success ? 'primary' : 'textPrimary'}
      >
        {title}
      </Typography>
      {children}
      {withIcon ? (
        <IconButton
          className="dialog__close-btn"
          onClick={onClose}
          color="primary"
        >
          <img src={CloseIcon} alt={t('Common.Close')} width="20px" height="20px" />
        </IconButton>
      ) : null}
    </Box>
  );
};

StyledDialogTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType(PropTypes.arrayOf(PropTypes.elementType), PropTypes.elementType),
  error: PropTypes.bool,
  success: PropTypes.bool,
};

StyledDialogTitle.defaultProps = {
  children: [],
  success: false,
  error: false,
};

export default StyledDialogTitle;
