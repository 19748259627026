export default new Map([
  [0, 'Error'],
  [1, 'Success'],
  [2, 'NoContractInLocation'],
  [3, 'NoClientUnitInContract'],
  [4, 'PatientError'],
  [5, 'NoServiceInPriceBook'],
  [6, 'NoServiceInOrder'],
  [7, 'NotFoundService'],
  [8, 'NoLaboratorySettingsForService'],
  [9, 'NoAnyPriceBookService'],
  [10, 'ServiceExistInOrder'],
  [11, 'ServiceIsIncludedInPackage'],
  [12, 'CalculatorError'],
  [13, 'ValidateDiscountError'],
  [14, 'BarcodeIsUsed'],
  [15, 'GenerateBarcodeError'],
]);
