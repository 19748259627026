import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions } from '@material-ui/core';
import './Dialog.scss';
import StyledDialogTitle from './StyledDialogTitle';

const AlertDialog = ({
  open,
  onClose,
  titleProps,
  closeIcon,
  message,
  error,
  success,
  children,
}) => (
  <Dialog className="dialog" open={open} onClose={onClose}>
    <StyledDialogTitle
      title={message}
      withIcon={closeIcon}
      onClose={onClose}
      success={success}
      error={error}
    >
      {titleProps}
    </StyledDialogTitle>
    {children}
    <DialogActions>
      <Button className="dialog__btn dialog__submit" onClick={onClose}>
        Ок
      </Button>
    </DialogActions>
  </Dialog>
);

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  children: PropTypes.objectOf(PropTypes.any),
  titleProps: PropTypes.arrayOf(PropTypes.elementType),
  closeIcon: PropTypes.bool,
};

AlertDialog.defaultProps = {
  titleProps: [],
  message: '',
  error: false,
  success: false,
  closeIcon: false,
  children: null,
};

export default AlertDialog;
