import { ButtonBase } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppRoute } from '../../../const/app-routes';
import useLangRoute from '../../../_helpers/scripts/useLangRoute';
import articleService from '../../../_services/article.service';
import { ReactComponent as Date2Icon } from '../img/date2.svg';

const RelatedNewsBlock = ({ loading, articles }) => {
  const route = useLangRoute();
  const history = useHistory();

  const handleToArticle = (id) => () => {
    history.push(route(`${AppRoute.InfoRoutes.Article}/${id}`));
  };
  const heroArticle = () => {
    const article = articles[0];
    return (
      <div className="related__hero">
        <ButtonBase
          onClick={handleToArticle(article.name)}
          className="news-item"
          style={{
            backgroundImage: `url(data:image/png;base64,${article.image})`,
          }}
        >
          <div className="news-item__bar">
            <p className="news-item__title">{article.title}</p>
            {article.createdDate ? (
              <p className="news-item__date">
                <span className="news-item__date-icon">
                  <Date2Icon />
                </span>
                {format(new Date(article.createdDate), 'EEEE MMM dd, yyyy')}
              </p>
            ) : null}
          </div>
        </ButtonBase>
      </div>
    );
  };

  const otherArticles = () => {
    const content = [];
    const maxElem = 4;
    let newsCounter = 0;
    const otherNews = articles.slice(1);

    if (otherNews.length === 0) {
      otherNews.push(articles[0]);
    }
    for (let index = 0; index < maxElem; index += 1) {
      if (otherNews[index]) {
        content.push(otherNews[newsCounter]);
        newsCounter += 1;
      } else {
        newsCounter = 0;
        content.push(otherNews[newsCounter]);
      }
    }

    return content.map((article, index) => (
      <ButtonBase
        // eslint-disable-next-line react/no-array-index-key
        key={article.name + index}
        onClick={handleToArticle(article.name)}
        className="article-small related__grid-item"
        style={{
          backgroundImage: `url(data:image/png;base64,${article.image})`,
        }}
      >
        <div className="article-small__bar">
          <p className="article-small__title">{article.title}</p>
          {article.createdDate ? (
            <p className="article-small__date">
              <span className="article-small__date-icon">
                <Date2Icon />
              </span>
              {format(new Date(article.createdDate), 'EEEE MMM dd, yyyy')}
            </p>
          ) : null}
        </div>
      </ButtonBase>
    ));
  };

  return (
    <div className="news__important-news important-news">
      {loading ? (
        <div className="related">
          <div className="related__hero">
            <Skeleton
              className="related__skeleton"
              width="100%"
              component="div"
              style={{ minHeight: 312 }}
            />
          </div>
          <div className="related__other">
            <div className="related__grid">
              <div className="related__grid-item">
                <Skeleton
                  className="related__skeleton"
                  width="100%"
                  component="div"
                  style={{
                    minHeight: 152,
                  }}
                />
              </div>
              <div className="related__grid-item">
                <Skeleton
                  className="related__skeleton"
                  width="100%"
                  component="div"
                  style={{
                    minHeight: 152,
                  }}
                />
              </div>
              <div className="related__grid-item">
                <Skeleton
                  className="related__skeleton"
                  width="100%"
                  component="div"
                  style={{
                    minHeight: 152,
                  }}
                />
              </div>
              <div className="related__grid-item">
                <Skeleton
                  className="related__skeleton"
                  width="100%"
                  component="div"
                  style={{
                    minHeight: 152,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {!loading && articles.length > 0 ? (
        <div className="related">
          {articles.length > 0 && heroArticle()}
          <div className="related__other">
            <div className="related__grid">{otherArticles()}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RelatedNewsBlock;
