import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useReactToPrint } from 'react-to-print';
import { useSnackbar } from 'notistack';
import { Box } from '@material-ui/core';
import ApiClient from '../../ApiClient/ApiClient';

const Direction = ({
  onClose,
  barcode,
  loading,
  setLoading,
  component: Component,
  defaultData,
  contracts,
  ordering,
  ...props
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [data, setData] = useState(defaultData);
  const { enqueueSnackbar } = useSnackbar();

  const fetchByDirections = async () => {
    setLoading(true);
    const token = await executeRecaptcha();

    await ApiClient.SortingDirections(barcode, token, contracts).then((res) => {
      if (res && res.isSuccess) {
        setData(res);
      }
    }).catch((e) => {
      enqueueSnackbar(e.message, { variant: 'error' });
      setLoading(false);
    });
  };

  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(
    () => componentRef.current,
    [componentRef.current]
  );

  const handleAfterPrint = () => {
    onClose();
    if (setLoading) {
      setLoading(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: barcode || null,
    onBeforeGetContent: data === null ? fetchByDirections : undefined,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  useEffect(() => {
    if ((!loading && data === null) || ordering) {
      handlePrint();
    }
  }, [data, loading, ordering]);

  return (
    <Box display="none">
      <Component ref={componentRef} barcode={barcode} data={data} {...props} />
    </Box>
  );
};

Direction.propTypes = {
  onClose: PropTypes.func.isRequired,
  barcode: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  defaultData: PropTypes.objectOf(PropTypes.string),
};

Direction.defaultProps = {
  defaultData: null,
};

export default Direction;
