import React from 'react';
import PropTypes from 'prop-types';

const LazyImg = ({ src, width, height, alt, className, style }) => (
  <img
    style={style}
    src={src}
    width={width}
    height={height}
    alt={alt}
    className={className}
    loading="lazy"
  />
);

LazyImg.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LazyImg.defaultProps = {
  height: '24px',
  width: '24px',
  alt: '',
  className: null,
  style: undefined,
};

export default LazyImg;
