import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import './Lang.scss';

const lang = {
  ru: 'RUS',
  be: 'BEL',
  en: 'ENG',
};

const ChangeLangButton = () => {
  const location = useLocation();
  const history = useHistory();
  const { i18n } = useTranslation();

  const handleChangeLanguage = (newLang) => {
    let url;
    const selectedLang = i18n.language;
    if (selectedLang === newLang) {
      return false;
    }

    const newLocation =
      selectedLang === 'ru'
        ? location.pathname.substring(0)
        : location.pathname.substring(3);

    if (newLang === 'ru') {
      url = `${newLocation}`;
    } else {
      url = `/${newLang}${newLocation}`;
    }
    history.push(url);
    return i18n.changeLanguage(newLang);
  };

  const handleClickListItem = (event) => {
    event.stopPropagation();
  };

  const handleMenuItemClick = (event, newLang) => {
    handleChangeLanguage(newLang);
  };

  return (
    <>
      <div
        className="dropup"
        tabIndex="0"
        role="menu"
        onClick={handleClickListItem}
        onKeyPress={handleClickListItem}
      >
        <div className="dropup__btn">{lang[i18n.language]}</div>
        <div className="dropup__content dropup__content_animated">
          {Object.entries(lang).map(([value, label]) => {
            if (value === i18n.language) {
              return null;
            }
            return (
              <button
                type="button"
                key={value}
                selected={value === i18n.language}
                onClick={(event) => handleMenuItemClick(event, value)}
                className="dropup__li"
              >
                {label}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ChangeLangButton;
