import React, { useRef } from 'react';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BasketOutIcon } from '../img/basket_out.svg';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import './ServiceButton.scss';
import useServices from './useServices';

const ServiceButton = ({ code }) => {
  const { t } = useTranslation();
  const { AddService, DeleteService, serviceCodes } = useServices();
  const button = useRef();

  const selected = serviceCodes.indexOf(code) !== -1;

  const handleAddService = async () => {
    if (selected) {
      button.current?.blur();
      return DeleteService(code, false, true);
    }
    return AddService(code, false, true);
  };

  return (
    <CustomizedTooltip title={t('CreateOrder.addService')}>
      <IconButton
        ref={button}
        className="service-button"
        onClick={handleAddService}
      >
        <BasketOutIcon
          className={clsx(
            'service-button__icon',
            { 'service-button__icon--active': selected }
          )}
        />
      </IconButton>
    </CustomizedTooltip>
  );
};

ServiceButton.propTypes = {
  code: PropTypes.string.isRequired,
};

export default ServiceButton;
