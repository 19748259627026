import React from 'react';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LazyImg from '../../../_helpers/LazyImg';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import LearnIcon from '../img/learn.svg';
import useDialog from '../../../_helpers/scripts/useDialog';
import ViewResultDialog from './ViewResultDialog';

const ViewResultButton = ({ barcode, patient }) => {
  const { t } = useTranslation();
  const { open, handleClose, handleOpen } = useDialog();

  return (
    <>
      <CustomizedTooltip title={t('Tooltip.AboutResults')}>
        <IconButton size="small" onClick={handleOpen}>
          <LazyImg
            className="btn-group__img"
            src={LearnIcon}
            width="32px"
            height="32px"
            alt={t('Tooltip.AboutResults')}
          />
        </IconButton>
      </CustomizedTooltip>
      <ViewResultDialog patient={patient} open={open} onClose={handleClose} barcode={barcode} />
    </>
  );
};

ViewResultButton.propTypes = {
  barcode: PropTypes.number.isRequired,
};

export default ViewResultButton;
