import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';

import languageRu from './locales/ru/translation.json';
import languageEn from './locales/en/translation.json';
import languageBe from './locales/be/translation.json';

const locales = ['/en', '/ru', '/be'];

const myDetector = {
  name: 'customDetector',
  type: 'languageDetector',
  async: true,
  lookup(options) {
    let found;
    if (typeof window !== 'undefined') {
      const language = window.location.pathname.match(/\/([a-zA-Z-]*)/g);
      if (language instanceof Array) {
        if (typeof options.lookupFromPathIndex === 'number') {
          if (typeof language[options.lookupFromPathIndex] !== 'string') {
            return undefined;
          }
          if (language[options.lookupFromPathIndex] === '/') {
            return 'ru';
          }
          if (locales.indexOf(language[options.lookupFromPathIndex]) === -1) {
            return 'ru';
          }
          found = language[options.lookupFromPathIndex].replace('/', '');
        } else {
          found = language[0].replace('/', '');
        }
      }
    }
    return found;
  },
};

const languageDetector = new I18nextBrowserLanguageDetector();

languageDetector.addDetector(myDetector);

i18n
  .use(languageDetector)
  .use(i18nextPlugin)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['customDetector', 'path'],
      lookupFromPathIndex: 0,
    },
    translationStats: {
      // optional options, if not provided it will guess based on your i18next config
      queryStringParam: 'showtranslations',
      sourceLng: 'en',
      targetLngs: ['ru', 'en', 'be'],
      preserveEmptyStrings: false,
    },
    resources: {
      en: languageEn,
      ru: languageRu,
      be: languageBe,
    },
    debug: process.env.NODE_ENV === 'development',

    ns: ['translations', 'EmptyTable', 'Direction'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  });

export default i18n;
