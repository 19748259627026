import { Chip } from '@material-ui/core';
import React from 'react';
import './Chip.scss';
import PropTypes from 'prop-types';

const StyledChip = ({ label, onDelete }) => <Chip label={label} onDelete={onDelete} className="styled-chip" />;

StyledChip.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default StyledChip;
