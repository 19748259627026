import { Box, CircularProgress, Fade } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

const CustomProgress = ({ padding }) => {
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Box
      visibility={showComponent ? 'visible' : 'hidden'}
      textAlign="center"
      p={padding || 4}
    >
      <Fade in={showComponent}>
        <CircularProgress color="primary" />
      </Fade>
    </Box>
  );
};

export default CustomProgress;
