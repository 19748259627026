import {
  Box,
  Breadcrumbs,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FavoritesIcon } from '../../img/favorites.svg';
import { ReactComponent as FilterIcon } from '../../img/Filter.svg';
import ServiceSearch from '../../Search/ServiceSearch';
import CustomizedTooltip from '../../../CustomizedTooltip/CustomizedTooltip';
import SelectedServicesContext from '../SelectedServicesContext';
import { ReactComponent as BackIcon } from '../../../../_icons/back.svg';
import StyledBreadcrumbsButton from '../../../_components/StyledBreadcrumbsButton';

const ServicesHeader = () => {
  const { t } = useTranslation();
  const { favorites, setFavorites } = useContext(SelectedServicesContext);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('xs'));

  const btnSize = useMediaQuery(theme.breakpoints.down('md'))
    ? 'small'
    : 'medium';

  const handleChangeFavorites = () => {
    setFavorites((value) => !value);
  };

  return (
    <Box
      paddingTop={4}
      paddingX={{ xs: 2, sm: 3, md: 4 }}
      paddingBottom={{ xs: 2, md: 3 }}
      bgcolor="blueLightPlusNew.main"
    >
      <div className="flex-center">
        <Box
          position="absolute"
          top={-20}
          mx="auto"
          py={1}
          px={{
            xs: 2,
            sm: 3,
            md: 4,
            lg: 7,
          }}
          borderRadius={5}
          bgcolor="white"
        >
          {favorites ? (
            <Breadcrumbs separator=">" aria-label="breadcrumb">
              <StyledBreadcrumbsButton onClick={handleChangeFavorites}>
                {t('CreateOrder.Services')}
              </StyledBreadcrumbsButton>
              <Typography variant="h3" color="textPrimary">
                {t('Info.Directory_favorites.Breadcrumbs.FavoriteServices')}
              </Typography>
            </Breadcrumbs>
          ) : (
            <Typography variant="h3" color="textPrimary">
              {t('CreateOrder.Services')}
            </Typography>
          )}
        </Box>
      </div>
      <Box display="flex" alignItems="center" gridGap={sm ? 12 : 4}>
        <Box display="flex" alignItems="center">
          <CustomizedTooltip title={favorites ? t('Common.Back') : t('Info.Directory.Favorites')}>
            <IconButton
              size={btnSize}
              onClick={handleChangeFavorites}
              color="primary"
              className="service-list__basket-btn"
            >
              {favorites ? (
                <BackIcon />
              ) : (
                <FavoritesIcon className="service-list__icon" />
              )}
            </IconButton>
          </CustomizedTooltip>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            disabled
            size={btnSize}
            color="primary"
            className="service-list__basket-btn"
          >
            <FilterIcon className="service-list__icon" />
          </IconButton>
        </Box>
        <ServiceSearch />
      </Box>
    </Box>
  );
};

export default ServicesHeader;
