const renderClientUnits = (clientUnit) => {
  if (clientUnit) {
    if (clientUnit.length > 0) {
      return clientUnit.map((item) => item.name).join(', ');
    }
    return clientUnit.name;
  }
  return '';
};

export default renderClientUnits;
