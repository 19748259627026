import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Box,
  Typography,
  TableContainer,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ServicesHeader from './Header/ServicesHeader';
import SortFunc from '../../../_helpers/sort-funtions';
import ServiceTableHead from './ServiceTableHead';
import ServiceButton from './ServiceButton';
import './ServiceList.scss';
import ServiceInfo from './ServiceInfo';
import { setError } from '../../../_reducers/serviceReducer';
import fetchStatus from '../../../_constants/fetchStatus';
import tablePaginationHelper from '../../../_helpers/tablePaginationHelper';
import useContract from '../../Contract/useContract';
import useApiContracts from '../../Contract/useApiContracts';
import CustomProgress from '../../_components/CustomProgress';

const ServicesTable = ({ getServices }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const contracts = useApiContracts();
  const { currentContract } = useContract();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const services = useSelector((state) => state.service.services);
  const searchServices = useSelector((state) => state.service.searchServices);
  const status = useSelector((state) => state.service.status);
  const message = useSelector((state) => state.service.message);
  const search = useSelector((state) => state.service.search);
  const totalCount = useSelector((state) => state.service.totalCount);

  const fulfilled = status === fetchStatus.FULFILLED;
  const loading = status === fetchStatus.PENDING;
  const error = status === fetchStatus.REJECTED;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = useMemo(
    () => (search ? searchServices : services),
    [search, searchServices, services]
  );

  useEffect(() => {
    if (!currentContract) {
      dispatch(setError(t('Sort.NoContract')));
    }
  }, []);

  useEffect(() => {
    if (currentContract) {
      getServices(contracts, page, rowsPerPage);
    }
  }, [page, rowsPerPage, currentContract]);

  const servicesToRender = SortFunc.stableSort(
    rows,
    SortFunc.getComparator(order, orderBy)
  );

  return (
    <div className="service-list">
      <Box
        overflow="hidden"
        borderRadius={11}
        boxShadow={1}
        className="service-list__paper"
      >
        <ServicesHeader />
        {fulfilled && (
          <>
            <TableContainer>
              <Table>
                <ServiceTableHead
                  onRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {servicesToRender.map((service) => {
                    const {
                      comment,
                      remark,
                      description,
                      results,
                      code,
                      name,
                      price,
                      workingDays,
                    } = service;
                    const hadInfo = comment || remark || description || results;
                    return (
                      <TableRow key={code} hover className="service-list__row">
                        <TableCell width="10%" className="service-list__cell">
                          {code}
                        </TableCell>
                        <TableCell width="30%" className="service-list__cell">
                          {name}
                        </TableCell>
                        <TableCell className="service-list__cell service-list__cell--btn">
                          {hadInfo ? (
                            <ServiceInfo serviceInfo={service} />
                          ) : null}
                        </TableCell>
                        <TableCell className="service-list__cell">
                          {price}
                        </TableCell>
                        <TableCell className="service-list__cell">
                          {workingDays}
                        </TableCell>
                        <TableCell className="service-list__cell service-list__cell--btn">
                          <ServiceButton code={code} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {!search && (
              <TablePagination
                className="service-list__pagination"
                rowsPerPageOptions={tablePaginationHelper.rowsPerPageOptions}
                component="div"
                page={page}
                rowsPerPage={rowsPerPage}
                count={totalCount}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </>
        )}
      </Box>
      {loading && <CustomProgress />}
      {error && (
        <Box pt={4} px={2}>
          <Typography align="center" variant="h5" color="error">
            {message}
          </Typography>
        </Box>
      )}
    </div>
  );
};

ServicesTable.propTypes = {
  getServices: PropTypes.func.isRequired,
};

export default ServicesTable;
