/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppRoute } from '../../const/app-routes';
import Routes from '../../Routing/Routes';
import { matchedRoute } from '../../_helpers/func';
import Error404 from '../Error404/Error404';

const Test = React.lazy(() => import('../_components/Test'));

const LangRouter = () => {
  const [, i18n] = useTranslation();
  const locale = i18n.language;
  return (
    <Switch>
      <Route
        path="/"
        exact
        render={() => (
          <Redirect to={matchedRoute(i18n.language, AppRoute.Login)} />
        )}
      />
      <Route
        path="/ru/*"
        render={(propsRouter) => {
          const routes = propsRouter.location.pathname.match(/\/([a-zA-Z-]*)/g);
          const path = routes.slice(1).join('');
          return <Redirect to={path} />;
        }}
      />
      <Route
        path="/*"
        render={(propsRouter) => <Routes {...propsRouter} locale={locale} />}
      />
      <Route
        path="/en/*"
        render={(propsRouter) => <Routes {...propsRouter} locale={locale} />}
      />
      <Route
        path="/be/*"
        render={(propsRouter) => <Routes {...propsRouter} locale={locale} />}
      />
      <Route path="*" render={() => <Error404 />} />
    </Switch>
  );
};

export default LangRouter;
