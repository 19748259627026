import { IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ApiClient from '../../../ApiClient/ApiClient';
import { AppRoute } from '../../../const/app-routes';
import fetchStatus from '../../../_constants/fetchStatus';
import useLangRoute from '../../../_helpers/scripts/useLangRoute';
import { setError } from '../../../_reducers/errorReducer';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import { ReactComponent as EditIcon } from '../../../_icons/edit.svg';
import useSortContracts from '../useSortContracts';
import { setContractAndClientUnit } from '../../../_reducers/contractReducer';
import useContract from '../../Contract/useContract';

const EditOrder = ({ barcode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const { contracts: globalContracts } = useContract();
  const contracts = useSortContracts();
  const route = useLangRoute();
  const history = useHistory();

  const changeContractAndClientUnit = (order) => {
    const contract = globalContracts.find(
      (item) => item.id === order.contract.id
    );
    const clientUnit = order.clientUnit?.id
      ? contract.clientUnits.find((item) => item.id === order.clientUnit.id)
      : null;

    dispatch(setContractAndClientUnit({ contract, clientUnit }));
  };

  const handleEdit = async () => {
    setStatus(fetchStatus.PENDING);
    const order = await ApiClient.GetOrderByBarcode(barcode, contracts)
      .then((res) => {
        setStatus(fetchStatus.FULFILLED);
        return res;
      })
      .catch((e) => {
        setStatus(fetchStatus.REJECTED);
        dispatch(setError(e.message));
        return null;
      });

    if (order) {
      changeContractAndClientUnit(order);
      history.push({
        pathname: route(AppRoute.CreateOrder),
        state: { order },
      });
    }
  };

  return (
    <CustomizedTooltip title={t('Tooltip.EditOrder')}>
      <span>
        <IconButton
          disabled={status === fetchStatus.PENDING}
          color="primary"
          onClick={handleEdit}
        >
          <EditIcon />
        </IconButton>
      </span>
    </CustomizedTooltip>
  );
};

EditOrder.propTypes = {
  barcode: PropTypes.number.isRequired,
};

export default EditOrder;
