import {
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import StyledTableSortLabel from '../../_components/StyledTableSortLabel';

const headCells = [
  {
    id: 'code',
    numeric: true,
    label: 'CreateOrder.Code',
    width: '10%',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'CreateOrder.Service',
    last: true,
    colSpan: 2,
    width: '30%',
  },
  {
    id: 'details',
    numeric: false,
    label: '',
    hide: true,
    width: '5%',
  },
  {
    id: 'price',
    numeric: true,
    label: 'CreateOrder.Price',
    width: '15%',
  },
  {
    id: 'workingDays',
    numeric: true,
    label: 'CreateOrder.WorkingDays',
    last: true,
    colSpan: 2,
    width: '15%',
  },
  {
    id: 'buy-service',
    numeric: false,
    label: '',
    width: '10%',
  },
];

const ServiceTableHead = ({ onRequestSort, order, orderBy }) => {
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const theme = useTheme();

  const mob = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <TableHead>
      <TableRow className="service-list__head-row">
        {headCells.map((headCell) => (
          <TableCell
            className={clsx(
              'service-list__head-cell',
              headCell.hide && 'service-list__head-cell--sm-hide'
            )}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : undefined}
            colSpan={headCell.last && mob ? headCell.colSpan : undefined}
            width={headCell.width}
          >
            <StyledTableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
            </StyledTableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ServiceTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default ServiceTableHead;
