const APP_ID = 'appid';
const CABINET_API_VERSION = 'cabinet-api-version';
const ORDER_IMPORT_FORM = 'order-import-form';
const FIREFOX = 'firefox';
const HELIX_ID = 244;
const ALL_CONTRACTS_DEFAULT_VALUE = '-1';

export {
  APP_ID, CABINET_API_VERSION, ORDER_IMPORT_FORM, FIREFOX, HELIX_ID, ALL_CONTRACTS_DEFAULT_VALUE
};
