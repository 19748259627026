const calculateIframeHeight = (offsetWidth, numPages) => {
  const maxWidth = 1090;
  const minWidth = 320;

  const currentWidth = offsetWidth;
  let width;

  if (currentWidth > maxWidth) {
    width = maxWidth;
  } else if (currentWidth < minWidth) {
    width = minWidth;
  } else {
    width = currentWidth;
  }

  return (4.3 / 3) * width * numPages;
};

export default calculateIframeHeight;
