import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { GetIdentificationNumberTypesAsync } from '../../../_reducers/identificationNumberTypeReducer';
import useApiContracts from '../../Contract/useApiContracts';

const GetIdentificationNumberTypes = ({ setMessage }) => {
  const dispatch = useDispatch();
  const hasTypes = useSelector(
    (state) => state.identificationNumberType.types.length > 0
  );
  const contracts = useApiContracts();

  useEffect(() => {
    if (!hasTypes) {
      dispatch(GetIdentificationNumberTypesAsync(contracts))
        .unwrap()
        .catch((message) => {
          setMessage(message);
        });
    }
  }, []);
  return null;
};

export default GetIdentificationNumberTypes;
