const { useState } = require('react');

const useFetchResults = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const resetValues = () => {
    if (success) {
      setSuccess(false);
    }
    if (error) {
      setError(false);
    }
    if (message) {
      setMessage('');
    }
  };

  return {
    error,
    setError,
    loading,
    setLoading,
    message,
    setMessage,
    success,
    setSuccess,
    resetValues,
  };
};

export default useFetchResults;
