import { fetchWrapper } from './fetchWrapper';

const baseUrl = '/articles';

const getArticles = async () => {
  const response = await fetchWrapper.get(baseUrl).then((res) => res);
  return response;
};

const getArticleById = async (id) => {
  const response = await fetchWrapper
    .get(`${baseUrl}/${id}`)
    .then((res) => res);
  return response;
};

const getArticleByName = async (name) => {
  const response = await fetchWrapper
    .get(`${baseUrl}/${name}`)
    .then((res) => res);
  return response;
};

const create = async (data) => {
  const response = await fetchWrapper.post(baseUrl, data);
  return response;
};

const update = async (id, data) => {
  const response = await fetchWrapper.put(`${baseUrl}/${id}`, data);
  return response;
};
const Delete = async (id) => {
  const response = await fetchWrapper.delete(`${baseUrl}/${id}`);
  return response;
};

const articleAgrement = async (id, name) => {
  // const response = await fetchWrapper.post(`${baseUrl}`, {
  //   id, name
  // });
  const response = await new Promise((resolve) => {
    setTimeout(() => {
      localStorage.setItem('message-view', true);
      resolve();
    }, 1500);
  });
  return response;
};

const articleService = {
  getAll: getArticles,
  getById: getArticleById,
  getByName: getArticleByName,
  delete: Delete,
  create,
  update,
  articleAgrement,
};

export default articleService;
