import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Mail from '../../Email/Mail.svg';
import CustomOutlinedInput from '../../Inputs/BaseOutlinedInput/CustomOutlinedInput';
import emailPattern from '../../../_helpers/emailPattern';
import ApiClient from '../../../ApiClient/ApiClient';
import LazyImg from '../../../_helpers/LazyImg';
import StyledDialogTitle from '../../Dialogs/StyledDialogTitle';
import errorHelper from '../../../_constants/errorHelper';

const ModalEmail = ({ open, onClose, barcode }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const [emailField, setEmailField] = useState('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({ state: false, message: '' });
  const [error, setError] = useState({ state: false, message: '' });
  const {
    control, errors, getValues, handleSubmit
  } = useForm();
  const contracts = useSelector((state) => state.resultsReducer.previousContracts);

  const SendEmail = async (email) => {
    try {
      const token = await executeRecaptcha();
      setLoading(true);

      const body = {
        email,
        barcode: barcode.toString(),
      };
      await ApiClient.Mail(body, token, contracts)
        .then((res) => {
          if (res) {
            if (res.isSuccess) {
              setSuccess({ state: true, message: res.statusMessage });
            } else {
              throw new Error(res.statusMessage);
            }
          } else {
            throw new Error(errorHelper.common);
          }
        })
        .catch((e) => {
          setError({ state: true, message: e.message });
        })
        .finally(() => setLoading(false));
    } catch (e) {
      setLoading(false);
      setError({ state: true, message: e.message });
    }
  };

  const onSubmit = (data) => {
    SendEmail(data.email);
  };

  const handleCloseError = () => {
    setError({ state: false, message: '' });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-email-field"
      className="dialog"
    >
      {!success.state && !error.state && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledDialogTitle title={t('Tooltip.Email')} onClose={onClose} />
          <DialogContent>
            <p className="dialog__text">{t('Dialog.EmailHint')}</p>
            <Controller
              name="email"
              control={control}
              rules={{
                required: t('ValidateErrors.Required'),
                validate: () => emailPattern.test(getValues('email'))
                  || t('ValidateErrors.Email'),
              }}
              defaultValue={emailField}
              render={({ onChange, value }) => (
                <CustomOutlinedInput
                  icon={<LazyImg src={Mail} />}
                  label="Email"
                  name="Email"
                  value={value}
                  onChange={(e) => {
                    setEmailField(e.target.value);
                    onChange(e);
                  }}
                  errors={errors.email}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loading}
              className="dialog__btn dialog__cancel"
              onClick={onClose}
            >
              {t('Common.Cancel')}
            </Button>
            <Button
              disabled={loading}
              className="dialog__btn dialog__submit"
              type="submit"
            >
              {t('Common.Send')}
            </Button>
          </DialogActions>
        </form>
      )}
      {success.state && (
        <>
          <DialogContent>
            <Typography
              component={(props) => <Box сomponent="h4" color="success.main" {...props} />}
              variant="h6"
            >
              {success.message}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} className="dialog__btn dialog__submit">
              {t('Common.Ok')}
            </Button>
          </DialogActions>
        </>
      )}

      {error.state && (
        <>
          <DialogTitle className="feedback__title dialog__error">
            {error.message}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={handleCloseError}
              className="dialog__btn dialog__submit"
            >
              {t('Common.Ok')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

ModalEmail.propTypes = {
  barcode: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalEmail;
