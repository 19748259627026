class Storage {
  static saveItem = (name, value) => {
    window.localStorage.setItem(name, JSON.stringify(value));
  };

  static getItem = (item) => JSON.parse(window.localStorage.getItem(item));

  static save = (name, value) => {
    window.localStorage.setItem(name, value);
  };

  static get = (item) => window.localStorage.getItem(item);

  static removeItem = (item) => window.localStorage.removeItem(item);

  static removeAll = () => {
    Storage.removeItem('currentUser');
  };

  static removeStorage = (name) => {
    try {
      window.localStorage.removeItem(name);
      window.localStorage.removeItem(`${name}_expiresIn`);
    } catch (e) {
      // console.log(
      //   `removeStorage: Error removing key [${name}] from localStorage: ${JSON.stringify(
      //     e
      //   )}`
      // );
      return false;
    }
    return true;
  }

  static getStorage = (key) => {
    const now = Date.now();
    let expiresIn = window.localStorage.getItem(`${key}_expiresIn`);
    if (expiresIn === undefined || expiresIn === null) {
      expiresIn = 0;
    }

    if (expiresIn < now) {
      this.removeStorage(key);
      return null;
    }
    try {
      const value = window.localStorage.getItem(key);
      return JSON.parse(value);
    } catch (e) {
      // console.log(
      //   `getStorage: Error reading key [${key}] from localStorage: ${JSON.stringify(
      //     e
      //   )}`
      // );
      return null;
    }
  }

  // expires в секундах

  static setStorage = (key, value, expires) => {
    if (expires === undefined || expires === null) {
      // eslint-disable-next-line no-param-reassign
      expires = 2 * 60 * 60; // default: 2 hours
    } else {
      // eslint-disable-next-line no-param-reassign
      expires = Math.abs(expires); // make sure it positive
    }

    const now = Date.now();
    const schedule = now + expires * 1000;
    try {
      const serializedState = JSON.stringify(value);
      window.localStorage.setItem(key, serializedState);
      window.localStorage.setItem(`${key}_expiresIn`, schedule);
    } catch (e) {
      // console.log(
      //   `setStorage: Error setting key [${key}] in localStorage: ${JSON.stringify(
      //     e
      //   )}`
      // );
      return false;
    }
    return true;
  }
}

export default Storage;
