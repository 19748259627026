import i18n from '../../i18n';

const localizeTimePeriod = (id) => {
  switch (id) {
    case 1: {
      return i18n.t(`TimePeriodType.period_${id}`);
    }
    case 2: {
      return i18n.t(`TimePeriodType.period_${id}`);
    }
    case 3: {
      return i18n.t(`TimePeriodType.period_${id}`);
    }
    default:
      return '';
  }
};

export default localizeTimePeriod;
