import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: '',
};

const serviceExtensionReducer = createSlice({
  name: 'service-extension',
  initialState,
  reducers: {
    setAlertMessage(state, action) {
      state.message = action.payload;
    },
    clearMessage(state) {
      state.message = '';
    },
  },
});

export const { setAlertMessage, clearMessage } = serviceExtensionReducer.actions;
export default serviceExtensionReducer.reducer;
