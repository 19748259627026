import {
  Button, Typography, Box, DialogTitle
} from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Alert } from '@material-ui/lab';
import CustomOutlinedInput from '../../../Inputs/BaseOutlinedInput/CustomOutlinedInput';
import AdminActions from '../../_components/AdminActions';
import AdminContent from '../../_components/AdminContent';
import AdminDialog from '../../_components/AdminDialog';
import '../../../Dialogs/Dialog.scss';
import '../../_components/AdminDialog.scss';
import fetchStatus from '../../../../_constants/fetchStatus';
import errorHelper from '../../../../_constants/errorHelper';
import ApiClient from '../../../../ApiClient/ApiClient';
import DiscountGenerationTypeField from '../Field/DiscountGenerationTypeField';
import DiscountPrefixGenerationTypeStatus from '../_helpers/DiscountPrefixGenerateTypeStatus';
import CardPrefixSwitch from '../CardPrefix/CardPrefixSwitch';
import LoadingButton from '../../../_components/LoadingButton';

const EditCardPrefix = ({ open, onClose, data }) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const generationType = useSelector(
    (state) => state.prefixGenerationType.generationType
  );
  const generationTypeStatus = useSelector(
    (state) => state.prefixGenerationType.status
  );
  const error = generationTypeStatus === fetchStatus.REJECTED
    && generationType.length === 0;

  const [status, setStatus] = useState(fetchStatus.IDLE);
  const [fetchMessage, setFetchMessage] = useState('');
  const success = status === fetchStatus.FULFILLED;
  const wasFetched = status !== fetchStatus.IDLE;

  const {
    control, handleSubmit, formState, errors, reset
  } = useForm({
    defaultValues: {
      prefix: data.prefix,
      discountPrefixGenerateType: data.discountPrefixGenerateType.id.toString(),
      description: data.description,
      isActive: data.isActive,
    },
  });
  const { isDirty, isSubmitting } = formState;

  const fetchToChangeDiscountPrefix = async (formData) => {
    const token = await executeRecaptcha();

    const typeId = parseInt(formData.discountPrefixGenerateType, 10);

    const discountPrefixGenerateType = generationType.find(
      (item) => item.id === typeId
    );

    const fetchData = {
      ...formData,
      discountPrefixGenerateType,
      id: data.id,
    };

    return ApiClient.ChangeDiscountPrefix(fetchData, token)
      .then((res) => {
        if (res) {
          if (res.isSuccess) {
            setStatus(fetchStatus.FULFILLED);
            reset(formData);
            // TODO: локализировать
            return setFetchMessage(
              `Изменения для префикса ${data.prefix} успешно применены!`
            );
          }
          throw new Error(res.statusMessage);
        }
        throw new Error(errorHelper.common);
      })
      .catch((e) => {
        setFetchMessage(e.message);
        setStatus(fetchStatus.REJECTED);
      });
  };
  const onSubmit = async (formData) => fetchToChangeDiscountPrefix(formData);

  return (
    <AdminDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      className="dialog"
    >
      <DialogTitle disableTypography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap
        >
          <Typography className="dialog__title" variant="h5">
            {t('Admin:Discount.prefixEdit')}
          </Typography>
          <Controller
            control={control}
            name="isActive"
            render={({ value, onChange }) => (
              <CardPrefixSwitch
                value={value}
                onChange={onChange}
                id={[data.id]}
              />
            )}
          />
        </Box>
      </DialogTitle>
      <AdminContent>
        {wasFetched && (
          <Box marginBottom={3}>
            <Alert color={success ? 'success' : 'error'}>{fetchMessage}</Alert>
          </Box>
        )}
        <div className="a-dialog__field">
          <Controller
            control={control}
            name="prefix"
            render={({ value, onChange }) => (
              <CustomOutlinedInput
                value={value}
                onChange={onChange}
                label={t('Admin:Discount.cardPrefix')}
                required
              />
            )}
          />
        </div>
        <div className="a-dialog__field">
          <Controller
            control={control}
            name="description"
            rules={{ required: errorHelper.required }}
            render={({ value, onChange }) => (
              <CustomOutlinedInput
                multiline
                label={t('Admin:Text.Description')}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="a-dialog__field_last">
          {!error && (
            <Controller
              control={control}
              name="discountPrefixGenerateType"
              rules={{ required: errorHelper.required }}
              render={({ value, onChange }) => (
                <DiscountGenerationTypeField
                  value={value}
                  onChange={onChange}
                  errors={errors.discountPrefixGenerateType}
                />
              )}
            />
          )}
          <DiscountPrefixGenerationTypeStatus />
        </div>
      </AdminContent>
      <AdminActions>
        <Button className="dialog__btn dialog__cancel" onClick={onClose}>
          {t('Common.Cancel')}
        </Button>
        <LoadingButton
          loading={isSubmitting}
          className="dialog__btn dialog__submit"
          disabled={!isDirty || isSubmitting || error}
          type="submit"
        >
          {t('Common.Save')}
        </LoadingButton>
      </AdminActions>
    </AdminDialog>
  );
};

// TODO: propTypes
EditCardPrefix.propTypes = {};

export default EditCardPrefix;
