import {
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Basket from '../Basket/Basket';

import AddingServicesService from './AddingServicesService';
import ServicesTablePage from '../ServicesTable/ServicesTablePage';

const Services = ({ handleNext }) => {
  const theme = useTheme();

  const spacing = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <div id="services">
      <Grid container spacing={spacing ? 2 : undefined}>
        <Grid item xs={12} md={7}>
          <ServicesTablePage />
        </Grid>
        <Grid item xs={12} md={5}>
          <Basket handleNext={handleNext} />
        </Grid>
      </Grid>
      <AddingServicesService />
    </div>
  );
};

Services.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default Services;
