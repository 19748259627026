import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InfoHub from '../../Hubs/InfoHub';
import Storage from '../../_helpers/Storage';
import authenticationService from '../../_services/authentication.service';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { AppRoute } from '../../const/app-routes';

const CheckAuth = () => {
  const isLogged = useSelector((state) => state.userReducer.user);
  const location = useLocation();

  useEffect(() => {
    const user = Storage.getItem('currentUser');
    if (user && user.expiresOn) {
      const expiresDateTime = new Date(user.expiresOn * 1e3);
      const currentDateTime = new Date().getTime();
      if (expiresDateTime < currentDateTime) {
        authenticationService.logout();
      }
    }
  }, []);

  return isLogged && location.pathname !== AppRoute.AdminRoutes.ServiceExtensionRefresh && <InfoHub />;
};

export default CheckAuth;
