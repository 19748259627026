import { Button, Box, Typography, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Alert } from '@material-ui/lab';
import CustomOutlinedInput from '../../../Inputs/BaseOutlinedInput/CustomOutlinedInput';
import AdminActions from '../../_components/AdminActions';
import AdminContent from '../../_components/AdminContent';
import AdminDialog from '../../_components/AdminDialog';
import '../../../Dialogs/Dialog.scss';
import '../../_components/AdminDialog.scss';
import fetchStatus from '../../../../_constants/fetchStatus';
import ApiClient from '../../../../ApiClient/ApiClient';
import errorHelper from '../../../../_constants/errorHelper';
import ColorSwitch from '../../_components/ColorSwitch';
import DiscountGenerationTypeField from '../Field/DiscountGenerationTypeField';
import DiscountPrefixGenerationTypeStatus from '../_helpers/DiscountPrefixGenerateTypeStatus';
import StyledFormControlLabel from '../../../_components/StyledFormControlLabel';
import LoadingButton from '../../../_components/LoadingButton';

const CreateCardPrefix = ({ open, onClose }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();

  const [fetchMessage, setFetchMessage] = useState('');
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const success = status === fetchStatus.FULFILLED;
  const wasFetched = status !== fetchStatus.IDLE;

  const generationType = useSelector(
    (state) => state.prefixGenerationType.generationType
  );
  const generationTypeStatus = useSelector(
    (state) => state.prefixGenerationType.status
  );

  const error =
    generationTypeStatus === fetchStatus.REJECTED ||
    generationType.length === 0;

  const { control, handleSubmit, formState, errors, reset } = useForm({
    defaultValues: {
      id: 0,
      prefix: '',
      description: '',
      discountPrefixGenerateType: null,
      isActive: true,
    },
  });

  const fetchToCreateCardPrefix = async (data) => {
    const token = await executeRecaptcha();
    const typeId = parseInt(data.discountPrefixGenerateType, 10);

    const discountPrefixGenerateType = generationType.find(
      (item) => item.id === typeId
    );
    const fetchData = {
      ...data,
      id: 0,
      discountPrefixGenerateType,
    };

    return ApiClient.CreateDiscountPrefix(fetchData, token)
      .then((res) => {
        if (res) {
          if (res.isSuccess) {
            setStatus(fetchStatus.FULFILLED);
            reset(data);
            return setFetchMessage(res.statusMessage);
          }
          throw new Error(res.statusMessage);
        }
        throw new Error(errorHelper.common);
      })
      .catch((e) => {
        setFetchMessage(e.message);
        setStatus(fetchStatus.REJECTED);
      });
  };

  const { isDirty, isSubmitting } = formState;
  const onSubmit = async (data) => fetchToCreateCardPrefix(data);

  return (
    <AdminDialog
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      className="dialog"
    >
      <DialogTitle disableTypography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap
        >
          <Typography className="dialog__title" variant="h5">
            {t('Admin:Discount.cardPrefixCreate')}
          </Typography>
          <Controller
            control={control}
            name="isActive"
            render={({ value, onChange }) => (
              <StyledFormControlLabel
                label={
                  value
                    ? t('Admin:Control.active')
                    : t('Admin:Control.nonActive')
                }
                control={
                  <ColorSwitch
                    value={value}
                    checked={value}
                    onChange={(e) => onChange(e.currentTarget.checked)}
                  />
                }
              />
            )}
          />
        </Box>
      </DialogTitle>
      <AdminContent>
        {wasFetched && (
          <Box marginBottom={3}>
            <Alert color={success ? 'success' : 'error'}>{fetchMessage}</Alert>
          </Box>
        )}
        <div className="a-dialog__field">
          <Controller
            control={control}
            name="prefix"
            rules={{ required: errorHelper.required }}
            render={({ value, onChange }) => (
              <CustomOutlinedInput
                value={value}
                onChange={onChange}
                label={t('Admin:Discount.prefix')}
                errors={errors.prefix}
              />
            )}
          />
        </div>
        <div className="a-dialog__field">
          <Controller
            control={control}
            name="description"
            rules={{ required: errorHelper.required }}
            render={({ value, onChange }) => (
              <CustomOutlinedInput
                multiline
                label={t('Admin:Text.Description')}
                value={value}
                onChange={onChange}
                errors={errors.description}
              />
            )}
          />
        </div>
        <div className="a-dialog__field_last">
          {!error && (
            <Controller
              control={control}
              name="discountPrefixGenerateType"
              rules={{ required: errorHelper.required }}
              render={({ value, onChange }) => (
                <DiscountGenerationTypeField
                  value={value}
                  onChange={onChange}
                  errors={errors.discountPrefixGenerateType}
                />
              )}
            />
          )}
          <DiscountPrefixGenerationTypeStatus />
        </div>
      </AdminContent>
      <AdminActions>
        <Button className="dialog__btn dialog__cancel" onClick={onClose}>
          {t('Common.Cancel')}
        </Button>
        <LoadingButton
          loading={isSubmitting}
          disabled={!isDirty || isSubmitting || error}
          className="dialog__btn dialog__submit"
          type="submit"
        >
          {t('Common.Create')}
        </LoadingButton>
      </AdminActions>
    </AdminDialog>
  );
};

CreateCardPrefix.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateCardPrefix;
