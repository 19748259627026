import {
  Checkbox,
  Fade,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Box,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DialogButton from '../../MailManager/DialogButton';
import './CardPrefix.scss';

import { ReactComponent as SortActiveIcon } from '../../img/Sort_up.svg';
import { ReactComponent as SortIcon } from '../../img/Sort2.svg';
import SortFunc from '../../../../_helpers/sort-funtions';
import CreateCardPrefix from '../Dialog/CreateCardPrefix';
import EditCardPrefixButton from '../Dialog/EditCardPrefixButton';
import fetchStatus from '../../../../_constants/fetchStatus';
import {
  fetchByAllDiscountPrefix,
  setPageNumber,
  setPageSize,
  setSearch,
} from '../../../../_reducers/prefixReducer';
import GetGenerationType from '../_helpers/GetGenerationType';
import InputWithDebounce from '../../../Inputs/InputWithDebounce';
import BaseFilledInput from '../../../Inputs/BaseFilledInput/BaseInput';
import SelectAllCheckbox from './SelectAllCheckbox';
import CardPrefixSwitch from './CardPrefixSwitch';
import DiscountPrefixStatusButton from '../_helpers/DiscountPrefixStatusButton';
import tablePaginationHelper from '../../../../_helpers/tablePaginationHelper';

const headCells = [
  {
    id: 'prefix',
    label: 'Discount.prefix1',
  },
  {
    id: 'description',
    label: 'Text.Description',
  },
  {
    id: 'discountPrefixGenerateTypeid',
    label: 'Discount.generationType',
    colSpan: true,
  },
  {
    id: 'isActive',
    label: 'Discount.status',
  },
];

const CardPrefixPage = () => {
  const { t } = useTranslation('Admin');
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const pageSize = useSelector((state) => state.prefix.pageSize);
  const page = useSelector((state) => state.prefix.pageNumber);
  const totalCount = useSelector((state) => state.prefix.totalCount);
  const rows = useSelector((state) => state.prefix.prefixes);
  const message = useSelector((state) => state.prefix.message);
  const status = useSelector((state) => state.prefix.status);
  const error = status === fetchStatus.REJECTED;
  const FULFILLED = status === fetchStatus.FULFILLED;
  const loading = status === fetchStatus.PENDING;
  const search = useSelector((state) => state.prefix.search);

  const handleChangeSearch = (value) => {
    dispatch(setSearch(value));
  };

  const handleChangePage = (e, newPage) => {
    dispatch(setPageNumber(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setPageSize(parseInt(event.target.value, 10)));
  };

  const handleResetSelected = () => {
    setSelected([]);
  };

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (value) => selected.indexOf(value) !== -1;

  const rowCount = rows.length;
  const numSelected = selected.length;

  useEffect(() => {
    dispatch(
      fetchByAllDiscountPrefix({ pageSize, pageNumber: page + 1, search })
    );
  }, [page, pageSize, search]);

  const renderRows = SortFunc.stableSort(
    rows,
    SortFunc.getComparator(order, orderBy)
  ).map((row) => {
    const isItemSelected = isSelected(row.id);

    return (
      <TableRow
        key={row.id}
        selected={isItemSelected}
        className="styled-table__row"
      >
        <TableCell
          padding="checkbox"
          className="styled-table__cell discount-table__cell"
        >
          <Checkbox
            onClick={(event) => handleClick(event, row.id)}
            checked={isItemSelected}
            size="small"
            color="primary"
          />
        </TableCell>
        <TableCell className="styled-table__cell discount-table__cell">
          {row.prefix}
        </TableCell>
        <TableCell className="styled-table__cell discount-table__cell">
          {row.description}
        </TableCell>
        <TableCell className="styled-table__cell discount-table__cell">
          {t(
            `Admin:Discount.generationType_${row.discountPrefixGenerateType?.id}`
          )}
        </TableCell>
        <TableCell className="styled-table__cell discount-table__cell">
          <CardPrefixSwitch value={row.isActive} id={[row.id]} />
        </TableCell>
        <TableCell className="styled-table__cell styled-table__cell_icons discount-table__cell">
          <Box textAlign="end">
            <EditCardPrefixButton row={row} />
          </Box>
        </TableCell>
      </TableRow>
    );
  });

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleValidateSearch = (value) => {
    if (value.length > 0 && value.length < 3) {
      return t('translations:ValidateErrors.MinLen', { count: 3 });
    }
    return true;
  };

  return (
    <div className="styled-table card-prefix">
      <GetGenerationType />
      <Paper className="styled-table__container">
        <div className="styled-table__header">
          <div className="card-prefix__search">
            {numSelected > 0 ? (
              <Fade in={numSelected > 0}>
                <div className="styled-table__selected">
                  <span className="styled-table__selected-label">
                    {`${t('Discount.total')}: ${numSelected}`}
                  </span>
                  <DiscountPrefixStatusButton
                    resetSelected={handleResetSelected}
                    id={selected}
                  />
                </div>
              </Fade>
            ) : (
              <InputWithDebounce
                value={search}
                onChange={handleChangeSearch}
                component={BaseFilledInput}
                onClear
                validate={handleValidateSearch}
                isTargetNeed
                label={
                  mobile
                    ? t('Input.SearchPlaceholder', { ns: 'translations' })
                    : t('Discount.searchPrefix')
                }
              />
            )}
          </div>
          <DialogButton
            mobileIcon
            component={CreateCardPrefix}
            title={t('Discount.prefixNew')}
          />
        </div>
        {FULFILLED && (
          <div className="styled-table__table-container services-table">
            <Table className="styled-table__table card-prefix__table">
              <TableHead>
                <TableRow className="styled-table__thead-row">
                  <TableCell padding="checkbox" className="styled-table__th">
                    <SelectAllCheckbox
                      total={totalCount}
                      rowsCount={rowCount}
                      setSelected={setSelected}
                      numSelected={selected.length}
                    />
                  </TableCell>
                  {headCells.map((headCell, index) => {
                    const active = orderBy === headCell.id;
                    return (
                      <TableCell
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        sortDirection={active ? order : false}
                        component="th"
                        className="styled-table__th"
                      >
                        <TableSortLabel
                          hideSortIcon={isTablet}
                          active={active}
                          direction={active ? order : 'asc'}
                          onClick={handleRequestSort(headCell.id)}
                          IconComponent={active ? SortActiveIcon : SortIcon}
                        >
                          {t(headCell.label)}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                  <TableCell className="styled-table__th" />
                </TableRow>
              </TableHead>
              <TableBody>{renderRows}</TableBody>
            </Table>
            <TablePagination
              className="services-table__pagination"
              rowsPerPageOptions={tablePaginationHelper.rowsPerPageOptions}
              component="div"
              count={totalCount}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
      {loading && (
        <Box p={5} textAlign="center">
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box p={5} textAlign="center">
          <Typography color="error" variant="h6">
            {message}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default CardPrefixPage;
