import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Dialog.scss';

const ConfirmDialog = ({
  open,
  onClose,
  title,
  message,
  value,
  submitMessage,
  onSubmit,
}) => {
  const handleSubmit = (e) => {
    onSubmit(value, e);
    onClose(e);
  };
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} className="dialog">
      <DialogTitle className="dialog__title">{title}</DialogTitle>
      {message && (
        <DialogContent className="dialog__content">
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} className="dialog__btn dialog__cancel">
          {t('Common.Cancel')}
        </Button>
        <Button onClick={handleSubmit} className="dialog__btn dialog__submit">
          {submitMessage}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  value: PropTypes.any,
  submitMessage: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ConfirmDialog.defaultProps = {
  message: '',
  value: '',
};
export default ConfirmDialog;
