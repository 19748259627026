export const Role = {
  Sorter: 5,
  Admin: 4,
  Manager: 2,
  User: 3,
  Nurse: 1,
  AdvancedUser: 6,
};

export const AllRoles = [
  { RoleId: Role.User, Name: 'Roles.User' },
  { RoleId: Role.Manager, Name: 'Roles.Manager' },
  { RoleId: Role.Admin, Name: 'Roles.Admin' },
  { RoleId: Role.Sorter, Name: 'Roles.Sorter' },
  { RoleId: Role.Nurse, Name: 'Roles.Nurse' },
  { RoleId: Role.AdvancedUser, Name: 'Roles.AdvancedUser' },
];

export const AllowedRoles = {
  News: [Role.Admin, Role.Manager, Role.Sorter, Role.User, Role.Nurse, Role.AdvancedUser],
  Results: [Role.Admin, Role.Manager, Role.Sorter, Role.User, Role.Nurse, Role.AdvancedUser],

  CreateOrder: [Role.Admin, Role.Manager, Role.Sorter, Role.Nurse],
  Sort: [Role.Admin, Role.Manager, Role.Sorter, Role.Nurse, Role.AdvancedUser],
  Outsource: [Role.Admin, Role.Sorter],

  Settings: [Role.Admin, Role.Manager, Role.User, Role.Sorter, Role.Nurse, Role.AdvancedUser],
  Admin: [Role.Admin, Role.Manager],
  PreOrder: [Role.Admin],
};

export function isSatisfiedRoles(allowedRoles, role) {
  return allowedRoles.indexOf(role) !== -1;
}
