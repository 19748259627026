import React, { PureComponent } from 'react';
import { Translation } from 'react-i18next';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import logo from './img/logo_h.svg';
import date1 from './img/date1.svg';
import './AccompanyingDirection.scss';
import './Direction.scss';
import { sortState } from '../../_constants/OrderStatusConst';
import getPatientFullName from '../../_helpers/scripts/getPatientFullName';

class AccompanyingDirectionToPrint extends PureComponent {
  state = {};

  render() {
    const { data, sortingState } = this.props;

    if (data) {
      const contracts = () => {
        const names = [];
        data.directions.map((item) => {
          if (names.indexOf(item.contract.name) === -1) {
            names.push(item.contract.name);
          }
        });
        return [names.join(', '), names.length];
      };

      const [contract, conLength] = contracts();
      return (
        <div className="direction acc-direction">
          <div className="direction__container">
            <div className="dir__header acc-direction__header">
              <img alt="Helix" src={logo} width="123px" height="38px" />
              <div>
                <span className="dir__header-span">
                  <img
                    alt=""
                    src={date1}
                    width="32px"
                    height="32px"
                    className="dir__header-icon"
                  />
                  <h2 className="dir__header-title">
                    {format(new Date(), 'dd.MM.yyyy HH:mm')}
                  </h2>
                </span>
                <h4 className="dir__header-subtitle">
                  <Translation>{(t) => t('Direction.createdDate')}</Translation>
                </h4>
              </div>
            </div>
          </div>
          <div className="acc-direction__title direction__container">
            <h4 className="dir__title">{contract}</h4>
            <h6 className="dir__order-info-subtitle">
              <Translation>
                {(t) =>
                  t('AccompanyingDirection:contracts', { count: conLength })
                }
              </Translation>
            </h6>
          </div>
          <table
            border="0"
            cellPadding="0"
            cellSpacing="0"
            className="dir-table acc-direction__table"
          >
            <thead>
              <tr className="dir-table__head">
                <th className="dir-table__cell_head">
                  <Translation>{(t) => t('Direction.Order')}</Translation>
                </th>
                <th className="dir-table__cell_head">
                  <Translation>{(t) => t('Direction.Patient')}</Translation>
                </th>
                <th className="dir-table__cell_head">
                  <Translation>{(t) => t('Direction.Service')}</Translation>
                </th>
                <th className="dir-table__cell_head">
                  <Translation>{(t) => t('Direction.Tubes')}</Translation>
                </th>
                <th className="dir-table__cell_head">
                  <Translation>{(t) => t('Direction.WorkingDays')}</Translation>
                </th>
                {sortingState === sortState.NEW ? null : (
                  <th className="dir-table__cell_head">
                    <Translation>
                      {(t) => t('Direction.ImportDate')}
                    </Translation>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {data.directions.map((order) => (
                <tr className="dir-table__row">
                  <td className="dir-table__cell dir-table__cell_small">
                    {order.barcode}
                  </td>
                  <td className="dir-table__cell dir-table__cell_small">
                    {getPatientFullName(order.patient)}
                  </td>
                  <td className="dir-table__cell dir-table__cell_small">
                    {order.services
                      ? order.services.map((s) => (
                          <>
                            {`${s.code} — ${s.name}`}
                            <br />
                          </>
                        ))
                      : '———'}
                  </td>
                  <td className="dir-table__cell dir-table__cell_small">
                    {order.services
                      ? order.tubes.map((tube) => (
                          <>
                            {`${tube.code} — ${tube.count}`}
                            <br />
                          </>
                        ))
                      : '———'}
                  </td>
                  <td className="dir-table__cell dir-table__cell_small">
                    {order.workingDays}
                  </td>
                  {sortingState === sortState.NEW ? null : (
                    <td className="dir-table__cell dir-table__cell_small">
                      {order.importedDate}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="dir-table__cell_foot" colSpan={6}>
                  <h6 className="dir__comment acc-direction__table-footer-text">
                    <Translation>{(t) => t('Direction.text')}</Translation>
                  </h6>
                </td>
              </tr>
            </tfoot>
          </table>
          <div>
            <h4 className="dir__title-m-b acc-direction__bio-title">
              <Translation>{(t) => t('Direction.Biomaterial')}</Translation>
            </h4>
            {data.totalTubes.length > 0 && (
              <div className="dir__about acc-direction__bio">
                <table
                  border="0"
                  cellPadding="0"
                  cellSpacing="0"
                  className="dir-table"
                >
                  <thead>
                    <tr className="dir-table__head_small">
                      <th width="50%" className="dir-table__cell_head">
                        <Translation>{(t) => t('Direction.Name')}</Translation>
                      </th>
                      <th width="50%" className="dir-table__cell_head">
                        <Translation>{(t) => t('Direction.count')}</Translation>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.totalTubes.map((tube) => (
                      <tr className="dir-table__row_bio">
                        <td className="dir-table__cell">{tube.code}</td>
                        <td className="dir-table__cell">{tube.count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <div className="b-dir__sign direction__container">
              <div>
                <div className="dir__sign" />
                <p className="dir__order-info-subtitle dir__sign-subtitle">
                  <Translation>{(t) => t('Direction.Passed')}</Translation>
                </p>
              </div>
              <div>
                <div className="dir__sign" />
                <p className="dir__order-info-subtitle dir__sign-subtitle">
                  <Translation>{(t) => t('Direction.accepted')}</Translation>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

AccompanyingDirectionToPrint.propTypes = {
  data: PropTypes.shape({
    directions: PropTypes.arrayOf(
      PropTypes.shape({
        barcode: PropTypes.number,
        createdDate: PropTypes.string,
        importedDate: PropTypes.string,
        printedDate: PropTypes.string,
        sumPrice: PropTypes.number,
        workingDays: PropTypes.number,
        patient: PropTypes.shape({
          id: PropTypes.number,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          gender: PropTypes.string,
          dateOfBirth: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
        contract: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
        tubes: [
          PropTypes.shape({
            code: PropTypes.string,
            count: PropTypes.number,
          }),
        ],
        services: [
          PropTypes.shape({
            id: PropTypes.number,
            code: PropTypes.string,
            name: PropTypes.string,
            price: PropTypes.number,
          }),
        ],
      })
    ),
    totalTubes: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        count: PropTypes.number,
      })
    ),
  }).isRequired,
};

export default AccompanyingDirectionToPrint;
