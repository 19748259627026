import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Popover,
} from '@material-ui/core';
import { Done } from '@material-ui/icons';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactInputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import BaseFilledInput from '../../Inputs/BaseFilledInput/BaseInput';
import BaseInputDatePicker from '../../Inputs/BaseFilledInput/BaseInputDatePicker';
import LazyImg from '../../../_helpers/LazyImg';
import { ReactComponent as CloseIcon } from '../../../_icons/close.svg';
import emailPattern from '../../../_helpers/emailPattern';
import phonePattern from '../../../_helpers/phoneNumPattern';
import PhoneIcon from '../../Dialogs/img/phone.svg';
import CustomChip from './_components/CustomChip';

import { setFilterData } from '../../../_reducers/patientReducer';

import { ReactComponent as FilterIcon } from '../img/Filter.svg';
import MailIcon from '../../Dialogs/img/mail.svg';

import '../../Inputs/BaseFilledInput/BaseInput.scss';
import './Filter.scss';
import useDebounce from '../../../_helpers/scripts/useDebounce';
import errors from '../../../_constants/errors';
import StyledTextField from '../../Inputs/StyledTextField';

const InputWithValidationAndValidation = ({
  component: Component,
  value,
  onChange,
  validate,
  isTargetNeed,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value);

  const debouncedSearchValue = useDebounce(inputValue, 200);
  const [error, setError] = useState('');

  useEffect(() => {
    const isValid = validate(inputValue);
    if (typeof isValid === 'boolean' && isValid) {
      onChange(inputValue);
      setError('');
    } else {
      setError(isValid);
    }
  }, [debouncedSearchValue]);

  const customOnChange = isTargetNeed
    ? (e) => setInputValue(e.target.value)
    : setInputValue;

  return (
    <Component
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={inputValue}
      onChange={customOnChange}
      error={Boolean(error)}
      helperText={error}
    />
  );
};

InputWithValidationAndValidation.propTypes = {
  component: PropTypes.elementType.isRequired,
  onChange: PropTypes.func.isRequired,
  isTargetNeed: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const FilterDialog = ({ disabled }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterValues = useSelector((state) => state.patient.filterData);
  const [gender, setGender] = useState(filterValues.gender);

  const handleSelect = (newVal) => {
    if (gender === newVal) {
      setGender('');
    } else {
      setGender(newVal);
    }
  };

  const handleDelete = () => {
    setGender('');
  };

  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (filterValues.gender !== gender) {
      const value = {
        ...filterValues,
        gender,
      };
      dispatch(setFilterData(value));
    }
  }, [gender]);

  useEffect(() => {
    if (filterValues.email !== email) {
      const value = {
        ...filterValues,
        email,
      };
      dispatch(setFilterData(value));
    }
  }, [email]);

  useEffect(() => {
    if (
      firstName.length > 2
      || (firstName === '' && filterValues.firstName !== firstName)
    ) {
      const value = {
        ...filterValues,
        firstName,
      };
      dispatch(setFilterData(value));
    }
  }, [firstName]);

  useEffect(() => {
    if (
      lastName.length > 2
      || (lastName === '' && filterValues.lastName !== lastName)
    ) {
      const value = {
        ...filterValues,
        lastName,
      };
      dispatch(setFilterData(value));
    }
  }, [lastName]);

  useEffect(() => {
    if (phone) {
      let value;
      if (phone === '+375 __ ___ __ __') {
        value = {
          ...filterValues,
          phone: '',
        };
      } else {
        value = {
          ...filterValues,
          phone,
        };
      }
      dispatch(setFilterData(value));
    }
  }, [phone]);

  useEffect(() => {
    if (dateOfBirth) {
      const value = {
        ...filterValues,
        dateOfBirth: format(dateOfBirth, 'dd.MM.yyyy'),
      };
      dispatch(setFilterData(value));
    }
    if (dateOfBirth === null && filterValues.dateOfBirth !== '') {
      const value = {
        ...filterValues,
        dateOfBirth: '',
      };
      dispatch(setFilterData(value));
    }
  }, [dateOfBirth]);

  const phoneValidation = (value) => {
    if (value === '' || value === '+375 __ ___ __ __') {
      return true;
    }
    if (value.indexOf('_') !== -1) {
      return t('ValidateErrors.PhonePattern');
    }
    if (phonePattern.test(value)) {
      return true;
    }
    return false;
  };

  const handleNameValidation = (value) => {
    if (value.length > 0 && value.length < 3) {
      return t('ValidateErrors.MinLen', { count: 3 });
    }
    return true;
  };

  const handleDateValidation = (value) => {
    if (value === null) {
      return true;
    }
    // eslint-disable-next-line no-restricted-globals
    if (value instanceof Date && !isNaN(value)) {
      return true;
    }
    return errors.invalidDate;
  };

  const handleEmailValidation = (value) => {
    if (value === '') {
      return true;
    }
    if (emailPattern.test(value)) {
      return true;
    }
    return t('ValidateErrors.Email');
  };

  return (
    <PopupState variant="popover" popupId="info-popup-popover">
      {(popupState) => (
        <>
          <CustomizedTooltip title={t('CreateOrder.Filter.Name')}>
            <span>
              <IconButton
                color="primary"
                disabled={disabled}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bindTrigger(popupState)}
              >
                <FilterIcon />
              </IconButton>
            </span>
          </CustomizedTooltip>
          <Popover
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bindPopover(popupState)}
            className="filter filter__popover"
          >
            <IconButton
              className="filter__close-btn"
              color="primary"
              onClick={bindPopover(popupState).onClose}
            >
              <CloseIcon width="16px" height="16px" />
            </IconButton>
            <form>
              <Paper className="filter__paper">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputWithValidationAndValidation
                      component={BaseInputDatePicker}
                      value={dateOfBirth}
                      validate={handleDateValidation}
                      onChange={setDateOfBirth}
                      label={t('Input.DateOfBirth')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomChip
                      value="M"
                      selected={gender === 'M'}
                      className="filter__chip"
                      label={t('CreateOrder.Filter.Male')}
                      onClick={handleSelect}
                      onDelete={handleDelete}
                      selectedIcon={<Done />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomChip
                      value="F"
                      selected={gender === 'F'}
                      className="filter__chip"
                      label={t('CreateOrder.Filter.Female')}
                      onDelete={handleDelete}
                      onClick={handleSelect}
                      selectedIcon={<Done />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputWithValidationAndValidation
                      component={BaseFilledInput}
                      isTargetNeed
                      name="firstName"
                      label={t('Input.FirstName')}
                      validate={handleNameValidation}
                      value={firstName}
                      onChange={setFirstName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputWithValidationAndValidation
                      component={BaseFilledInput}
                      isTargetNeed
                      name="lastName"
                      label={t('Input.LastName')}
                      validate={handleNameValidation}
                      value={lastName}
                      onChange={setLastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputWithValidationAndValidation
                      component={BaseFilledInput}
                      isTargetNeed
                      validate={handleEmailValidation}
                      icon={<LazyImg src={MailIcon} />}
                      label="Email"
                      value={email}
                      name="Email"
                      onChange={setEmail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputWithValidationAndValidation
                      isTargetNeed
                      validate={phoneValidation}
                      component={ReactInputMask}
                      mask="+375 99 999 99 99"
                      maskChar="_"
                      value={phone}
                      onChange={setPhone}
                    >
                      {(inputProps) => (
                        <StyledTextField
                          {...inputProps}
                          variant="filled"
                          fullWidth
                          label={t('Input.Phone')}
                          name="phone"
                          InputProps={{
                            ...inputProps.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <LazyImg src={PhoneIcon} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </InputWithValidationAndValidation>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

FilterDialog.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default FilterDialog;
