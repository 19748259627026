import { Paper, withStyles } from '@material-ui/core';

const CustomizedPaper = (props) => <Paper {...props} />;

const StyledPaper = withStyles({
  elevation1: {
    border: '1px solid #E7E7E7',
    borderRadius: 11,
    boxShadow: 'none',
  },
  elevation2: {
    boxShadow:
      '0px 3px 6px rgba(75, 81, 91, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.15)',
    borderRadius: 11,
  },
})(CustomizedPaper);

export default StyledPaper;
