import { ButtonBase } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import StyledTableCell from '../_components/StyledTableCell';
import { ReactComponent as ArrowIcon } from './Outsourcing/img/arrow.svg';
import './Outsourcing/StyledNestedTable.scss';

const TableCollapseButton = ({ open, onClick, TableCellProps }) => (
  <StyledTableCell
    {...TableCellProps}
    className={clsx(TableCellProps?.className, 'table-collapse__cell')}
  >
    <ButtonBase
      onClick={onClick}
      className={clsx('table-collapse__block', {
        'table-collapse__block--collapsed': open,
      })}
    >
      <ArrowIcon className="table-collapse__icon" />
      <ArrowIcon className="table-collapse__icon-bottom" />
    </ButtonBase>
  </StyledTableCell>
);

export default TableCollapseButton;
