import { useSelector } from 'react-redux';

const useApiContracts = () => {
  const Contracts = useSelector((state) => state.contract.value?.id);
  const ClientUnit = useSelector((state) => state.contract.clientUnit?.id);

  return { Contracts, ClientUnit };
};

export default useApiContracts;
