import React, { Suspense, useState } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ReactComponent as PhoneIcon } from './img/Phone.svg';
import { ReactComponent as MailIcon } from './img/Mail.svg';
import { ReactComponent as HeadphonesIcon } from './img/Headphones.svg';
import './Footer.scss';
import lazyWithPreload from '../../_helpers/scripts/lazyWithPreload';
import Fallback from '../_components/Fallback';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    color: 'inherit',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const FeedbackDialog = lazyWithPreload(() => import('./FeedbackForm'));

const StyledLink = ({ children, href }) => (
  <Box
    gridGap={4}
    component="a"
    href={href}
    color="inherit"
    display="inline-flex"
  >
    {children}
  </Box>
);

const Footer = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    FeedbackDialog.preload();
    setOpen(true);
  };

  return (
    <footer className={clsx(classes.root, 'footer')}>
      <Grid container spacing={2} className="footer__grid">
        <Grid item xs={12} md={6} lg={4} className="footer__title">
          <Typography variant="h6">{t('Footer.Title')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <StyledLink href="tel:+375172562888">
            <PhoneIcon />
            +375172562888
          </StyledLink>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <StyledLink href="tel:+375172562888">
            <PhoneIcon />
            +375172562888
          </StyledLink>
        </Grid>
        <Grid item xs="auto" md lg="auto" className="footer__grid--empty" />
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <button
            type="button"
            className={classes.button}
            onClick={handleOpenDialog}
          >
            <MailIcon />
            b2b@helix.by
          </button>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <StyledLink href="#">
            <HeadphonesIcon />
            {t('Footer.TechnicalSupport')}
          </StyledLink>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" color="inherit">
            {t('Footer.Copywriting')}
          </Typography>
        </Grid>
      </Grid>
      {open && (
        <Suspense fallback={<Fallback />}>
          <FeedbackDialog open={open} onClose={handleCloseDialog} />
        </Suspense>
      )}
    </footer>
  );
};

export default Footer;
