import {
  Hidden,
  Box,
  Button,
  Grid,
  Typography,
  Collapse,
} from '@material-ui/core';
import { format } from 'date-fns';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../../../_helpers/scripts/useDebounce';
import CustomAutocomplete from '../../../Inputs/CustomAutocomplete';
import StyledChip from '../../../_components/StyledChip';
import { ReactComponent as FilterIcon } from '../../img/filter.svg';
import { ReactComponent as FilterCloseIcon } from '../../img/filter_close.svg';
import DateRangeField from '../../../Inputs/DateRange/DateRangeField';
import localizeTimePeriod from '../../../../_helpers/scripts/localizeTimePeriod';
import fetchStatus from '../../../../_constants/fetchStatus';
import SearchCardField from './SearchCardField';
import { setSearchParams } from '../../../../_reducers/discountReducer';
import StyledNumberTextField from '../../../Inputs/NumberField/StyledNumberTextField';
import StyledTextField from '../../../Inputs/StyledTextField';

const formatDate = (value) => format(value, 'dd/MM/yyyy');

const Filter = ({ children }) => {
  const { t } = useTranslation('Admin');
  const [openFilter, setOpenFilter] = useState(false);
  const dispatch = useDispatch();

  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => setOpenFilter(false);

  const [values, setValues] = useState({
    prefix: null,
    cardNumber: '',
    possibleNumberOfUses: '',
    realNumberOfUses: '',
    contract: '',
    discountType: '',
    customerFullName: '',
    startEndValidity: [null, null],
  });

  const debouncedValues = useDebounce(values, 1000);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleClear = (name) => {
    if (name === true) {
      return setValues({
        prefix: '',
        cardNumber: '',
        possibleNumberOfUses: '',
        periodTypeOfUsesId: '',
        realNumberOfUses: '',
        contract: '',
        discountType: '',
        customerFullName: '',
        startEndValidity: [null, null],
      });
    }
    if (name === 'startEndValidity') {
      return setValues({ ...values, [name]: [null, null] });
    }

    return setValues({ ...values, [name]: '' });
  };

  const [chipData, setChipData] = React.useState([]);

  const handleDelete = (chipToDelete) => () => {
    if (chipToDelete === true) {
      handleClear(true);
      return setChipData([]);
    }
    handleClear(chipToDelete.key);
    return setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  useEffect(() => {
    const RenderChipAsync = async () => {
      const data = Object.entries(debouncedValues);
      const result = await Promise.all(
        data.map(async ([name, value]) => {
          if (name === 'startEndValidity') {
            if (value.indexOf(null) === -1) {
              return {
                key: 'startEndValidity',
                label: `${formatDate(value[0])} - ${formatDate(value[1])}`,
              };
            }
            return undefined;
          }
          if (name === 'prefix' && value) {
            return {
              key: 'prefix',
              label: value.prefix,
            };
          }
          if (name === 'contract' && value) {
            return {
              key: 'contract',
              label: value.contract.name,
            };
          }
          if (name === 'periodTypeOfUsesId' && value) {
            return {
              key: 'periodTypeOfUsesId',
              label: localizeTimePeriod(value.id),
            };
          }
          if (name === 'discountType' && value) {
            return {
              key: 'discountType',
              label: value.value,
            };
          }
          if (value) {
            return {
              key: name,
              label: value,
            };
          }
          return undefined;
        })
      ).then((res) => res.filter((item) => item));
      setChipData(result);
    };
    RenderChipAsync();
  }, [debouncedValues]);

  const [discountTypeOptions, setDiscountTypeOptions] = useState([]);

  useEffect(() => {
    if (openFilter) {
      const fetchData = {};
      Object.entries(debouncedValues).forEach(([name, value]) => {
        if (name === 'startEndValidity') {
          if (value.indexOf(null) === -1) {
            fetchData.startValidity = format(value[0], 'yyyyMMdd');
            fetchData.endValidity = format(value[1], 'yyyyMMdd');
          }
          return;
        }
        if (name === 'prefix' && value) {
          fetchData.prefixId = value.id;
          return;
        }
        if (name === 'contract' && value) {
          fetchData.contractId = value.contract.id;
          return;
        }
        if (name === 'periodTypeOfUsesId' && value) {
          fetchData.periodTypeOfUsesId = value.id;
          return;
        }
        if (name === 'discountType' && value) {
          fetchData.discountTypeId = value.id;
          return;
        }
        if (value) {
          fetchData[name] = value;
        }
      });
      dispatch(setSearchParams(fetchData));
    }
  }, [debouncedValues]);

  const [options, setOptions] = useState({
    contracts: [],
    timePeriodTypes: [],
    prefixes: [],
  });

  const filterDataStatus = useSelector(
    (state) => state.searchCardsByFilterReducer.status
  );

  const filterData = useSelector(
    (state) => state.searchCardsByFilterReducer.initInfo
  );

  useEffect(() => {
    if (filterDataStatus === fetchStatus.FULFILLED) {
      setOptions(filterData);
    }
  }, [filterDataStatus]);

  const getDiscountTypeByContract = () => {
    const { discountTypes } = options.contracts.find(
      (item) => item.contract.id === values.contract.contract.id
    );
    return discountTypes;
  };

  useEffect(() => {
    if (!filterDataStatus !== fetchStatus.PENDING) {
      if (values.contract?.contract) {
        const discountTypes = getDiscountTypeByContract();
        setDiscountTypeOptions(discountTypes);
      } else {
        setDiscountTypeOptions([]);
      }
      setValues({ ...values, discountType: null });
    }
  }, [values.contract]);

  useEffect(() => {
    if (!values.prefix && values.cardNumber) {
      setValues((value) => ({ ...value, cardNumber: null }));
    }
  }, [values.prefix, values.cardNumber]);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <h2 className="admin__title">
          {openFilter ? (
            <Button
              onClick={handleCloseFilter}
              startIcon={<FilterCloseIcon />}
              color="primary"
            >
              {t('Discount.hideFilter')}
            </Button>
          ) : (
            <Button
              onClick={handleOpenFilter}
              startIcon={<FilterIcon />}
              color="primary"
            >
              {t('Discount.showFilter')}
            </Button>
          )}
        </h2>
        {children}
      </Box>
      <Collapse in={openFilter}>
        <div>
          {filterDataStatus === fetchStatus.PENDING && (
            <Typography>Загрузка...</Typography>
          )}
          <Grid container spacing={2} className="current-discount__filter">
            <Grid item xs={12} sm className="current-discount__filter-label">
              {t('Discount.cardNumber')}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <CustomAutocomplete
                filled
                label={t('Discount.prefix')}
                options={options.prefixes}
                getOptionLabel={(option) => option && option.prefix}
                name="prefix"
                value={values.prefix}
                onChange={(val) => handleChange({
                  target: {
                    name: 'prefix',
                    value: val,
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <SearchCardField
                prefix={values.prefix ? values.prefix.prefix : false}
                value={values.cardNumber}
                onChange={handleChange}
              />
            </Grid>

            <Hidden only={['xs', 'md']}>
              <Grid item xs={12} lg={4} />
            </Hidden>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              className="current-discount__filter-label"
            >
              {t('Discount.numberOfUses')}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              {
              // TODO: при изминении типа скидки очистить поля использований
              }
              <CustomAutocomplete
                filled
                label={t('Discount.timePeriod')}
                value={values.periodTypeOfUsesId}
                name="periodTypeOfUsesIdId"
                options={options.timePeriodTypes}
                getOptionLabel={(option) => option && localizeTimePeriod(option.id)}
                onChange={(val) => handleChange({
                  target: {
                    name: 'periodTypeOfUsesId',
                    value: val,
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <StyledNumberTextField
                variant="filled"
                disabled={!values.periodTypeOfUsesId}
                label={`${t('Discount.possible')}  ${localizeTimePeriod(
                  values.periodTypeOfUsesId?.id
                )}`}
                value={values.possibleNumberOfUses}
                onChange={({ floatValue }) => handleChange({
                  target: {
                    name: 'possibleNumberOfUses',
                    value: floatValue,
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <StyledNumberTextField
                variant="filled"
                disabled={!values.periodTypeOfUsesId}
                label={`${t('Discount.usedIn')}  ${localizeTimePeriod(
                  values.periodTypeOfUsesId?.id
                )}`}
                value={values.realNumberOfUses}
                onChange={({ floatValue }) => handleChange({
                  target: {
                    name: 'realNumberOfUses',
                    value: floatValue,
                  },
                })}
              />
            </Grid>
            <Hidden only={['md']}>
              <Grid item xs={12} lg={4} />
            </Hidden>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <DateRangeField
                name="startEndValidity"
                value={values.startEndValidity}
                onChange={(value) => handleChange({
                  target: {
                    name: 'startEndValidity',
                    value,
                  },
                })}
                label={t('Discount.period')}
              />
            </Grid>

            <Grid item xs={12} sm={6} md lg={2}>
              <CustomAutocomplete
                value={values.contract}
                options={options.contracts}
                getOptionLabel={(option) => option && option.contract.name}
                onChange={(value) => handleChange({
                  target: {
                    name: 'contract',
                    value,
                  },
                })}
                filled
                label={t('Discount.Contract')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md lg={2}>
              <CustomAutocomplete
                filled
                label={t('Discount.discountType')}
                value={values.discountType}
                name="discountType"
                options={discountTypeOptions}
                getOptionLabel={(option) => option && option.value.toString()}
                onChange={(val) => handleChange({
                  target: {
                    name: 'discountType',
                    value: val,
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md lg={4}>
              <StyledTextField
                fullWidth
                variant="filled"
                value={values.customerFullName}
                onChange={handleChange}
                name="customerFullName"
                label={t('translations:Input.FullName')}
              />
            </Grid>
          </Grid>
        </div>
      </Collapse>
      {chipData.length > 0 ? (
        <Box display="flex" alignItems="center" flexWrap="wrap" pt={4}>
          {chipData.map((item) => (
            <Box key={item.key} pr={3}>
              <StyledChip
                label={item.label.toString()}
                onDelete={handleDelete(item)}
              />
            </Box>
          ))}
          <Button variant="text" color="primary" onClick={handleDelete(true)}>
            {t('Discount.clear')}
          </Button>
        </Box>
      ) : null}
    </>
  );
};

Filter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.symbol, PropTypes.object])
    .isRequired,
};

export default Filter;
