import React, { useMemo, useState } from 'react';

import SelectedServicesContext from './SelectedServicesContext';
import Services from './Services';
import FavoriteServices from './FavoriteServices';

const ServicesTablePage = () => {
  const [favorites, setFavorites] = useState(false);

  const contextValue = useMemo(
    () => ({ favorites, setFavorites }),
    [favorites, setFavorites]
  );

  return (
    <SelectedServicesContext.Provider value={contextValue}>
      {favorites ? <FavoriteServices /> : <Services />}
    </SelectedServicesContext.Provider>
  );
};

export default ServicesTablePage;
