const getPatientFullName = (patient) => {
  let fullName = '';

  if (patient?.lastName) {
    fullName += patient.lastName;
  }
  if (patient?.firstName) {
    fullName += ` ${patient.firstName}`;
  }
  if (patient?.middleName) {
    fullName += ` ${patient.middleName}`;
  } else if (patient?.paternalName) {
    fullName += ` ${patient.paternalName}`;
  }

  return fullName;
};

export default getPatientFullName;
