import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00539F',
    },
    primaryHover: {
      main: '#002955',
    },
    blueLightNew: {
      main: '#E6ECF5',
    },
    blueLightPlusNew: {
      main: '#F4F7FC',
    },
    secondary: {
      main: '#00BE6E',
    },
    text: {
      primary: '#2E3B52',
      main: '#2E3B52',
      secondary: '#758AB0',
    },
    error: {
      main: '#B00020',
    },
    title: {
      main: '#758AB0',
    },
    greyDark: {
      main: '#727272',
    },
    greyLight: {
      main: '#BCBCBC',
    },
    blueLight: {
      main: '#CCD6E3',
    },
    greyLightPlus: {
      main: '#E7E7E7',
    },
    blueLightPlus: {
      main: '#F4F6F9',
    },
    background: {
      main: '#F2F2F2',
    },
  },

  typography: {
    fontSize: 14,
    fontFamily: ['Lato', 'Arial', 'Helvetica', 'sans-serif'].join(','),
    h1: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: '29px',
      '@media (min-width:600px)': {
        fontSize: 32,
        lineHeight: '38px',
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '22px',
      letterSpacing: '0.15px',
      '@media (min-width:600px)': {
        fontSize: 24,
        lineHeight: '28px',
      },
    },
    h3: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '18px',
      letterSpacing: '0.15px',
      '@media (min-width:600px)': {
        fontSize: 18,
        lineHeight: '21.8px',
      },
    },
    body1: {
      fontSize: '0.9rem',
      lineHeight: 1.2,
      '@media (min-width:600px)': {
        fontSize: '1rem',
        lineHeight: 1.5,
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: 48,
        borderRadius: 11,
        textTransform: 'initial',
        fontSize: 16,
        lineHeight: '19px',
      },
      outlined: {
        '&:disabled': {
          border: '1px solid #BCBCBC',
          color: '#BCBCBC',
        },
      },
      containedPrimary: {
        boxShadow: 'none',
        '&:hover': {
          color: 'white !important',
          backgroundColor: '#002955',
          boxShadow: 'none',
        },
        '&:disabled': {
          backgroundColor: '#BCBCBC',
        },
      },
      contained: {
        '&:hover': {
          backgroundColor: '#002955',
        },
        '&:disabled': {
          backgroundColor: '#BCBCBC',
        },
      },
      href: {
        color: 'white',
        '&:hover': {
          color: 'white !important',
        },
      },
    },
    MuiInputBase: {
      root: {
        '&&&:before': {
          borderBottom: 'none',
        },
        '&&:after': {
          borderBottom: 'none',
        },
      },
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
  },
});

export default theme;
