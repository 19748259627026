const formatBarcode = (barcode) => {
  if (barcode.length > process.env.REACT_APP_BARCODE_MIN_LENGTH) {
    return Number.parseInt(
      barcode.substring(0, process.env.REACT_APP_BARCODE_MIN_LENGTH),
      10
    );
  }
  return Number.parseInt(barcode, 10);
};

export default formatBarcode;
