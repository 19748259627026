import React from 'react';
import './Faq.scss';
import first from './files/1 Pereanaliticheskie trebovania k vzyatiu biologicheskogo materiala.pdf';
import sec from './files/2. Akt priema biomateriala obshchiy.pdf';
import third from './files/3. Akt priema biomateriala ginekologiya.pdf';
import fourth from './files/4. Zayavlenie na izmenenie personalnih dannih.pdf';
import six from './files/6. Zayavlenie na peremarkirovku proby.pdf';
import seven from './files/7. Zayavlenie na zapros micropreparata.pdf';
import eight from './files/8. Направление по гистологии.pdf';
import ten from './files/10.Pravila vzyatia gytkosti citologyi.pdf';
import elev from './files/11. Pravila vzyatia i hranenia biologicheskogo materiala.pdf';

const links = [
  {
    label: 'Преаналитические требования к взятию биологического материала',
    link: first,
  },
  {
    label: 'Шаблон акта приема биологического материла (общий)',
    link: sec,
  },
  {
    label: 'Шаблон акта приема биологического материла (гинекология)',
    link: third,
  },
  {
    label: 'Шаблон заявления на изменение персональных данных клиента',
    link: fourth,
  },
  {
    label: 'Шаблон заявления на перемаркировку пробы',
    link: six,
  },
  {
    label:
      'Шаблон заявления на запрос микропрепарата (стекла по гистологии и цитологии)',
    link: seven,
  },
  {
    label: 'Шаблон направления на гистологию',
    link: eight,
  },
  {
    label:
      'Правила маркировки проб. Видео доступно по ссылке: https://youtu.be/_IaPcIfPqBw.',
    link: 'https://youtu.be/_IaPcIfPqBw.',
  },
  {
    label: 'Правила взятия жидкостной цитологии',
    link: ten,
  },
  {
    label: 'Правила взятия и хранения биологического материала',
    link: elev,
  },
];

const FAQ = () => (
  <div className="faq">
    {/* <h4 className="faq__title">GENERAL</h4> */}
    <ul className="faq-list">
      {links.map((item) => (
        <li key={item.label} className="faq-list__li">
          <a
            href={item.link}
            target="_blank"
            rel="nofollow noopener noreferrer"
            className="faq-list__link"
          >
            {item.label}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default FAQ;
