import i18n from '../i18n';

const common = i18n.t('Errors.BadRequest');
const ordersNotFound = i18n.t('Errors.OrdersNotFound');
const selectContract = i18n.t('Errors.SelectContract');
const selectUser = i18n.t('Errors.SelectUser');
const direction = (barcodes) => i18n.t('Errors.direction', { barcodes });
const required = i18n.t('ValidateErrors.Required');
const emailIncorrect = i18n.t('ValidateErrors.Email');
const invalidDate = i18n.t('ValidateErrors.DateFormat');
const notFoundByFilter = i18n.t('Errors.notFoundByFilter');
const minLength = (min = 3) => i18n.t('ValidateErrors.MinLen', { count: min });
const tryLater = i18n.t('Common.ErrorTryLater');

const getErrors = (e) => (e?.errors ? Object.values(e.errors) : []);

/**
 * список невалидных полей дто
 * @param {Error} e ошибка с бэкенда
 * @returns {string} невалидные поля
 */
const getValidationErrors = (e) => {
  const hasErros = Boolean(e?.errors);

  if (hasErros) {
    const errorMessage = Object.entries(e.errors)
      .map(([name, value]) => `${name}: ${value.map((i) => i).join(' ')}`)
      .join(';');

    return errorMessage;
  }

  return null;
};

const errorHelper = {
  common,
  required,
  direction,
  ordersNotFound,
  selectContract,
  selectUser,
  emailIncorrect,
  invalidDate,
  notFoundByFilter,
  minLength,
  getErrors,
  tryLater,
  getValidationErrors,
};

export default errorHelper;
