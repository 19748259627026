import { Button, Fade } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Calculator from '../Calculator/Calculator';
import Directory from '../ServiceExtension/ServiceExtensionSearchTable';
import '../MailManager/Mail.scss';
import MedicalCenters from '../MedicalCenters/MedicalCenters';
import './Tabs.scss';
import { ReactComponent as Reload } from '../img/reload.svg';
import { AppRoute } from '../../../const/app-routes';
import useLangRoute from '../../../_helpers/scripts/useLangRoute';
import RBAC from '../../../_helpers/RBAC';
import { Role } from '../../../_constants/Roles';
import SyncAlert from './SyncAlert';

const pages = [<Directory />, <Calculator />, <MedicalCenters />];

const AdminSynchronization = () => {
  const { t } = useTranslation('Admin');

  const [value, setValue] = React.useState(0);

  const handleChange = (event) => {
    setValue(Number.parseInt(event.currentTarget.value, 10));
  };

  const isDirectory = value === 0;

  const route = useLangRoute();

  return (
    <>
      <SyncAlert />
      <div className="mail tabs">
        <div className="tabs__container">
          <div className="mail__tabs">
            <Button
              value={0}
              onClick={handleChange}
              className={clsx('mail__button tabs__button', {
                mail__button_selected: value === 0,
              })}
            >
              {t('Title.MedicalManual')}
            </Button>
            <RBAC allowedRoles={[Role.Admin]}>
              <Button
                value={1}
                onClick={handleChange}
                className={clsx('mail__button', {
                  mail__button_selected: value === 1,
                })}
              >
                {t('Title.Calculator')}
              </Button>
            </RBAC>
            <RBAC allowedRoles={[Role.Admin]}>
              <Button
                value={2}
                onClick={handleChange}
                className={clsx('mail__button', {
                  mail__button_selected: value === 2,
                })}
              >
                {t('Title.MedicalCenters')}
              </Button>
            </RBAC>
          </div>
          <RBAC allowedRoles={[Role.Admin]}>
            {isDirectory && (
              <Fade in={isDirectory}>
                <Button
                  component={Link}
                  to={route(AppRoute.AdminRoutes.ServiceExtensionRefresh)}
                  startIcon={<Reload />}
                  color="primary"
                >
                  {t('Title.RefreshManuals')}
                </Button>
              </Fade>
            )}
          </RBAC>
        </div>
        <div>{pages[value]}</div>
      </div>
    </>
  );
};

export default AdminSynchronization;
