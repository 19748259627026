import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contracts: [],
  workSpaces: [],
  isLogged: false,
  workSpace: null,
  orderState: null,
  selectedContract: null,
  userName: null,
};

const orderImportSlice = createSlice({
  name: 'order-import',
  initialState,
  reducers: {
    setContractsAndWorkSpaces(state, action) {
      state.contracts = action.payload.contracts;
      state.workSpaces = action.payload.workSpaces;
      state.userName = action.payload.userName;
      state.isLogged = true;
    },
    setWorkSpace(state, action) {
      state.workSpace = action.payload;
    },
    setOrderState(state, action) {
      state.orderState = action.payload;
    },
    setSelectedContract(state, action) {
      state.selectedContract = action.payload;
    },
  },
});

export const {
  setContractsAndWorkSpaces,
  setWorkSpace,
  setOrderState,
  setSelectedContract,
} = orderImportSlice.actions;
export default orderImportSlice.reducer;
