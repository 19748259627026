import { IconButton } from '@material-ui/core';
import React from 'react';
import useDialog from '../../../../_helpers/scripts/useDialog';
import EditCardPrefix from './EditCardPrefix';
import { ReactComponent as EditIcon } from '../../img/edit.svg';

const EditCardPrefixButton = ({ row, disabled }) => {
  const { open, handleClose, handleOpen } = useDialog();
  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          handleOpen();
        }}
        size="small"
        color="primary"
      >
        <EditIcon />
      </IconButton>
      {open && <EditCardPrefix open={open} onClose={handleClose} data={row} />}
    </>
  );
};

export default EditCardPrefixButton;
