import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import useLangRoute from '../../../_helpers/scripts/useLangRoute';

const HeaderLink = ({ link, label }) => {
  const route = useLangRoute();

  return (
    <div className="header__item">
      <NavLink
        className="header__link"
        activeClassName="header__link--selected"
        to={route(link)}
      >
        {label}
      </NavLink>
    </div>
  );
};

HeaderLink.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default HeaderLink;
