const New = 1;
const WaitForPayment = 2;
const Paid = 3;
const Canceled = 4;

export default {
  new: New,
  waitForPayment: WaitForPayment,
  paid: Paid,
  canceled: Canceled,
  all: new Map([
    [New, 'New'],
    [WaitForPayment, 'WaitForPayment'],
    [Paid, 'Paid'],
    [Canceled, 'Canceled'],
  ]),
};