import React, { useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ReactComponent as PrintIcon } from '../../../_icons/print.svg';
import ApiClient from '../../../ApiClient/ApiClient';
import fetchStatus from '../../../_constants/fetchStatus';
import errorHelper from '../../../_constants/errorHelper';
import { setError } from '../../../_reducers/errorReducer';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import base64ToType from '../../../_helpers/scripts/base64ToType';
import useSortContracts from '../useSortContracts';

const PrintBarcodeButton = ({ barcode }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const contracts = useSortContracts();

  const GetBarcodePdfAsync = async () => {
    setStatus(fetchStatus.PENDING);
    await ApiClient.GetBarcodePdfToPrint(barcode, contracts)
      .then((res) => {
        if (res.isSuccess) {
          setStatus(fetchStatus.FULFILLED);

          const blob = base64ToType(res.base64Barcode, 'application/pdf');
          const url = URL.createObjectURL(blob);

          const container = document.createElement('div', {});
          container.style.display = 'none';
          container.id = `barcode-${barcode}`;
          document.body.appendChild(container);

          const iframe = document.createElement('iframe', {});
          iframe.width = '520px';
          iframe.height = '620px';
          iframe.src = url;
          iframe.id = `barcode-window-${barcode}`;

          container.appendChild(iframe);

          iframe.contentWindow.focus();
          iframe.contentWindow.print();

          const listener = () => {
            document.body.removeChild(container);
            setStatus(fetchStatus.IDLE);
          };

          iframe.contentWindow.addEventListener('beforeprint', listener, false);
          iframe.contentWindow.removeEventListener(
            'beforeprint',
            listener,
            false
          );
        } else {
          throw new Error(errorHelper.common);
        }
      })
      .catch((e) => {
        setStatus(fetchStatus.REJECTED);
        dispatch(setError(e.message));
      });
  };

  const handleClick = () => GetBarcodePdfAsync();

  const loading = status === fetchStatus.PENDING;

  return (
    <>
      <Box>
        <CustomizedTooltip title="Распечатать штрих-код">
          <IconButton
            disabled={loading}
            onClick={handleClick}
            size="small"
            color="primary"
          >
            <PrintIcon />
          </IconButton>
        </CustomizedTooltip>
      </Box>
    </>
  );
};

PrintBarcodeButton.propTypes = {
  barcode: PropTypes.number.isRequired,
};

export default PrintBarcodeButton;
