export default [
  {
    id: 'code',
    numeric: true,
    label: 'CreateOrder.Code',
  },
  {
    id: 'name',
    numeric: false,
    label: 'CreateOrder.Name',
  },
  {
    id: 'price',
    numeric: true,
    label: 'CreateOrder.Price',
  },
  {
    id: 'workingDays',
    numeric: true,
    label: 'CreateOrder.WorkingDays',
  },
  {
    id: 'remove-service',
    numeric: false,
    label: '',
  },
];
