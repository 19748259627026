import * as signalR from '@microsoft/signalr';
import { APP_ID } from '../_constants/CONSTANT';
import Storage from '../_helpers/Storage';
import authenticationService from '../_services/authentication.service';

const getAuthHeaders = () => {
  const token = Storage.getItem('currentUser')?.accessToken;
  if (token) {
    return {
      Authorization: `Bearer ${Storage.getItem('currentUser')?.accessToken}`,
    };
  }
  return {};
};

class CustomHttpClient extends signalR.DefaultHttpClient {
  constructor() {
    super(console); // the base class wants a signalR.ILogger
  }

  async send(request) {
    let authHeaders = getAuthHeaders();
    request.headers = {
      ...request.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      appId: window.localStorage.getItem(APP_ID),
      ...authHeaders,
    };

    try {
      const response = await super.send(request);
      return response;
    } catch (er) {
      if (er instanceof signalR.HttpError) {
        const error = er;
        if (error.statusCode === 401) {
          // token expired - trying a refresh via refresh token
          const isValid = await authenticationService.refreshTokenAndUserData();
          if (isValid) {
            authHeaders = getAuthHeaders();
            request.headers = { ...request.headers, ...authHeaders };
          } else {
            throw new Error('Unauthorized');
          }
        }
      } else {
        throw er;
      }
    }
    // re try the request
    return super.send(request);
  }
}

export default CustomHttpClient;
