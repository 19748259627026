import { IconButton } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useDialog from '../../../../_helpers/scripts/useDialog';
import CustomizedTooltip from '../../../CustomizedTooltip/CustomizedTooltip';
import { ReactComponent as EditIcon } from '../../img/edit.svg';
import './EditDiscount.scss';
import EditCardDialog from './EditCardDialog';

const EditCard = ({ data, disabled }) => {
  const { open, handleClose, handleOpen } = useDialog();
  const { t } = useTranslation('Admin');

  return (
    <>
      <CustomizedTooltip title={t('Discount.cardEdit')}>
        <IconButton
          disabled={disabled}
          onClick={handleOpen}
          color="primary"
          className="styled-table__button"
        >
          <EditIcon />
        </IconButton>
      </CustomizedTooltip>
      {open && <EditCardDialog open={open} onClose={handleClose} data={data} />}
    </>
  );
};

export default EditCard;
