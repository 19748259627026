import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import replaceItems from './replaceItems';
import ApiClient from '../../../../ApiClient/ApiClient';
import errorHelper from '../../../../_constants/errorHelper';
import fetchStatus from '../../../../_constants/fetchStatus';
import '../../../Dialogs/Dialog.scss';
import { setPrefixes } from '../../../../_reducers/prefixReducer';
import StyledDialogTitle from '../../../Dialogs/StyledDialogTitle';

const DiscountPrefixStatusDialog = ({
  open, onClose, id, resetSelected
}) => {
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const { t } = useTranslation('Admin');

  const dispatch = useDispatch();
  const prefixes = useSelector((state) => state.prefix.prefixes);
  const loading = status === fetchStatus.PENDING;

  const [message, setMessage] = useState('');

  const fetchByChangeStatus = (activeStatus) => {
    setStatus(fetchStatus.PENDING);
    const fetchData = {
      prefixIds: id,
      isActive: activeStatus,
    };
    return ApiClient.ChangeDiscountPrefixStatus(fetchData)
      .then((res) => {
        if (res) {
          if (res.isSuccess) {
            const newPrefixes = replaceItems(activeStatus, prefixes, id);
            dispatch(setPrefixes(newPrefixes));

            resetSelected();
            setStatus(fetchStatus.FULFILLED);
            onClose();
            return;
          }
          throw new Error(res.statusMessage);
        }
        throw new Error(errorHelper.common);
      })
      .catch((e) => {
        setStatus(fetchStatus.REJECTED);
        setMessage(e.message);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} className="dialog">
      <StyledDialogTitle
        title={t('Discount.StatusDialog.Title', { context: 'prefix' })}
        onClose={onClose}
      />

      {message && (
        <DialogContent>
          <DialogContentText color="error">{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => fetchByChangeStatus(false)}
          className="dialog__btn dialog__cancel"
        >
          {t('Discount.StatusDialog.Deactivate')}
        </Button>
        <Button
          disabled={loading}
          onClick={() => fetchByChangeStatus(true)}
          className="dialog__btn dialog__submit"
        >
          {t('Discount.StatusDialog.Activate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DiscountPrefixStatusDialog.propTypes = {
  id: PropTypes.arrayOf(PropTypes.number).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DiscountPrefixStatusDialog;
