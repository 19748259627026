import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contractIds: null,
  cabinetOrderStatuses: null,
  clientUnitId: null,
  barcode: null,
  lastName: null,
  firstName: null,
  createdDateStart: null,
  createdDateEnd: null,
  resultDateStart: null,
  resultDateEnd: null,
};

const FieldGroupFormReducer = createSlice({
  name: 'FieldGroupForm',
  initialState,
  reducers: {
    setFormData(state, action) {},
  },
});

export const { setFormData } = FieldGroupFormReducer.actions;
export default FieldGroupFormReducer.reducer;
