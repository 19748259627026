import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu';
import './Content.scss';
import ErrorDialog from '../Dialogs/ErrorDialog/ErrorDialog';
import { closeError } from '../../_reducers/errorReducer';

const Content = ({ children, error, message, removeContainer }) => {
  const dispatch = useDispatch();
  return (
    <div className="content">
      <div className="content__main">
        <div
          className={`content__child  content__container  ${
            !removeContainer && ''
          } `}
        >
          {children}
        </div>
        <Footer />
      </div>
      <nav className="content__menu">
        <Menu />
      </nav>
      {error && (
        <ErrorDialog
          onClose={() => dispatch(closeError())}
          open={error}
          message={message}
        />
      )}
    </div>
  );
};

const mapState = (state) => ({
  error: state.errorReducer.error,
  message: state.errorReducer.message,
});

Content.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default connect(mapState)(Content);
