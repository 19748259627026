import { Box, Grid, Paper } from '@material-ui/core';
import React from 'react';
import '../Basket/Basket.scss';
import '../ServicesTable/ServiceList.scss';
import '../Basket/OrderInfo.scss';
import './Ordering.scss';

import { useSelector } from 'react-redux';
import SmallBasket from './Basket';
import UpdateOrder from './UpdateOrder';
import CreateOrder from './CreateOrder';
import CreatePreOrder from './CreatePreOrder';

const Ordering = () => {
  const isEditOrder = useSelector((state) => state.basket.editOrder);
  const isPreOrder = useSelector((state) => state.basket.isPreOrder);

  const form = React.useMemo(() => {
    if (isEditOrder) {
      return <UpdateOrder />;
    } if (isPreOrder) {
      return <CreatePreOrder />;
    }
    return <CreateOrder />;
  }, [isEditOrder, isPreOrder]);

  return (
    <div className="ordering basket service-list">
      <Grid container component={Paper} className="ordering__grid">
        <Grid
          item
          xs={12}
          component={Box}
          display="flex"
          flexDirection="column"
          pl={{ sm: 2, md: 3, lg: 5 }}
          pr={{ sm: 2, md: 4, lg: 7 }}
          md={6}
        >
          <SmallBasket />
        </Grid>
        <Grid item xs={12} md={6} className="ordering__form-container">
          {form}
        </Grid>
      </Grid>
    </div>
  );
};

export default Ordering;
