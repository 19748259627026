/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Alert } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import LazyImg from '../../../_helpers/LazyImg';
import SexIcon from '../../Dialogs/img/sex.svg';
import MailIcon from '../../Dialogs/img/mail.svg';
import LocationIcon from '../../Dialogs/img/location.svg';

import '../../Dialogs/Dialog.scss';
import '../../Admin/_components/AdminDialog.scss';
import '../NewPatient/NewPatient.scss';

import { ReactComponent as PrintIcon } from '../img/print.svg';
import { ReactComponent as EditIcon } from '../../Dialogs/img/edit2.svg';
import StyledKeyboardDatePicker from '../../Inputs/StyledKeyboardDatePicker';
import errorService from '../../../_constants/errors';
import emailPattern from '../../../_helpers/emailPattern';
import ApiClient from '../../../ApiClient/ApiClient';
import fetchStatus from '../../../_constants/fetchStatus';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import renderClientUnits from '../../../_helpers/scripts/renderClientUnits';
import genderHelper from '../../../_constants/genderHelper';
import StyledTextField from '../../Inputs/StyledTextField';
import errorHelper from '../../../_constants/errorHelper';
import dateHelper from '../../../_helpers/dateHelper';
import mobilePhoneHelper from '../../../_helpers/mobilePhoneHelper';
import StyledDialogTitle from '../../Dialogs/StyledDialogTitle';
import MobilePhoneField from './MobilePhoneField';
import IdentificationNumberFields from './IdentificationNumberFields';
import { setPatients } from '../../../_reducers/patientReducer';
import useContract from '../../Contract/useContract';
import useClientUnits from '../../Contract/useClientUnits';
import useApiContracts from '../../Contract/useApiContracts';

const PatientCard = ({
  open, onClose, patient, handleNext
}) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const [message, setMessage] = useState('');
  const error = status === fetchStatus.REJECTED;
  const success = status === fetchStatus.FULFILLED;

  const { currentContract } = useContract();
  const { currentClientUnit } = useClientUnits();
  const contracts = useApiContracts();
  const formatedClientUnit = renderClientUnits(currentClientUnit);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      lastName: patient.lastName,
      firstName: patient.firstName,
      paternalName: patient.paternalName,
      gender: patient.gender,
      dateOfBirth: dateHelper.formatStringDateToDate(patient.dateOfBirth),
      mobilePhone: null,
      email: patient.email,
      address: patient.address,
      identificationNumberTypeId: patient.identificationNumberTypeId,
      identificationNumber: patient.identificationNumber,
    },
  });

  const {
    handleSubmit, control, errors, reset, formState
  } = methods;

  const { isSubmitting, isDirty } = formState;

  const patientsList = useSelector((state) => state.patient.rows);
  const dispatch = useDispatch();

  const GetPatientInfo = (data) => {
    const {
      lastName, firstName, gender, dateOfBirth
    } = data;

    return {
      lastName,
      firstName,
      gender,
      dateOfBirth: dateHelper.formatDate(dateOfBirth),
    };
  };

  const UpdatePatientList = (id, newData) => {
    const index = patientsList.findIndex((item) => item.id === id);

    if (index !== -1) {
      const updatedPatient = GetPatientInfo(newData);
      updatedPatient.id = id;

      const newList = [
        ...patientsList.slice(0, index),
        updatedPatient,
        ...patientsList.slice(index + 1),
      ];

      dispatch(setPatients(newList));
    }
  };

  const fetchBySavePatient = async (data) => {
    let fetchData;
    let token;
    try {
      setStatus(fetchStatus.PENDING);

      fetchData = {
        ...patient,
        ...data,
        patientCode: null,
        mobilePhone: mobilePhoneHelper.formatPhoneToFetch(data.mobilePhone),
        dateOfBirth: dateHelper.formatDateToSilabStandart(data.dateOfBirth),
      };

      if (data.identificationNumberTypeId) {
        fetchData.identificationNumberTypeId = data.identificationNumberTypeId.id;
      }

      token = await executeRecaptcha();
    } catch (e) {
      setStatus(fetchStatus.REJECTED);
      setMessage(e.message || errorHelper.common);
      return false;
    }

    return ApiClient.UpdatePatient(fetchData, token, contracts)
      .then((res) => {
        if (res.isSuccess) {
          setMessage(res.statusMessage);
          reset(data);
          setEdit(false);
          UpdatePatientList(patient.id, fetchData);
          setStatus(fetchStatus.FULFILLED);
          return true;
        }
        throw new Error(res.statusMessage);
      })
      .catch((e) => {
        setStatus(fetchStatus.REJECTED);
        setMessage(e.message || errorService.common);
        return false;
      });
  };

  const onSubmit = async (data) => fetchBySavePatient(data);

  const handleEdit = () => {
    if (edit) {
      reset();
    }
    setEdit(!edit);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleValidateEmail = (value) => {
    if (value === '' || emailPattern.test(value)) {
      return true;
    }
    return t('ValidateErrors.Email');
  };

  return (
    <FormProvider {...methods}>
      <Dialog
        onClick={(e) => e.stopPropagation()}
        fullScreen={fullScreen}
        className="a-dialog dialog new-patient__dialog"
        scroll="body"
        open={open}
        onClose={onClose}
        PaperComponent="form"
        onSubmit={handleSubmit(onSubmit)}
        fullWidth
        PaperProps={{ className: 'a-dialog__paper', noValidate: true }}
      >
        <StyledDialogTitle
          title={t('Patient.PatientCard')}
          withIcon
          onClose={onClose}
        >
          {!edit && (
            <>
              <IconButton size="small">
                <PrintIcon width={32} height={32} />
              </IconButton>
              <CustomizedTooltip title={t('Tooltip.EditPatient')}>
                <IconButton size="small" onClick={handleEdit}>
                  <EditIcon />
                </IconButton>
              </CustomizedTooltip>
            </>
          )}
        </StyledDialogTitle>
        <DialogContent>
          {(error || success) && (
            <Box paddingBottom={2}>
              <Alert severity={success ? 'success' : 'error'}>{message}</Alert>
            </Box>
          )}
          <Grid container className="new-patient__grid" spacing={2}>
            <Grid item xs={6}>
              <StyledTextField
                variant="outlined"
                disabled
                fullWidth
                label={t('Input.Contract')}
                value={currentContract?.name}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                variant="outlined"
                disabled
                fullWidth
                label={t('Input.Subdivision')}
                value={formatedClientUnit}
              />
            </Grid>
          </Grid>

          <div className="new-patient__divider">
            <h2 className="new-patient__content-title">
              {t('Dialog.Profile')}
            </h2>
            <Divider />
          </div>
          <Grid container className="new-patient__grid" spacing={2}>
            <Grid item xs={12} sm={4} className="new-patient__grid-item">
              <Controller
                name="lastName"
                control={control}
                rules={{ required: errorHelper.required }}
                render={({ onChange, value }) => (
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    required={edit}
                    disabled={!edit}
                    label={t('Input.LastName')}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.lastName?.message)}
                    helperText={errors.lastName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: errorHelper.required }}
                render={({ onChange, value }) => (
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    required={edit}
                    disabled={!edit}
                    label={t('Input.FirstName')}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.firstName?.message)}
                    helperText={errors.firstName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="paternalName"
                control={control}
                render={({ onChange, value }) => (
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    disabled={!edit}
                    label={t('Input.MiddleName')}
                    value={value}
                    onChange={onChange}
                    errors={Boolean(errors.paternalName?.message)}
                    helperText={errors.paternalName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="gender"
                control={control}
                rules={{ required: errorHelper.required }}
                render={({ onChange, value }) => (
                  <StyledTextField
                    select
                    disabled={!edit}
                    variant="outlined"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    label={t('Input.Sex')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LazyImg src={SexIcon} alt="" />
                        </InputAdornment>
                      ),
                    }}
                  >
                    {genderHelper.genders.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {t(item.label)}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="dateOfBirth"
                control={control}
                rules={{ required: errorHelper.required }}
                render={({ onChange, value }) => (
                  <StyledKeyboardDatePicker
                    format="dd.MM.yyyy"
                    inputVariant="outlined"
                    label={t('Input.DateOfBirth')}
                    required
                    disabled={!edit}
                    value={value}
                    onChange={onChange}
                    errors={errors.dateOfBirth}
                  />
                )}
              />
            </Grid>
          </Grid>
          <div className="new-patient__divider">
            <h2 className="new-patient__content-title">
              {t('Dialog.Contacts')}
            </h2>
            <Divider />
          </div>
          <Grid container className="new-patient__grid" spacing={2}>
            <Grid item xs={12} sm={6}>
              <MobilePhoneField
                isEdit={edit}
                mobilePhone={patient.mobilePhone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="email"
                control={control}
                rules={{
                  validate: handleValidateEmail,
                }}
                render={({ onChange, value }) => (
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    disabled={!edit}
                    label="Email"
                    value={value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LazyImg src={MailIcon} loading="lazy" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={onChange}
                    error={Boolean(errors.email?.message)}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="address"
                control={control}
                render={({ onChange, value }) => (
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    disabled={!edit}
                    label={t('Input.Address')}
                    value={value}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LazyImg src={LocationIcon} loading="lazy" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={onChange}
                    errors={Boolean(errors.address?.message)}
                    helperText={errors.address?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box py={2} color="greyLight.main">
            <Divider color="inherit" />
          </Box>
          <Box mb={{ xs: 2, sm: 3 }}>
            <IdentificationNumberFields
              isEdit={edit}
              setStatus={setStatus}
              setMessage={setMessage}
            />
          </Box>
        </DialogContent>
        <DialogActions className="dialog__actions">
          {edit ? (
            <>
              <Button
                disabled={isSubmitting}
                onClick={handleEdit}
                className="dialog__btn dialog__cancel"
              >
                {t('Common.Cancel')}
              </Button>
              <Button
                disabled={isSubmitting || !isDirty}
                className="dialog__btn dialog__submit"
                type="submit"
              >
                {t('Common.Save')}
              </Button>
            </>
          ) : (
            <Button className="dialog__btn dialog__submit" onClick={handleNext}>
              {t('Patient.Continue')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

PatientCard.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number,
    silabPersonId: PropTypes.number,
    patientCode: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    paternalName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    gender: PropTypes.string,
    email: PropTypes.string,
    mobilePhone: PropTypes.string,
    address: PropTypes.string,
    contractId: PropTypes.string,
    userName: PropTypes.string,
    identificationNumber: PropTypes.string,
    identificationNumberTypeId: PropTypes.number,
  }).isRequired,
  handleNext: PropTypes.func.isRequired,
};

export default PatientCard;
