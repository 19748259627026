import { Box, CircularProgress, InputAdornment } from '@material-ui/core';
import React from 'react';
import StyledTextField from './StyledTextField';

const StyledTextFieldWithLoading = ({ loading, ...props }) => (
  <StyledTextField
    {...props}
    InputProps={{
      ...props.InputProps,
      endAdornment: (
        <>
          <InputAdornment position="end">
            <Box
              display="flex"
              component="span"
              width={20}
              visibility={loading ? 'visible' : 'hidden'}
            >
              <CircularProgress color="primary" size={20} />
            </Box>
          </InputAdornment>
          {props.InputProps?.endAdornment}
        </>
      ),
    }}
  />
);

export default StyledTextFieldWithLoading;
