import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setEditOrder } from '../../../_reducers/basketReducer';
import { setSearchValue } from '../../../_reducers/patientReducer';
import useServices from '../ServicesTable/useServices';

const GetFullName = (patient) => `${patient.lastName} ${patient.firstName}`;

const EditOrderService = ({ setStep }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { AddService } = useServices();

  useEffect(() => {
    if (location.state?.order) {
      setStep(1);
      const { order } = location.state;
      dispatch(setEditOrder(order));

      const fullName = GetFullName(order.patient);
      dispatch(setSearchValue(fullName));

      if (order.services.length > 0) {
        AddService(order.services, false, true);
      }
    }
    return () => {
      if (location.state?.order) {
        location.state.order = null;
      }
    };
  }, []);

  return null;
};

export default EditOrderService;
