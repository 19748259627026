import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../ApiClient/ApiClient';
import errorHelper from '../_constants/errorHelper';
import fetchStatus from '../_constants/fetchStatus';

const initialState = {
  status: fetchStatus.PENDING,
  types: [],
  message: '',
};

const GetIdentificationNumberTypesAsync = createAsyncThunk(
  'identificationNumberType/get',
  async (data, thunkApi) => ApiClient.GetIdentificationNumberTypes(data)
    .then((res) => {
      if (res?.length > 0) {
        return res;
      }
      return thunkApi.rejectWithValue(errorHelper.common);
    })
    .catch((e) => thunkApi.rejectWithValue(e.message))
);

const identificationNumberTypeSlice = createSlice({
  name: 'identificationNumberType',
  initialState,
  extraReducers: {
    [GetIdentificationNumberTypesAsync.pending]: (state) => {
      state.status = fetchStatus.PENDING;
    },
    [GetIdentificationNumberTypesAsync.fulfilled]: (state, action) => {
      state.status = fetchStatus.FULFILLED;
      state.types = action.payload;
    },
    [GetIdentificationNumberTypesAsync.rejected]: (state, action) => {
      state.status = fetchStatus.REJECTED;
      state.message = action.payload;
    },
  },
});

export { GetIdentificationNumberTypesAsync };
export default identificationNumberTypeSlice.reducer;
