import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error500 from '../Components/Error500/Error500';
import { AppRoute } from '../const/app-routes';

const OrderImportPage = React.lazy(() => import('../Components/OrderImport/OrderImportPage'));
const PersonDublicatesPage = React.lazy(() => import('../Components/PersonDublicatesManualCheck/PersonDublicatesPage'));

const OrderImport = ({ match }) => {
  const { path } = match;
  return (
    <Switch>
      <Route path={path} exact component={OrderImportPage} />
      <Route
        path={AppRoute.OrderImportRoutes.PersonDublicates}
        component={PersonDublicatesPage}
      />
      <Route path={`${path}${AppRoute.Error500}`} component={Error500} />
    </Switch>
  );
};

export default OrderImport;
