import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fetchStatus from '../../../../_constants/fetchStatus';
import { fetchByPrefixGenerationType } from '../../../../_reducers/prefixGenerationTypeReducer';

const GetGenerationType = () => {
  const dispatch = useDispatch();

  const status = useSelector(
    (state) => state.prefixGenerationType.status
  );

  useEffect(() => {
    if (status !== fetchStatus.FULFILLED) {
      dispatch(fetchByPrefixGenerationType());
    }
  }, []);
  return null;
};

export default GetGenerationType;
