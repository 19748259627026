import { TextField } from '@material-ui/core';
import React from 'react';
import NumberFormatCustom from './NumberFormatCustom';

const NumberFormatField = ({
  value,
  onChange,
  label,
  name,
  id,
  filled,
  component,
  InputProps,
  ...other
}) => {
  const inputComponent = component || NumberFormatCustom;
  return (
    <TextField
      {...other}
      className={filled ? 'styled-filled-field' : 'styled-outlined-field'}
      label={label}
      value={value}
      onChange={onChange}
      name={name}
      id={id}
      InputProps={{
        ...InputProps,
        inputComponent,
      }}
    />
  );
};

export default NumberFormatField;
