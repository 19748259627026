import { useTranslation } from 'react-i18next';

const useLangRoute = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const langRoute = (path) => {
    if (lang === 'ru') {
      return `${path}`;
    }
    return `/${lang}${path}`;
  };
  return langRoute;
};

export default useLangRoute;
