import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import Contract from '../../_enchancers/Contract';

const getUserContracts = createSelector(
  (state) => state.contract.contracts,
  (_, allowAll) => allowAll,
  (contracts, allowAll) => {
    if (allowAll && contracts?.length > 1) {
      return Contract.selectAllContracts(contracts);
    }
    return contracts;
  }
);

const useContract = (allowAllContracts = false) => {
  const currentContract = useSelector((state) => state.contract.value);
  const contracts = useSelector((state) => getUserContracts(state, allowAllContracts));

  const Contracts = currentContract?.id;

  return { currentContract, contracts, Contracts };
};

export default useContract;
