import {
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import './OutlinedInput.scss';
import StyledTextField from '../StyledTextField';

const CustomOutlinedInput = ({
  icon,
  onChange,
  value,
  errors,
  label,
  name,
  readOnly,
  fullWidth,
  password,
  className,
  multiline,
  rows,
  rowsMax,
  disabled,
  CustomTextHelperPosition,
  loading,
  ...props
}) => {
  const error = Boolean(errors?.message);

  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const Visibility = () => {
    if (password) {
      return visible ? 'text' : 'password';
    }
    return 'text';
  };

  let multipleProps = {};

  if (rows) {
    multipleProps = {
      ...multipleProps,
      rows,
    };
  }
  if (rowsMax) {
    multipleProps = {
      ...multipleProps,
      rowsMax,
    };
  }

  return (
    <StyledTextField
      {...props}
      multiline={multiline}
      {...multipleProps}
      error={error}
      type={Visibility()}
      onChange={onChange}
      value={value}
      label={label}
      disabled={disabled}
      name={name}
      fullWidth
      helperText={errors?.message}
      variant="outlined"
      InputProps={{
        ...props.InputProps,
        disableUnderline: true,
        startAdornment:
          icon || props.InputProps?.startAdornment ? (
            <>
              {icon && <InputAdornment position="start">{icon}</InputAdornment>}
              {props.InputProps?.startAdornment}
            </>
          ) : undefined,
        endAdornment: (
          <>
            {loading ? (
              <InputAdornment position="end">
                <CircularProgress color="primary" size={20} />
              </InputAdornment>
            ) : null}
            {props.InputProps?.endAdornment}
            {password ? (
              <InputAdornment position="end">
                <IconButton onClick={toggleVisibility}>
                  {visible ? (
                    <VisibilityIcon color="primary" />
                  ) : (
                    <VisibilityOffIcon color="primary" />
                  )}
                </IconButton>
              </InputAdornment>
            ) : null}
          </>
        ),
        readOnly,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      CustomTextHelperPosition={CustomTextHelperPosition}
    />
  );
};

CustomOutlinedInput.propTypes = {
  icon: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  password: PropTypes.bool,
  className: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
};

CustomOutlinedInput.defaultProps = {
  icon: null,
  multiline: false,
  errors: {},
  fullWidth: false,
  password: false,
  className: '',
  rows: 0,
  name: undefined,
  rowsMax: 0,
  onChange: undefined,
  value: undefined,
};

export default CustomOutlinedInput;
