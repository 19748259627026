import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../ApiClient/ApiClient';
import fetchStatus from '../_constants/fetchStatus';

const fetchByAllDiscountPrefix = createAsyncThunk(
  'prefix/getAllDiscountPrefix',
  async (data = { pageNumber: 1, pageSize: 10, search: '' }, thunk) => {
    const isSearch = data.search.length > 0;
    const url = isSearch
      ? ApiClient.DiscountPrefix
      : ApiClient.AllDiscountPrefix;

    return url(data.search, data.pageNumber, data.pageSize).then((res) => {
      if (res.isSuccess) {
        const { prefixes, totalCount } = res;
        const mutablePrefixes = prefixes.map((item) => ({
          ...item,
          discountPrefixGenerateTypeid: item.discountPrefixGenerateType.id,
        }));
        return { prefixes: mutablePrefixes, totalCount };
      }
      throw thunk.rejectWithValue(res.statusMessage);
    });
  }
);

const initialState = {
  pageNumber: 0,
  totalCount: null,
  pageSize: 10,
  search: '',
  status: fetchStatus.IDLE,
  prefixes: [],
};

const prefixReducer = createSlice({
  name: 'prefix',
  initialState,
  reducers: {
    setPageSize(state, action) {
      state.pageNumber = 0;
      state.pageSize = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    setSearch(state, action) {
      state.search = action.payload;
      state.pageNumber = 0;
    },
    setPrefixes(state, action) {
      state.prefixes = action.payload;
    },
  },
  extraReducers: {
    [fetchByAllDiscountPrefix.pending]: (state) => {
      state.status = fetchStatus.PENDING;
    },
    [fetchByAllDiscountPrefix.fulfilled]: (state, action) => {
      state.status = fetchStatus.FULFILLED;
      state.prefixes = action.payload.prefixes;
      state.totalCount = action.payload.totalCount;
    },
    [fetchByAllDiscountPrefix.rejected]: (state, action) => {
      state.status = fetchStatus.REJECTED;
      state.message = action.payload;
    },
  },
});

export { fetchByAllDiscountPrefix };
export const { setPageNumber, setPageSize, setSearch, setPrefixes } =
  prefixReducer.actions;
export default prefixReducer.reducer;
