import React from 'react';
import { Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomAutocomplete from './CustomAutocomplete';

const ContractAutocomplete = ({
  options,
  value,
  onChange,
  filterSelectedOptions,
  limitTags,
  multiple,
  size,
  disabled,
  errors,
  name,
  InputProps,
  ...props
}) => {
  const { i18n } = useTranslation();
  const getOptionSelected = (option, val) => {
    if (val.id === -1) {
      return true;
    }
    return option.id === val.id;
  };

  const getOptionLabel = (option) => {
    if (option.id === -1) {
      return option.id === -1 ? option.name[i18n.language] : option.name;
    }
    if (option) {
      return option.id === -1 ? option.name[i18n.language] : option.name;
    }
    return null;
  };

  return (
    <CustomAutocomplete
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      name={name}
      errors={errors}
      disabled={disabled}
      limitTags={limitTags}
      filterSelectedOptions={filterSelectedOptions}
      getOptionSelected={getOptionSelected}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      size={size}
      renderTags={(val, getTagProps) =>
        val.map((option, index) => (
          <Chip
            variant="outlined"
            label={getOptionLabel(option)}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getTagProps({ index })}
          />
        ))
      }
      InputProps={InputProps}
      onChange={onChange}
      value={value}
      options={options || []}
      getOptionLabel={getOptionLabel}
    />
  );
};

ContractAutocomplete.propTypes = {
  limitTags: PropTypes.number,
  filterSelectedOptions: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
};

ContractAutocomplete.defaultProps = {
  limitTags: -1,
  disabled: false,
  multiple: false,
  size: undefined,
  filterSelectedOptions: undefined,
  value: null,
  errors: {},
};

export default ContractAutocomplete;
