import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ApiClient from '../../../../ApiClient/ApiClient';
import errorHelper from '../../../../_constants/errorHelper';
import fetchStatus from '../../../../_constants/fetchStatus';
import localizeTimePeriod from '../../../../_helpers/scripts/localizeTimePeriod';
import { fetchToInitCreateDisountCard } from '../../../../_reducers/initCreateDiscountCardReducer';
import CustomAutocomplete from '../../../Inputs/CustomAutocomplete';
import DateRangeField from '../../../Inputs/DateRange/DateRangeField';
import NumberFormatField from '../../../Inputs/NumberField/NumberFormatField';
import LoadingButton from '../../../_components/LoadingButton';
import AdminActions from '../../_components/AdminActions';
import AdminContent from '../../_components/AdminContent';
import AdminDialog from '../../_components/AdminDialog';
import AdminTitle from '../../_components/AdminTitle';
import formatDate from '../_helpers/formatDate';
import './CreateCard.scss';

// TODO: поменять кнопки после ответа: если ок то добавить "ок", смотреть за dirty после ответа,
// если поменяеться то изменить значение кнопок на initial
const CreateCard = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [discountTypeOptions, setDiscountTypeOptions] = useState([]);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(fetchStatus.IDLE);

  const wasFetched = status !== fetchStatus.IDLE;

  const success = status === fetchStatus.FULFILLED;

  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    handleSubmit, control, reset, errors, formState, setValue, watch
  } = useForm({
    defaultValues: {
      prefixId: null,
      contractId: null,
      discountTypeId: null,
      startEndValidity: null,
      possibleNumberOfUses: null,
      periodTypeOfUsesId: null,
      isCreateCardPool: false,
      numberOfCardsToCreate: null,
    },
  });

  const { isSubmitting, isDirty } = formState;

  const fetchByCreateDiscountCard = async (formData) => {
    const token = await executeRecaptcha();

    const [startValidity, endValidity] = [
      formatDate(formData.startEndValidity[0]),
      formatDate(formData.startEndValidity[1]),
    ];

    const fetchData = {
      prefixId: formData.prefixId.id,
      contractId: formData.contractId.id,
      discountTypeId: formData.discountTypeId.id,
      startValidity,
      endValidity,
      possibleNumberOfUses: parseInt(formData.possibleNumberOfUses, 10),
      periodTypeOfUsesId: formData.periodTypeOfUsesId.id,
      isCreateCardPool: formData.isCreateCardPool,
    };

    if (formData.isCreateCardPool) {
      fetchData.numberOfCardsToCreate = formData.numberOfCardsToCreate;
    }

    return ApiClient.CreateDiscountCard(fetchData, token)
      .then((res) => {
        if (res) {
          if (res.isSuccess) {
            setStatus(fetchStatus.FULFILLED);
            reset(formData);
            return setMessage(res.statusMessage);
          }
          throw new Error(res.statusMessage);
        }
        throw new Error(errorHelper.common);
      })
      .catch((e) => {
        setMessage(e.message);
        setStatus(fetchStatus.REJECTED);
      });
  };
  const onSubmit = async (data) => fetchByCreateDiscountCard(data);

  const contracts = useSelector(
    (state) => state.initCreateDiscountCard.contracts
  );

  const onlyContracts = contracts.length > 0 ? contracts.map((item) => item.contract) : [];

  const prefixes = useSelector(
    (state) => state.initCreateDiscountCard.prefixes
  );
  const timePeriodTypes = useSelector(
    (state) => state.initCreateDiscountCard.timePeriodTypes
  );
  const initStatus = useSelector(
    (state) => state.initCreateDiscountCard.initStatus
  );

  const initDataLoading = initStatus === fetchStatus.PENDING;
  const initDataError = initStatus === fetchStatus.REJECTED;

  const contractId = watch('contractId');

  const getDiscountTypeByContract = () => {
    const { discountTypes } = contracts.find(
      (item) => item.contract.id === contractId.id
    );
    return discountTypes;
  };

  useEffect(() => {
    if (!initDataLoading) {
      if (contractId?.name) {
        const discountTypes = getDiscountTypeByContract();
        setDiscountTypeOptions(discountTypes);
        setValue('discountTypeId', null);
      } else {
        setValue('discountTypeId', null);
        setDiscountTypeOptions([]);
      }
    }
  }, [contractId]);

  useEffect(() => {
    dispatch(fetchToInitCreateDisountCard());
  }, []);

  return (
    <AdminDialog
      open={open}
      scroll="body"
      onClose={onClose}
      className="dialog"
      paperClassName="dialog-disable-overflow"
      onSubmit={handleSubmit(onSubmit)}
    >
      <AdminTitle>{t('Admin:Discount.createCard')}</AdminTitle>
      <AdminContent className="dialog-disable-overflow">
        {initDataLoading && (
          <Box display="flex" alignItems="center" mb={2}>
            <Box marginRight={2}>
              <CircularProgress style={{ width: 16, height: 16 }} />
            </Box>
            <Typography>{t('Admin:Discount.initFormCardCreate')}</Typography>
          </Box>
        )}
        {initDataError && (
          <Box mb={2}>
            <Typography color="error" variant="body1">
              {t('Admin:Discount.initErrorFormCardCreate')}
            </Typography>
          </Box>
        )}
        {wasFetched && (
          <Box marginBottom={3}>
            <Alert color={success ? 'success' : 'error'}>{message}</Alert>
          </Box>
        )}
        <Controller
          name="isCreateCardPool"
          control={control}
          render={({ value, onChange }) => (
            <FormControl>
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    value={value}
                    checked={value}
                    onChange={(e) => onChange(e.currentTarget.checked)}
                  />
                )}
                label={t('Admin:Discount.poolCards')}
              />
            </FormControl>
          )}
        />
        <Grid className="card-grid" container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="prefixId"
              control={control}
              rules={{ required: errorHelper.required }}
              render={({ value, onChange }) => (
                <CustomAutocomplete
                  label={`${t('Admin:Discount.cardType')}*`}
                  value={value}
                  onChange={onChange}
                  errors={errors.prefixId}
                  options={prefixes}
                  getOptionLabel={(option) => option && option.prefix}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="startEndValidity"
              control={control}
              rules={{ required: errorHelper.required }}
              render={({ value, onChange }) => (
                <DateRangeField
                  outlined
                  error={errors.startEndValidity}
                  label={`${t('Admin:Discount.cardValidity')}*`}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          {watch('isCreateCardPool') && (
            <Grid item xs={12}>
              <Controller
                name="numberOfCardsToCreate"
                control={control}
                rules={{ required: errorHelper.required }}
                render={({ value, onChange }) => (
                  <NumberFormatField
                    label={t('Admin:Discount.cardCount')}
                    value={value}
                    error={Boolean(errors.numberOfCardsToCreate?.message)}
                    helperText={errors.numberOfCardsToCreate?.message}
                    onChange={(field) => onChange(field.floatValue)}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Controller
              name="contractId"
              control={control}
              rules={{ required: errorHelper.required }}
              render={({ value, onChange }) => (
                <CustomAutocomplete
                  label={t('Admin:Discount.Contract')}
                  value={value}
                  getOptionLabel={(option) => option && option.name}
                  errors={errors.contractId}
                  options={onlyContracts}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="discountTypeId"
              control={control}
              rules={{ required: errorHelper.required }}
              render={({ value, onChange }) => (
                <CustomAutocomplete
                  label={`${t('Admin:Discount.discountType')}*`}
                  value={value}
                  options={discountTypeOptions}
                  getOptionLabel={(option) => (option ? `${option?.name} - ${option?.value}` : null)}
                  errors={errors.discountTypeId}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="periodTypeOfUsesId"
              control={control}
              rules={{ required: errorHelper.required }}
              render={({ value, onChange }) => (
                <CustomAutocomplete
                  label={t('Admin:Discount.timePeriod')}
                  value={value}
                  options={timePeriodTypes}
                  getOptionLabel={(option) => option && localizeTimePeriod(option.id)}
                  errors={errors.periodTypeOfUsesId}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="possibleNumberOfUses"
              control={control}
              rules={{
                required: errorHelper.required,
              }}
              render={({ value, onChange }) => (
                <NumberFormatField
                  label={`${t('Admin:Discount.possibleNumberOfUses')}*`}
                  value={value}
                  error={Boolean(errors.possibleNumberOfUses?.message)}
                  helperText={errors.possibleNumberOfUses?.message}
                  onChange={(field) => onChange(field.floatValue)}
                />
              )}
            />
          </Grid>
        </Grid>
      </AdminContent>
      <AdminActions>
        <Button onClick={onClose} className="dialog__btn dialog__cancel">
          {t('Common.Cancel')}
        </Button>
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          disabled={!isDirty || isSubmitting}
          className="dialog__btn dialog__submit"
        >
          {t('Common.Create')}
        </LoadingButton>
      </AdminActions>
    </AdminDialog>
  );
};

CreateCard.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateCard;
