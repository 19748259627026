import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../Inputs/img/search.svg';
import { ReactComponent as CloseIcon } from '../../Inputs/img/close.svg';
import useDebounce from '../../../_helpers/scripts/useDebounce';

const SearchField = ({
  label,
  disabled,
  value,
  onChange,
  hasClearIcon,
  onResetValue,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(value);
  const debouncedSearchValue = useDebounce(inputValue, 200);

  const [isValid, setIsValid] = useState(false);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const validate = (val) => {
        if (val !== '' && val.length < 3) {
          return setIsValid(t('ValidateErrors.MinLen', { count: 3 }));
        }
        if (isValid) {
          setIsValid('');
        }
        return onChange(inputValue);
      };

      validate(inputValue);
    }
  }, [debouncedSearchValue]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleResetSearch = () => {
    onResetValue();
    setInputValue('');
  };

  const clearIcon = hasClearIcon && inputValue;
  return (
    <TextField
      className="filled-input"
      disabled={disabled}
      error={Boolean(isValid)}
      fullWidth
      helperText={isValid}
      label={label}
      name="search-patient"
      onChange={handleChange}
      value={inputValue}
      variant="filled"
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <>
            {clearIcon ? (
              <InputAdornment position="end">
                <IconButton color="primary" onClick={handleResetSearch} size="small">
                  <CloseIcon width="16px" height="16px" />
                </IconButton>
              </InputAdornment>
            ) : null}
            {!inputValue && (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
};

SearchField.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasClearIcon: PropTypes.bool.isRequired,
  onResetValue: PropTypes.func.isRequired,
};

export default SearchField;
