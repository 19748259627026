import { Checkbox } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import fetchStatus from '../../../../_constants/fetchStatus';
import ApiClient from '../../../../ApiClient/ApiClient';
import errorHelper from '../../../../_constants/errorHelper';
import AlertDialog from '../../../Dialogs/AlertDialog';
import useFetchStatus from '../../../../_helpers/scripts/useFetchStatus';

const SelectAllCheckbox = ({ rowsCount, numSelected, setSelected }) => {
  const {
    message, setMessage, error, setStatus
  } = useFetchStatus();

  const handleClose = () => setStatus(fetchStatus.IDLE);
  const searchParametrs = useSelector(
    (state) => state.discount.searchParametrs
  );
  const totalCount = useSelector((state) => state.discount.totalCount);

  const fetchByAllDiscountCards = async (event) => {
    try {
      if (event.currentTarget.checked) {
        setStatus(fetchStatus.PENDING);

        const data = {
          searchParametrs,
          pagination: {
            pageNumber: 1,
            pageSize: totalCount,
          },
          isGetExtendedInfo: false,
        };

        await ApiClient.SearchDiscountCardByFilters(data)
          .then((res) => {
            if (res) {
              if (res.isSuccess) {
                if (res.shortInfoOfDiscountCards?.length > 0) {
                  const allSelectedId = res.shortInfoOfDiscountCards.map(
                    (item) => item.id
                  );
                  setSelected(allSelectedId);
                  setStatus(fetchStatus.FULFILLED);
                  return;
                }
              }
              throw new Error(res.statusMessage);
            }
            throw new Error(errorHelper.common);
          })
          .catch((e) => {
            setMessage(e.message);
            setStatus(fetchStatus.REJECTED);
          });
      } else {
        setSelected([]);
      }
    } catch {
      setMessage(errorHelper.common);
      setStatus(fetchStatus.REJECTED);
    }
  };

  return (
    <>
      <Checkbox
        indeterminate={numSelected > 0 && numSelected < totalCount}
        size="small"
        checked={rowsCount > 0 && numSelected === totalCount}
        onChange={fetchByAllDiscountCards}
        color="primary"
      />
      {error && (
        <AlertDialog
          open={error}
          onClose={handleClose}
          error
          message={message}
        />
      )}
    </>
  );
};

SelectAllCheckbox.propTypes = {
  rowsCount: PropTypes.number.isRequired,
  numSelected: PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default SelectAllCheckbox;
