import React, { useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import authenticationService from '../_services/authentication.service';
import retryHelper from '../_helpers/retryHelper';
import CustomHttpClient from './CustomHttpClient';
import { clearConnection, setConnection } from '../_reducers/infoHubReducer';
import cacheHelper from '../_helpers/cacheHelper';
import config from '../ApiClient/config';

const InfoHub = () => {
  const dispatch = useDispatch();
  const connection = useSelector((state) => state.infoHub.connection);
  const { enqueueSnackbar } = useSnackbar();

  const start = () => {
    connection
      .start()
      .then(() => {
        connection.on('UpdateVersion', (result) => {
          cacheHelper.resetCache(result?.version);
        });

        connection.on('UpdateSettings', (isUpdated) => {
          if (isUpdated) {
            authenticationService.refreshTokenAndUserData();
          }
        });
      })
      .catch((e) => {
        if (process.env.NODE_ENV === 'development') {
          enqueueSnackbar(`Occured error by connecting to hub: ${e.message}`, {
            variant: 'error',
          });
        }
      });
  };

  useEffect(() => {
    let connect;
    try {
      connect = new signalR.HubConnectionBuilder()
        .withUrl(`${config.apiUrl}/SettingsHub`, {
          httpClient: new CustomHttpClient(),
        })
        .configureLogging(signalR.LogLevel.Error)
        .withAutomaticReconnect()
        .build();

      connect.onclose((e) => {
        if (e) {
          if (process.env.NODE_ENV === 'development') {
            enqueueSnackbar(`onClose disconected - ${e}`);
          }
        }
      });

      dispatch(setConnection(connect));
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        enqueueSnackbar(`Occured error by connecting to hub: ${e.message}`, {
          variant: 'error',
        });
      }
    }
    return () => {
      if (
        ![
          signalR.HubConnectionState.Disconnected,
          signalR.HubConnectionState.Disconnecting,
        ].includes(connect?.state)
      ) {
        connect
          ?.stop()
          .then(() => {
            if (process.env.NODE_ENV === 'development') {
              enqueueSnackbar('hub disconected');
            }
          })
          .catch((e) => {
            if (process.env.NODE_ENV === 'development') {
              enqueueSnackbar(e.message);
            }
          });
      }
      dispatch(clearConnection());
    };
  }, []);

  useEffect(() => {
    if (connection) {
      retryHelper.retry(start);
    }
    return () => {};
  }, [connection]);

  return null;
};

export default InfoHub;
