import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearBasket,
  fetchByCalculator,
} from '../../../_reducers/basketReducer';

const useServices = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const serviceCodes = useSelector((state) => state.basket.serviceCodes);
  const parrentCodes = useSelector((state) => state.basket.parrentCodes);

  const AddService = async (
    code,
    isCalculateByContract = false,
    isBiomaterial = false
  ) => {
    let token = null;
    if (executeRecaptcha) {
      token = await executeRecaptcha();
    }

    let newCodes;

    if (code && Array.isArray(code)) {
      newCodes = code;
    } else {
      newCodes = [...parrentCodes, code];
    }

    dispatch(
      fetchByCalculator([newCodes, token, isCalculateByContract, isBiomaterial])
    );
  };

  const DeleteService = async (
    code,
    isCalculateByContract = false,
    isBiomaterial = false
  ) => {
    const token = await executeRecaptcha();

    const index = parrentCodes.indexOf(code);

    const newServices = [
      ...parrentCodes.slice(0, index),
      ...parrentCodes.slice(index + 1),
    ];

    if (newServices.length === 0) {
      return dispatch(clearBasket());
    }

    return dispatch(
      fetchByCalculator([
        newServices,
        token,
        isCalculateByContract,
        isBiomaterial,
      ])
    );
  };

  return {
    DeleteService,
    AddService,
    serviceCodes,
    parrentCodes,
  };
};

export default useServices;
