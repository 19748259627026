import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import LazyImg from '../../../_helpers/LazyImg';
import MailDoneIcon from '../img/mail_done.svg';
import '../../Dialogs/Dialog.scss';
import ModalEmail from './ModalEmail';

const ModalEmailButton = ({ barcode, disabled }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomizedTooltip title={t('Tooltip.Email')}>
        <IconButton disabled={disabled} size="small" onClick={handleOpen}>
          <LazyImg
            className="btn-group__img"
            src={MailDoneIcon}
            width="32px"
            height="32px"
            alt={t('Tooltip.Email')}
          />
        </IconButton>
      </CustomizedTooltip>
      {open && (
        <ModalEmail barcode={barcode} open={open} onClose={handleClose} />
      )}
    </>
  );
};

ModalEmailButton.propTypes = {
  barcode: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ModalEmailButton.defaultProps = {
  disabled: false,
};

export default ModalEmailButton;
