import { CircularProgress, IconButton } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { ReactComponent as Date2Icon } from '../img/date2.svg';
import { ReactComponent as CloseIcon } from '../../../_icons/close.svg';
import './Article.scss';

import articleService from '../../../_services/article.service';
import useLangRoute from '../../../_helpers/scripts/useLangRoute';
import { AppRoute } from '../../../const/app-routes';

const ArticlePage = ({ match }) => {
  const [data, setData] = useState({
    title: 'Без названия',
    content: '',
    image: null,
    createdDate: '',
  });
  const { article } = match.params;
  const [loading, setLoading] = useState(true);

  const path = useLangRoute();

  useEffect(() => {
    articleService
      .getByName(article)
      .then((res) => {
        let image = '';
        if (res.image.length > 0) {
          image = `data:image/png;base64,${res.image}`;
        }

        const responseData = {
          ...res,
          image,
        };

        setData(responseData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <section className="article">
      {loading ? (
        <div className="article__scroll">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <div
            className="article__image-container"
            style={{ backgroundImage: `url(${data.image})` }}
          />
          <div className="article__head">
            <IconButton
              title="Назад к новостям"
              className="article__close-button"
              component={Link}
              to={path(AppRoute.InfoRoutes.News)}
            >
              <CloseIcon />
            </IconButton>
            <div className="article__content-title">
              <h2 className="article__title">{data.title}</h2>
              {data.createdDate ? (
                <h4 className="article__date">
                  <span className="article__date-icon">
                    <Date2Icon />
                  </span>
                  {format(new Date(data.createdDate), 'EEEE MMM dd, yyyy')}
                </h4>
              ) : null}
            </div>
          </div>

          <article
            className="article__content"
            dangerouslySetInnerHTML={{ __html: data.content }}
          />
        </>
      )}
    </section>
  );
};

export default ArticlePage;
