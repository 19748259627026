import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
  useMediaQuery,
  Hidden,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import Row from './Row';
import MobileRow from './MobileResultsTable/MobileRow';
import { changePageSize, changePage } from '../../_reducers/resultsReducer';
import StyledTableSortLabel from '../_components/StyledTableSortLabel';
import tablePaginationHelper from '../../_helpers/tablePaginationHelper';

const ResultTable = ({
  orders,
  totalCount,
  getResults,
  previousBody,
  pageSize,
  getResultByRequestSort,
}) => {
  const { t } = useTranslation();
  const page = useSelector((state) => state.resultsReducer.page);
  const rowsPerPage = useSelector((state) => state.resultsReducer.pageSize);
  const dispatch = useDispatch();

  const order = useSelector((state) => state.resultsReducer.order);
  const orderBy = useSelector((state) => state.resultsReducer.orderBy);

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    getResultByRequestSort(isAsc ? 'desc' : 'asc', property);
  };

  const handleChangePage = (event, newPage) => {
    getResults(previousBody, newPage, pageSize);
    dispatch(changePage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changePageSize(parseInt(event.target.value, 10)));
    getResults(previousBody, 0, parseInt(event.target.value, 10));
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderRows = orders.map((row) =>
    mobile ? (
      <MobileRow key={row.name} row={row} />
    ) : (
      <Row key={row.name} row={row} />
    )
  );

  return (
    <>
      <TableContainer component="div">
        <Table className="services-table">
          <TableHead>
            <TableRow className="services-table__tr">
              <TableCell className="services-table__th" />
              <TableCell
                sortDirection={orderBy === 'FullName' ? order : false}
                className="services-table__th"
              >
                <StyledTableSortLabel
                  active={orderBy === 'FullName'}
                  direction={orderBy === 'FullName' ? order : 'asc'}
                  onClick={handleRequestSort('FullName')}
                >
                  {t('Sort.FullName')}
                </StyledTableSortLabel>
              </TableCell>
              <Hidden smDown>
                <TableCell className="services-table__th">
                  {t('Sort.DateOfBirth')}
                </TableCell>
              </Hidden>
              <TableCell
                sortDirection={orderBy === 'Barcode' ? order : false}
                className="services-table__th"
              >
                <StyledTableSortLabel
                  active={orderBy === 'Barcode'}
                  direction={orderBy === 'Barcode' ? order : 'asc'}
                  onClick={handleRequestSort('Barcode')}
                >
                  {t('Sort.Barcode')}
                </StyledTableSortLabel>
              </TableCell>
              <TableCell className="services-table__th">
                {t('Sort.Status')}
              </TableCell>
              <Hidden smDown>
                <TableCell
                  sortDirection={orderBy === 'ExaminationDate' ? order : false}
                  className="services-table__th"
                >
                  <StyledTableSortLabel
                    active={orderBy === 'ExaminationDate'}
                    direction={orderBy === 'ExaminationDate' ? order : 'asc'}
                    onClick={handleRequestSort('ExaminationDate')}
                  >
                    {t('Result.OrderDate')}
                  </StyledTableSortLabel>
                </TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell
                  sortDirection={orderBy === 'DeadLineDate' ? order : false}
                  className="services-table__th"
                >
                  <StyledTableSortLabel
                    active={orderBy === 'DeadLineDate'}
                    direction={orderBy === 'DeadLineDate' ? order : 'asc'}
                    onClick={handleRequestSort('DeadLineDate')}
                  >
                    {t('Result.DeadLineDate')}
                  </StyledTableSortLabel>
                </TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell className="services-table__th" />
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody className="services-table__body">{renderRows}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="services-table__pagination"
        rowsPerPageOptions={tablePaginationHelper.rowsPerPageOptions}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

ResultTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      orderId: PropTypes.number,
      barcode: PropTypes.string,
      patient: PropTypes.shape({
        personId: PropTypes.number,
        lastName: PropTypes.string,
        firstName: PropTypes.string,
        gender: PropTypes.string,
        dateOfBirth: PropTypes.string,
      }),
      services: PropTypes.arrayOf(
        PropTypes.shape({
          serviceCode: PropTypes.string,
          serviceName: PropTypes.string,
          serviceStatus: PropTypes.string,
        })
      ),
      orderDate: PropTypes.string,
      state: PropTypes.string,
      deadLineDate: PropTypes.string,
      clientUnitId: PropTypes.number,
      clientUnitName: PropTypes.string,
      createdByUserAccount: PropTypes.string,
      workSpace: PropTypes.string,
    })
  ).isRequired,
  totalCount: PropTypes.number.isRequired,
  getResults: PropTypes.func.isRequired,
  previousBody: PropTypes.shape({}).isRequired,
  pageSize: PropTypes.number.isRequired,
  getResultByRequestSort: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  orders: state.resultsReducer.orders,
  totalCount: state.resultsReducer.totalCount,
  previousBody: state.resultsReducer.previousBody,
  pageSize: state.resultsReducer.pageSize,
});

export default connect(mapState)(ResultTable);
