import { Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import React from 'react';

const SubmitButton = ({ loading, label, className, children, onClick }) => (
  <Button
    onClick={onClick}
    disabled={loading}
    type="submit"
    fullWidth
    variant="contained"
    color="primary"
    className={clsx('login__submit', className)}
  >
    {loading ? (
      <CircularProgress style={{ width: 20, height: 20 }} />
    ) : (
      label || children
    )}
  </Button>
);

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string.isRequired,
  children: PropTypes.symbol,
};

SubmitButton.defaultProps = {
  onClick: () => {},
  label: '',
  children: null,
};

export default SubmitButton;
