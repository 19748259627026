import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogTitle
} from '@material-ui/core';

import clsx from 'clsx';
import '../../Dialogs/Dialog.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useServices from '../ServicesTable/useServices';

const ServiceDialog = ({
  open,
  onClose,
  message,
  error,
  success,
  children,
  onExited,
}) => {
  const conflict = useSelector((state) => state.basket.conflict);
  const { t } = useTranslation();
  const { parrentCodes, AddService } = useServices();

  const handleReplaceService = () => {
    const i = conflict.indexOf('-');
    const ServicesToDelete = conflict.substring(i + 1).split(',');
    const ServiceToSwap = conflict.substring(0, i);

    let newServices = parrentCodes;

    ServicesToDelete.forEach((item) => {
      const index = parrentCodes.indexOf(item);
      newServices = [
        ...newServices.slice(0, index),
        ...newServices.slice(index + 1),
      ];
    });

    AddService([...newServices, ServiceToSwap], false, true);
    onClose();
  };

  // TODO: вывод сообщения про конфликт
  const buttons = conflict ? (
    <>
      <Button className="dialog__btn dialog__cancel" onClick={onClose}>
        {t('Common.No')}
      </Button>
      <Button
        className="dialog__btn dialog__submit"
        onClick={handleReplaceService}
      >
        {t('Common.Yes')}
      </Button>
    </>
  ) : (
    <Button className="dialog__btn dialog__submit" onClick={onClose}>
      Ок
    </Button>
  );
  return (
    <Dialog
      TransitionProps={{
        onExited,
      }}
      className="dialog"
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        className={clsx('dialog__title', {
          dialog__error: error,
          dialog__success: success,
        })}
      >
        {message}
      </DialogTitle>
      {children}
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  );
};

ServiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  children: PropTypes.symbol,
};

ServiceDialog.defaultProps = {
  message: '',
  error: false,
  success: false,
  children: null,
};

export default ServiceDialog;
