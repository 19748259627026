import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ApiClient from '../../../ApiClient/ApiClient';
import errorHelper from '../../../_constants/errorHelper';
import fetchStatus from '../../../_constants/fetchStatus';
import base64ToType from '../../../_helpers/scripts/base64ToType';
import { setError } from '../../../_reducers/errorReducer';
import { ReactComponent as PrintIcon } from '../../../_icons/print.svg';
import useApiContracts from '../../Contract/useApiContracts';

const PrintBarcodeButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const contracts = useApiContracts();
  const [status, setStatus] = useState(fetchStatus.IDLE);

  const barcode = useSelector((state) => state.direction.barcode);

  const GetBarcodePdfAsync = async () => {
    setStatus(fetchStatus.PENDING);

    await ApiClient.GetBarcodePdfToPrint(barcode, contracts)
      .then((res) => {
        if (res.isSuccess) {
          setStatus(fetchStatus.FULFILLED);

          const blob = base64ToType(res.base64Barcode, 'application/pdf');
          const url = URL.createObjectURL(blob);

          const doc = document.querySelector('#print-barcode');

          const container = document.createElement('div', {});
          container.style.display = 'none';
          container.id = `barcode-${barcode}`;
          doc.appendChild(container);

          const iframe = document.createElement('iframe', {});
          iframe.width = '520px';
          iframe.height = '620px';
          iframe.src = url;
          iframe.id = `barcode-window-${barcode}`;

          container.appendChild(iframe);

          iframe.contentWindow.focus();
          iframe.contentWindow.print();

          const listener = () => {
            doc.removeChild(container);
            setStatus(fetchStatus.IDLE);
          };

          iframe.contentWindow.addEventListener('beforeprint', listener, false);
          iframe.contentWindow.removeEventListener(
            'beforeprint',
            listener,
            false
          );
        } else {
          throw new Error(errorHelper.common);
        }
      })
      .catch((e) => {
        setStatus(fetchStatus.REJECTED);
        dispatch(setError(e.message));
      });
  };

  const handleClick = () => GetBarcodePdfAsync();

  const loading = status === fetchStatus.PENDING;
  return (
    <div id="print-barcode">
      <Button
        onClick={handleClick}
        disabled={loading || !barcode}
        color="primary"
        startIcon={<PrintIcon />}
      >
        {t('Tooltip.PrintBarcode')}
      </Button>
    </div>
  );
};

export default PrintBarcodeButton;
