import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  connection: null,
};

const infoHubSlice = createSlice({
  name: 'infoHub',
  initialState,
  reducers: {
    setConnection(state, action) {
      state.connection = action.payload;
    },
    clearConnection(state) {
      state.connection = null;
    },
  },
});

export const { setConnection, clearConnection } = infoHubSlice.actions;
export default infoHubSlice.reducer;
