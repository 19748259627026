import { Button, FormHelperText, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LastNameInput, OrderStatusField, FirstNameField } from './Fields';
import MiddleNameField from './Fields/MiddleNameField';
import { orders, sortState } from '../../_constants/OrderStatusConst';
import './ServiceFormGroup.scss';
import DateRangeField from '../Inputs/DateRange/DateRangeField';
import fetchStatus from '../../_constants/fetchStatus';
import StyledNumberTextField from '../Inputs/NumberField/StyledNumberTextField';
import useContract from '../Contract/useContract';
import useClientUnits from '../Contract/useClientUnits';
import ContractFields from './ContractFields';

const ServiceFormGroup = ({
  setFormData,
  sorting,
  resultsLoading,
  sortingLoading,
}) => {
  const { currentContract } = useContract();
  const { currentClientUnit } = useClientUnits();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      contract: currentContract,
      clientUnit: currentClientUnit,
    },
  });

  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    getValues,
    formState,
    watch,
  } = methods;

  const { errors } = formState;

  const { t } = useTranslation();

  const onSubmit = (data) => {
    setFormData(data);
  };

  const contrSelected = Boolean(watch('contract'));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="services-form">
        <Grid container className="services-form__grid">
          <Grid container item xs={12} lg={10}>
            <FormProvider {...methods}>
              <ContractFields />
            </FormProvider>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              className="services-form__grid-item"
            >
              <Controller
                name="cabinetOrderStatus"
                control={control}
                defaultValue={sorting ? sortState.NEW : orders[0].id}
                render={({ onChange, value }) => (
                  <OrderStatusField
                    sorting={sorting}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              className="services-form__grid-item"
            >
              <Controller
                name="barcode"
                control={control}
                defaultValue={null}
                render={({ onChange, value, ...field }) => (
                  <StyledNumberTextField
                    variant="filled"
                    label={t('Input.Barcode')}
                    name="barcode"
                    onChange={(v) => onChange(v.floatValue)}
                    value={value}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              className="services-form__grid-item"
            >
              <Controller
                name="lastName"
                control={control}
                defaultValue={null}
                render={({ onChange, value }) => (
                  <LastNameInput value={value} onChange={onChange} />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              className="services-form__grid-item"
            >
              <Controller
                name="firstName"
                control={control}
                defaultValue={null}
                render={({ onChange, value }) => (
                  <FirstNameField value={value} onChange={onChange} />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              className="services-form__grid-item"
            >
              <Controller
                name="middleName"
                control={control}
                defaultValue={null}
                render={({ onChange, value }) => (
                  <MiddleNameField onChange={onChange} value={value} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="services-form__grid-item">
              <Controller
                name="createdDate"
                control={control}
                rules={{
                  required: t('ValidateErrors.Required'),
                  validate: () => {
                    if (
                      getValues('createdDate')[0] instanceof Date
                      && getValues('createdDate')[1] instanceof Date
                    ) {
                      return true;
                    }
                    return t('ValidateErrors.Required');
                  },
                }}
                defaultValue={[
                  new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
                  new Date(),
                ]}
                render={({ onChange, value }) => (
                  <DateRangeField
                    onCalendarOpen={() => {
                      setError('createdDate', {
                        type: 'manual',
                        message: t('ValidateErrors.TimeRange'),
                      });
                    }}
                    onChange={(event) => {
                      clearErrors('createdDate');
                      return onChange(event);
                    }}
                    label={t('Input.createdDate')}
                    id="date-create-order"
                    value={value}
                    error={errors.createdDate}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="services-form__grid-item">
              <Controller
                name="resultDate"
                control={control}
                defaultValue={[null, null]}
                render={({ onChange, value }) => (
                  <DateRangeField
                    onChange={(event) => {
                      clearErrors('resultDate');
                      return onChange(event);
                    }}
                    onCalendarOpen={() => {
                      setError('resultDate', {
                        type: 'manual',
                        message: t('ValidateErrors.TimeRange'),
                      });
                    }}
                    label={
                      sorting ? t('Input.resultDate') : t('Input.DeadLineDate')
                    }
                    id="date-copmlete-order"
                    value={value}
                    error={errors.resultDate}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={2}
            justify="flex-end"
            alignItems="flex-end"
            container
          >
            <Grid item xs={0} sm={6} lg />
            <Grid item xs lg={12} className="services-form__grid-item">
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={resultsLoading || sortingLoading || !contrSelected}
                className="services-form__submit"
              >
                {t('Input.Submit')}
              </Button>
              {!contrSelected && (
                <FormHelperText className="service-form__tooltip" error>
                  {t('Sort.NoContract')}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div className="b-services-form__submit" />
      </div>
    </form>
  );
};

ServiceFormGroup.propTypes = {
  setFormData: PropTypes.func,
  resultsLoading: PropTypes.bool.isRequired,
};

ServiceFormGroup.defaultProps = {
  setFormData: () => {},
};

const mapState = (state) => ({
  formData: state.FieldGroupFormReducer,
  resultsLoading: state.resultsReducer.status === fetchStatus.PENDING,
  sortingLoading: state.sort.status === fetchStatus.PENDING,
});

export default connect(mapState)(ServiceFormGroup);
