import {
  Box,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import ApiClient from '../../../ApiClient/ApiClient';
import errorHelper from '../../../_constants/errorHelper';
import fetchStatus from '../../../_constants/fetchStatus';
import useFetchStatus from '../../../_helpers/scripts/useFetchStatus';

import StyledPaper from '../../_components/StyledPaper';
import PreOrderForm from './PreOrderForm';
import PreOrderTable from './PreOrderTable';
import CustomProgress from '../../_components/CustomProgress';

const PreOrderPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    error, setMessage, message, loading, fulFilled, setStatus
  } = useFetchStatus();

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [orders, setOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [requestBody, setRequestBody] = useState({
    body: null,
    Contracts: null,
  });

  const mapOrders = (source) => source.map((order) => ({
    ...order,
    dateOfBirth: order.patient.dateOfBirth,
    lastName: order.patient.lastName,

  }));

  const getPreorders = async (body, Contracts) => {
    setStatus(fetchStatus.PENDING);
    setOrders([]);
    const token = await executeRecaptcha();

    return ApiClient.SearchPreOrderByFilters(body, token, {
      Contracts,
    })
      .then((res) => {
        if (res.isSuccess) {
          if (res.preOrders?.length > 0) {
            setRequestBody({ body, Contracts });
            setOrders(mapOrders(res.preOrders));
            setTotalCount(res.totalCount);
            setStatus(fetchStatus.FULFILLED);

            return true;
          }
        }
        throw new Error(res.statusMessage || errorHelper.common);
      })
      .catch((e) => {
        setMessage(e.message);
        setStatus(fetchStatus.REJECTED);
      });
  };

  const handleError = (msg) => {
    setMessage(msg);
    setStatus(fetchStatus.REJECTED);
  };

  const getFormResult = (formData, contract) => {
    const body = formData;
    body.pageNumber = 1;
    body.pageSize = pageSize;

    setPageNumber(0);

    return getPreorders(body, contract.id);
  };

  const handleChangePage = (pageNum) => {
    setPageNumber(pageNum);

    const { body } = requestBody;

    body.pageNumber = pageNum + 1;

    return getPreorders(body, requestBody.Contracts);
  };

  const handleChangePageSize = (pageSz) => {
    setPageNumber(0);
    setPageSize(pageSz);

    const { body } = requestBody;

    body.pageNumber = 1;
    body.pageSize = pageSz;

    return getPreorders(body, requestBody.Contracts);
  };

  return (
    <>
      <Box
        mb={{
          xs: 4,
          sm: 5,
          md: 7,
          lg: 10,
        }}
      >
        <StyledPaper elevation={2}>
          <PreOrderForm
            withTable={orders.length > 0}
            setMessage={handleError}
            getOrders={getFormResult}
          />
          {fulFilled && (
            <PreOrderTable
              totalCount={totalCount}
              pageNumber={pageNumber}
              pageSize={pageSize}
              setPageNumber={handleChangePage}
              setPageSize={handleChangePageSize}
              rows={orders}
            />
          )}
        </StyledPaper>
        {loading && (
          <CustomProgress />
        )}
        {error && (
          <Box textAlign="center" p={3}>
            <Typography variant="h6" color="error">
              {message}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PreOrderPage;
