import { useEffect } from 'react';
import { useDispatch, } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { resetBasket, } from '../../_reducers/basketReducer';
import { setSearchValue } from '../../_reducers/patientReducer';

const CreateOrderRouteService = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.resetBasket) {
      dispatch(resetBasket());
      dispatch(setSearchValue(''));
    }
  }, [location.state]);

  useEffect(() => () => {
    dispatch(resetBasket());
    dispatch(setSearchValue(''));
  }, []);

  return null;
};

export default CreateOrderRouteService;
