/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../ApiClient/ApiClient';
import errorHelper from '../_constants/errorHelper';
import fetchStatus from '../_constants/fetchStatus';
import regexpHelper from '../_helpers/regexpHelper';

const initialState = {
  status: fetchStatus.IDLE,
  orders: [],
  message: '',
  totalCount: 0,
  pageSize: 10,
  page: 0,
  body: {
    sortingState: null,
    clientUnitId: null,
    lastName: null,
    firstName: null,
    createdDateStart: null,
    createdDateEnd: null,
    importedDateStart: null,
    importedDateEnd: null,
  },
  contracts: {
    Contracts: null,
  },
  isModifed: false,
  selected: [],
  sortingState: 1,
  submited: null,
  order: 'asc',
  orderingParameterName: 'FullName',
};

const fetchByOrders = createAsyncThunk(
  'sort/fetchBySortOrders',
  async (data, thunk) =>
    ApiClient.Sorting(data.body, data.reCaptchaToken, data.contracts)
      .then((res) => {
        if (res.isSuccess) {
          if (res.orders?.length > 0) {
            const immetRes = res.orders.map((item) => ({
              ...item,
              dateOfBirth: item.patient.dateOfBirth.match(
                regexpHelper.number
              )[0],
              firstName: item.patient.firstName,
              contractName: item.contract.name,
              contractId: item.contract.id,
            }));
            return { ...res, orders: immetRes };
          }
        }
        return thunk.rejectWithValue(res.statusMessage);
      })
      .catch((e) => thunk.rejectWithValue(e.message || errorHelper.common))
);

// TODO: переименовать
const newSortReducer = createSlice({
  name: 'sort',
  initialState,
  reducers: {
    setSortingState(state, action) {
      state.sortingState = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
      state.page = 0;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setPreviousBody(state, action) {
      state.body = action.payload.body;
      state.contracts = action.payload.contracts;
      state.status = fetchStatus.PENDING;
      state.submited = action.payload.submited;
      state.sortingState = action.payload.sortingState;
      state.selected = [];
      state.page = 0;
      state.orderingParameterName = 'FullName';
      state.order = 'asc';
    },
    reset(state) {
      state.status = fetchStatus.IDLE;
      state.page = 0;
      state.orders = [];
      state.totalCount = 0;
      state.message = '';
      state.submited = null;
      state.selected = [];
      state.orderingParameterName = 'FullName';
      state.order = 'asc';
    },
    setOrders(state, action) {
      state.orders = action.payload.orders;
      state.totalCount = action.payload.totalCount;
    },
    setOrderingParameterName(state, action) {
      state.orderingParameterName = action.payload.parameter;
      state.order = action.payload.order;
    },
  },
  extraReducers: {
    [fetchByOrders.pending]: (state, action) => {
      state.status = fetchStatus.PENDING;
      state.orders = [];
    },
    [fetchByOrders.fulfilled]: (state, action) => {
      state.status = fetchStatus.FULFILLED;
      state.orders = action.payload.orders;
      state.totalCount = action.payload.totalCount;
    },
    [fetchByOrders.rejected]: (state, action) => {
      state.status = fetchStatus.REJECTED;
      state.message = action.payload;
      state.totalCount = 0;
    },
  },
});

export { fetchByOrders };
export const {
  setSortingState,
  setStatus,
  setPageSize,
  setSelected,
  setPreviousBody,
  setPage,
  reset,
  setOrders,
  setOrderingParameterName,
} = newSortReducer.actions;
export default newSortReducer.reducer;
