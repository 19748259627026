/* eslint-disable react/jsx-props-no-spreading */
import { Box, Paper } from '@material-ui/core';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InfoPopup from '../../_components/InfoPopup';
import './OrderForm.scss';
import ContracrAdditionalField from './ContractAdditionalFiled';
import StyledNumberTextField from '../../Inputs/NumberField/StyledNumberTextField';
import LoadingButton from '../../_components/LoadingButton';
import Commissionaires from './Commissionaires';
import StyledTextField from '../../Inputs/StyledTextField';
import OrderPrice from './OrderPrice';

const OrderForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const emptyBasket = useSelector(
    (state) => state.basket.addedServices.length === 0
  );

  const isEdit = useSelector(
    (state) => state.basket.editOrder || state.basket.isPreOrder
  );
  const isPreOrder = useSelector((state) => state.basket.isPreOrder);
  const defaultValues = useSelector((state) => state.basket.order);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      commissionaire: null,
      barcode: isEdit ? defaultValues.barcode : null,
      remarks: isEdit ? defaultValues.remarks : null,
    },
  });
  const { control, handleSubmit, formState } = methods;

  const { isSubmitting, dirtyFields, errors } = formState;

  const submit = async (data) => onSubmit(data, dirtyFields);

  const handleValidateBarcode = (value) => {
    if (value?.length > 0) {
      if (value?.length > 9) {
        return true;
      }
      return t('Errors.BarcodeLength');
    }
    return true;
  };

  return (
    <FormProvider {...methods}>
      <Paper
        onSubmit={handleSubmit(submit)}
        component="form"
        noValidate
        className="service-list__paper service-list__wrapper ordering__form"
        variant="elevation"
        elevation={0}
      >
        <div className="ordering__container">
          <h3 className="ordering__title">{t('CreateOrder.Ordering.Title')}</h3>
          <div className="order-form">
            <div className="order-form__item">
              <Controller
                name="barcode"
                rules={{
                  validate: handleValidateBarcode,
                }}
                control={control}
                render={({ value, onChange }) => (
                  <StyledNumberTextField
                    value={value}
                    disabled={isPreOrder}
                    variant="filled"
                    error={Boolean(errors.barcode?.message)}
                    helperText={errors.barcode?.message}
                    onChange={(v) => onChange(v.formattedValue)}
                    InputProps={{
                      endAdornment: (
                        <InfoPopup paperClass="ordering__popover">
                          {t('CreateOrder.popupInfo')}
                        </InfoPopup>
                      ),
                    }}
                    label={t('Input.Barcode')}
                  />
                )}
              />
            </div>
            <div className="order-form__item">
              <Controller
                name="remarks"
                control={control}
                render={({ value, onChange }) => (
                  <StyledTextField
                    fullWidth
                    value={value}
                    variant="filled"
                    onChange={onChange}
                    label={t('Input.Remarks')}
                  />
                )}
              />
            </div>
            <Commissionaires />
            <ContracrAdditionalField />
          </div>
          <OrderPrice />
        </div>
        <Box position="absolute" bottom={-24} maxWidth="304px" width="100%">
          <LoadingButton
            fullWidth
            loading={isSubmitting}
            disabled={emptyBasket || isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
          >
            {t('Common.Submit')}
          </LoadingButton>
        </Box>
      </Paper>
    </FormProvider>
  );
};

OrderForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default OrderForm;
