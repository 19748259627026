import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderLink from '../../Settings/Link/HeaderLink';
import '../../../_helpers/styles/Header.scss';
import RBAC from '../../../_helpers/RBAC';
import routes from '../_constants/admin-routes';

const AdminHeader = () => {
  const { t } = useTranslation('Admin');

  return (
    <header className="admin-header header">
      <div className="header__wrapper">
        <h1 className="header__title">{t('Nav.Title')}</h1>
        <div className="header__link-group">
          {routes.map((item) => (
            <RBAC key={item.route} allowedRoles={item.roles}>
              <HeaderLink label={t(item.label)} link={item.route} />
            </RBAC>
          ))}
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
