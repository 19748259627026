import {
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ColorSwitch from '../../_components/ColorSwitch';
import ApiClient from '../../../../ApiClient/ApiClient';
import errorHelper from '../../../../_constants/errorHelper';
import useDialog from '../../../../_helpers/scripts/useDialog';
import './DiscountSwitch.scss';
import fetchStatus from '../../../../_constants/fetchStatus';
import ConfirmDialog from '../../../Dialogs/ConfirmDialog/ConfirmDialog';
import { setDiscountCards } from '../../../../_reducers/discountReducer';
import replaceItems from './replaceItems';

const DiscountSwitch = ({
  value,
  end,
  className,
  onChange,
  disabled,
  id,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Admin');
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const [message, setMessage] = useState('');
  const [checkedValue, setCheckedValue] = useState(null);
  const cards = useSelector((state) => state.discount.cards);

  const { open, handleClose, handleOpen } = useDialog();
  const error = status === fetchStatus.REJECTED;
  const loading = status === fetchStatus.PENDING;

  const fetchByChangeStatus = async (val) => {
    setStatus(fetchStatus.PENDING);
    const fetchData = {
      discountCardIds: id,
      isActive: val,
    };
    return ApiClient.UpdateDiscountCardStatus(fetchData)
      .then((res) => {
        if (res) {
          if (res.isSuccess) {
            if (onSuccess) {
              onSuccess();
            }
            setStatus(fetchStatus.FULFILLED);
            return val;
          }
          throw new Error(res.statusMessage);
        }
        throw new Error(errorHelper.common);
      })
      .catch((e) => {
        setStatus(fetchStatus.REJECTED);
        setMessage(e.message);
        return !val;
      });
  };

  const handleSubmit = async (val) => {
    const responseValue = await fetchByChangeStatus(val);

    onChange(responseValue);

    const newCards = replaceItems(responseValue, cards, id);
    dispatch(setDiscountCards(newCards));
  };

  const handleChange = (e) => {
    setCheckedValue(e.currentTarget.checked);
    handleOpen();
  };

  const titleMessage = checkedValue
    ? t('Discount.StatusDialog.ActivateSelected', { context: 'card' })
    : t('Discount.StatusDialog.DeactivateSelected', { context: 'card' });

  const submitMessage = checkedValue
    ? t('Discount.StatusDialog.Activate')
    : t('Discount.StatusDialog.Deactivate');

  return (
    <>
      <FormControl error={error} className="discount-switch">
        <FormControlLabel
          control={(
            <ColorSwitch
              checked={value}
              onChange={handleChange}
              disabled={disabled || loading}
            />
          )}
          label={value ? t('Control.active') : t('Control.nonActive')}
          className={className}
          labelPlacement={end ? 'end' : 'bottom'}
        />

        {error && <FormHelperText>{message}</FormHelperText>}
      </FormControl>
      {open && (
        <ConfirmDialog
          open={open}
          onClose={handleClose}
          value={checkedValue}
          title={titleMessage}
          submitMessage={submitMessage}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

DiscountSwitch.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.arrayOf(PropTypes.number).isRequired,
  end: PropTypes.bool,
  onSuccess: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.bool.isRequired,
};

DiscountSwitch.defaultProps = {
  end: false,
  disabled: undefined,
  className: undefined,
  onChange: () => {},
  onSuccess: undefined,
};

export default DiscountSwitch;
