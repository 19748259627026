import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import errors from '../../../_constants/errors';
import fetchStatus from '../../../_constants/fetchStatus';
import { ReactComponent as CalcIcon } from '../../../_icons/calculator.svg';
import orderCreateService from '../../../_services/orderCreate.service';
import useApiContracts from '../../Contract/useApiContracts';
import AlertDialog from '../../Dialogs/AlertDialog';
import LoadingButton from '../../_components/LoadingButton';
import CalcDialog from './CalcDialog';

const CalcButton = () => {
  const { t } = useTranslation();
  const contracts = useApiContracts();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [state, setState] = useState({
    status: fetchStatus.IDLE,
    services: [],
    message: '',
  });
  const { status, services, message } = state;
  const open = status === fetchStatus.FULFILLED;
  const loading = status === fetchStatus.PENDING;
  const error = status === fetchStatus.REJECTED;
  const handleClose = () => setState({
    ...state,
    status: fetchStatus.IDLE,
  });

  const codes = useSelector((s) => s.basket.serviceCodes);
  const gender = useSelector((s) => s.basket.patient?.gender);

  const fetchByCalculator = async () => {
    setState((s) => ({ ...s, status: fetchStatus.PENDING }));
    let token;

    if (executeRecaptcha) {
      token = await executeRecaptcha();
    }

    await orderCreateService
      .calculator(codes, gender, token, contracts, true, true)
      .then((res) => {
        if (res) {
          if (res.isSuccess) {
            return setState({
              ...state,
              status: fetchStatus.FULFILLED,
              services: res,
            });
          }
          throw new Error(
            `${res.statusMessage}. ${res.serviceInfo?.statusMessage}`
          );
        }
        throw new Error(errors.common);
      })
      .catch((e) => {
        setState({
          ...state,
          status: fetchStatus.REJECTED,
          message: e.message,
        });
      });
  };

  return (
    <>
      <LoadingButton
        color="primary"
        loading={loading}
        disabled={loading}
        startIcon={<CalcIcon />}
        onClick={fetchByCalculator}
      >
        {t('CreateOrder.Calculator')}
      </LoadingButton>
      <CalcDialog services={services} open={open} onClose={handleClose} />
      {error && (
        <AlertDialog
          open={error}
          onClose={handleClose}
          message={message}
          error={error}
        />
      )}
    </>
  );
};

export default CalcButton;
