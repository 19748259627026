import { Checkbox } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import fetchStatus from '../../../../_constants/fetchStatus';
import ApiClient from '../../../../ApiClient/ApiClient';
import errorHelper from '../../../../_constants/errorHelper';
import AlertDialog from '../../../Dialogs/AlertDialog';
import useFetchStatus from '../../../../_helpers/scripts/useFetchStatus';

// TODO: сделать один компонент

const SelectAllCheckbox = ({
  rowsCount, numSelected, total, setSelected
}) => {
  const {
    message, setMessage, error, setStatus
  } = useFetchStatus();

  const handleClose = () => setStatus(fetchStatus.IDLE);

  const fetchByAllDiscountCards = async (event) => {
    try {
      if (event.currentTarget.checked) {
        setStatus(fetchStatus.PENDING);
        await ApiClient.AllDiscountPrefix('', 1, total)
          .then((res) => {
            if (res) {
              if (res.isSuccess) {
                const allSelectedId = res.prefixes.map((item) => item.id);
                setSelected(allSelectedId);
                setStatus(fetchStatus.FULFILLED);
                return;
              }
              throw new Error(res.statusMessage);
            }
            throw new Error(errorHelper.common);
          })
          .catch((e) => {
            setMessage(e.message);
            setStatus(fetchStatus.REJECTED);
          });
      } else {
        setSelected([]);
      }
    } catch {
      setMessage(errorHelper.common);
      setStatus(fetchStatus.REJECTED);
    }
  };

  const totalSelected = total;

  return (
    <>
      <Checkbox
        indeterminate={numSelected > 0 && numSelected < total}
        size="small"
        checked={rowsCount > 0 && numSelected === totalSelected}
        onChange={fetchByAllDiscountCards}
        color="primary"
      />
      {error && (
        <AlertDialog
          open={error}
          onClose={handleClose}
          error
          message={message}
        />
      )}
    </>
  );
};

SelectAllCheckbox.propTypes = {
  rowsCount: PropTypes.number.isRequired,
  numSelected: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default SelectAllCheckbox;
