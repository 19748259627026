import React from 'react';

const DiscountCustomer = ({ customer }) => {
  if (customer) {
    return (
      <>
        {customer.fullName}
        <br />
        {customer.birthday}
      </>
    );
  }

  return '---';
};

export default DiscountCustomer;
