import IconButton from '@material-ui/core/IconButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import ApiClient from '../../../ApiClient/ApiClient';
import fetchStatus from '../../../_constants/fetchStatus';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import useLangRoute from '../../../_helpers/scripts/useLangRoute';
import { AppRoute } from '../../../const/app-routes';
import { setPreOrder } from '../../../_reducers/basketReducer';
import { ReactComponent as SuccessIcon } from '../../../_icons/success.svg';

const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'absolute',
    top: 2,
    left: 1,
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      top: 10,
      left: 10,
    },
  },
}));

const СreatePreOrderButton = ({ size, barcode, contract }) => {
  const { t } = useTranslation();
  const localize = useLangRoute();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const [redirect, setRedirect] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const GetPreOrder = async () => {
    setStatus(fetchStatus.PENDING);

    return ApiClient.GetPreOrderByBarcode(barcode, { Contracts: contract.id })
      .then((res) => {
        if (res !== null) {
          dispatch(setPreOrder(res));
          setRedirect(true);
        } else {
          throw new Error(t('ResultMessage.NotFound', { barcode }));
        }
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error' });
        setStatus(fetchStatus.REJECTED);
      });
  };

  if (redirect) {
    return (
      <Redirect
        push
        to={{
          pathname: localize(AppRoute.CreateOrder),
          state: { preOrder: true },
        }}
      />
    );
  }

  const handleRedirectToEdit = (e) => {
    e.stopPropagation();
    GetPreOrder();
  };

  return (
    <Box position="relative" display="flex" alignItems="center">
      {status === fetchStatus.PENDING && (
        <CircularProgress className={classes.progress} size={28} />
      )}
      <CustomizedTooltip title={t('Tooltip.CreateOrder')}>
        <IconButton
          size={size}
          onClick={handleRedirectToEdit}
          disabled={status === fetchStatus.PENDING}
          color="primary"
        >
          <SuccessIcon width={24} height={24} />
        </IconButton>
      </CustomizedTooltip>
    </Box>
  );
};

СreatePreOrderButton.propTypes = {
  barcode: PropTypes.number.isRequired,
  size: PropTypes.objectOf('small', 'medium').isRequired,
  contract: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

export default СreatePreOrderButton;
