import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import CustomOutlinedSelect from '../Inputs/BaseOutlinedInput/CustomOutlinedSelect';
import CustomOutlinedInput from '../Inputs/BaseOutlinedInput/CustomOutlinedInput';
import MailIcon from '../Dialogs/img/mail.svg';
import { AllRoles } from '../../_constants/Roles';
import ApiClient from '../../ApiClient/ApiClient';
import passIcon from '../Settings/img/password.svg';
import RoleIcon from './img/Role.svg';
import LazyImg from '../../_helpers/LazyImg';
import useDialog from '../../_helpers/scripts/useDialog';
import AlertDialog from '../Dialogs/AlertDialog';
import SubmitButton from '../Login/SubmitButton';
import useFetchStatus from '../../_helpers/scripts/useFetchStatus';
import errorHelper from '../../_constants/errorHelper';
import validationHelper from '../../_helpers/validationHelper';

const RegisterForm = ({ onUserCreatedSuccess }) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    control, handleSubmit, getValues, reset, formState
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      role: '',
      email: '',
      password: '',
      reenterPassword: '',
    },
  });
  const { errors } = formState;
  const { open, handleOpen, handleClose } = useDialog();

  const {
    error,
    fetchStatus,
    loading,
    fulFilled,
    setMessage,
    setStatus,
    message,
  } = useFetchStatus();

  const RegisterUser = async (data) => {
    setStatus(fetchStatus.PENDING);
    const body = {
      email: data.email,
      password: data.password,
      confirmPassword: data.reenterPassword,
      roleId: data.role,
    };
    const token = await executeRecaptcha('registration');

    try {
      await ApiClient.Registration(body, token).then((res) => {
        if (res?.isSuccess) {
          if (onUserCreatedSuccess) {
            onUserCreatedSuccess(data);
          }
          reset();
          setStatus(fetchStatus.FULFILLED);
        } else {
          setStatus(fetchStatus.REJECTED);
        }
        setMessage(res?.statusMessage);
        handleOpen();
      });
    } catch (e) {
      setMessage(e?.message || errorHelper.common);
      setStatus(fetchStatus.REJECTED);
    }
  };

  const onSubmit = (data) => {
    RegisterUser(data);
  };

  const validateReEnteredPassword = (reenteredValue) => (getValues('password') !== reenteredValue
    ? t('Errors.PasswordError')
    : true);

  const img = <LazyImg src={passIcon} />;

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="register__field">
          <Controller
            control={control}
            name="role"
            rules={{ required: errorHelper.required }}
            render={({ value, onChange }) => (
              <CustomOutlinedSelect
                icon={<LazyImg src={RoleIcon} />}
                label={t('Input.Role')}
                name="role"
                errors={errors.role}
                value={value}
                onChange={onChange}
              >
                {AllRoles.map((role) => (
                  <MenuItem value={role.RoleId}>{t(role.Name)}</MenuItem>
                ))}
              </CustomOutlinedSelect>
            )}
          />
        </div>
        <div className="register__field">
          <Controller
            name="email"
            control={control}
            rules={{
              required: errorHelper.required,
              validate: validationHelper.email,
            }}
            render={({ onChange, value }) => (
              <CustomOutlinedInput
                icon={<LazyImg src={MailIcon} />}
                label="Email"
                InputProps={{
                  autoComplete: 'off',
                }}
                name="Email"
                value={value}
                onChange={onChange}
                errors={errors.email}
              />
            )}
          />
        </div>
        <div className="register__field">
          <Controller
            name="password"
            control={control}
            rules={{
              required: errorHelper.required,
              minLength: {
                value: 8,
                message: t('ValidateErrors.Min8Len'),
              },
            }}
            render={({ onChange, value }) => (
              <CustomOutlinedInput
                password
                icon={img}
                InputProps={{
                  autoComplete: 'off',
                }}
                name="password"
                label={t('EditPassword.NewPassword')}
                value={value}
                onChange={onChange}
                errors={errors.password}
              />
            )}
          />
        </div>
        <div className="register__field">
          <Controller
            name="reenterPassword"
            control={control}
            rules={{
              required: errorHelper.required,
              validate: validateReEnteredPassword,
            }}
            render={({ onChange, value }) => (
              <CustomOutlinedInput
                password
                name="reenter-password"
                icon={img}
                label={t('EditPassword.ConfirmPassword')}
                value={value}
                onChange={onChange}
                errors={errors.reenterPassword}
              />
            )}
          />
        </div>
        <h4 className="register__prompt">{t('Register.InputLabel')}</h4>
        <SubmitButton
          label={t('Register.SubmitButton')}
          className="register__submit"
          loading={loading}
        />
      </form>
      {open && (
        <AlertDialog
          open={open}
          onClose={handleClose}
          message={message}
          error={error}
          success={fulFilled}
        />
      )}
    </>
  );
};

RegisterForm.propTypes = {
  onUserCreatedSuccess: PropTypes.func,
};

RegisterForm.defaultProps = {
  onUserCreatedSuccess: undefined,
};

export default RegisterForm;
