import {
  Step, StepButton, StepLabel, Stepper
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StepIconCustom from './StepIcon';
import './Stepper.scss';

const OrderStepper = ({ steps, activeStep, setStep }) => {
  const { t } = useTranslation();
  const isPreOrder = useSelector((state) => state.basket.isPreOrder);

  return (
    <div>
      <Stepper activeStep={activeStep} connector={null} className="stepper">
        {steps.map(({ label }, index) => {
          const disabled = isPreOrder && [0, 1].includes(index);
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Step key={label} className="stepper__item">
              <StepButton
                className="stepper__button"
                onClick={() => setStep(index)}
                disabled={activeStep <= index || disabled}
              >
                <StepLabel
                  classes={{
                    label: 'stepper__label',
                    completed: 'stepper__completed',
                    disabled: 'stepper__disabled',
                  }}
                  StepIconComponent={StepIconCustom}
                >
                  {t(`Breadcrumbs.${label}`)}
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

OrderStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
      Component: PropTypes.elementType,
    })
  ).isRequired,
  activeStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default OrderStepper;
