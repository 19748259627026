import {
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../../_icons/close.svg';
import './BaseInput.scss';

const BaseFilledInput = ({
  name,
  id,
  label,
  AdornmentType,
  Adornment,
  onChange,
  type,
  disabled,
  value,
  card,
  className,
  errors,
  loading,
  readOnly,
  text,
  onClear,
  ...props
}) => {
  const hasAdorment = onClear !== undefined;

  return (
    <>
      <TextField
        className={clsx(
          'filled-input',
          hasAdorment && 'filled-input--with-end-btn',
          className
        )}
        type={type}
        fullWidth
        error={Boolean(errors?.message)}
        helperText={errors?.message}
        variant="filled"
        InputProps={{
          ...props.InputProps,
          disableUnderline: true,
          readOnly,
          [AdornmentType]: Adornment,
          endAdornment:
            (onClear && (
              <InputAdornment position="end">
                <IconButton
                  disabled={!value}
                  onClick={onClear}
                  className="filled-input__btn filled-input__on-clear"
                  size="small"
                >
                  <CloseIcon width="12px" height="12px" />
                </IconButton>
              </InputAdornment>
            ))
            || (loading && (
              <InputAdornment position="end">
                <CircularProgress color="primary" size={16} />
              </InputAdornment>
            ))
            || null,
          className: clsx('filled-input__input', {
            'filled-input__input--card': card,
            'filled-input__input_text': text,
          }),
        }}
        InputLabelProps={{
          className: clsx(
            'filled-input__label',
            {
              'filled-input__label--card': card || text,
            },
            AdornmentType === 'startAdornment'
              && 'filled-input__label_adornment'
          ),
        }}
        disabled={disabled}
        label={label}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        {...props}
      />
    </>
  );
};

BaseFilledInput.propTypes = {
  Adornment: PropTypes.node,
  AdornmentType: PropTypes.oneOf(['startAdornment', 'endAdornment']),
  card: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

BaseFilledInput.defaultProps = {
  Adornment: undefined,
  AdornmentType: undefined,
  card: false,
  className: undefined,
  disabled: false,
  errors: {},
  id: undefined,
  label: '',
  name: '',
  onChange: () => {},
  readOnly: false,
  type: '',
  value: '',
};

export default BaseFilledInput;
