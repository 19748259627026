import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../_icons/close.svg';

import './CalcDialog.scss';
import '../../Dialogs/Dialog.scss';
import OrderDirectionButton from '../OrderDirection/OrderDirectionButton';

const CalcDialog = ({ open, onClose, services }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const hadServices = services?.serviceInfo?.services.length > 0;

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      className="dialog"
      fullScreen={fullScreen}
    >
      <DialogTitle disableTypography>
        <Box display="flex" alignItems="center">
          <h4 className="dialog__title">{t('CreateOrder.Calculator')}</h4>
          <Box marginLeft={1}>
            <OrderDirectionButton caclData={services} />
          </Box>
          <Box flexGrow="1" textAlign="end">
            <IconButton size="small" color="primary" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TableContainer className="calc-dialog__scroll calc-dialog__table-container">
          <Table stickyHeader className="styled-table">
            <TableHead>
              <TableRow className="styled-table__row">
                <TableCell className="styled-table__th calc-dialog__th styled-table__th--rounded">
                  {t('Info.Directory.Code')}
                </TableCell>
                <TableCell className="styled-table__th calc-dialog__th styled-table__th--rounded">
                  {t('Info.Directory.Service')}
                </TableCell>
                <TableCell className="styled-table__th calc-dialog__th styled-table__th--rounded">
                  {t('Info.Directory.Price')}
                </TableCell>
                <TableCell className="styled-table__th calc-dialog__th styled-table__th--rounded">
                  {t('Info.Directory.WorkingDays')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hadServices
                ? services.serviceInfo.services.map((service, index) => (
                  <TableRow
                      // eslint-disable-next-line react/no-array-index-key
                    key={service.name + index}
                    className="styled-table__row"
                  >
                    <TableCell className="styled-table__cell">
                      {service.code}
                    </TableCell>
                    <TableCell className="styled-table__cell">
                      {service.name}
                    </TableCell>
                    <TableCell className="styled-table__cell">
                      {service.price}
                    </TableCell>
                    <TableCell className="styled-table__cell">
                      {service.workingDays}
                    </TableCell>
                  </TableRow>
                ))
                : null}
              <TableRow>
                <TableCell colSpan={4}>
                  <Box display="flex">

                    <div className="order-info__grid-item">
                      <p className="order-info__label">
                        {t('CreateOrder.Basket.OrdersDeadline')}
                      </p>
                      <p className="order-info__value">
                        {t('CreateOrder.Basket.days.key', {
                          count: services?.serviceInfo?.totalWorkingDays,
                        })}
                      </p>
                    </div>
                    <div className="order-info__grid-item">
                      <p className="order-info__label">
                        {t('CreateOrder.Basket.Price')}
                      </p>
                      <p className="order-info__value">
                        {services?.serviceInfo?.totalPrice}
                      </p>
                    </div>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

CalcDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.string,
      workingDays: PropTypes.number,
    })
  ).isRequired,
};

export default CalcDialog;
