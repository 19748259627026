export const orderResultStatus = {
  Error: 0,
  NewOrder: 1,
  Ready: 2,
  InWork: 3,
  Canceled: 4,
  NonProductive: 5,
  all: new Map([
    [-1, 'All'],
    [1, 'NewOrder'],
    [2, 'Ready'],
    [3, 'InWork'],
    [4, 'Canceled'],
    [5, 'NonProductive'],
  ]),
};

export const orders = [
  {
    id: 'All',
  },
  {
    id: 'NewOrder',
  },
  {
    id: 'Ready',
  },
  {
    id: 'InWork',
  },
  {
    id: 'Canceled',
  },
  {
    id: 'NonProductive',
  },
];

export const sortState = {
  NEW: 1,
  SENT: 2,
  IMPORTED: 3,
  CANCELLED: 4,
  INTEGRATED: 5,
  REQUEST_FOR_PAYMENT: 6,
};

export const sortingOrderStatus = [
  {
    name: 'New',
    id: sortState.NEW,
  },
  {
    name: 'Sent',
    id: sortState.SENT,
  },
  {
    name: 'Imported',
    id: sortState.IMPORTED,
  },
  {
    name: 'Cancelled',
    id: sortState.CANCELLED,
  },
  {
    name: 'Integrates',
    id: sortState.INTEGRATED,
  },
  {
    name: 'RequestForPayment',
    id: sortState.REQUEST_FOR_PAYMENT,
  },
];
