import { format } from 'date-fns';

/**
 *
 * @param {string} value дата в формате dd.MM.yyyy
 * @returns Возвращает дату из строки
 */
const formatStringDateToDate = (value) => {
  if (value) {
    // format date dd.MM.yyyy
    const [day, mounth, year] = value.split('.');

    return new Date(year, mounth - 1, day);
  }
  return null;
};

const formatDateToSilabStandart = (date) => format(new Date(date), 'yyyyMMdd');

/**
 *
 * @param {string} date строка в формате dd.MM.yyyy HH:mm:ss
 * @returns дату
 */
const formatFullDateTimeStringToDate = (date) => {
  try {
    const [day, month, year] = date.substring(0, 10).split('.');

    const [hour, minutes, seconds] = date.substring(11).split(':');

    return new Date(+year, +month - 1, +day, +hour, +minutes, +seconds);
  } catch {
    return null;
  }
};

/**
 * Форматировать строку даты
 * @param {string} date дата в формате yyyyMMdd
 * @returns дату в формате dd.MM.yyyy
 */
function formatDate(date) {
  try {
    // default format yyyyMMdd
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);

    return `${day}.${month}.${year}`;
  } catch (error) {
    return 'Ошибка';
  }
}
/**
 * @param {string} strDate дата в строке формате yyyyMMdd
 * @returns Возвращает дату из строки
 */
const getDate = (strDate) => {
  const year = strDate.substring(0, 4);
  const month = strDate.substring(4, 6);
  const day = strDate.substring(6, 8);

  return new Date(+year, +month - 1, +day);
};

/**
 * @param {Date} date
 * @returns  дата формата dd.MM.yyyy
 */
const formatDateToStandart = (date) => format(date, 'dd.MM.yyyy');

const days = (firstDate, secondDate) => {
  const difference = firstDate.getTime() - secondDate.getTime();
  const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
};

export default {
  formatStringDateToDate,
  formatDate,
  formatDateToSilabStandart,
  formatDateToStandart,
  getDate,
  days,
  formatFullDateTimeStringToDate,
};
