import { Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ApiClient from '../../../ApiClient/ApiClient';
import errorHelper from '../../../_constants/errorHelper';
import fetchStatus from '../../../_constants/fetchStatus';
import StyledTextField from '../../Inputs/StyledTextField';
import useApiContracts from '../../Contract/useApiContracts';

const IdentificationNumberFields = ({ isEdit, setStatus, setMessage }) => {
  const { t } = useTranslation();
  const { control, formState, setValue, watch } = useFormContext();
  const { errors, dirtyFields } = formState;
  const [options, setOptions] = useState([]);
  const contracts = useApiContracts();

  const identificationNumberTypeId = watch('identificationNumberTypeId');

  useEffect(() => {
    if (isEdit && dirtyFields.identificationNumberType) {
      setValue('identificationNumber', '', { shouldDirty: false });
    }
  }, [identificationNumberTypeId]);

  useEffect(() => {
    const GetIdentificationNumberTypesAsync = async () =>
      ApiClient.GetIdentificationNumberTypes(contracts)
        .then((res) => {
          if (identificationNumberTypeId) {
            setValue(
              'identificationNumberTypeId',
              res.find((item) => item.id === identificationNumberTypeId),
              { shouldDirty: false }
            );
          }
          setOptions(res);
        })
        .catch((e) => {
          setStatus(fetchStatus.REJECTED);
          setMessage(e.message);
        });

    if (options.length === 0) GetIdentificationNumberTypesAsync();
  }, [options]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="identificationNumberTypeId"
            control={control}
            render={({ onChange, value }) => (
              <Autocomplete
                fullWidth
                disabled={!isEdit}
                value={value}
                onChange={(_, newVal) => onChange(newVal)}
                options={options}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    error={Boolean(errors.identificationNumberTypeId)}
                    helperText={errors.identificationNumberTypeId?.message}
                    variant="outlined"
                    label={t('Input.DocumentType')}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="identificationNumber"
            control={control}
            rules={{
              required: identificationNumberTypeId
                ? errorHelper.required
                : false,
            }}
            render={({ onChange, value }) => (
              <StyledTextField
                fullWidth
                CustomTextHelperPosition="absolute"
                disabled={!isEdit}
                value={value}
                onChange={onChange}
                error={Boolean(errors.identificationNumber)}
                helperText={errors.identificationNumber?.message}
                variant="outlined"
                label={t('Input.DocumentNo')}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

IdentificationNumberFields.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  setStatus: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default IdentificationNumberFields;
