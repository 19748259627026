import { IconButton, Paper } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { ReactComponent as InfoIcon } from './img/info.svg';
import CustomizedTooltip from '../CustomizedTooltip/CustomizedTooltip';

const ToolTip = ({ title, children }) => (title ? (
  <CustomizedTooltip title={title}>{children}</CustomizedTooltip>
) : (
  <>{children}</>
));

ToolTip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType(
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.elementType
  ).isRequired,
};
ToolTip.defaultProps = {
  title: '',
};

const InfoPopup = ({
  children, popoverClass, paperClass, title, size
}) => (
  <PopupState variant="popover" popupId="info-popup-popover">
    {(popupState) => (
      <div>
        <IconButton
          size={size}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...bindTrigger(popupState)}
        >
          <ToolTip title={title}>
            <InfoIcon />
          </ToolTip>
        </IconButton>
        <Popover
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...bindPopover(popupState)}
          className={popoverClass}
        >
          <Paper className={clsx('service-list__popover', paperClass)}>
            {children}
          </Paper>
        </Popover>
      </div>
    )}
  </PopupState>
);

InfoPopup.propTypes = {
  children: PropTypes.PropTypes.func.isRequired,
  popoverClass: PropTypes.string,
  paperClass: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};
InfoPopup.defaultProps = {
  popoverClass: '',
  paperClass: '',
  title: '',
  size: 'medium',
};

export default InfoPopup;
