import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import { ReactComponent as DeleteIcon } from '../img/delete.svg';
import RemoveServiceDialog from './RemoveServiceDialog';

const RemoveServiceButton = ({ serviceCode, barcode, setCanceled }) => {
  const [t] = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const DisableDeleteButton = () => {
    setDisabled(true);
  };

  return (
    <>
      <CustomizedTooltip title={t('Tooltip.RemoveService')}>
        <IconButton onClick={handleOpenDialog} disabled={disabled}>
          <DeleteIcon
            className={clsx(
              'btn-group__img delete-icon',
              disabled && 'delete-icon--disabled'
            )}
          />
        </IconButton>
      </CustomizedTooltip>
      {openDialog && (
        <RemoveServiceDialog
          setCanceled={setCanceled}
          open={openDialog}
          onClose={handleCloseDialog}
          serviceCode={serviceCode}
          barcode={barcode}
          disabledButton={DisableDeleteButton}
        />
      )}
    </>
  );
};

RemoveServiceButton.propTypes = {
  serviceCode: PropTypes.string.isRequired,
  barcode: PropTypes.number.isRequired,
  setCanceled: PropTypes.func.isRequired
};

export default RemoveServiceButton;
