import { InputAdornment } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import validationHelper from '../../../_helpers/validationHelper';
import StyledTextField from '../../Inputs/StyledTextField';
import PhoneIcon from '../../Dialogs/img/phone.svg';
import mobilePhoneHelper from '../../../_helpers/mobilePhoneHelper';

const MobilePhoneField = ({ isEdit, mobilePhone }) => {
  const { t } = useTranslation();
  const {
    control, formState, setValue, setError
  } = useFormContext();
  const { errors } = formState;

  const validatePhone = () => {
    try {
      const value = mobilePhoneHelper.formatPhone(mobilePhone);
      setValue('mobilePhone', value, { shouldDirty: false });
    } catch (e) {
      setError('mobilePhone', {
        message: e.message,
      });
    }
  };

  useEffect(() => {
    if (mobilePhone) {
      validatePhone();
    }
  }, [mobilePhone]);

  return (
    <Controller
      name="mobilePhone"
      control={control}
      rules={{
        validate: validationHelper.phoneNumber,
      }}
      render={({ onChange, value }) => (
        <ReactInputMask
          mask="+375 99 999 99 99"
          maskChar="_"
          disabled={!isEdit}
          value={value}
          onChange={onChange}
        >
          {(inputProps) => (
            <StyledTextField
              {...inputProps}
              value={value}
              onChange={onChange}
              disabled={!isEdit}
              label={t('Input.Phone')}
              required
              variant="outlined"
              fullWidth
              helperText={errors.mobilePhone?.message}
              error={Boolean(errors.mobilePhone?.message)}
              InputProps={{
                ...inputProps.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={PhoneIcon} alt="" width={24} height={24} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </ReactInputMask>
      )}
    />
  );
};

export default MobilePhoneField;
