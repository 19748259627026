import { DialogTitle } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import './AdminDialog.scss';

const AdminTitle = ({ children }) => {
  return <DialogTitle className="a-dialog__title">{children}</DialogTitle>;
};

AdminTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]).isRequired,
};

export default AdminTitle;
