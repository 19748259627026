import base64ToType from './base64ToType';

const decodeImage = (image) => new Promise((resolve, reject) => {
  const blob = base64ToType(image, 'image/png');
  const file = new window.File([blob], 'image.png', {
    type: 'image/png',
  });

  const reader = new window.FileReader();

  reader.onloadend = () => {
    resolve(reader.result);
  };

  if (file) {
    reader.readAsDataURL(file);
  } else {
    reject();
  }
});

export default decodeImage;
