import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import StyledFormContainer from '../../_components/StyledFormContainer';
import StyledNumberTextField from '../../Inputs/NumberField/StyledNumberTextField';
import StyledTextField from '../../Inputs/StyledTextField';
import DateRangeField from '../../Inputs/DateRange/DateRangeField';
import { sortState } from '../../../_constants/OrderStatusConst';
import dateHelper from '../../../_helpers/dateHelper';
import errorHelper from '../../../_constants/errorHelper';
import PreOrderStatusField from './PreOrderStatusField';
import useContract from '../../Contract/useContract';
import ContractField from '../../Contract/ContractField';

const PreOrderForm = ({ getOrders, withTable, setMessage }) => {
  const { t } = useTranslation();

  const { currentContract } = useContract();
  const {
    control, formState, handleSubmit
  } = useForm({
    defaultValues: {
      contract: currentContract,
      barcode: null,
      status: sortState.NEW,
      lastName: '',
      firstName: '',
      middleName: '',
      createdDate: [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()],
    },
  });

  const { isSubmitting } = formState;

  const onSubmit = async (formData) => {
    const { createdDate, contract } = formData;
    let fetchData;

    try {
      const createdDateStart = dateHelper.formatDateToSilabStandart(
        createdDate[0]
      );
      const createdDateEnd = dateHelper.formatDateToSilabStandart(
        createdDate[1]
      );

      fetchData = {
        createdDateStart,
        createdDateEnd,
        status: formData.status,
      };

      const fields = ['lastName', 'middleName', 'firstName', 'barcode'];

      fields
        .filter((filed) => formData[filed])
        .forEach((item) => {
          fetchData[item] = formData[item];
        });
    } catch (e) {
      setMessage(e.message);
      return false;
    }

    return getOrders(fetchData, contract);
  };

  return (
    <StyledFormContainer
      squareBottom={withTable}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item container spacing={2} lg={10}>
          <Grid item xs={12} md={4} lg={4}>
            <Controller
              name="contract"
              rules={{ required: errorHelper.required }}
              control={control}
              render={({ onChange }) => (
                <ContractField variant="filled" onChange={onChange} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Controller
              name="barcode"
              control={control}
              render={({ onChange, value }) => (
                <StyledNumberTextField
                  fullWidth
                  variant="filled"
                  label={t('Input.Barcode')}
                  onChange={(v) => onChange(v.floatValue)}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Controller
              name="status"
              control={control}
              render={({ onChange, value }) => (
                <PreOrderStatusField onChange={onChange} value={value} />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Controller
              name="lastName"
              control={control}
              render={({ onChange, value }) => (
                <StyledTextField
                  fullWidth
                  variant="filled"
                  label={t('Input.LastName')}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="firstName"
              control={control}
              defaultValue={sortState.NEW}
              render={({ onChange, value }) => (
                <StyledTextField
                  fullWidth
                  variant="filled"
                  label={t('Input.FirstName')}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="middleName"
              control={control}
              defaultValue={sortState.NEW}
              render={({ onChange, value }) => (
                <StyledTextField
                  fullWidth
                  variant="filled"
                  label={t('Input.MiddleName')}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="createdDate"
              control={control}
              render={({ onChange, value }) => (
                <DateRangeField
                  label={t('Input.CreatedDatePreorder')}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          alignContent="center"
          wrap="nowrap"
          xs={12}
          sm={4}
          lg={2}
        >
          <Grid item xs>
            <Button
              disabled={isSubmitting}
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
            >
              {t('Input.Submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
};

export default PreOrderForm;
