import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import './FormDialog.scss';
import PropTypes from 'prop-types';

const FormDialog = ({ onSubmit, children, ...other }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dialog {...other} fullScreen={fullScreen} fullWidth>
      <form onSubmit={onSubmit} className="form-dialog">
        {children}
      </form>
    </Dialog>
  );
};

FormDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default FormDialog;
