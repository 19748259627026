import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppRoute } from '../../../const/app-routes';
import fetchStatus from '../../../_constants/fetchStatus';
import { matchedRoute } from '../../../_helpers/func';
import authenticationService from '../../../_services/authentication.service';
import { ReactComponent as Exit } from '../img/exit.svg';
import './Exit.scss';

const ExitButton = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const connection = useSelector((state) => state.infoHub.connection);
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const loading = status === fetchStatus.PENDING;

  const Logout = async () => {
    setStatus(fetchStatus.PENDING);
    if (connection) {
      connection.stop();
    }

    await authenticationService.revoke().catch((e) => {
      enqueueSnackbar(e.message, { variant: 'error' });
    });
    authenticationService.logout();
    setStatus(fetchStatus.FULFILLED);

    history.push(matchedRoute(i18n.language, AppRoute.Login));
  };

  return (
    <div
      tabIndex="-1"
      role="button"
      className="exit"
      onClick={Logout}
      onKeyPress={Logout}
    >
      {loading ? (
        <CircularProgress color="secondary" style={{ width: 20, height: 20 }} />
      ) : (
        <>
          <button disabled={loading} type="button" className="exit__btn">
            <Exit width="20px" height="20px" />
          </button>
          <div className="exit__wrapper">
            <span className="b-exit__label">
              <span className="exit__label">{t('Menu.Exit')}</span>
            </span>
            <div className="exit__wrapper-color" />
          </div>
        </>
      )}
    </div>
  );
};

export default ExitButton;
