import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Box,
  CircularProgress,
} from '@material-ui/core';
import './Directory.scss';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import FetchSearchInput from '../../Inputs/FetchSearchInput';
import { ReactComponent as AlertIcon } from '../img/alert.svg';
import { ReactComponent as EditIcon } from '../img/edit.svg';
import '../StyledTable.scss';
import SortFunc from '../../../_helpers/sort-funtions';
import { AppRoute } from '../../../const/app-routes';
import ApiClient from '../../../ApiClient/ApiClient';
import {
  addServices,
  setPageNumber,
  setPageSize,
  setServices,
} from '../../../_reducers/manualReducer';
import useFetchResults from '../../../_helpers/scripts/useFetchResults';
import '../../Results/Results.scss';
import useLangRoute from '../../../_helpers/scripts/useLangRoute';
import tablePaginationHelper from '../../../_helpers/tablePaginationHelper';

const headCells = [
  {
    id: 'serviceCode',
    label: 'Name.Code',
    colSpan: false,
  },
  {
    id: 'serviceName',
    label: 'Name.ServiceName',
    colSpan: true,
  },
];

const Directory = () => {
  const { t } = useTranslation('Admin');
  const history = useHistory();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const route = useLangRoute();

  const {
    error, setError, message, setMessage, loading, setLoading
  } = useFetchResults();

  const handleChangePage = (event, newPage) => {
    dispatch(setPageNumber(newPage));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(setPageSize(parseInt(event.target.value, 10)));
  };

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);

  const pageSize = useSelector((state) => state.manual.pageSize);
  const pageNumber = useSelector((state) => state.manual.pageNumber);
  const totalRows = useSelector((state) => state.manual.totalCount);

  const rows = useSelector((state) => state.manual.services);
  const hadRows = rows.length > 0;

  const [term, setTerm] = useState('');
  const [prevTerm, setPrevTerm] = useState('');
  const isNewTerm = prevTerm !== term;

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleGoToDetails = (id) => () => {
    history.push(route(`${AppRoute.AdminRoutes.ServiceExtension}?code=${id}`));
  };

  const deleteError = () => {
    setError(false);
    setMessage('');
  };

  const handleEdit = (e) => {};

  const fetchByServiceManual = async () => {
    setLoading(true);
    const token = await executeRecaptcha();
    deleteError();
    setPrevTerm(term);
    let pageNumberToFetch = pageNumber + 1;
    if (isNewTerm) {
      pageNumberToFetch = 1;
    }
    await ApiClient.ServiceManualSearch(
      term,
      pageNumberToFetch,
      pageSize,
      token
    )
      .then((res) => {
        // console.log(res);
        if (res) {
          if (res.isSuccess) {
            const { totalCount, services } = res;
            if (rows.length > 0 && !isNewTerm) {
              dispatch(addServices({ services, totalCount }));
            } else {
              dispatch(setServices({ services, totalCount }));
            }
          } else {
            dispatch(setServices({ services: [], totalCount: 0 }));
            throw Error(res.statusMessage);
          }
        }
      })
      .catch((e) => {
        setError(true);
        setMessage(e.message);
      });
    setLoading(false);
  };

  useEffect(() => {
    const isPageChanged = rows.length < pageNumber * pageSize + pageSize;
    const isMaxPage = rows.length === totalRows && totalRows !== 0;
    if ((term && isPageChanged && !isMaxPage) || isNewTerm) {
      fetchByServiceManual();
    }
  }, [pageNumber, pageSize, term]);

  const emptyRows = pageSize - Math.min(pageSize, rows.length - pageNumber * pageSize);

  const rowsToRender = rows.slice(
    pageNumber * pageSize,
    pageNumber * pageSize + pageSize
  );

  const renderRows = SortFunc.stableSort(
    rowsToRender,
    SortFunc.getComparator(order, orderBy)
  ).map((row, index) => {
    const selected = false;
    return (
      <TableRow
        hover
        className={clsx(
          'styled-table__row',
          'styled-table__row--clickable',
          selected ? 'styled-table__row--selected' : null
        )}
        index={index}
        onClick={handleGoToDetails(row.serviceCode)}
      >
        <TableCell className="styled-table__cell">{row.serviceCode}</TableCell>
        <TableCell className="styled-table__cell">{row.serviceName}</TableCell>
        <TableCell className="styled-table__cell styled-table__cell_icons">
          <div className="styled-table__button-group">
            {!row.isGeneralCharacteristics ? (
              <IconButton className="styled-table__button">
                <AlertIcon />
              </IconButton>
            ) : null}

            <IconButton
              color="primary"
              onClick={handleEdit}
              className="styled-table__button"
            >
              <EditIcon />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <div className="directory styled-table">
      <Paper className="directory__paper directory__paper--b-t-radius styled-table__container">
        <div className="directory__color-wrap">
          <div className="directory__search">
            <FetchSearchInput
              fetch={setTerm}
              label={t('Text.EnterCodeOrService')}
            />
          </div>
        </div>
        <div className="styled-table__table-container services-table">
          {!loading && hadRows && (
            <>
              <Table className="styled-table__table">
                <TableHead>
                  <TableRow className="styled-table__thead-row">
                    {headCells.map((headCell, index) => (
                      <TableCell
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        colSpan={headCell.colSpan ? 2 : 1}
                        sortDirection={orderBy === headCell.id ? order : false}
                        component="th"
                        className="styled-table__th"
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleRequestSort(headCell.id)}
                        >
                          {t(headCell.label)}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderRows}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{ height: 50 * emptyRows }}
                      className="styled-table__row"
                    >
                      <TableCell className="styled-table__cell" colSpan={3} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                className="services-table__pagination"
                rowsPerPageOptions={tablePaginationHelper.rowsPerPageOptions}
                component="div"
                count={totalRows}
                rowsPerPage={pageSize}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
      </Paper>
      {error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="services-table"
        >
          <h3 className="b-services-table__empty">{message}</h3>
        </Box>
      ) : null}
      {loading ? (
        <Box padding={4} display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      ) : null}
    </div>
  );
};

export default Directory;
