import React, { useEffect, useState } from 'react';
import {
  Dialog,
  CircularProgress,
  useMediaQuery,
  Box,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useResult from '../useResult';
import base64ToType from '../../../_helpers/scripts/base64ToType';
import '../../Dialogs/Dialog.scss';
import StyledDialogTitle from '../../Dialogs/StyledDialogTitle';
import ModalEmailButton from '../Dialogs/ModalEmailButton';
import calculateIframeHeight from '../../../_helpers/scripts/calculateIframeHeigth';
import DownloadResultButton from './DownloadResultButton';

const ViewResultDialog = ({
  open, onClose, barcode, patient
}) => {
  const { t } = useTranslation();
  const {
    GetResultBase64Async, message, status, SetStatusIdle
  } = useResult();
  const [view, setView] = useState(null);

  const changeIframeHeight = (numPages) => {
    const iframe = document.querySelector('#result-iframe');
    if (iframe) {
      iframe.height = calculateIframeHeight(iframe.offsetWidth, numPages);
    }
  };

  useEffect(() => {
    const fetchByResult = async () => {
      const result = await GetResultBase64Async(barcode);

      if (result) {
        const blob = base64ToType(result, 'application/pdf');
        const url = URL.createObjectURL(blob);
        setView(url);

        const pdfjs = window.pdfjsLib;
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        const loadingTask = pdfjs.getDocument(url);

        loadingTask.promise.then((loadedPdf) => {
          changeIframeHeight(loadedPdf.numPages);
        });
      }
    };

    if (open && !status.success) {
      fetchByResult();
    }
  }, [open]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const ResetIfError = () => {
    URL.revokeObjectURL(view);
    SetStatusIdle();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={status.success}
      scroll="body"
      PaperProps={{ className: 'syn-scroll' }}
      maxWidth="lg"
      fullScreen={mobile}
      TransitionProps={{
        onExited: ResetIfError,
      }}
      className="dialog"
    >
      {status.error && (
        <Box px={2} py={5}>
          <Typography align="center" variant="h6" color="error">
            {message}
          </Typography>
        </Box>
      )}
      {status.loading && (
        <Box p={4} textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      )}
      {status.success && view && (
        <>
          <StyledDialogTitle
            withIcon
            onClose={onClose}
            title={t('Result.About.Title')}
          >
            <ModalEmailButton disabled={status.error} barcode={barcode} />
            <DownloadResultButton patient={patient} barcode={barcode} view={view} />
          </StyledDialogTitle>

          <Box px={{ xs: 0, sm: 2, md: 3 }}>
            <iframe
              id="result-iframe"
              title="Результат"
              height="1754px"
              src={`${window.location.origin}/assets/web/viewer.html?file=${view}`}
              width="100%"
              allowFullScreen
              webkitallowfullscreen="true"
            />
          </Box>
        </>
      )}
    </Dialog>
  );
};

ViewResultDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  barcode: PropTypes.number.isRequired,
};

export default ViewResultDialog;
