import store from './configureStore';
import i18n from '../i18n';
import isObject from '../_helpers/scripts/isObject';

class Contract {
  static selectAllContracts = (value) => {
    const clientUnits = [];
    value.forEach((item) => {
      if (item.clientUnits?.length > 0) {
        item.clientUnits.forEach((unit) => {
          clientUnits.push({ ...unit, groupBy: item.name });
        });
      }

      return item;
    });

    const allContracts = {
      id: -1,
      name: {
        en: 'All Contracts',
        ru: 'Все контракты',
        be: 'Все контракты ',
      },
      clientUnits,
    };

    if (value.length > 0) {
      return [allContracts, ...value];
    }
    return value;
  };

  static onValueChanged = (newValue, state, callback = () => {}) => {
    if (Array.isArray(state)) {
      if (state.length > 0) {
        const allSelected = newValue.find((item) => item.id === -1);

        if (allSelected) {
          return [allSelected];
        }
      }
    }
    callback(newValue);
    return newValue;
  };

  static allUnits = {
    name: {
      en: 'All Client Units',
      ru: 'Все подразделения',
      be: 'Все подразделения ',
    },
    id: -1,
  };

  static allContracts = {
    id: -1,
    name: {
      en: 'All Contracts',
      ru: 'Все контракты',
      be: 'Все контракты ',
    },
  };

  static Name = (name) => (isObject(name) ? name[i18n.language] : name);

  /**
   * Возвращает список елементов для дальнейшего выбора их в компонентах Autocomplete и Select
   * @param {Object} contract выбраные контракты
   * @param {Object} contract.clientUnits подразделения контракта
   *
   * @param {boolean} isAllUnitSelectAllowed добавить возможность для выбора всех подразделений
   * @returns возвращает выбраные контракты и все принадлежащие им подраздиления
   */
  static getClientUnitsByContract(contract, isAllUnitSelectAllowed = false) {
    if (contract !== null) {
      let AllClientUnits = [];
      if (Array.isArray(contract)) {
        contract.map((item) => {
          if (item.clientUnits !== null) {
            item.clientUnits.map((unit) => {
              AllClientUnits.push(unit);
              return unit;
            });
          }
        });
      }
      if (contract.clientUnits) {
        contract.clientUnits.map((unit) => {
          AllClientUnits.push(unit);
          return unit;
        });
      }

      if (AllClientUnits.length > 0 && isAllUnitSelectAllowed) {
        AllClientUnits = Contract.addAllUnits(AllClientUnits);
      }
      return { contract, clientUnits: AllClientUnits };
    }

    return { contract, clientUnits: [] };
  }

  /**
   * Добавлает в подразделения локализированый елемент для выбора всех подразделений в ComboBox
   * @param {Object[]} clientUnits подразделения
   */
  static addAllUnits = (clientUnits) => [this.allUnits, ...clientUnits];

  static getContractId = (id) => {
    if (id !== -1) {
      return id;
    }
    const { contracts } = store.getState().contract;
    return contracts.map((item) => item.id);
  };

  static getClientUnitId = (clientUnitId, contract) => {
    if (clientUnitId !== -1) {
      return clientUnitId;
    }

    return contract.clientUnits.map((item) => item.id);
  };
}

export default Contract;
