import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: false,
  message: '',
};

const errorReducer = createSlice({
  initialState,
  name: 'error',
  reducers: {
    setError(state, action) {
      state.error = true;
      state.message = action.payload;
    },
    closeError(state) {
      state.error = false;
    },
  },
});

export const { setError, closeError } = errorReducer.actions;
export default errorReducer.reducer;
