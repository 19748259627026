import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import { ReactComponent as DeleteIcon } from '../img/delete.svg';
import DeleteOrderDialog from './DeleteOrderDialog';

const DeleteOrderButton = ({ barcode, hideRow }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomizedTooltip title={t('Tooltip.RemoverOrder')}>
        <IconButton onClick={handleOpen}>
          <DeleteIcon className="btn-group__img delete-icon" />
        </IconButton>
      </CustomizedTooltip>
      {open && (
        <DeleteOrderDialog
          open={open}
          hideRow={hideRow}
          onClose={handleClose}
          barcode={barcode}
        />
      )}
    </>
  );
};

DeleteOrderButton.propTypes = {
  barcode: PropTypes.number.isRequired,
  hideRow: PropTypes.func.isRequired,
};

export default DeleteOrderButton;
