import formatBarcode from '../formatBarcode';
import getExtraFields from './getExtraFields';

const getHeaderDto = (formData, services) => {
  const headerDto = {
    services,
  };

  if (formData.remarks) {
    headerDto.remarks = formData.remarks;
  }

  if (formData.paymentChannel) {
    headerDto.paymentChannel = formData.paymentChannel;
  }

  if (formData.barcode) {
    headerDto.barcode = formatBarcode(formData.barcode);
  }
  if (formData.commissionaire) {
    headerDto.commissionaireId = formData.commissionaire.id;
  }

  if (formData['Online promocode']) {
    headerDto.discountCardNo = formData['Online promocode'];
  }

  const orderExtraFields = getExtraFields(formData);
  if (orderExtraFields) headerDto.orderExtraFields = orderExtraFields;

  return headerDto;
};

export default getHeaderDto;
