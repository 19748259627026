import ReactDOM from 'react-dom';
import React from 'react';
import ConfirmDialog from './ConfirmDialog';

const UserLeaveConfirmation = (message, callback) => {
  const container = document.createElement('div');

  const handleConfirm = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
    document.getElementById('root').removeChild(container);
  };

  const handleCancel = () => {
    ReactDOM.unmountComponentAtNode(container);
    callback();
    document.getElementById('root').removeChild(container);
  };

  document.getElementById('root').appendChild(container);

  ReactDOM.render(
    <ConfirmDialog
      open
      onClose={handleCancel}
      handleConfirm={handleConfirm}
      message={message}
    />,
    container
  );
};

export default UserLeaveConfirmation;
