import React from 'react';

import Button from '@material-ui/core/Button';
import MaterialContainer from '@material-ui/core/Container';

import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { matchedRoute } from '../../_helpers/func';
import ErrorSvg from './Error500.svg';

export default function Error500() {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  return (
    <MaterialContainer component={Box} maxWidth="sm">
      <Box
        pt={{ xs: 3, md: 5, lg: 7 }}
        mb={{ xs: 2, md: 3, lg: 4 }}
        textAlign="center"
      >
        <Typography variant="h1" paragraph>
          {t('Error_500.Title')}
        </Typography>
        <Typography paragraph>{t('Error_500.Subtitle')}</Typography>
      </Box>
      <Box maxWidth={304} mb={4} mx="auto">
        <Button
          component={Link}
          to={matchedRoute(i18n.language, location.state?.from || '/')}
          fullWidth
          variant="contained"
          color="primary"
        >
          {t('Error_500.SubmitButton')}
        </Button>
      </Box>
      <MaterialContainer component="div" maxWidth="sm">
        <Box
          component="img"
          maxWidth="100%"
          src={ErrorSvg}
          alt="Error"
          width="527px"
          height="auto"
        />
      </MaterialContainer>
    </MaterialContainer>
  );
}
