import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ServiceDialog from './ServiceDialog';
import { setErrorFalse } from '../../../_reducers/basketReducer';
import useDialog from '../../../_helpers/scripts/useDialog';
import { Backdrop, CircularProgress } from '@material-ui/core';

const AddingServicesService = () => {
  const dispatch = useDispatch();

  const error = useSelector((state) => state.basket.error);
  const loading = useSelector((state) => state.basket.loading);
  const message = useSelector((state) => state.basket.message);

  const { open, handleClose, handleOpen } = useDialog();

  const closeDialog = () => {
    dispatch(setErrorFalse());
  };

  useEffect(() => {
    if (error) {
      handleOpen();
    }
  }, [error]);

  return (
    <>
      <ServiceDialog
        open={open}
        onExited={closeDialog}
        onClose={handleClose}
        error={error}
        message={message}
      />
      <Backdrop open={loading} className="backdrop__z-index">
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default AddingServicesService;
