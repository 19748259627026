import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { ReactComponent as EditIcon } from '../../img/edit.svg';
import useDialog from '../../../../_helpers/scripts/useDialog';
import DiscountStatusDialog from './DiscountStatusDialog';

const DiscountStatusButton = ({ id, resetSelected }) => {
  const { open, handleClose, handleOpen } = useDialog();
  const { t } = useTranslation();
  return (
    <>
      <Button onClick={handleOpen} startIcon={<EditIcon />} color="primary">
        {t('Common.Edit')}
      </Button>
      <DiscountStatusDialog
        resetSelected={resetSelected}
        open={open}
        onClose={handleClose}
        id={id}
      />
    </>
  );
};

export default DiscountStatusButton;
