import React from 'react';
import StyledTextField from '../StyledTextField';
import NumberFormatCustom from './NumberFormatCustom';

const StyledNumberTextField = (props) => (
  <StyledTextField
    {...props}
    fullWidth
    InputProps={{
      ...props.InputProps,
      inputComponent: NumberFormatCustom,
    }}
  />
);

export default StyledNumberTextField;
