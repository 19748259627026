import i18n from '../i18n';

function formatPhone(phoneNumberString) {
  try {
    if (!phoneNumberString) {
      return phoneNumberString;
    }
    let cleaned = phoneNumberString.replace(/\s|-/g, '');

    if (/^\D?80/.test(cleaned)) {
      cleaned = cleaned.replace(/^\D?80/, '');
    } else {
      cleaned = cleaned.replace(/^\D?375/, '');
    }

    if (cleaned.length === 9) {
      const match = cleaned.match(/^(\d{2})(\d{3})(\d{2})(\d{2})$/);
      return `+375 ${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
    }
    throw new Error(
      i18n.t('ValidateErrors.PhoneLength', { phone: phoneNumberString })
    );
  } catch (error) {
    throw new Error(
      i18n.t('ValidateErrors.PhoneFormat', { phone: phoneNumberString })
    );
  }
}

const formatPhoneToFetch = (phone) => {
  let formated = phone.replace(/\s|-/g, '');

  if (/^375/.test(formated)) {
    if (formated.length === 12) {
      return formated;
    }
  }
  if (/^\+375/.test(formated)) {
    formated = formated.replace(/^\+/, '');

    if (formated.length === 12) {
      return formated;
    }
  }
  if (/^80/.test(formated)) {
    if (formated.length === 11) {
      return formated;
    }
  }

  throw new Error(i18n.t('ValidateErrors.PhoneFormat', { phone }));
};

export default { formatPhone, formatPhoneToFetch };
