import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NestedTableRow from './NestedTableRow';
import './NestedTable.scss';

const NestedTable = ({ services, barcode }) => {
  const { t } = useTranslation();
  return (
    <>
      {services ? (
        <Table size="small" className={clsx('nested-table')}>
          <TableHead>
            <TableRow className="nested-table__row">
              <TableCell className="nested-table__cell-th">
                {t('Sort.ServiceCode')}
              </TableCell>
              <TableCell className="nested-table__cell-th">
                {t('Sort.Service')}
              </TableCell>
              <TableCell colSpan="2" className="nested-table__cell-th" />
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((item) => (
              <NestedTableRow key={item.serviceId} service={item} barcode={barcode} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table size="small" className={clsx('nested-table')}>
          <TableBody>
            <TableRow className="nested-table__row">
              <TableCell className="nested-table__cell nested-table__cell_epmty">{t('Errors.NullServices')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
};

NestedTable.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({
    serviceId: PropTypes.number,
    serviceCode: PropTypes.string,
    serviceName: PropTypes.string,
  })).isRequired,
  barcode: PropTypes.number.isRequired,
};
export default NestedTable;
