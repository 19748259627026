import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from '../../Dialogs/img/add.svg';
import './NewPatient.scss';
import CreatePatientDialog from './CreatePatientDialog';

const NewPatientButton = ({ disabled, savePatient }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        disabled={disabled}
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        className="new-patient__btn"
        onClick={handleOpen}
      >
        {t('Patient.AddPatientButton')}
      </Button>
      {open && (
        <CreatePatientDialog
          savePatient={savePatient}
          open={open}
          onClose={handleClose}
        />
      )}
    </>
  );
};

NewPatientButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  savePatient: PropTypes.func.isRequired,
};

export default NewPatientButton;
