import { Switch, withStyles } from '@material-ui/core';

const color = '#0C9C00';
const disabled = '#BDBDBD';

const ColorSwitch = withStyles({
  switchBase: {
    color: disabled,
    '&$checked': {
      color: color,
    },
    '&$checked + $track': {
      backgroundColor: color,
    },
  },
  checked: {},
  track: {},
})(Switch);

export default ColorSwitch;
