import React from 'react';
import PropTypes from 'prop-types';
import InfoAgreement from '../../InfoAgreement/InfoAgreement';
import useApiContracts from '../../Contract/useApiContracts';

const PatientInfoReport = ({ patientId }) => {
  const contracts = useApiContracts();

  return (
    <InfoAgreement
      patientId={patientId}
      headerContract={contracts}
      className="patient-table__cell--icon"
    />
  );
};

PatientInfoReport.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default React.memo(PatientInfoReport);
