import { Grid } from '@material-ui/core';
import React from 'react';
import ClientUnitField from '../../Contract/ClientUnitField';
import ContractField from '../../Contract/ContractField';
import useContract from '../../Contract/useContract';

const PatientContractFields = () => {
  const { currentContract } = useContract();

  return (
    <>
      <Grid item className="patient__grid-item" xs={12} sm={6} md={3} lg={2}>
        <ContractField variant="filled" />
      </Grid>
      <Grid item className="patient__grid-item" xs={12} sm={6} md={3} lg={2}>
        <ClientUnitField disabled={!currentContract} variant="filled" />
      </Grid>
    </>
  );
};

export default PatientContractFields;
