import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { ReactComponent as EditIcon } from '../../img/edit.svg';
import useDialog from '../../../../_helpers/scripts/useDialog';
import DiscountPrefixStatusDialog from './DiscountPrefixStatusDialog';

const DiscountPrefixStatusButton = ({ id, resetSelected }) => {
  const { open, handleClose, handleOpen } = useDialog();
  const { t } = useTranslation();
  return (
    <>
      <Button onClick={handleOpen} startIcon={<EditIcon />} color="primary">
        {t('Common.Edit')}
      </Button>
      <DiscountPrefixStatusDialog resetSelected={resetSelected} open={open} onClose={handleClose} id={id} />
    </>
  );
};

DiscountPrefixStatusButton.propTypes = {
  id: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default DiscountPrefixStatusButton;
