import { makeStyles } from '@material-ui/core';
import theme from './MuiTheme';

export const useStyles = makeStyles(() => ({
  grid: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#FFFFFF',
    borderRadius: '11px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    color: '#758AB0',
    marginLeft: '0px !important',
    '&.MuiInputLabel-formControl': {
      transform: 'translate(0, 15px) scale(1)',
    },
    '&.MuiInputLabel-shrink': {
      transform: 'translate(0, 8px) scale(0.75)',
    },
    '&.Mui-focused': {
      color: '#758AB0',
    },
    '&.MuiFormLabel-root.Mui-error': {
      color: '#B00020',
    },
  },
  iconSelect: {
    color: '#00539F',
    right: 21,
  },
  filled: {
    paddingLeft: 0,
  },
  input: {
    padding: ['22px 12px 7px 0px'],
  },
  menuPaper: {
    borderRadius: 11,
  },
  menuList: {
    borderRadius: 20,
    '&.MuiListItem-root.Mui-selected': {
      borderRadius: 20,
      color: '#00539F',
    },
  },
  theLastGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const styles = {
  formHelperText: {
    color: theme.palette.error.main,
    marginLeft: 15,
    height: 16,
    position: 'relative',
    top: '-5px',
  },
};

export const useFilledInputStyles = makeStyles(() => ({
  input: {
    backgroundColor: `${theme.palette.blueLightNew.main} !important`,
    '& .MuiFilledInput-input': {
      borderRadius: '11px',
      color: theme.palette.text.main,
      '&.MuiSelect-select:focus': {
        backgroundColor: `${theme.palette.blueLight.main} !important`,
        borderRadius: '11px',
      },
      padding: ['21px 24px 8px'],
    },
  },
  card: {
    backgroundColor: `${theme.palette.background.main} !important`,
  },
  label: {
    color: theme.palette.title.main,
    '&.Mui-focused': {
      color: theme.palette.title.main,
    },
    '&.MuiFormLabel-root.Mui-error': {
      color: '#B00020',
    },
    '&.MuiInputLabel-formControl': {
      transform: 'translate(24px, 15px) scale(1)',
    },
    '&.MuiInputLabel-shrink': {
      transform: 'translate(24px, 8px) scale(0.75)',
    },
  },
  paper: {
    borderRadius: 11,
    border: `1px solid ${theme.palette.greyLight.main}`,
  },
  icon: {
    color: theme.palette.title.main,
  },
  formHelper: {
    position: 'absolute',
    top: 45,
  },
  datePickerInput: {
    backgroundColor: theme.palette.blueLightNew.main,
    borderRadius: '11px',
    color: theme.palette.text.main,
    paddingLeft: 24,
    '& .MuiButtonBase-root': {
      color: theme.palette.title.main,
      position: 'relative',
      top: -7,
    },
  },
  cardDatePicker: {
    backgroundColor: `${theme.palette.background.main} !important`,
  },
}));
