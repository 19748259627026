import { Tooltip, withStyles } from '@material-ui/core';

const CustomizedTooltip = withStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(2),
    borderRadius: '11px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow:
      '0px 3px 6px rgba(75, 81, 91, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.15)',
    fontSize: 16,
    lineHeight: '19px',
  },
}))(Tooltip);

export default CustomizedTooltip;
