import { useSelector } from 'react-redux';

const useRole = (allowedRoles) => {
  const role = useSelector((state) => state.userReducer.userRoles);

  if (role) {
    const access = allowedRoles.indexOf(role.id) !== -1;
    return access;
  }
  return false;
};

export default useRole;
