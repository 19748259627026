import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import { ReactComponent as DeleteIcon } from '../../Sort/img/delete.svg';
import useServices from '../ServicesTable/useServices';

const DeleteButton = ({ code }) => {
  const { DeleteService } = useServices();
  const { t } = useTranslation();

  const isPreOrder = useSelector((state) => state.basket.isPreOrder)

  const handleDelete = async () => {
    DeleteService(code, false, true);
  };

  return (
    <CustomizedTooltip title={t('Tooltip.RemoveService')}>
      <IconButton
        color="primary"
        disabled={isPreOrder}
        size="small"
        className="service-list__basket-btn"
        onClick={handleDelete}
      >
        <DeleteIcon />
      </IconButton>
    </CustomizedTooltip>
  );
};

DeleteButton.propTypes = {
  code: PropTypes.string.isRequired,
};

export default DeleteButton;
