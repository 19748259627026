import { createSlice } from '@reduxjs/toolkit';
import fetchStatus from '../_constants/fetchStatus';

const initialState = {
  rows: [],
  previousData: null,
  pageSize: 10,
  page: 0,
  maxPage: 0,
  previousBody: null,
  loading: false,
  message: '',
  searchValue: '',
  total: 300,
  status: fetchStatus.IDLE,
  filterData: {
    lastName: '',
    firstName: '',
    email: '',
    dateOfBirth: '',
    phone: '',
    gender: '',
  },
};

const patientReducer = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setPage(state, action) {
      state.page = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
      state.page = 0;
    },
    setError(state, action) {
      state.error = action.payload.error;
      state.message = action.payload.message;
    },
    setMaxPage(state, action) {
      state.maxPage = action.payload;
    },
    setSearchValue(state, action) {
      state.page = 0;
      state.searchValue = action.payload;
    },
    resetPagination(state) {
      state.page = 0;
      state.rows = [];
      state.status = fetchStatus.IDLE;
    },
    setStatus(state, action) {
      const status = action.payload;
      state.status = status;
      if (status === fetchStatus.REJECTED) {
        state.rows = [];
      }
    },
    setRows(state, action) {
      state.rows = action.payload.rows;
      state.total = action.payload.total;
    },
    setFilterData(state, action) {
      state.filterData = action.payload;
      state.page = 0;
    },
    setPatients(state, action) {
      state.rows = action.payload;
    }
  },
});

export const {
  setPage,
  setPageSize,
  setError,
  setTotalCount,
  setSearchValue,
  resetPagination,
  setStatus,
  setRows,
  setFilterData,
  setPatients,
} = patientReducer.actions;
export default patientReducer.reducer;
