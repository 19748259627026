import {
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ApiClient from '../../../../ApiClient/ApiClient';
import errorHelper from '../../../../_constants/errorHelper';
import fetchStatus from '../../../../_constants/fetchStatus';
import useDialog from '../../../../_helpers/scripts/useDialog';
import ConfirmDialog from '../../../Dialogs/ConfirmDialog/ConfirmDialog';
import ColorSwitch from '../../_components/ColorSwitch';
import { setPrefixes } from '../../../../_reducers/prefixReducer';
import replaceItems from '../_helpers/replaceItems';

const CardPrefixSwitch = ({
  value,
  onChange,
  onSuccess,
  id,
  disabled,
  end,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Admin');
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const [message, setMessage] = useState('');
  const [checkedValue, setCheckedValue] = useState(null);
  const prefixes = useSelector((state) => state.prefix.prefixes);

  const { open, handleClose, handleOpen } = useDialog();
  const error = status === fetchStatus.REJECTED;
  const loading = status === fetchStatus.PENDING;

  const fetchByChangeStatus = async (val) => {
    setStatus(fetchStatus.PENDING);
    const fetchData = {
      prefixIds: id,
      isActive: val,
    };
    return ApiClient.ChangeDiscountPrefixStatus(fetchData)
      .then((res) => {
        if (res) {
          if (res.isSuccess) {
            if (onSuccess) {
              onSuccess();
            }
            setStatus(fetchStatus.FULFILLED);
            return val;
          }
          throw new Error(res.statusMessage);
        }
        throw new Error(errorHelper.common);
      })
      .catch((e) => {
        setStatus(fetchStatus.REJECTED);
        setMessage(e.message);
        return !val;
      });
  };

  const handleSubmit = async (val) => {
    const responseValue = await fetchByChangeStatus(val);
    if (onChange) {
      onChange(responseValue);
    }

    const newPrefixes = replaceItems(responseValue, prefixes, id);
    dispatch(setPrefixes(newPrefixes));
  };

  const handleChange = (e) => {
    setCheckedValue(e.currentTarget.checked);
    handleOpen();
  };

  const titleMessage = checkedValue
    ? t('Discount.StatusDialog.ActivateSelected', { context: 'prefix' })
    : t('Discount.StatusDialog.DeactivateSelected', { context: 'prefix' });

  const submitMessage = checkedValue
    ? t('Discount.StatusDialog.Activate')
    : t('Discount.StatusDialog.Deactivate');
  return (
    <>
      <FormControl error={error} className="discount-switch">
        <FormControlLabel
          control={(
            <ColorSwitch
              checked={value}
              onChange={handleChange}
              disabled={disabled || loading}
            />
          )}
          label={value ? t('Control.active') : t('Control.nonActive')}
          labelPlacement={end ? 'end' : 'bottom'}
        />

        {error && <FormHelperText>{message}</FormHelperText>}
      </FormControl>
      {open && (
        <ConfirmDialog
          open={open}
          onClose={handleClose}
          value={checkedValue}
          title={titleMessage}
          submitMessage={submitMessage}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

CardPrefixSwitch.propTypes = {
  onChange: PropTypes.func,
  end: PropTypes.bool,
};

CardPrefixSwitch.defaultProps = {
  onChange: undefined,
};

export default CardPrefixSwitch;
