import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import fetchStatus from '../../../_constants/fetchStatus';
import dateHelper from '../../../_helpers/dateHelper';
import mobilePhoneHelper from '../../../_helpers/mobilePhoneHelper';
import { ReactComponent as PrintIcon } from '../../../_icons/print.svg';
import useContract from '../../Contract/useContract';
import AlertDialog from '../../Dialogs/AlertDialog';
import Direction from '../../Direction/Direction';
import DirectionToPrint from '../../Direction/DirectionToPrint';

const OrderDirectionButton = ({ caclData }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const [message, setMessage] = useState('');
  const { currentContract } = useContract();
  const handleClose = () => setStatus(fetchStatus.IDLE);
  const patient = useSelector((s) => s.basket.patient);

  const [data, setData] = useState(null);

  const handlePrint = () => {
    try {
      const { serviceInfo, biomaterials } = caclData;

      const phone = mobilePhoneHelper.formatPhone(
        patient.mobilePhone || patient.phone
      );

      const formatedPatinet = {
        ...patient,
        phone,
      };

      setData({
        directions: [
          {
            barcode: null,
            sumPrice: serviceInfo.totalPrice,
            workingDays: serviceInfo.totalWorkingDays,
            patient: formatedPatinet,
            contract: currentContract,
            services: serviceInfo.services,
            tubes: biomaterials.substances,
            createdDate: dateHelper.formatDateToStandart(new Date()),
          },
        ],
      });
      setStatus(fetchStatus.FULFILLED);
    } catch (e) {
      setMessage(e.message);
      setStatus(fetchStatus.REJECTED);
    }
  };

  return (
    <>
      <Button
        startIcon={<PrintIcon width="24px" height="24px" />}
        color="primary"
        onClick={handlePrint}
      >
        {t('Tooltip.Direction')}
      </Button>
      {status === fetchStatus.FULFILLED && (
        <Direction
          ordering
          onClose={handleClose}
          component={DirectionToPrint}
          defaultData={data}
        />
      )}
      {status === fetchStatus.REJECTED && (
        <AlertDialog
          open={status === fetchStatus.REJECTED}
          onClose={handleClose}
          message={message}
          error
        />
      )}
    </>
  );
};

export default OrderDirectionButton;
