import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../ApiClient/ApiClient';
import fetchStatus from '../_constants/fetchStatus';

const initialState = {
  status: fetchStatus.IDLE,
  generationType: [],
  message: '',
};

const fetchByPrefixGenerationType = createAsyncThunk(
  'discountPrefixGenerationType/fetch',
  async (_, { rejectWithValue }) => ApiClient.DiscountPrefixGenerationType()
    .then((res) => {
      if (res?.length > 0) {
        return res;
      }
      // TODO: локализировать
      return rejectWithValue(
        'Произошла ошибка при получении типов генерации!'
      );
    })
    .catch((e) => rejectWithValue(
      'Произошла ошибка при получении типов генерации!'
    ))
);

const prefixGenerationTypeReducer = createSlice({
  name: 'discountPrefixGenerationType',
  initialState,
  extraReducers: {
    [fetchByPrefixGenerationType.pending]: (state) => {
      state.status = fetchStatus.PENDING;
    },
    [fetchByPrefixGenerationType.fulfilled]: (state, action) => {
      state.status = fetchStatus.FULFILLED;
      state.generationType = action.payload;
    },
    [fetchByPrefixGenerationType.rejected]: (state, action) => {
      state.status = fetchStatus.REJECTED;
      state.message = action.payload;
    },
  },
});

export { fetchByPrefixGenerationType };
export default prefixGenerationTypeReducer.reducer;
