import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import setInputFilter from '../../../_helpers/scripts/setInputFilter';
import BaseInput from '../../Inputs/BaseFilledInput/BaseInput';

const MiddleNameField = ({ onChange, value }) => {
  const { t } = useTranslation();
  const onFieldChange = () => {
    setInputFilter(
      document.getElementById('MiddleNameFieldId'),
      (val) => /^[^0-9\s]*$/i.test(val),
      onChange
    );
  };
  return (
    <BaseInput
      label={t('Input.MiddleName')}
      name="MiddleName"
      id="MiddleNameFieldId"
      onChange={onFieldChange}
      value={value}
    />
  );
};

MiddleNameField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default MiddleNameField;
