import React from 'react';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = ({ inputRef, onChange, name, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={onChange}
    isNumericString
  />
);

export default NumberFormatCustom;
