import {
  Button, Grid, useTheme, useMediaQuery
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LazyImg from '../../../_helpers/LazyImg';
import image from '../img/EndImage.svg';
import './OrderingCompleted.scss';

import { ReactComponent as AddIcon } from '../../../_icons/add.svg';
import useLangRoute from '../../../_helpers/scripts/useLangRoute';
import { AppRoute } from '../../../const/app-routes';
import PrintBarcodeButton from './PrintBarcodeButton';
import PrintDirectionButton from './PrintDirectionButton';
import {
  resetBasket,
} from '../../../_reducers/basketReducer';
import { setSearchValue } from '../../../_reducers/patientReducer';

const OrderingCompleted = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const route = useLangRoute();
  const createdDate = useSelector((state) => state.basket.createdDate);
  const barcode = useSelector((state) => state.direction.barcode);

  useEffect(
    () => () => {
      dispatch(setSearchValue(''));
      dispatch(resetBasket());
    },
    []
  );

  return (
    <div className="order-complete">
      <div className="order-complete__image-container">
        <LazyImg
          src={image}
          className="order-complete__image"
          width="400px"
          height="400px"
        />
      </div>
      <div className="order-complete__content">
        <h1 className="order-complete__title">
          {t('CreateOrder.Complete.Title')}
          &nbsp;
          <span className="order-complete__primary-color">
            {barcode}
          </span>
          &nbsp;
          {t('CreateOrder.Complete.Title1')}
        </h1>

        <h2 className="order-complete__sub-title order-complete__text">
          <span className="order-complete__primary-color">
            {t('CreateOrder.Complete.Subtitle', {
              barcode,
            })}
          </span>
          {createdDate
            && t('CreateOrder.Complete.Subtitle1', {
              date: format(new Date(createdDate), 'dd.MM.yyyy HH:mm:ss'),
            })}
        </h2>
        <p className="order-complete__text order-complete__sub-title">
          {t('CreateOrder.Complete.text')}
        </p>
        <p className="order-complete__text order-complete__text--bold">
          {t('CreateOrder.Complete.thx')}
        </p>
        <Grid
          container
          className="order-complete__grid"
          spacing={mobile ? 1 : 3}
        >
          <Grid item xs={12} md={6}>
            <PrintBarcodeButton />
          </Grid>
          <Grid item xs={12} md={6}>
            <PrintDirectionButton />
          </Grid>
          <Grid item xs={12}>
            <Button
              component={Link}
              to={route(AppRoute.CreateOrder)}
              color="primary"
              startIcon={<AddIcon />}
              fullWidth
              variant="contained"
            >
              {t('CreateOrder.CreateNewOrder')}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OrderingCompleted;
