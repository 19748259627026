import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import setInputFilter from '../../../../_helpers/scripts/setInputFilter';
import CustomOutlinedInput from '../../../Inputs/BaseOutlinedInput/CustomOutlinedInput';

const FullNameField = ({ value, onChange, ...props }) => {
  const onFieldChange = () => {};

  useEffect(() => {
    setInputFilter(
      document.getElementById('fullNameId'),
      (val) => /^[^0-9]*$/.test(val),
      onChange
    );
  }, [value]);

  return (
    <CustomOutlinedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={value}
      onChange={onFieldChange}
      id="fullNameId"
    />
  );
};

FullNameField.propTypes = {
  value: PropTypes.string.isRequired, onChange: PropTypes.func.isRequired
};

export default FullNameField;
