import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { SvgIcon } from '@material-ui/core';
import './Stepper.scss';

const StepIcon = React.forwardRef((props, ref) => {
  const { completed = false, icon, active = false } = props;

  const className = clsx('stepper__icon', {
    stepper__icon_active: active,
    stepper__icon_completed: completed,
  });

  const textStyles = clsx('stepper_text', {
    stepper_text_active: active || completed,
  });

  return (
    <SvgIcon className={className} ref={ref}>
      <circle cx="12" cy="12" r="12" />
      <text className={textStyles} x="12" y="16" textAnchor="middle">
        {icon}
      </text>
    </SvgIcon>
  );
});

StepIcon.propTypes = {
  completed: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
};

export default StepIcon;
