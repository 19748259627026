import i18n from '../i18n';
import emailPattern from './emailPattern';
import regexpHelper from './regexpHelper';

const phoneNumber = (value) => {
  if (value && regexpHelper.phoneClean.test(value.replace(/\s/gm, ''))) {
    return true;
  }
  return i18n.t('ValidateErrors.PhonePattern');
};

const email = (value) => {
  if (value) {
    if (emailPattern.test(value)) {
      return true;
    }
    return i18n.t('ValidateErrors.Email');
  }
  return null;
};

const validationHelper = {
  phoneNumber,
  email,
};

export default validationHelper;
