import { FormHelperText } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ApiClient from '../../../ApiClient/ApiClient';
import fetchStatus from '../../../_constants/fetchStatus';
import useApiContracts from '../../Contract/useApiContracts';
import CustomAutocomplete from '../../Inputs/CustomAutocomplete';

const Commissionaires = () => {
  const { t } = useTranslation();
  const { control, errors, setValue } = useFormContext();
  const [status, setStatus] = useState(fetchStatus.PENDING);
  const [message, setMessage] = useState();
  const contracts = useApiContracts();

  const commisionaireId = useSelector(
    (state) => state.basket.order?.commissionaireId
  );
  const isEditOrder = useSelector((state) => state.basket.editOrder);

  const [commissionaires, setCommissionaires] = useState([]);

  useEffect(() => {
    const GetCommisionairesAsync = async () => {
      await ApiClient.Commissionaires(contracts)
        .then((res) => {
          if (res.isSuccess) {
            if (res.commissionaires?.length > 0) {
              setCommissionaires(res.commissionaires);
            }
            setStatus(fetchStatus.FULFILLED);
          } else {
            throw new Error(res.statusMessage);
          }
        })
        .catch((e) => {
          setMessage(e.message);
          setStatus(fetchStatus.REJECTED);
        });
    };

    if (status === fetchStatus.PENDING) {
      GetCommisionairesAsync();
    }
  }, [status, contracts]);

  useEffect(() => {
    if (isEditOrder && commisionaireId && status === fetchStatus.FULFILLED) {
      const commisionaire = commissionaires.find(
        (item) => item.id === commisionaireId
      );
      if (commisionaire) {
        setValue('commissionaire', commisionaire, { shouldDirty: false });
      }
    }
  }, [status, commissionaires, isEditOrder, commisionaireId]);

  return (
    <div className="order-form__item">
      <Controller
        name="commissionaire"
        control={control}
        defaultValue={null}
        render={({ value, onChange }) => (
          <CustomAutocomplete
            errors={errors.commissionaire}
            filled
            value={value}
            onChange={onChange}
            label={t('Input.Doctor')}
            options={commissionaires}
            getOptionLabel={(option) => option && `${option.lastName} ${option.firstName}`}
          />
        )}
      />
      {status === fetchStatus.REJECTED ? (
        <FormHelperText color="error">
          {message || t('Errors.NoDoctors')}
        </FormHelperText>
      ) : null}
    </div>
  );
};

export default Commissionaires;
