import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Row from './Row';

import MobileRow from './MobileTable/MobileRow';
import fetchStatus from '../../_constants/fetchStatus';
import {
  setPage,
  setPageSize,
  setSelected,
} from '../../_reducers/newSortReducer';
import tablePaginationHelper from '../../_helpers/tablePaginationHelper';
import SortTableHead from './SortTableHead';

const SortTable = () => {
  const rows = useSelector((state) => state.sort.orders);
  const selected = useSelector((state) => state.sort.selected);
  const totalCount = useSelector((state) => state.sort.totalCount);
  const rowsPerPage = useSelector((state) => state.sort.pageSize);
  const page = useSelector((state) => state.sort.page);
  const status = useSelector((state) => state.sort.status);

  const success = status === fetchStatus.FULFILLED;

  const dispatch = useDispatch();

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    dispatch(setSelected(newSelected));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setPageSize(parseInt(event.target.value, 10)));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderRows = rows.map((row) => {
    const isItemSelected = isSelected(row.barcode);
    const labelId = `enhanced-table-checkbox-${row.barcode}`;
    if (mobile) {
      return (
        <MobileRow
          key={row.barcode}
          setSelected={handleClick}
          row={row}
          labelId={labelId}
          isItemSelected={isItemSelected}
        />
      );
    }
    return (
      <Row
        key={row.barcode}
        setSelected={handleClick}
        row={row}
        labelId={labelId}
        isItemSelected={isItemSelected}
      />
    );
  });

  if (success) {
    return (
      <>
        <TableContainer component="div">
          <Table className="services-table">
            <SortTableHead />
            <TableBody className="services-table__body">{renderRows}</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="services-table__pagination"
          rowsPerPageOptions={tablePaginationHelper.rowsPerPageOptions}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  }
  return null;
};

export default SortTable;
