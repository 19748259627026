import React from 'react';
import { TableSortLabel, makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    color: `${theme.palette.title.main}`,
    '&:hover': {
      color: `${theme.palette.greyDark.main}`,
    },
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
    '& $icon': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  icon: {},
}));

const StyledTableSortLabel = (props) => {
  const classes = styles();
  return <TableSortLabel {...props} classes={classes} />;
};

export default StyledTableSortLabel;
