import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const SortState = ({ allowedState, children }) => {
  const sortingState = useSelector((state) => state.sort.sortingState);

  const isAllowed = allowedState.indexOf(sortingState) !== -1;
  return isAllowed && children;
};

SortState.propTypes = {
  allowedState: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SortState;
