import i18n from '../i18n';

const common = i18n.t('Errors.BadRequest');
const ordersNotFound = i18n.t('Errors.OrdersNotFound');
const selectContract = i18n.t('Errors.SelectContract');
const selectUser = i18n.t('Errors.SelectUser');
const direction = (barcodes) => i18n.t('Errors.direction', { barcodes });
const required = i18n.t('ValidateErrors.Required');
const emailIncorrect = i18n.t('ValidateErrors.Email');
const invalidDate = i18n.t('ValidateErrors.DateFormat');

const errors = {
  common,
  required,
  direction,
  ordersNotFound,
  selectContract,
  selectUser,
  emailIncorrect,
  invalidDate,
};

export default errors;
