import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppRoute } from '../../const/app-routes';
import useLangRoute from '../../_helpers/scripts/useLangRoute';
import '../../_helpers/styles/Header.scss';
import HeaderLink from '../Settings/Link/HeaderLink';
import Fallback from '../_components/Fallback';
import ArticlePage from './Article/ArticlePage';
import FAQ from './FAQ/FAQ';
import NewsPage from './Info/NewsPage';

const DirectoryPage = React.lazy(() => import('../Directory/DirectoryPage'));

const News = () => {
  const route = useLangRoute();
  const { t } = useTranslation();

  return (
    <>
      <header className="header">
        <div className="header__wrapper">
          <h1 className="header__title">{t('Info.Title')}</h1>
          <div className="header__link-group">
            <HeaderLink
              label={t('Info.Nav.item')}
              link={AppRoute.InfoRoutes.News}
            />
            <HeaderLink
              label={t('Info.Nav.item_1')}
              link={AppRoute.InfoRoutes.Manual}
            />
            <HeaderLink
              label={t('Info.Nav.item_3')}
              link={AppRoute.InfoRoutes.FAQ}
            />
          </div>
        </div>
      </header>
      <Suspense fallback={<Fallback />}>
        <Switch>
          <Route
            exact
            path={route(AppRoute.News)}
            render={() => <Redirect to={route(AppRoute.InfoRoutes.News)} />}
          />
          <Route exact path={route(AppRoute.InfoRoutes.FAQ)} component={FAQ} />
          <Route
            exact
            path={route(AppRoute.InfoRoutes.News)}
            component={NewsPage}
          />
          <Route
            exact
            path={route(`${AppRoute.InfoRoutes.Article}/:article`)}
            component={ArticlePage}
          />
          <Route
            exact
            path={route(AppRoute.InfoRoutes.Manual)}
            component={DirectoryPage}
          />
          <Redirect to={route(AppRoute.Error404)} />
        </Switch>
      </Suspense>
    </>
  );
};

export default News;
