import React from 'react';
import { TextField, withStyles } from '@material-ui/core';

// .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      color: (props) => (props.disabled ? theme.palette.text.main : 'unset'),
    },
    // filled
    '& .MuiFilledInput-root': {
      borderRadius: 11,
      backgroundColor: (props) => (props.disabled
        ? theme.palette.background.main
        : 'rgba(230, 236, 245, 1)'),
      '&:hover': {
        backgroundColor: (props) => (props.disabled ? theme.palette.background.main : '#ccd6e3'),
      },
    },
    '& .MuiFilledInput-input': {
      padding: (props) => (props?.InputProps?.startAdornment
        ? '21px 10px 8px 16px'
        : '21px 10px 8px 24px'),
    },
    '& .MuiInputLabel-filled': {
      transform: 'translate(24px, 12px) scale(1)',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: (props) => (props?.InputProps?.startAdornment
        ? 'translate(56px, 6px) scale(0.75)'
        : 'translate(24px, 6px) scale(0.75)'),
    },
    '& .MuiFilledInput-multiline': {
      padding: '0px',
    },
    '& .MuiFilledInput-inputMultiline': {
      padding: 0,
    },
    '& .MuiSelect-iconFilled': {
      color: theme.palette.title.main,
    },
    // label
    '& .MuiFormLabel-root': {
      color: (props) => (props.light ? theme.palette.greyDark.main : theme.palette.title.main),
    },

    '& .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.greyDark.main,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.error.main,
    },
    '& .MuiFilledInput-adornedStart': {
      paddingLeft: 16,
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart': {
      '&:not(.MuiInputAdornment-hiddenLabel)': {
        marginTop: 0,
      },
    },

    // outlined
    '& .MuiOutlinedInput-root': {
      transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      borderRadius: 11,

      backgroundColor: (props) => (props.disabled ? theme.palette.background.main : 'rgba(0, 0, 0, 0)'),

      boxShadow: (props) => (props.disabled ? 'none' : '0px 0px 4px rgba(0, 0, 0, 0.25)'),
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '21px 16px 8px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparrent',
      border: 'none',
    },
    '& .MuiInputLabel-outlined': {
      transform: (props) => {
        if (props.multiple) {
          return 'translate(16px, 22px) scale(1)';
        }
        return 'translate(16px, 12px) scale(1)';
      },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: (props) => {
        if (props.multiple) {
          return 'translate(16px, 6px) scale(0.75)';
        }
        return props?.InputProps?.startAdornment
          ? 'translate(56px, 6px) scale(0.75)'
          : 'translate(16px, 6px) scale(0.75)';
      },
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 16,
    },
    '& .MuiOutlinedInput-inputMultiline': {
      padding: 0,
    },
    // Adornment
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
    },
    // filled autocomplete
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: 0,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input':
      {
        padding: '21px 10px 8px 16px',
      },
    '& .MuiAutocomplete-clearIndicator, & .MuiAutocomplete-popupIndicator': {
      color: theme.palette.primary.main,
    },
    // TODO: outlined autocomplete
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: (props) => (props.multiple ? '20px 0px 8px 8px' : 1),
      '& .MuiAutocomplete-input': {
        padding: (props) => (props.multiple ? '10px 8px' : '22px 10px 6px 16px'),
      },
    },
    // select
    '& .MuiSelect-selectMenu': {
      height: '19px',
      borderRadius: 11,
    },
    // formHelperText
    '& .MuiFormHelperText-root': {
      position: (props) => (props.CustomTextHelperPosition ? 'absolute' : 'static'),
      top: (props) => (props.CustomTextHelperPosition ? '48px' : undefined),
      marginLeft: (props) => (props.variant === 'outlined' ? 16 : 24),
    },
    '& .MuiFilledInput-root + .MuiFormHelperText-root': {},
  },
}))(TextField);

export default StyledTextField;
