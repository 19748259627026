import { CircularProgress, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LazyImg from '../../../_helpers/LazyImg';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import Direction from '../../Direction/Direction';
import PrintDoneIcon from '../../Direction/img/print_done.svg';
import DirectionToPrint from '../../Direction/DirectionToPrint';
import useSortContracts from '../useSortContracts';

const PrintDirectionButton = ({ barcode }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const contracts = useSortContracts();

  return (
    <>
      <CustomizedTooltip title={t('Tooltip.Direction')}>
        <IconButton onClick={handleOpen}>
          {(loading && (
            <CircularProgress style={{ width: 24, height: 24 }} />
          )) || (
            <LazyImg
              className="btn-group__img"
              src={PrintDoneIcon}
              width="24px"
              height="24px"
              alt="Распечатать результаты"
            />
          )}
        </IconButton>
      </CustomizedTooltip>
      {open && (
        <Direction
          onClose={handleClose}
          barcode={barcode}
          contracts={contracts}
          setLoading={setLoading}
          loading={loading}
          component={DirectionToPrint}
        />
      )}
    </>
  );
};

PrintDirectionButton.propTypes = {
  barcode: PropTypes.number.isRequired,
};

export default PrintDirectionButton;
