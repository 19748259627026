import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clearBasket } from '../../../_reducers/basketReducer';

import '../ServicesTable/ServiceList.scss';
import './Basket.scss';
import basketHeadCells from './basketHeadCells';
import CalcButton from './CalcButton';
import DeleteButton from './DeleteButton';


const BasketTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.basket.addedServices);
  const parrentCodes = useSelector((state) => state.basket.parrentCodes);
  const hadServices = rows.length > 0;

  const handleClear = () => {
    dispatch(clearBasket());
  };

  return hadServices ? (
    <>
      <TableContainer className="basket__service-container syn-scroll">
        <Table stickyHeader className="service-list">
          <TableHead>
            <TableRow>
              {basketHeadCells.map((cell) => (
                <TableCell
                  key={cell.id}
                  className="basket__cell basket__head-cell"
                  id={cell.id}
                >
                  {t(cell.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.code} className="service-list__row">
                <TableCell className="basket__cell">{row.code}</TableCell>
                <TableCell className="basket__cell">{row.name}</TableCell>
                <TableCell className="basket__cell">{row.price}</TableCell>
                <TableCell className="basket__cell">
                  {row.workingDays}
                </TableCell>
                <TableCell className="basket__cell service-list__cell--btn">
                  {parrentCodes.indexOf(row.code) !== -1 ? (
                    <DeleteButton code={row.code} />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="basket__btn-group">
        <CalcButton />
        <Button onClick={handleClear} color="primary">
          {t('CreateOrder.Basket.clear')}
        </Button>
      </div>
    </>
  ) : null;
};

export default BasketTable;
