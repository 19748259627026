import {
  Box, Checkbox, CircularProgress, TableCell
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import ApiClient from '../../../ApiClient/ApiClient';
import fetchStatus from '../../../_constants/fetchStatus';
import { sortState } from '../../../_constants/OrderStatusConst';
import { setSelected } from '../../../_reducers/newSortReducer';
import SortState from '../SortState';
import useSortContracts from '../useSortContracts';

const TableHeadCheckbox = ({
  className,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [status, setStatus] = useState(fetchStatus.IDLE);
  const contracts = useSortContracts();
  const requestBody = useSelector((state) => state.sort.body);
  const totalCount = useSelector((state) => state.sort.totalCount);
  const numSelected = useSelector((state) => state.sort.selected.length);
  const rowCount = useSelector((state) => state.sort.orders.length);

  const dispatch = useDispatch();

  const onSelectAllClick = async (event) => {
    if (event.target.checked) {
      setStatus(fetchStatus.PENDING);

      const token = await executeRecaptcha();

      const bodyWithPagination = {
        ...requestBody,
        pageNumber: 1,
        pageSize: totalCount,
      };

      const barcodes = await ApiClient.Sorting(
        bodyWithPagination,
        token,
        contracts
      )
        .then((res) => {
          setStatus(fetchStatus.FULFILLED);
          return res.orders.map((o) => o.barcode);
        })
        .catch((e) => {
          setStatus(fetchStatus.REJECTED);
          return [];
        });

      return dispatch(setSelected(barcodes));
    }
    return dispatch(setSelected([]));
  };

  const loading = status === fetchStatus.PENDING;
  return (
    <>
      <SortState allowedState={[sortState.NEW, sortState.IMPORTED]}>
        <TableCell
          padding="checkbox"
          className={clsx(className, 'services-table__th')}
        >
          <Checkbox
            size="small"
            disabled={loading}
            color="primary"
            className="services-table__checkbox"
            disableRipple
            indeterminate={numSelected > 0 && numSelected < totalCount}
            checked={rowCount > 0 && numSelected === totalCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
          {loading && (
            <Box
              position="absolute"
              component="span"
              style={{ transform: 'translate(-10px)' }}
            >
              <CircularProgress style={{ width: 16, height: 16 }} />
            </Box>
          )}
        </TableCell>
      </SortState>
    </>
  );
};

export default TableHeadCheckbox;
