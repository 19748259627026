import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useDialog from '../../../_helpers/scripts/useDialog';
import CustomizedTooltip from '../../CustomizedTooltip/CustomizedTooltip';
import { ReactComponent as ReloadIcon } from '../img/reload.svg';
import RestoreServiceDialog from './RestoreOrderDialog';

const RestoreOrderButton = ({ barcode, hideRow }) => {
  const [t] = useTranslation();

  const { open, handleClose, handleOpen } = useDialog();

  return (
    <>
      <CustomizedTooltip title={t('Tooltip.RestoreService')}>
        <IconButton onClick={handleOpen}>
          <ReloadIcon className="btn-group__img delete-icon" />
        </IconButton>
      </CustomizedTooltip>
      {open && (
        <RestoreServiceDialog
          open={open}
          hideRow={hideRow}
          onClose={handleClose}
          barcode={barcode}
        />
      )}
    </>
  );
};

RestoreOrderButton.propTypes = {
  barcode: PropTypes.number.isRequired,
  hideRow: PropTypes.func.isRequired,
};

export default RestoreOrderButton;
