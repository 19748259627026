import {
  Dialog, DialogActions, DialogTitle, Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import ApiClient from '../../../ApiClient/ApiClient';
import { sortState } from '../../../_constants/OrderStatusConst';
import '../../Dialogs/Dialog.scss';
import fetchStatus from '../../../_constants/fetchStatus';
import errorHelper from '../../../_constants/errorHelper';
import useSortContracts from '../useSortContracts';

const DeleteOrderDialog = ({
  barcode, open, onClose, hideRow
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const [message, setMessage] = useState('');
  const error = status === fetchStatus.REJECTED;
  const fulFilled = status === fetchStatus.FULFILLED;
  const loading = status === fetchStatus.PENDING;

  const contracts = useSortContracts();

  const handleDeleteService = async () => {
    setStatus(fetchStatus.PENDING);
    await ApiClient.SortingChangeOrderStatus(barcode, sortState.CANCELLED, contracts)
      .then((res) => {
        if (res?.isSuccess) {
          setStatus(fetchStatus.FULFILLED);
          hideRow(true);
        } else {
          throw new Error(res?.statusMessage || errorHelper.common);
        }
      })
      .catch((e) => {
        setStatus(fetchStatus.REJECTED);
        setMessage(e.message);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} className="dialog">
      {!fulFilled && !error && (
        <>
          <DialogTitle className="dialog__title">
            {t('Dialog.CancelOrder')}
          </DialogTitle>
          <DialogActions>
            <Button
              disabled={loading}
              onClick={onClose}
              className="dialog__cancel dialog__btn"
            >
              {t('Common.No')}
            </Button>
            <Button
              disabled={loading}
              onClick={handleDeleteService}
              className="dialog__submit dialog__btn"
            >
              {t('Common.Yes')}
            </Button>
          </DialogActions>
        </>
      )}
      {fulFilled && (
        <>
          <DialogTitle className="dialog__title">
            {t('Dialog.CancelOrderSuccess')}
          </DialogTitle>
          <DialogActions>
            <Button onClick={onClose} className="dialog__submit dialog__btn">
              {t('Common.Ok')}
            </Button>
          </DialogActions>
        </>
      )}
      {error && (
        <>
          <DialogTitle className="dialog__title dialog__error">
            {message}
          </DialogTitle>
          <DialogActions>
            <Button onClick={onClose} className="dialog__submit dialog__btn">
              {t('Common.Ok')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

DeleteOrderDialog.propTypes = {
  barcode: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hideRow: PropTypes.func.isRequired,
};

export default DeleteOrderDialog;
