function formatDate(date) {
  try {
    // default format yyyyMMdd
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);

    return `${day}.${month}.${year}`;
  } catch (error) {
    return 'Ошибка';
  }
}

export default formatDate;
