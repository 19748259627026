import { useState } from 'react';
import fetchStatus from '../../_constants/fetchStatus';

const useFetchStatus = () => {
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const [message, setMessage] = useState('');

  const error = status === fetchStatus.REJECTED;
  const fulFilled = status === fetchStatus.FULFILLED;
  const loading = status === fetchStatus.PENDING;
  const idle = status === fetchStatus.IDLE;

  return {
    status,
    setStatus,
    message,
    setMessage,
    error,
    fulFilled,
    loading,
    idle,
    fetchStatus,
  };
};

export default useFetchStatus;
