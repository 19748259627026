import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../ApiClient/ApiClient';
import fetchStatus from '../_constants/fetchStatus';

const initialState = {
  initStatus: fetchStatus.IDLE,
  contracts: [],
//   discountTypes: [],
  timePeriodTypes: [],
  prefixes: [],
};

const fetchToInitCreateDisountCard = createAsyncThunk(
  'initCreateDiscountCardData',
  async (_, { rejectWithValue }) =>
    ApiClient.InitCreateDiscountCard().then((res) => {
      if (res?.isSuccess) {
        return res.initDiscountCard;
      }
      throw rejectWithValue();
    })
);

const initCreateDiscountCard = createSlice({
  name: 'initCreateDiscountCardData',
  initialState,
  extraReducers: {
    [fetchToInitCreateDisountCard.pending]: (state) => {
      state.initStatus = fetchStatus.PENDING;
    },
    [fetchToInitCreateDisountCard.fulfilled]: (state, action) => {
      const { contracts, timePeriodTypes, prefixes } = action.payload;
      state.initStatus = fetchStatus.FULFILLED;
      state.contracts = contracts;
      state.timePeriodTypes = timePeriodTypes;
      state.prefixes = prefixes;
    },
    [fetchToInitCreateDisountCard.rejected]: (state) => {
      state.initStatus = fetchStatus.REJECTED;
    },
  },
});

export { fetchToInitCreateDisountCard };
export default initCreateDiscountCard.reducer;
