import {
  Button,
  Dialog,
  useMediaQuery,
  DialogTitle,
  ListItemText,
  ListItem,
  List,
  withStyles,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export const LangDialog = ({ open, setOpen }) => {
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [t, i18n] = useTranslation();

  const handleChangeLanguage = (newLang) => {
    let url;
    const selectedLang = i18n.language;
    if (selectedLang === newLang) {
      return setOpen(!open);
    }

    const newLocation = selectedLang === 'ru'
      ? location.pathname.substring(0)
      : location.pathname.substring(3);

    if (newLang === 'ru') {
      url = `${newLocation}`;
    } else {
      url = `/${newLang}${newLocation}`;
    }
    history.push(url);
    i18n.changeLanguage(newLang);
    return setOpen(!open);
  };
  return (
    <Dialog open={open} onClose={() => setOpen(!open)} fullScreen={fullScreen}>
      <DialogTitle color="primary">{t('ChangeLanguage')}</DialogTitle>
      <List>
        <ListItem button onClick={() => handleChangeLanguage('ru')}>
          <ListItemText>Русский</ListItemText>
        </ListItem>
        <ListItem button onClick={() => handleChangeLanguage('be')}>
          <ListItemText>Белорусский</ListItemText>
        </ListItem>
        <ListItem button onClick={() => handleChangeLanguage('en')}>
          <ListItemText>English</ListItemText>
        </ListItem>
      </List>
    </Dialog>
  );
};

LangDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const DialogButton = withStyles({
  root: {
    fontWeight: 700,
  },
})(Button);

export const ChangeLanguageButton = () => {
  const [Open, setOpen] = useState(false);
  const [t] = useTranslation();

  return (
    <>
      <DialogButton onClick={() => setOpen(!Open)} color="primary">
        {t('ChangeLanguage')}
      </DialogButton>
      <LangDialog open={Open} setOpen={setOpen} />
    </>
  );
};
