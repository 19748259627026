import { AppRoute } from '../../../const/app-routes';
import { Role } from '../../../_constants/Roles';

const routes = [
  {
    route: AppRoute.AdminRoutes.Discount,
    label: 'Nav.Discount',
    roles: [Role.Admin, Role.Manager, Role.Sorter],
  },
  {
    route: AppRoute.AdminRoutes.Contracts,
    label: 'Nav.Contracts',
    roles: [Role.Admin],
  },
  {
    route: AppRoute.AdminRoutes.Users,
    label: 'Nav.Users',
    roles: [Role.Admin],
  },
  {
    route: AppRoute.AdminRoutes.Barcode,
    label: 'Nav.Barcode',
    roles: [Role.Admin],
  },
  {
    route: AppRoute.AdminRoutes.MailManager,
    label: 'Nav.MailManager',
    roles: [Role.Admin],
  },
  {
    route: AppRoute.AdminRoutes.Sync,
    label: 'Nav.Sync',
    roles: [Role.Admin, Role.Manager, Role.Sorter],
  },
  {
    route: AppRoute.AdminRoutes.Promotion,
    label: 'Nav.Promotion',
    roles: [Role.Admin],
  },
  {
    route: AppRoute.NewsRoutes.Articles,
    label: 'Nav.News',
    roles: [Role.Admin, Role.Manager, Role.Sorter],
  },
];

export default routes;
