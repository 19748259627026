import { createSlice } from '@reduxjs/toolkit';
import Contract from '../_enchancers/Contract';

const initialState = {
  value: null,
  clientUnit: null,
  contracts: [],
};

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setContract(state, action) {
      const { contract } = Contract.getClientUnitsByContract(
        action.payload,
        false
      );

      state.value = contract;
      state.clientUnit = null;
    },
    setClientUnit(state, action) {
      state.clientUnit = action.payload;
    },
    setUserContracts(state, action) {
      state.contracts = action.payload;
      if (action.payload?.length === 1) {
        state.value = action.payload[0];
      }
    },
    setContractAndClientUnit(state, action) {
      const { contract, clientUnit } = action.payload;
      if (contract) {
        state.value = contract;
      }
      if (clientUnit) {
        state.clientUnit = clientUnit;
      }
    },
  },
});

export const {
  setContract,
  setClientUnit,
  setUserContracts,
  setContractAndClientUnit,
} = contractSlice.actions;
export default contractSlice.reducer;
