import {
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import fetchStatus from '../../../_constants/fetchStatus';
import useDebounce from '../../../_helpers/scripts/useDebounce';
import { useIsMount } from '../../../_helpers/scripts/useIsMout';
import {
  disableSearch,
  fetchBySearchServices,
  setStatus,
} from '../../../_reducers/serviceReducer';
import { ReactComponent as SearchIcon } from '../../Inputs/img/search.svg';
import { ReactComponent as CloseIcon } from '../../Inputs/img/close.svg';
import useApiContracts from '../../Contract/useApiContracts';

const ServiceSearch = () => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();
  const Contracts = useApiContracts();
  const isMount = useIsMount();
  const status = useSelector((state) => state.service.status);
  const error = status === fetchStatus.REJECTED;
  const search = useSelector((state) => state.service.search);

  const handleChange = (e) => setInputValue(e.currentTarget.value);

  const debouncedInputValue = useDebounce(inputValue, 500);

  useEffect(() => {
    const SearchAsync = async () => {
      dispatch(setStatus(fetchStatus.PENDING));
      let token;

      if (executeRecaptcha) {
        token = await executeRecaptcha();
      }
      dispatch(
        fetchBySearchServices({
          term: debouncedInputValue,
          token,
          Contracts,
        })
      );
    };

    if (!isMount) {
      if (debouncedInputValue.length > 2) {
        SearchAsync();
      } else if (search) {
        dispatch(disableSearch());
      }
    }
  }, [debouncedInputValue]);

  useEffect(() => () => {
    if (search) {
      dispatch(disableSearch());
    }
  }, []);

  const isDefault = inputValue === '';
  const helperText = inputValue?.length !== 0 && inputValue?.length < 3
    ? t('Search.Warning')
    : '';

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('xs'));
  const label = sm ? t('Input.SearchPlaceholder') : t('CreateOrder.Search');

  const handleClear = () => {
    setInputValue('');
  };

  return (
    <TextField
      helperText={helperText}
      label={label}
      name="services"
      error={error}
      value={inputValue}
      onChange={handleChange}
      className={clsx('filled-input')}
      fullWidth
      variant="filled"
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <>
            {isDefault ? (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ) : null}
            {!isDefault ? (
              <InputAdornment position="end">
                <IconButton color="primary" onClick={handleClear} size="small">
                  <CloseIcon width="20px" height="20px" />
                </IconButton>
              </InputAdornment>
            ) : null}
          </>
        ),
      }}
    />
  );
};

export default ServiceSearch;
