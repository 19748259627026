import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const DialogBarcodeItem = ({ order }) => {
  if (order) {
    return (
      <p
        className={clsx(
          'import__list-item',
          order.isSuccess
            ? 'import__list-item_success'
            : 'import__list-item_error'
        )}
      >
        {`${order.barcode} — ${order.statusMessage}`}
      </p>
    );
  }
  return null;
};

DialogBarcodeItem.propTypes = {
  order: PropTypes.shape({
    isSuccess: PropTypes.bool,
    barcode: PropTypes.number,
    statusMessage: PropTypes.string,
  }).isRequired,
};

export default DialogBarcodeItem;
