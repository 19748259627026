import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../ApiClient/ApiClient';
import errorHelper from '../_constants/errorHelper';
import fetchStatus from '../_constants/fetchStatus';

const initialState = {
  status: fetchStatus.IDLE,
  message: '',
  initInfo: null,
};

const fetchByInitDiscountFilterData = createAsyncThunk(
  'initDiscountCardFilterData/fetch',
  async (_, thunk) =>
    ApiClient.InitSearchDiscountCardByFilters()
      .then((res) => {
        if (res) {
          if (res.isSuccess) {
            return res.initInfo;
          }
          return new Error(res.statusMessage);
        }
        throw new Error(errorHelper.common);
      })
      .catch((e) => thunk.rejectWithValue(e.message))
);

const searchCardsByFilterReducer = createSlice({
  name: 'initDiscountCardFilterData',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = fetchStatus.IDLE;
    },
  },
  extraReducers: {
    [fetchByInitDiscountFilterData.pending]: (state) => {
      state.status = fetchStatus.PENDING;
    },
    [fetchByInitDiscountFilterData.fulfilled]: (state, action) => {
      state.status = fetchStatus.FULFILLED;
      state.initInfo = action.payload;
    },
    [fetchByInitDiscountFilterData.rejected]: (state, action) => {
      state.status = fetchStatus.REJECTED;
      state.message = action.payload;
    },
  },
});

export { fetchByInitDiscountFilterData };
export const { setIdleStatus } = searchCardsByFilterReducer.actions;
export default searchCardsByFilterReducer.reducer;
