import { makeStyles, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import StyledTableRow from './StyledTableRow';

const styles = makeStyles((theme) => ({
  root: {
    '& :first-child': {
      borderBottomLeftRadius: 11,
    },
    '& :last-child': {
      borderBottomRightRadius: 11,
    },
  },
}));

const StyledTableHead = ({ children }) => {
  const classes = styles();
  return (
    <TableHead>
      <StyledTableRow classes={{ root: classes.root }}>{children}</StyledTableRow>
    </TableHead>
  );
};

export default StyledTableHead;
