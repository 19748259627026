import { createSelector } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ApiClient from '../../../ApiClient/ApiClient';
import { AppRoute } from '../../../const/app-routes';
import fetchStatus from '../../../_constants/fetchStatus';
import formatBarcode from '../../../_helpers/formatBarcode';
import useLangRoute from '../../../_helpers/scripts/useLangRoute';
import { setCreatedDate } from '../../../_reducers/basketReducer';
import { setBarcode } from '../../../_reducers/directionReducer';
import useApiContracts from '../../Contract/useApiContracts';
import AlertDialog from '../../Dialogs/AlertDialog';
import OrderForm from './OrderForm';

const currentPatient = (state) => state.basket.patient;
const initialPatient = (state) => state.basket.order.patient;

const getPatient = createSelector([currentPatient, initialPatient], (sp, ip) => (sp.id !== ip.id ? sp.id : null));

const initialServices = (state) => state.basket.order.services;
const currentServices = (state) => state.basket.serviceCodes;

const getServices = createSelector(
  [initialServices, currentServices],
  (is, cs) => {
    if (JSON.stringify(is) !== JSON.stringify(cs)) {
      return cs;
    }
    return null;
  }
);

const UpdateOrder = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(fetchStatus.IDLE);
  const [message, setMessage] = useState();
  const langPath = useLangRoute();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const contracts = useApiContracts();

  const editedOrder = useSelector((state) => state.basket.order);
  const services = useSelector(getServices);
  const patientId = useSelector(getPatient);

  const handleClose = () => setStatus(fetchStatus.IDLE);

  const UpdateOrderAsync = async (formData, dirtyFields) => {
    const propertiesToUpdate = {};
    let token;
    try {
      const {
        commissionaire, remarks, barcode, ...extraFields
      } = formData;

      if (patientId) {
        propertiesToUpdate.patientId = patientId;
      }
      if (services) {
        propertiesToUpdate.serviceCodes = services;
      }

      const dirtyFieldsnames = Object.keys(dirtyFields);

      if (dirtyFieldsnames.includes('remarks')) {
        propertiesToUpdate.remark = remarks;
      }

      if (dirtyFieldsnames.includes('barcode') && barcode) {
        propertiesToUpdate.barcode = formatBarcode(barcode);
      }

      if (dirtyFieldsnames.includes('commissionaire')) {
        propertiesToUpdate.commissionaireId = commissionaire.id;
      }

      if (dirtyFieldsnames.includes('Online promocode')) {
        propertiesToUpdate.discountCardNo = extraFields['Online promocode'];
      }

      const extraFieldsEntries = Object.entries(extraFields).filter(
        ([fieldName]) => dirtyFieldsnames.includes(fieldName)
      );
      if (extraFieldsEntries.length > 0) {
        propertiesToUpdate.orderExtraField = {};

        extraFieldsEntries.forEach(([name, value]) => {
          propertiesToUpdate.orderExtraField[name] = value;
        });
      }

      token = await executeRecaptcha();
    } catch (e) {
      setMessage(e.message);
      setStatus(fetchStatus.REJECTED);
      return false;
    }

    return ApiClient.UpdateOrder(
      propertiesToUpdate,
      editedOrder.orderId,
      token,
      contracts
    )
      .then((res) => {
        if (res.isSuccess) {
          dispatch(
            setBarcode(formatBarcode(formData.barcode) || editedOrder.barcode)
          );
          setStatus(fetchStatus.FULFILLED);
          dispatch(setCreatedDate(new Date()));
        } else {
          throw new Error(res.statusMessage);
        }
      })
      .catch((e) => {
        setMessage(e.message);
        setStatus(fetchStatus.REJECTED);
      });
  };

  return (
    <>
      <OrderForm onSubmit={UpdateOrderAsync} />
      {status === fetchStatus.FULFILLED && (
        <Redirect
          to={{
            pathname: langPath(AppRoute.CreateOrderSuccess),
          }}
          push
        />
      )}
      <AlertDialog
        open={status === fetchStatus.REJECTED}
        onClose={handleClose}
        message={message}
        error
      />
    </>
  );
};

export default UpdateOrder;
