import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const OrderPrice = () => {
  const { t } = useTranslation();
  const price = useSelector((state) => state.basket.price);
  const workingDays = useSelector((state) => state.basket.workingDays);

  return (
    <div className="order-info">
      <div className="order-info__grid">
        <div className="order-info__grid-item">
          <h5 className="order-info__label">
            {t('CreateOrder.Basket.OrdersDeadline')}
          </h5>
          <p className="order-info__value">
            {t('CreateOrder.Basket.days.key', { count: workingDays })}
          </p>
        </div>
        <div className="order-info__grid-item">
          <h5 className="order-info__label">{t('CreateOrder.Basket.Price')}</h5>
          <p className="order-info__value">{price}</p>
        </div>
      </div>
    </div>
  );
};

export default OrderPrice;
