import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../ApiClient/ApiClient';
import errors from '../_constants/errorHelper';
import fetchStatus from '../_constants/fetchStatus';

const empty = 'EMPTY';

const initialState = {
  previousBody: null,
  totalCount: 0,
  loading: false,
  orders: [],
  previousContracts: null,
  message: '',
  pageSize: 20,
  page: 0,
  order: 'asc',
  orderBy: 'OrderId',
  status: fetchStatus.IDLE,
};

const fetchByResults = createAsyncThunk(
  'sort/fetchByResults',
  async (data, { rejectWithValue }) => ApiClient.Results(data.body, data.reCaptchaToken, data.formContracts)
    .then((res) => {
      if (res.orders && res.orders.length > 0) {
        return res;
      }
      return rejectWithValue({ type: empty, message: errors.ordersNotFound });
    })
    .catch(() => rejectWithValue({ type: empty, message: errors.ordersNotFound }))
);

const resultsReducer = createSlice({
  name: 'results',
  initialState,
  reducers: {
    changePageSize(state, action) {
      state.pageSize = action.payload;
      state.page = 0;
    },
    changePage(state, action) {
      state.page = action.payload;
    },
    setPreviousBody(state, action) {
      state.previousBody = action.payload;
    },
    setPrevContracts(state, action) {
      state.previousContracts = action.payload;
    },
    setOrderState(state, action) {
      state.order = action.payload.order;
      state.orderBy = action.payload.orderBy;
      state.page = 0;
    },
    setOrderBy(state, action) {
      state.orderBy = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    resetOrders(state) {
      state.orders = [];
      state.status = fetchStatus.IDLE;
      state.message = '';
      state.page = 1;
    },
  },
  extraReducers: {
    [fetchByResults.pending]: (state) => {
      state.status = fetchStatus.PENDING;
    },
    [fetchByResults.fulfilled]: (state, action) => {
      state.totalCount = action.payload.totalCount;
      state.orders = action.payload.orders;
      state.status = fetchStatus.FULFILLED;
    },
    [fetchByResults.rejected]: (state, action) => {
      state.status = fetchStatus.REJECTED;
      state.orders = [];
      state.message = action.payload.message;
    },
  },
});

export const {
  changePageSize,
  changePage,
  setPreviousBody,
  setPrevContracts,
  setOrderState,
  setStatus,
  resetOrders,
} = resultsReducer.actions;
export { fetchByResults };
export default resultsReducer.reducer;
