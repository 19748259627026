import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { AppRoute } from '../const/app-routes';
import { matchedRoute } from './func';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  const currentUser = useSelector((state) => state.userReducer.user);
  const userRoleId = useSelector((state) => state.userReducer.userRoles?.id);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!currentUser) {
          return (
            <Redirect
              to={{
                pathname: matchedRoute(i18n.language, AppRoute.Login),
                state: { from: location },
              }}
            />
          );
        }
        if (roles.length !== 0 && roles.indexOf(userRoleId) === -1) {
          return (
            <Redirect
              to={{
                pathname: matchedRoute(i18n.language, AppRoute.News),
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  roles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.bool,
  ]),
};

PrivateRoute.defaultProps = {
  roles: [],
};

export default PrivateRoute;
