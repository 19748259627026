import i18n from '../i18n';

const genders = [
  { label: 'Input.Male', value: 'M' },
  { label: 'Input.Female', value: 'F' },
];

const gendersWithNeutrals = [
  ...genders,
  { label: 'Input.Uncertain', value: -1 },
];

const GetGenderLocalizedLabel = (val) => {
  if (val === 'М' || val === 'M' || val === 1) {
    return i18n.t('Input.Male');
  }
  if (val === 'F' || val === 'Ж' || val === 2) {
    return i18n.t('Input.Female');
  }
  return 'Error';
};

const male = 'M';
const female = 'F';
const uknown = 3;

const all = new Map([
  [male, 1],
  [female, 2],
  [uknown, 3],
  [null, 3],
]);

const genderHelper = {
  male,
  female,
  uknown,
  all,
  genders,
  gendersWithNeutrals,
  GetGenderLocalizedLabel,
};

export default genderHelper;
