import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';

import { AppRoute } from '../../const/app-routes';
import { matchedRoute } from '../../_helpers/func';
import AdminHeader from './AdminHeader/AdminHeader';
import './Admin.scss';
import AdminSynchronization from './Synchronization/Synchronization';
import Discount from './Discount/Discount';
import PrivateRoute from '../../_helpers/PrivateRoute';
import { Role } from '../../_constants/Roles';
import Fallback from '../_components/Fallback';

const PromotionPage = React.lazy(() => import('./Promotion/PromotionPage'));
const NewsPage = React.lazy(() => import('./NewsPage/NewsPage'));
const Contracts = React.lazy(() => import('./Contracts/Contracts'));
const Barcode = React.lazy(() => import('./Barcode/Barcode'));
const Staff = React.lazy(() => import('./Staff/Staff'));
const MailManager = React.lazy(() => import('./MailManager/MailManager'));
const ServiceExtensionPage = React.lazy(() => import('./ServiceExtension/ServiceExtensionPage'));

const Admin = () => {
  const [, i18n] = useTranslation();
  return (
    <div id="admin">
      <AdminHeader />
      <Suspense fallback={<Fallback />}>
        <Switch>
          <Route
            exact
            path={matchedRoute(i18n.language, AppRoute.Admin)}
            render={() => (
              <Redirect
                to={matchedRoute(i18n.language, AppRoute.AdminRoutes.Discount)}
              />
            )}
          />
          <PrivateRoute
            roles={[Role.Admin]}
            path={matchedRoute(i18n.language, AppRoute.AdminRoutes.Contracts)}
            component={Contracts}
          />
          <PrivateRoute
            roles={[Role.Admin]}
            exact
            path={matchedRoute(i18n.language, AppRoute.AdminRoutes.Users)}
            component={Staff}
          />
          <PrivateRoute
            roles={[Role.Admin]}
            exact
            path={matchedRoute(i18n.language, AppRoute.AdminRoutes.Barcode)}
            component={Barcode}
          />
          <PrivateRoute
            roles={[Role.Admin]}
            path={matchedRoute(i18n.language, AppRoute.AdminRoutes.MailManager)}
            component={MailManager}
          />
          <PrivateRoute
            roles={[Role.Admin, Role.Manager]}
            path={matchedRoute(i18n.language, AppRoute.AdminRoutes.Sync)}
            component={AdminSynchronization}
          />
          <PrivateRoute
            roles={[Role.Admin, Role.Manager]}
            path={matchedRoute(
              i18n.language,
              AppRoute.AdminRoutes.ServiceExtension
            )}
            component={ServiceExtensionPage}
          />
          <PrivateRoute
            roles={[Role.Admin, Role.Manager]}
            path={matchedRoute(i18n.language, AppRoute.AdminRoutes.Discount)}
            component={Discount}
          />
          <PrivateRoute
            roles={[Role.Admin, Role.Manager]}
            path={matchedRoute(i18n.language, AppRoute.NewsRoutes.Articles)}
            component={NewsPage}
          />
          <PrivateRoute
            roles={[Role.Admin]}
            path={matchedRoute(i18n.language, AppRoute.AdminRoutes.Promotion)}
            component={PromotionPage}
          />
          <Redirect to={AppRoute.Error404} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default Admin;
