import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hidden } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { ReactComponent as DateIcon } from '../img/date2.svg';
import './DateRange.scss';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { matchLang } from '../../../_constants/LangCode';

const DateRangeField = ({
  error,
  label,
  name,
  onChange,
  onCalendarOpen,
  outlined,
  value,
}) => {
  const [, i18n] = useTranslation();
  const [locale, setLocale] = useState('ru-RU');
  useEffect(() => {
     setLocale(matchLang(i18n.language));
  }, [i18n.language]);

  const hadError = Boolean(error?.type);

  return (
    <>
      {hadError && (
        <Hidden smDown implementation="css">
          <p
            className={clsx(
              'date-range-picker__text-helper date-range-picker__text-helper--top',
              {
                'date-range-picker__text-helper--manual':
                  error.type === 'manual',
                'date-range-picker__text-helper--validate':
                  error.type === 'validate',
              }
            )}
          >
            {error.message}
          </p>
        </Hidden>
      )}
      <p className="label-date-picker">{label}</p>
      <DateRangePicker
        locale={locale}
        onCalendarOpen={onCalendarOpen}
        label="іі"
        className={clsx(
          'date-range-picker',
          outlined && 'date-range-picker--outlined'
        )}
        onChange={onChange}
        value={value}
        name={name}
        clearIcon={null}
        format="dd.MM.yyyy"
        calendarIcon={<DateIcon />}
        rangeDivider="-"
      />
      {hadError && (
        <Hidden mdUp implementation="css">
          <p
            className={clsx('date-range-picker__text-helper', {
              'date-range-picker__text-helper--manual': error.type === 'manual',
              'date-range-picker__text-helper--validate':
                error.type === 'validate',
            })}
          >
            {error.message}
          </p>
        </Hidden>
      )}
    </>
  );
};

DateRangeField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onCalendarOpen: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onChange: PropTypes.func,
  outlined: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
};

DateRangeField.defaultProps = {
  error: {},
  label: '',
  name: undefined,
  onChange: undefined,
  onCalendarOpen: undefined,
  outlined: false,
  value: [null, null],
};

export default DateRangeField;
