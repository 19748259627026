import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ServicesTable from './ServicesTable';
import ApiClient from '../../../ApiClient/ApiClient';
import {
  setError,
  setServices,
  setStatus,
} from '../../../_reducers/serviceReducer';
import fetchStatus from '../../../_constants/fetchStatus';
import errorHelper from '../../../_constants/errorHelper';

const FavoriteServices = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const abortController = new AbortController();
  const { signal } = abortController;

  useEffect(() => () => {
    abortController.abort();
  }, []);

  const getServicesAsync = async (contracts, pageNumber, pageSize) => {
    dispatch(setStatus(fetchStatus.PENDING));
    await ApiClient.GetSelectedServices(
      pageNumber + 1,
      pageSize,
      contracts,
      signal
    )
      .then((res) => {
        if (res?.isSuccess) {
          if (res.services?.length === 0) {
            dispatch(setError(t('Info.Directory_favorites.NoServices')));
          } else {
            dispatch(
              setServices({
                services: res.services,
                totalCount: res.totalCount,
              })
            );
            dispatch(setStatus(fetchStatus.FULFILLED));
          }
        } else {
          throw new Error(res.statusMessage || errorHelper.common);
        }
      })
      .catch((e) => {
        dispatch(setStatus(fetchStatus.REJECTED));
        dispatch(setError(e.message));
      });
  };

  return <ServicesTable getServices={getServicesAsync} />;
};

export default FavoriteServices;
