import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import theme from '../_helpers/MuiTheme/MuiTheme';
import './ConfirmDialog.scss';

const ConfirmDialog = ({
  message, open, onClose, handleConfirm
}) => {
  const { t } = useTranslation();
  return (
    <MuiThemeProvider theme={theme}>
      <Dialog open={open} onClose={onClose} className="dialog confirm-dialog">
        <DialogTitle className="confirm-dialog__title">
          {t('Dialog.Warning')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirm}
            className="dialog__btn dialog__cancel"
          >
            {t('Common.Leave')}
          </Button>
          <Button onClick={onClose} className="dialog__btn dialog__submit">
            {t('Common.Stay')}
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

ConfirmDialog.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default ConfirmDialog;
