import { Grow, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ApiClient from '../../../ApiClient/ApiClient';
import errorHelper from '../../../_constants/errorHelper';
import fetchStatus from '../../../_constants/fetchStatus';
import useContract from '../../Contract/useContract';
import StyledTextField from '../../Inputs/StyledTextField';

const ContracrAdditionalField = () => {
  const { t } = useTranslation();
  const { currentContract } = useContract();
  const fields = useSelector((state) => state.basket.order?.orderExtraFields);
  const isEditOrder = useSelector((state) => state.basket.editOrder || state.basket.isPreOrder);

  const { control, errors, setValue } = useFormContext();
  const [status, setStatus] = useState(fetchStatus.PENDING);
  const [message, setMessage] = useState('');
  const [additionalFields, setAdditionalFields] = useState([]);

  useEffect(() => {
    const GetContractAdditionalFieldAsync = async () => {
      if (!currentContract) {
        setMessage(t('Sort.NoContract'));
        setStatus(fetchStatus.REJECTED);
      } else {
        await ApiClient.ContractAdditionalField({ Contracts: currentContract.id })
          .then((res) => {
            if (res.isSuccess) {
              if (res.additionalFields.length > 0) {
                setAdditionalFields(res.additionalFields);
              }
              setStatus(fetchStatus.FULFILLED);
            } else {
              throw new Error(res.statusMessage);
            }
          })
          .catch((e) => {
            setMessage(e.message);
            setStatus(fetchStatus.REJECTED);
          });
      }
    };
    if (status === fetchStatus.PENDING) {
      GetContractAdditionalFieldAsync();
    }
  }, [status]);

  useEffect(() => {
    if (isEditOrder && fields && status === fetchStatus.FULFILLED) {
      Object.entries(fields).forEach(([name, value]) => {
        setValue(name, value, { shouldDirty: false });
      });
    }
  }, [status, fields, isEditOrder]);

  if (status === fetchStatus.PENDING) {
    return (
      <Typography gutterBottom align="center" variant="h6" color="primary">
        {t('CreateOrder.Ordering.AdditionalFieldsLoading')}
      </Typography>
    );
  }

  if (status === fetchStatus.FULFILLED) {
    return additionalFields
      .filter((i) => i.activeYN)
      .map((field, index) => (
        <Grow in timeout={{ enter: 500 + 400 * index }}>
          <div key={field.id} className="order-form__item">
            <Controller
              control={control}
              name={field.fieldName}
              rules={{
                required: field.mandatoryYN ? errorHelper.required : undefined,
              }}
              defaultValue={null}
              render={({ value, onChange }) => (
                <StyledTextField
                  variant="filled"
                  fullWidth
                  required={field.mandatoryYN ? true : undefined}
                  value={value}
                  error={Boolean(errors[field.fieldName]?.message)}
                  helperText={errors[field.fieldName]?.message}
                  onChange={onChange}
                  label={field.fieldName}
                  CustomTextHelperPosition="absolute"
                  multiline
                />
              )}
            />
          </div>
        </Grow>
      ));
  }

  if (status === fetchStatus.REJECTED) {
    return (
      <Typography align="center" gutterBottom variant="h6" color="error">
        {message}
      </Typography>
    );
  }

  return null;
};

export default ContracrAdditionalField;
