import { CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';

const BaseInputSelect = ({
  name,
  id,
  label,
  children,
  onChange,
  value,
  card,
  multiple,
  error,
  loading,
  disabled,
  className,
  ...props
}) => {
  const hasError = Boolean(error?.message);
  return (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      select
      onChange={onChange}
      disabled={disabled}
      value={value}
      error={hasError}
      fullWidth
      helperText={error?.message}
      variant="filled"
      InputProps={{
        disableUnderline: true,
        endAdornment: loading ? (
          <InputAdornment position="end">
            <CircularProgress className="filled-input__circular-progress" color="primary" style={{ width: 20, height: 20, }} />
          </InputAdornment>
        ) : undefined,
        className: clsx('filled-input__input', {
          'filled-input__input--card': card,
        }),
      }}
      InputLabelProps={{
        className: clsx('filled-input__label', {
          'filled-input__label--card': card,
        }),
      }}
      className={`filled-input ${className}`}
      SelectProps={{
        multiple,
        className: clsx('filled-input__input filled-input__icon', {
          'filled-input__input--card': card,
        }),
        MenuProps: {
          className: 'filled-input__paper',
        },
      }}
      label={label}
      name={name}
      id={id}
    >
      {children}
    </TextField>
  );
};

BaseInputSelect.propTypes = {
  card: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  multiple: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
};

BaseInputSelect.defaultProps = {
  children: [],
  card: false,
  disabled: undefined,
  multiple: undefined,
  name: undefined,
  loading: false,
  id: undefined,
  error: {},
  className: undefined,
};

export default BaseInputSelect;
