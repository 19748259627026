import { Paper, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import fetchStatus from '../../_constants/fetchStatus';
import { sortState } from '../../_constants/OrderStatusConst';
import Contract from '../../_enchancers/Contract';
import {
  setStatus,
  setPreviousBody,
  fetchByOrders,
  reset,
} from '../../_reducers/newSortReducer';
import formatDate from '../Admin/Discount/_helpers/formatDate';
import AccompanyingDirectionButton from '../Direction/AccompanyingDirectionButton';
import ServiceFormGroup from '../ServiceFormGroup/ServiceFormGroup';
import OrderImport from '../SortingOrderImport/OrderImport';
import SortState from './SortState';
import SortTable from './SortTable';
import { AllowedRoles, Role } from '../../_constants/Roles';
import './Sort.scss';
import CustomProgress from '../_components/CustomProgress';
import RBAC from '../../_helpers/RBAC';

const Sorting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const message = useSelector((state) => state.sort.message);
  const status = useSelector((state) => state.sort.status);
  const isAsc = useSelector((state) => state.sort.order === 'asc');
  const orderingParameterName = useSelector(
    (state) => state.sort.orderingParameterName
  );

  const loading = status === fetchStatus.PENDING;
  const success = status === fetchStatus.FULFILLED;
  const error = status === fetchStatus.REJECTED;

  const hadTabs = useSelector(
    (state) =>
      AllowedRoles.Outsource.indexOf(state.userReducer?.userRoles?.id) !== -1
  );

  const GetFormData = async (formData) => {
    const {
      cabinetOrderStatus,
      barcode,
      lastName,
      firstName,
      createdDate,
      resultDate,
      contract,
      clientUnit,
    } = formData;

    try {
      const createdDateStart = formatDate(createdDate[0]);
      const createdDateEnd = formatDate(createdDate[1]);

      const importedDateStart = formatDate(resultDate[0]);
      const importedDateEnd = formatDate(resultDate[1]);

      const body = {
        sortingState: cabinetOrderStatus,
        clientUnitId: clientUnit ? clientUnit.id : null,
        lastName,
        firstName,
        createdDateStart,
        createdDateEnd,
        importedDateStart,
        importedDateEnd,
      };

      if (barcode) {
        body.barcode = barcode;
      }

      let formContracts;

      formContracts = {
        Contracts: Contract.getContractId(contract.id),
      };

      if (clientUnit) {
        formContracts = {
          ...formContracts,
          ClientUnit: Contract.getClientUnitId(clientUnit.id, contract),
        };
      }
      const params = {
        body,
        contracts: formContracts,
        submited: new Date().toString(),
        sortingState: cabinetOrderStatus,
      };
      dispatch(setPreviousBody(params));
    } catch (e) {
      dispatch(setStatus({ status: fetchStatus.REJECTED, message: e.message }));
    }
  };

  const handleSubmit = (formData) => {
    dispatch(setStatus({ status: fetchStatus.PENDING }));
    GetFormData(formData);
  };

  const pageSize = useSelector((state) => state.sort.pageSize);
  const page = useSelector((state) => state.sort.page);
  const body = useSelector((state) => state.sort.body);
  const submited = useSelector((state) => state.sort.submited);
  const contracts = useSelector((state) => state.sort.contracts);

  useEffect(() => {
    const getOrdersAsync = async () => {
      dispatch(setStatus({ status: fetchStatus.PENDING }));

      let token;
      if (executeRecaptcha) {
        token = await executeRecaptcha();
      } else {
        dispatch(
          setStatus({
            status: fetchStatus.REJECTED,
            message: t('Errors.CaptchaNotAvalible'),
          })
        );
        return;
      }

      const params = {
        body: {
          ...body,
          pageSize,
          pageNumber: page + 1,
          orderingParameterName,
          isAsc,
        },
        reCaptchaToken: token,
        contracts,
      };

      dispatch(fetchByOrders(params));
    };

    if (submited) {
      getOrdersAsync();
    }
  }, [submited, page, pageSize, orderingParameterName, isAsc]);

  useEffect(() => () => dispatch(reset()), []);

  return (
    <main id="sortId" className="services sort">
      <Paper
        className={clsx('results__paper', hadTabs && 'sort__paper--with-tabs')}
        aria-busy={loading}
      >
        <div className="results__field-group">
          <ServiceFormGroup sorting setFormData={handleSubmit} />
        </div>
        {success && <SortTable />}
      </Paper>
      {loading && <CustomProgress />}
      {error && (
        <Box textAlign="center" p={3}>
          <Typography variant="h6" color="error">
            {message}
          </Typography>
        </Box>
      )}
      <RBAC allowedRoles={[Role.Admin, Role.Manager, Role.Nurse, Role.Nurse, Role.Sorter, Role.User]}>  
        {success && (
          <div className="sort__button-group">
            <SortState
              allowedState={[sortState.NEW, sortState.IMPORTED, sortState.SENT]}
            >
              <AccompanyingDirectionButton className="sort__acc-direction" />
            </SortState>
            <SortState allowedState={[sortState.NEW]}>
              <OrderImport />
            </SortState>
          </div>
        )}
      </RBAC>
    </main>
  );
};

export default Sorting;
