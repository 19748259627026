import {
  Button,
  Dialog,
  DialogActions,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import './ErrorDialog.scss';
import StyledDialogTitle from '../StyledDialogTitle';

const ErrorDialog = ({ open, onClose, message }) => (
  <Dialog
    open={open}
    onClose={onClose}
    className="dialog"
    aria-labelledby="modal-error-title"
  >
    <StyledDialogTitle error title={message} />
    <DialogActions>
      <Button className="dialog__btn dialog__submit" onClick={onClose}>
        Ок
      </Button>
    </DialogActions>
  </Dialog>
);

ErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
};

ErrorDialog.defaultProps = {
  message: '',
};

export default ErrorDialog;
