import { ListItem, ListItemIcon } from '@material-ui/core';
import React from 'react';

import { useSelector } from 'react-redux';
import { ReactComponent as UserIcon } from '../../_icons/role.svg';

const UserNameLabel = () => {
  const userName = useSelector((state) => state.userReducer.Name);

  return (
    <ListItem
      title={userName}
      id="menu-user-name"
      className="menu-list__user menu-list__tooltip"
    >
      <ListItemIcon title={userName} className="menu-list__user-icon">
        <UserIcon />
      </ListItemIcon>
      <p className="menu-list__user-text">{userName}</p>
    </ListItem>
  );
};

export default UserNameLabel;
