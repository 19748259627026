import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Box, Button, Paper } from '@material-ui/core';
import BasketTable from './BasketTable';
import TubesTable from './TubesTable/TubesTable';
import './OrderInfo.scss';

const Basket = ({ handleNext }) => {
  const { t } = useTranslation();
  const hadServices = useSelector((state) => state.basket.addedServices.length);
  const price = useSelector((state) => state.basket.price);
  const workingDays = useSelector((state) => state.basket.workingDays);
  const biometerials = useSelector((state) => state.basket.biomaterials);

  return (
    <Box
      display="flex"
      justifyContent="center"
      className="service-list basket basket__sticky"
    >
      <Paper className="service-list__paper basket__pt">
        <div
          className={clsx(
            'service-list__wrapper service-list__wrap-pad',
            'service-list__wrapper--empty'
          )}
        >
          <div className="flex-center">
            <h3 className="service-list__title">
              {t('CreateOrder.Basket.Title')}
            </h3>
          </div>
        </div>
        {!hadServices && (
          <div className="basket__epmty">
            <h3 className="basket__epmty-title">
              {t('CreateOrder.Basket.Empty')}
            </h3>
            <p className="basket__epmty-sub-title">
              {t('CreateOrder.Basket.EmptyText')}
            </p>
          </div>
        )}
        <BasketTable />
        <TubesTable biometerials={biometerials} />
        <div
          className={clsx('service-list__wrapper  order-info ', {
            'service-list__border-radius': hadServices,
            basket__gradient: hadServices,
          })}
        >
          <div
            className={clsx(
              'order-info__grid',
              !hadServices && 'order-info__grid--padding'
            )}
          >
            <div className="order-info__grid-item">
              <p className="order-info__label">
                {t('CreateOrder.Basket.OrdersDeadline')}
              </p>
              <p className="order-info__value">
                {t('CreateOrder.Basket.days.key', { count: workingDays })}
              </p>
            </div>
            <div className="order-info__grid-item">
              <p className="order-info__label">
                {t('CreateOrder.Basket.Price')}
              </p>
              <p className="order-info__value">{price}</p>
            </div>
          </div>
        </div>
      </Paper>
      <Box position="absolute" maxWidth="304px" width="100%" bottom={-24}>
        <Button
          fullWidth
          onClick={handleNext}
          disabled={!hadServices}
          variant="contained"
          color="primary"
        >
          {t('CreateOrder.Basket.SubmitButton')}
        </Button>
      </Box>
    </Box>
  );
};

Basket.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default Basket;
